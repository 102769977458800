import { Box, useTheme } from "@mui/material";
import { useContext } from "react";
import { UserContext } from "../../../user-context";
import Grid from "@mui/material/Unstable_Grid2";
import { Navigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Input from "@mui/material/Input";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { Stack } from "@mui/system";
import Checkbox from "@mui/material/Checkbox";
import ArrowWhite from "../../../Assets/Images/arrow-white.svg";
import { useMediaQuery } from "react-responsive";
import MailingList from "../../../SharedComponents/MailingList";
import Footer from "../../../SharedComponents/Footer";
import { ActionButton } from "../../../SharedComponents/Buttons";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithRedirect,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { useEffect, useState } from "react";
import { getFirestore, doc, getDoc } from "firebase/firestore";

const commonStyles = {
  pl: "4px",
  pt: "4px",
  mb: "12px",
  fontFamily: "Code Saver",
  fontStyle: "normal",
  fontWeight: "normal",
  fontSize: "16px",
  lineHeight: "145%",
  color: "blue.main",

  border: 1,
  borderColor: "lightOlive.main",
};

const SignIn = () => {
  const isDesktop = useMediaQuery({ minWidth: 1201 });
  const isMedium = useMediaQuery({ minWidth: 901 });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [needsPasswordChange, setNeedsPasswordChange] = useState(false);
  const provider = new GoogleAuthProvider();

  const user = useContext(UserContext).user;

  const signInWithGoogle = (e) => {
    const auth = getAuth();
    auth.useDeviceLanguage();

    const isDevelopment =
      process.env.NODE_ENV == 'development' ||
      window.location.hostname.match(/^mb-platform.*.web.app$/g);

    (isDevelopment
      ? signInWithPopup(auth, provider)
      : signInWithRedirect(auth, provider)
    )
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;

        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
       
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  };

  const signInWithEmail = (e) => {
    if (email === "") {
      setError("Email field cannot be empty");
      return;
    }

    if (password === "") {
      setError("Password field cannot be empty");
      return;
    }

    const auth = getAuth();

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        // ...
      })
      .catch((error) => {
        setError("Incorrect username or password");
        console.log(error);
      });
  };
  const db = getFirestore();
  const setUserAttributes = async (user) => {
    if (user) {
      const proRef = doc(db, `pros/${user.uid}/`);
      const docSnap = await getDoc(proRef);

      if (docSnap.exists()) {
        const proData = docSnap.data();

        if (proData.temporaryPassword) {
          setNeedsPasswordChange(true);
        } else {
          setLoggedIn(true);
        }
      } else {
        setLoggedIn(true);
      }
    }
  };

  useEffect(() => {
    if (user) {
      setUserAttributes(user);
    }
  }, [user]);

  return (
    <>
      {needsPasswordChange && <Navigate to="/change-password" />}
      {loggedIn && <Navigate to="/my-account" />}
      <Box>
        <Box
          width="100%"
          height="auto"
          sx={{
            display: "flex",
            justifyContent: "center",
            backgroundColor: "cream.main",
            py: "136px",
          }}
        >
          <Grid
            container
            width="100%"
            maxWidth="1220px"
            mx={isPortrait ? "20px" : "44px"}
          >
            <Grid
              item
              md={6}
              xs={12}
              sx={{
                borderRight: isMedium ? 1 : 0,

                color: "blue.main",
                pr: isMedium ? "30px" : undefined,
              }}
            >
              <Typography
                sx={{
                  whiteSpace: "pre-line",
                  color: "olive.main",
                  mb: "20px",
                }}
                display="block"
                variant="h2"
              >
                {"Sign In"}
              </Typography>
              <ActionButton
                title="Sign In With Google"
                onClick={signInWithGoogle}
              />
              <Stack width="100%" mt="68px">
                <Typography
                  sx={{
                    whiteSpace: "pre-line",
                    color: "blue.main",
                    mb: "20px",
                  }}
                  display="block"
                  variant="paragraphMono"
                >
                  {"or with email"}
                </Typography>
                <Input
                  type="text"
                  disableUnderline
                  fullWidth
                  height="40px"
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                  sx={{
                    ...commonStyles,
                  }}
                ></Input>
                <Input
                  type="password"
                  disableUnderline
                  fullWidth
                  height="40px"
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                  sx={{
                    ...commonStyles,
                  }}
                ></Input>

                <Stack direction="row" mb="20px">
                  <Checkbox />
                  <Typography
                    sx={{
                      whiteSpace: "pre-line",
                      color: "blue.main",
                      pt: "9px",
                    }}
                    display="block"
                    variant="paragraphMono"
                  >
                    {"Remember me"}
                  </Typography>
                </Stack>
                <a href="/forgot-password" style={{ marginBottom: "20px" }}>
                  <Typography
                    sx={{
                      whiteSpace: "pre-line",
                      color: "blue.main",
                    }}
                    display="inline"
                    variant="paragraphMono"
                  >
                    {"Forgot your password?"}
                  </Typography>
                </a>
                <ActionButton title="Sign In" onClick={signInWithEmail} />
                {error && (
                  <Typography
                    mt="15px"
                    sx={{
                      whiteSpace: "pre-line",
                      color: "blue.main",
                    }}
                    display="inline"
                    variant="paragraphMono"
                  >
                    {error}
                  </Typography>
                )}
              </Stack>
            </Grid>

            <Grid
              md={6}
              sx={{
                pl: isMedium ? "30px" : undefined,
                mt: !isMedium ? "40px" : undefined,
              }}
            >
              <Typography
                sx={{
                  whiteSpace: "pre-line",
                  color: "olive.main",
                  mb: "20px",
                }}
                display="block"
                variant="h2"
              >
                {"New to matriarchy build?"}
              </Typography>
              <Link width="100%" height="100%" to="/create-account">
                <ActionButton
                  title="Create a New Account"
                  linkTo="/create-account"
                />
              </Link>
            </Grid>
          </Grid>
        </Box>
        <MailingList />
        <Footer />
      </Box>
    </>
  );
};

export default SignIn;
