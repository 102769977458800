import { Box, useTheme } from "@mui/material";
import React from "react";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import Pen from "../../../../Assets/Images/pen-writing.png"
import Pencil from "../../../../Assets/Images/pencil.png"
import Paintbrushes from "../../../../Assets/Images/paint-brushes.png"
import ConstructionSite from "../../../../Assets/Images/construction-site.png"
import Hammer from "../../../../Assets/Images/hammer.png"
import ToolWall from "../../../../Assets/Images/tool-wall.png"
import { useMediaQuery } from "react-responsive";

const ProjectProfile = () => {
  const isPortrait = useMediaQuery({ orientation: 'portrait' })
  return (
    <Box
      width="100%"
      height="auto"
      display="flex"
      
      sx={{
        justifyContent: "center",
        backgroundColor: "pink.main",
        pt: "146px",
        px: isPortrait ? "20px" : "44px",
        pb:"194px",
      }}
    >
      <Grid
        container
        columnSpacing={isPortrait ? 0 : 4}
        width="100%"
        sx={{
          maxWidth: "1024px",
        }}
      >
        <Grid
          item
          xs={12}
          width="100%"
          height="auto"
          sx={{
            textAlign: "left",
            mb: "15px",

          }}
        >
          <Typography
            sx={{
              whiteSpace: "pre-line",
              mr: "7px",
              mb:"11px",
            }}
            display="block"
            variant="h2"
            color="blue.main"
          >
            {"Project Profile"}
          </Typography>
          <Box
          width="100%"
          height="1px"
          sx={{
            color:"lightOlive.main",
            borderBottom:1,
            mb:"11px",
          }}
          >

          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            width="100%"
            height="412px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundImage: `url(${Pen})`,

            }}
          ></Box>
           <Typography
            sx={{
              whiteSpace: "pre-line",
              mr: "7px",
              mt: "23px",
            }}
            display="block"
            variant="h3"
            color="olive.main"
          >
            {"DIY: Do-it-yourself"}
          </Typography>
          <Typography
            sx={{
              whiteSpace: "pre-line",
              mr: "7px",
              mt: "17px",
              mb:"17px",
            }}
            display="block"
            variant="paragraphSans"
            color="blue.main"
          >
            {"I’m looking to change my kitchen faucet and would love to ask some personalized questions about what to expect."}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            width="100%"
            height="412px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundImage: `url(${Pencil})`,
            }}
          ></Box>
           <Typography
            sx={{
              whiteSpace: "pre-line",
              mr: "7px",
              mt: "23px",
            }}
            display="block"
            variant="h3"
            color="olive.main"
          >
            {"Expert advice"}
          </Typography>
          <Typography
            sx={{
              whiteSpace: "pre-line",
              mr: "7px",
              mt: "17px",
              mb:"17px",
            }}
            display="block"
            variant="paragraphSans"
            color="blue.main"
          >
            {"I’m planning a larger remodel. Help me vet quotes, pick materials, talk about timelines and expectations."}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          width="100%"
          height="auto"
          sx={{
            textAlign: "left",
            mb: "15px",

          }}
        >
          <Typography
            sx={{
              whiteSpace: "pre-line",
              mr: "7px",
              mb:"11px",
              mt:"75px",

            }}
            display="block"
            variant="h2"
            color="blue.main"
          >
            {"Project Size"}
          </Typography>
          <Box
          width="100%"
          height="1px"
          sx={{
            color:"lightOlive.main",
            borderBottom:1,
            mb:"11px",
          }}
          >

          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            width="100%"
            height="412px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundImage: `url(${Paintbrushes})`,

            }}
          ></Box>
           <Typography
            sx={{
              whiteSpace: "pre-line",
              mr: "7px",
              mt: "23px",
            }}
            display="block"
            variant="h3"
            color="olive.main"
          >
            {"Basic"}
          </Typography>
          <Typography
            sx={{
              whiteSpace: "pre-line",
              mr: "7px",
              mt: "17px",
              mb:"17px",
            }}
            display="block"
            variant="paragraphSans"
            color="blue.main"
          >
            {"I’d like to patch a small hole in my wall, can you talk me through it."}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            width="100%"
            height="412px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundImage: `url(${ConstructionSite})`,
            }}
          ></Box>
           <Typography
            sx={{
              whiteSpace: "pre-line",
              mr: "7px",
              mt: "23px",
            }}
            display="block"
            variant="h3"
            color="olive.main"
          >
            {"Large"}
          </Typography>
          <Typography
            sx={{
              whiteSpace: "pre-line",
              mr: "7px",
              mt: "17px",
              mb:"17px",
            }}
            display="block"
            variant="paragraphSans"
            color="blue.main"
          >
            {"I’m looking to renovate my whole kitchen but don’t know where to start. I’d love to be armed with information about the process before I talk to my contractor."}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          width="100%"
          height="auto"
          sx={{
            textAlign: "left",
            mb: "15px",

          }}
        >
          <Typography
            sx={{
              whiteSpace: "pre-line",
              mr: "7px",
              mb:"11px",
              mt:"75px",

            }}
            display="block"
            variant="h2"
            color="blue.main"
          >
            {"Skill Level"}
          </Typography>
          <Box
          width="100%"
          height="1px"
          sx={{
            color:"lightOlive.main",
            borderBottom:1,
            mb:"11px",
          }}
          >

          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            width="100%"
            height="412px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundImage: `url(${Hammer})`,

            }}
          ></Box>
           <Typography
            sx={{
              whiteSpace: "pre-line",
              mr: "7px",
              mt: "23px",
            }}
            display="block"
            variant="h3"
            color="olive.main"
          >
            {"Beginner"}
          </Typography>
          <Typography
            sx={{
              whiteSpace: "pre-line",
              mr: "7px",
              mt: "17px",
              mb:"17px",
            }}
            display="block"
            variant="paragraphSans"
            color="blue.main"
          >
            {"I bought my first drill and would love to talk through safety protocol and how to use it for basic projects like shelves and hanging wall art."}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            width="100%"
            height="412px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundImage: `url(${ToolWall})`,
            }}
          ></Box>
           <Typography
            sx={{
              whiteSpace: "pre-line",
              mr: "7px",
              mt: "23px",
            }}
            display="block"
            variant="h3"
            color="olive.main"
          >
            {"Advanced"}
          </Typography>
          <Typography
            sx={{
              whiteSpace: "pre-line",
              mr: "7px",
              mt: "17px",
              mb:"17px",
            }}
            display="block"
            variant="paragraphSans"
            color="blue.main"
          >
            {"I’m getting really into woodworking. I’d like to build raised beds for my garden."}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProjectProfile;
