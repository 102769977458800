import ReactDOM from "react-dom";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useState, useMemo } from "react";
import { Box, useTheme } from "@mui/material";
import { useEffect } from "react";
import Typography from "@mui/material/Typography";
import Footer from "../../../SharedComponents/Footer";
import MailingList from "../../../SharedComponents/MailingList";
import { useMediaQuery } from "react-responsive";
import PostHeader from "../PostHeader";
import { useLocation, useParams } from "react-router-dom";

const Post = () => {
  const [posts, setPosts] = useState();
  const [post, setPost] = useState();
  const [html, setHTML] = useState();
  const { slug } = useParams();
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  useEffect(() => {
    async function loadPost() {
      const response = await fetch(
        `https://mblegacy.wpengine.com/wp-json/wp/v2/posts?slug=${slug}`
      );
      if (!response.ok) {
        // oops! something went wrong
        return;
      }
      const post = await response.json();
      post[0].content.rendered = post[0].content.rendered.replaceAll ("matriarchybuild.com", "mblegacy.wpengine.com")
      console.log (post[0].content.rendered) 
      setPost(post[0]);
    }

    loadPost();
  }, []);

  return (
    <>
      <Box width="100%" sx={{ position:"relative", zIndex:1,backgroundColor: "cream.main" }}>
        <PostHeader post={post} />

        <Box
          mx="auto"
          maxWidth="880px"
          py="88px"
          sx={{
            "& div": { ml: isPortrait ? "10px" : 0, mr: isPortrait ? "10px" : "20px", fontFamily: "BN Times Gothic",
             },
            "& table": {
              fontFamily: "BN Times Gothic",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "18px",
              lineHeight: "125%",
              
            },
            "& p": {
              fontFamily: "BN Times Gothic",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "20px",
              lineHeight: "125%",
              color: "blue.main",
            },
          }}
          dangerouslySetInnerHTML={{ __html: post?.content.rendered }}
        />
      </Box>
      <MailingList />
      <Footer />
    </>
  );
};

export default Post;
