import { Box, Dialog, useTheme } from '@mui/material';
import { colors, pinkBox } from '../../theme';
import Typography from '@mui/material/Typography';
import Input from '@mui/material/Input';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import Logo from '../../Assets/Images/logo.svg';
import ArrowPink from '../../Assets/Images/arrow-pink.svg';
import Search from '../../Assets/Images/search.svg';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { useState, useContext, useRef } from 'react';
import { UserContext } from '../../user-context';
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';
import styled from '@emotion/styled';
import {
  InstantSearch,
  Hits,
  SearchBox,
  Pagination,
  Highlight,
  ClearRefinements,
  RefinementList,
  Configure,
  connectStateResults,
} from 'react-instantsearch-dom';
import { v4 as uuidv4 } from 'uuid';

export const StyleWrapper = styled.div`
  .ais-SearchBox {
    width: 100%;
  }
  .ais-SearchBox-resetIcon {
    display: none;
  }
  .ais-SearchBox-submit {
    display: none;
  }
  .ais-SearchBox-reset {
    display: none;
  }
  .ais-SearchBox-input {

    padding-left: 4px;
    padding-top: 2px;
    font-color white;
    height: 30px;
    width: 100%;
    border:1px;
    color: white;
    font-family: "Code Saver";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17.5px;
    background-color: #2D457C;
    outline-width: 0;
    outline: none;

    
  }
  .ais-Hits-list {
    list-style-type:none;
    margin: 0;
    padding:0;
  }
}
`;
const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    nodes: [
      {
        host: 'u36otxk1qf4rmvybp-1.a1.typesense.net',
        port: '443',
        protocol: 'https',
      },
    ],
    apiKey: 'UpQOXUteZrmhmnNZSlWjr5nEDIelZchq',
  },
  additionalSearchParameters: {
    query_by: 'firstName,lastName,bio,email',
    filter_by: `verified:${true}`,
  },
});

const commonStyles = {
  border: 1,
  color: 'pink.main',
  borderColor: 'pink.main',
  display: 'flex',
  pt: '6px',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
};

const Header = () => {
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1201 });
  const isShrinkTextArea = useMediaQuery({ maxWidth: 1450, minWidth: 1202 });

  const h2StyleOverrides = {
    fontSize: isShrinkTextArea ? '30px' : '38px',
  };
  const [searchState, setSearchState] = useState({});
  let navigate = useNavigate();

  const user = useContext(UserContext);

  const [prevQuery, setPrevQuery] = useState();

  const onSearchStateChange = (searchState) => {
    if (searchState.query != prevQuery) {
      setSearchState({ ...searchState });
      setPrevQuery(searchState.query);
    }
  };

  const navigateToPro = (id) => {
    setSearchState({
      ...searchState,
      query: '',
    });
    navigate(`/pro/${id}`);
  };

  const navigationItems = 12 / 5;

  const Results = connectStateResults(
    ({ searchState }) =>
      searchState &&
      searchState.query && (
        <StyleWrapper>
          <Box
            sx={{
              position: 'relative',
              zIndex: 5,
              backgroundColor: 'white',
              boxShadow: 4,
            }}
          >
            <Box
              sx={{
                mx: '20px',
                borderBottom: 1,
                color: 'lightOlive.main',
              }}
            >
              <Typography
                pt="10px"
                pb="5px"
                sx={{
                  whiteSpace: 'pre-line',
                }}
                display="block"
                color="olive.main"
                variant="paragraphMonoSmall"
              >
                {'Pros'}
              </Typography>
            </Box>

            <Configure hitsPerPage={4} clickAnalytics={true} />
            <Hits hitComponent={Hit} />
          </Box>
        </StyleWrapper>
      )
  );

  function Hit(props) {
    var tradeList = '';

    props.hit.proTrades?.forEach((trade) => {
      if (tradeList === '') {
        tradeList = trade.displayName;
      } else {
        tradeList = tradeList.concat(', ', trade.displayName);
      }
    });

    return (
      <Box
        onClick={() => navigateToPro(props.hit.slug)}
        height="72px"
        id="header"
        sx={{
          borderBottom: 1,
          color: 'lightOlive.main',
          backgroundColor: 'white',
          mx: '20px',
          pt: '8px',
          position: 'relative',
          zIndex: 7,
          '&:hover': {
            backgroundcolor: 'pink.main',
          },
        }}
      >
        <img
          width="55px"
          height="55px"
          src={props.hit.headshot}
          align="left"
          alt={props.hit.firstName}
          style={{ marginRight: '10px', objectFit: 'cover' }}
        />
        <Stack direction="row">
          <Typography
            sx={{
              whiteSpace: 'pre-line',
              mt: '14px',
            }}
            display="block"
            color="blue.main"
            variant="h3"
            width="50%"
            cursor="pointer"
          >
            {`${props.hit.firstName} ${props.hit.lastName}`}
          </Typography>
          <Box
            width="100%"
            sx={{ mt: '10px', display: 'flex', justifyContent: 'right' }}
          >
            <Typography
              display="block"
              color="olive.main"
              variant="paragraphMono"
            >
              {tradeList}
            </Typography>
          </Box>
        </Stack>
      </Box>
    );
  }
  return (
    <Box
      height="184px"
      sx={{ position: 'relative', zIndex: 3, display: 'block', boxShadow: 4 }}
    >
      <Grid container spacing={0}>
        <Grid xs={4}>
          <Box
            height="184px"
            sx={{
              position: 'relative',
              zIndex: 3,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'pink.main',
              py: '44px',
              pl: '45px',
            }}
          >
            <Link to="/">
              <img
                src={Logo}
                width="90%"
                height="100%"
                style={{ objectFit: 'cover' }}
              />
            </Link>
          </Box>
        </Grid>
        <Grid xs={8}>
          <Box
            height="184px"
            sx={{
              backgroundColor: 'blue.main',
              pt: '20px',
              pr: '40px',
            }}
          >
            <Grid
              container
              width="100%"
              spacing={0}
              xs={10}
              sx={{
                ml: '20px',
                mr: '45px',
              }}
            >
              <Grid xs={navigationItems}>
                <Box
                  height="88px"
                  sx={{
                    ...commonStyles,
                    borderLeft: 0,
                  }}
                >
                  <Link to="/pro">
                    <Typography
                      sx={{
                        whiteSpace: 'pre-line',
                        '&:hover': {
                          color: 'white',
                        },
                        ...h2StyleOverrides,
                      }}
                      display="block"
                      variant="h2"
                      color="pink.main"
                    >
                      {'Online \n Consultations'}
                    </Typography>
                  </Link>
                </Box>
              </Grid>
              <Grid xs={navigationItems}>
                <Box
                  height="88px"
                  sx={{
                    ...commonStyles,
                    borderLeft: 0,
                  }}
                >
                  <Link to="/find-a-builder">
                    <Typography
                      sx={{
                        whiteSpace: 'pre-line',
                        '&:hover': {
                          color: 'white',
                        },
                        ...h2StyleOverrides,
                      }}
                      display="block"
                      variant="h2"
                      color="pink.main"
                    >
                      {'Find \n A Builder'}
                    </Typography>
                  </Link>
                </Box>
              </Grid>
              <Grid xs={navigationItems}>
                <Box
                  height="88px"
                  sx={{
                    ...commonStyles,
                    borderLeft: 0,
                  }}
                >
                  <Link to="/austin">
                    <Typography
                      sx={{
                        whiteSpace: 'pre-line',
                        '&:hover': {
                          color: 'white',
                        },
                        ...h2StyleOverrides,
                      }}
                      display="block"
                      variant="h2"
                      color="pink.main"
                    >
                      {'Local \n Services'}
                    </Typography>
                  </Link>
                </Box>
              </Grid>
              <Grid xs={navigationItems}>
                <Box
                  height="88px"
                  sx={{
                    ...commonStyles,
                    borderLeft: 0,
                  }}
                >
                  <Link to="/diy-inspiration">
                    <Typography
                      sx={{
                        whiteSpace: 'pre-line',
                        '&:hover': {
                          color: 'white',
                        },
                        ...h2StyleOverrides,
                      }}
                      display="block"
                      color="pink.main"
                      variant="h2"
                    >
                      {'DIY & \n Inspiration'}
                    </Typography>
                  </Link>
                </Box>
              </Grid>

              <Grid item xs={navigationItems}>
                <Box
                  height="88px"
                  sx={{
                    ...commonStyles,
                    borderLeft: 0,
                    borderRight: 0,
                  }}
                >
                  {user.loggedIn ? (
                    <Box>
                      <Link to="/my-account">
                        <Stack
                          direction="row"
                          sx={{
                            pt: '4px',
                            textAlign: 'center',
                          }}
                        >
                          <Typography
                            sx={{
                              whiteSpace: 'pre-line',
                              mr: '7px',
                              '&:hover': {
                                color: 'pink.main',
                              },
                            }}
                            display="block"
                            variant="paragraphMono"
                            color="white"
                          >
                            {'My Account'}
                          </Typography>

                          <img src={ArrowPink} />
                        </Stack>
                      </Link>
                    </Box>
                  ) : (
                    <Stack width="100%" height="88px" alignItems="stretch">
                      <Box
                        width="100%"
                        height="41px"
                        sx={{ ...pinkBox, border: 0, borderBottom: 1 }}
                      >
                        <Link width="100%" to="/sign-in">
                          <Stack
                            direction="row"
                            sx={{
                              pt: '4px',
                              textAlign: 'center',
                            }}
                          >
                            <Typography
                              sx={{
                                whiteSpace: 'pre-line',
                                mr: '7px',
                                '&:hover': {
                                  color: 'pink.main',
                                },
                              }}
                              display="block"
                              variant="paragraphMono"
                              color="white"
                            >
                              {'Sign In'}
                            </Typography>

                            <img src={ArrowPink} />
                          </Stack>
                        </Link>
                      </Box>
                      <Box
                        width="100%"
                        height="41px"
                        sx={{ ...pinkBox, border: 0 }}
                      >
                        <Link width="100%" to="/create-account">
                          <Stack
                            direction="row"
                            sx={{
                              pt: '4px',
                              textAlign: 'center',
                            }}
                          >
                            <Typography
                              sx={{
                                whiteSpace: 'pre-line',
                                mr: '7px',
                                '&:hover': {
                                  color: 'pink.main',
                                },
                              }}
                              display="block"
                              variant="paragraphMono"
                              color="white"
                            >
                              {'Sign Up'}
                            </Typography>
                            <img src={ArrowPink} />
                          </Stack>
                        </Link>
                      </Box>
                    </Stack>
                  )}
                </Box>
              </Grid>
              <Stack
                direction="row"
                width="100%"
                sx={{
                  mt: '23px',
                  mb: '12px',
                }}
              >
                <InstantSearch
                  indexName="pros"
                  searchClient={typesenseInstantsearchAdapter.searchClient}
                  searchState={searchState}
                  onSearchStateChange={(state) => onSearchStateChange(state)}
                >
                  <Box width="100%">
                    <Box
                      width="100%"
                      sx={{
                        pl: '4px',
                        pt: '2px',
                        color: 'white',
                        fontFamily: 'Code Saver',
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        fontSize: '14px',
                        lineHeight: '17.5px',
                        backgroundColor: 'blue.main',
                        border: 1,
                        borderRight: 0,
                        borderColor: 'pink.main',
                      }}
                    >
                      <StyleWrapper>
                        <SearchBox />
                      </StyleWrapper>
                    </Box>
                    <Results />
                  </Box>
                </InstantSearch>

                <Box
                  width="34px"
                  height="34px"
                  sx={{
                    ...pinkBox,
                    borderLeft: 0,
                  }}
                >
                  <img src={Search} />
                </Box>
              </Stack>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Header;
