import { Box, CircularProgress, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2/";
import { useMediaQuery } from "react-responsive";
import styled from "@emotion/styled";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import { Formik } from "formik";
import Input from "@mui/material/Input";
import { ActionButton } from "../../../../SharedComponents/Buttons";
import {
  addDoc,
  collection,
  getFirestore,
  onSnapshot,
  doc,
  setDoc,
  getDoc,
} from "firebase/firestore";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import couponCode from "coupon-code";
import { useEffect, useState } from "react";

const commonStyles = {
  pl: "4px",
  pt: "4px",
  mb: "12px",
  fontFamily: "Code Saver",
  fontStyle: "normal",
  fontWeight: "normal",
  fontSize: "16px",
  lineHeight: "145%",
  color: "blue.main",
  border: 1,
  borderColor: "lightOlive.main",
};

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 55,
  height: 29,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",

      "& .MuiSwitch-thumb:before": {},
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#F7CBAD",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: "#FBF8EA",
    width: 26,
    height: 26,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,

    borderRadius: 20 / 2,
  },
}));

const NewGiftCard = (props) => {
  const [submitting, setSubmitting] = useState(false);
  const isDesktop = useMediaQuery({ minWidth: 1201 });
  const [selectedAmount, setSelectedAmount] = useState("Choose an amount");
  const [fixedPrice, setFixedPrice] = useState(true);
  const [giftCardCode, setGiftCardCode] = useState();
  const db = getFirestore();
  const amounts = [
    { label: "Choose an amount", amount: 0 },
    { label: "$25.00", amount: 25},
    { label: "$50.00", amount: 50 },
    { label: "$75.00", amount: 75 },
    { label: "$100.00", amount: 100 },
    { label: "$150.00", amount: 150 },
    { label: "$200.00", amount: 200 },
    { label: "$500.00", amount: 500 },
  ];

  const generateUniqueGiftCardCode = async () => {
    var code = couponCode.generate({ parts: 4 });
    var apptDocRef = await getDoc(doc(db, `giftCards`, code));

    while (apptDocRef.exists()) {
      code = couponCode.generate({ parts: 4 });
      apptDocRef = await getDoc(doc(db, `giftCards`, code));

    }

    setGiftCardCode(code);
  };
  useEffect(() => {
    generateUniqueGiftCardCode();
  }, []);

  const addGiftCard = async (values) => {

    var cardDetails;
    if (fixedPrice) {
      cardDetails = {
        initialAmount: values.giftCardAmount,
        balance: values.giftCardAmount,
      };
    } else {
      cardDetails = {
        percentOff: values.percentOff,
        maximumAmount: values.maximumAmount,
        used: false,
      };
    }
    const apptDocRef = await setDoc(doc(db, `giftCards`, giftCardCode), {
      type: fixedPrice ? "fixed" : "percent",
      dateCreated: new Date(),
      recipientEmail: values.recipientEmail,
      senderEmail: "platform@matriarchybuild.com",
      cardDetails,
    });
    props.onClose();

    setSubmitting(false);
  };

  return (
    <Box p="44px">
      <Typography
        sx={{
          whiteSpace: "pre-line",
          my: "3px",
          color: "blue.main",
        }}
        display="block"
        variant="h3"
      >
        {giftCardCode}
      </Typography>
      <Formik
        initialValues={{
          giftCardAmount: "Choose an amount",
          yourName: "",
          recipientEmail: "",
          senderEmail: "",
          message: "",
        }}
        validate={(values) => {
          const errors = {};
          if (fixedPrice) {
            if (!values.giftCardAmount) {
              errors.amount = "Required";
            } else if (values.giftCardAmount == "Choose an amount") {
              errors.amount = "Required";
            }
          } else {
            if (!values.percentOff) {
              errors.percentOff = "Required";
            }
            if (!values.maximumAmount) {
              errors.maximumAmount = "Required";
            }
          }

          console.log(errors);
          return errors;
        }}
        onSubmit={(values) => {
          setSubmitting(true);
          addGiftCard(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid item xs={12} md={4}>
              <FormControl sx={{ width: "100%" }}>
                <Stack py="20px" direction="row">
                  <Typography
                    sx={{
                      whiteSpace: "pre-line",
                      my: "3px",
                      color: fixedPrice ? "lightOlive.main" : "blue.main",
                    }}
                    display="block"
                    variant="paragraphMono"
                  >
                    {"Percentage"}
                  </Typography>
                  <MaterialUISwitch
                    onChange={(e) => setFixedPrice(e.target.checked)}
                    checked={fixedPrice}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <Typography
                    sx={{
                      whiteSpace: "pre-line",
                      my: "3px",
                      color: fixedPrice ? "blue.main" : "lightOlive.main",
                    }}
                    display="block"
                    variant="paragraphMono"
                  >
                    {"Fixed Amount"}
                  </Typography>
                </Stack>
                {fixedPrice && (
                  <>
                    <Typography
                      sx={{
                        whiteSpace: "pre-line",
                        my: "3px",
                        color: "blue.main",
                      }}
                      display="block"
                      variant="paragraphSans"
                    >
                      {"Amount"}
                    </Typography>
                    <Select
                      size="small"
                      variant="standard"
                      name="giftCardAmount"
                      value={values.giftCardAmount}
                      onChange={handleChange}
                      disableUnderline={true}
                      width="100%"
                      height="31px"
                      sx={{
                        ...commonStyles,
                      }}
                    >
                      {amounts.map((amount) => (
                        <MenuItem key={amount.amount} value={amount.amount}>
                          <Typography
                            sx={{
                              whiteSpace: "pre-line",
                              my: "3px",
                              color: "blue.main",
                            }}
                            display="block"
                            variant="paragraphMono"
                          >
                            {amount.label}
                          </Typography>
                        </MenuItem>
                      ))}
                    </Select>
                    <Box>{errors.amount && errors.amount}</Box>
                  </>
                )}
                {!fixedPrice && (
                  <>
                    <Typography
                      sx={{
                        whiteSpace: "pre-line",
                        my: "3px",
                        color: "blue.main",
                      }}
                      display="block"
                      variant="paragraphSans"
                    >
                      {"Percent Off (%)"}
                    </Typography>

                    <Input
                      size="small"
                      type="number"
                      variant="standard"
                      name="percentOff"
                      value={values.percentOff}
                      onChange={handleChange}
                      disableUnderline={true}
                      width="100%"
                      height="31px"
                      sx={{
                        ...commonStyles,
                      }}
                    ></Input>
                    <Box>{errors.percentOff && errors.percentOff}</Box>

                    <Typography
                      sx={{
                        whiteSpace: "pre-line",
                        my: "3px",
                        color: "blue.main",
                      }}
                      display="block"
                      variant="paragraphSans"
                    >
                      {"Max Amount ($)"}
                    </Typography>

                    <Input
                      size="small"
                      type="number"
                      variant="standard"
                      name="maximumAmount"
                      value={values.maximimumAmount}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disableUnderline={true}
                      width="100%"
                      height="31px"
                      sx={{
                        ...commonStyles,
                      }}
                    ></Input>
                    <Box>{errors.maximumAmount && errors.maximumAmount}</Box>
                  </>
                )}
              </FormControl>
            </Grid>
            <Typography variant="paragraphSans" color="blue.main">
              Recipient Email (optional)
            </Typography>
            <Input
              type="email"
              name="recipientEmail"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.recipientEmail}
              disabled={submitting}
              disableUnderline
              fullWidth
              height="40px"
              placeholder="Please enter the recipent's email"
              sx={{
                ...commonStyles,
              }}
            ></Input>
            <Box>
              {errors.recipientEmail &&
                touched.recipientEmail &&
                errors.recipientEmail}
            </Box>

            <Box>{errors.lastName && touched.lastName && errors.lastName}</Box>

            <Typography variant="paragraphSans" color="blue.main">
              Message (optional)
            </Typography>
            <Input
              type="text"
              name="message"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.message}
              disabled={submitting}
              disableUnderline
              fullWidth
              multiline
              minRows={4}
              maxRows={4}
              height="40px"
              placeholder="Write your recipient a note"
              sx={{
                ...commonStyles,
              }}
            ></Input>
            <Box>{errors.message && touched.message && errors.message}</Box>

            {submitting && (
              <Box
                width="100%"
                sx={{
                  display: "flex",
                  mt: "40px",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </Box>
            )}

            <Grid container py="44px">
              <Grid item xs={12} md={4} mdOffset={8}>
                {!submitting && <ActionButton title="Checkout" type="submit" />}
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default NewGiftCard;
