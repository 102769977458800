import { useMediaQuery } from 'react-responsive';

import { addDoc, collection, getFirestore } from 'firebase/firestore';
import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';

import Bathroom from '../../Assets/Images/bathroom.png';
import Check from '../../Assets/Images/check.svg';
import Input from '@mui/material/Input';

import { Select } from '@material-ui/core';
import FindABuilderFAQs from '../../SharedComponents/FAQ/Components/FindABuilderFAQs';
import { ActionButton } from '../../SharedComponents/Buttons';
import Footer from '../../SharedComponents/Footer';
import MailingList from '../../SharedComponents/MailingList';
import { Formik } from 'formik';
import { useState } from 'react';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../App';
import { findABuilderResponseHtml } from '../../Assets/EmailAssets/response-email';
import { getAnalytics, logEvent } from 'firebase/analytics';

const commonStyles = {
  pl: '4px',
  pt: '4px',
  mb: '22px',
  fontFamily: 'Code Saver',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '16px',
  lineHeight: '145%',
  color: 'blue.main',
  border: 1,
  borderColor: 'lightOlive.main',
};

const desktopCardStyle = {
  backgroundImage: `url(${Bathroom})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  width: '100%',
  padding: '5rem',
  height: '695px',
  backgroundPosition: 'center center',
};

const textStyles = {
  color: 'blue.main',
  fontFamily: 'Code Saver',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '14px',
  lineHeight: '17.5px',
};

const errorStyles = {
  color: 'olive.main',
  height: '0px',
  overflow: 'visible',
  display: 'block',
  position: 'relative',
  top: '-20px',
};

const projectTypeOptions = [
  'Full House Remodel',
  'Kitchen',
  'Bath',
  'Addition',
  'New Construction',
  'Other',
];

const budgetOptions = [
  { label: '$15,000 - $25,000', value: '15k-25k' },
  { label: '$25,000 - $50,000', value: '25k-50k' },
  { label: '$50,000 - $100,000', value: '50k-100k' },
  { label: '$100,000 - $200,000', value: '100k-200k' },
  { label: '$200,000 - $500,000', value: '200k-500k' },
  { label: '$500,000+', value: '500k+' },
];

const commonProps = {
  width: '100%',
  spacing: 0,
  container: true,
  maxWidth: '72.6rem',
  margin: 'auto',
};

const FindABuilder = () => {
  const isDesktop = useMediaQuery({ minWidth: 1201 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [submitting, setSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const db = getFirestore();
  const analytics = getAnalytics();

  const sendEmail = async (values) => {
    const html = findABuilderResponseHtml(values);
    await addDoc(collection(db, 'mail'), {
      to: ['info@matriarchybuild.com'],
      message: {
        subject: 'Find A Builder: Form Submission!',
        html: html,
      },
    });
  };

  const submitForm = async (values) => {
    if (!values.email) {
      throw new Error('Form requires an email');
    }

    logEvent(analytics, 'form-submit', {
      form_name: 'find-a-builder',
      form_email: values.email,
    });

    //sign up for klayvio email list
    const addEmail = httpsCallable(functions, 'addEmailToList');
    await addEmail({
      email: values.email,
      list: 'VuxPfb', //newsletter list
    });
    await sendEmail(values);
    setSubmitting(false);
    setSubmitSuccess(true);
  };

  return (
    <>
      <Box
        sx={{
          ...(!isMobile && { ...desktopCardStyle }),
        }}
      >
        <Grid {...commonProps} margin="0px auto" px={isMobile ? '0px' : '44px'}>
          {isMobile && (
            <Grid item xs={12}>
              <Box sx={{ overflow: 'hidden' }}>
                <img
                  src={Bathroom}
                  style={{ maxWidth: '135%', marginLeft: '-20%' }}
                />
              </Box>
            </Grid>
          )}
          <Grid item xs={12}>
            <Box
              sx={{
                backgroundColor: 'white',
                textAlign: 'center',
                padding: '4rem 3.5rem',
                height: 'fit-content',
                width: 'fit-content',
                maxWidth: '71rem',
              }}
            >
              <Stack alignItems="center">
                <Typography
                  variant="section_h1"
                  color="blue.main"
                  fontSize="40px"
                  width="fit-content"
                >
                  How it Works
                </Typography>
                <Typography
                  variant="section_h2"
                  color="olive.main"
                  fontSize="24px"
                  width="fit-content"
                  marginTop="1rem"
                >
                  Matriarchy Build offers a robust network of hand-picked
                  General Contractors across the country. We provide builder
                  matchmaking for home renovations, kitchens, baths and beyond.
                  Tell us more about your project -- we'd love to match you with
                  a highly-vetted builder from our network.
                </Typography>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box backgroundColor="cream.main">
        <Grid
          {...commonProps}
          px={isDesktop ? '36px' : '20px'}
          sx={{
            maxWidth: '72.6rem',
            margin: '0 auto',
            justifyContent: 'space-between',
            flexDirection: 'column',
            gap: 2,
          }}
          py="36px"
        >
          <Grid item xs={12}>
            <Typography
              variant="section_h2"
              alignItems="center"
              color="olive.main"
            >
              Share a description of your project along with your address,
              timeline, and budget and we'll get the process started.
            </Typography>
          </Grid>
          {submitSuccess && (
            <Grid container p="20px">
              <img src={Check} style={{ paddingRight: '5px' }} />

              <Typography variant="paragraphMono">
                Thanks for contacting us. We'll get back to you as soon as
                possible.
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Formik
              validateOnBlur={false}
              validateOnChange={false}
              initialValues={{
                name: '',
                email: '',
                address: '',
                zipcode: '',
                projectType: '0',
                budget: '0',
                comment: '',
              }}
              validate={(values) => {
                const errors = {};
                if (!values.name) {
                  errors.name = 'Required';
                }
                if (!values.email) {
                  errors.email = 'Required';
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = 'Invalid email address';
                }
                if (!values.address) {
                  errors.address = 'Required';
                }
                if (!values.zipcode) {
                  errors.zipcode = 'Required';
                } else if (!/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(values.zipcode)) {
                  errors.zipcode = 'Invalid Zipcode';
                }
                if (values.projectType === '0') {
                  errors.projectType = 'Required';
                }
                if (values.budget === '0') {
                  errors.budget = 'Required';
                }
                if (!values.comment) {
                  errors.comment = 'Required';
                }

                return errors;
              }}
              onSubmit={(values) => {
                setSubmitting(true);
                submitForm(values);
              }}
            >
              {({
                values,
                errors,
                isSubmitting,
                isValidating,
                handleChange,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Input
                        type="text"
                        name="name"
                        onChange={handleChange}
                        value={values.name}
                        disableUnderline
                        disabled={isSubmitting}
                        height="40px"
                        placeholder="Name"
                        fullWidth
                        sx={{
                          ...commonStyles,
                          '& .MuiInput-input': {
                            fontFamily: 'Code Saver',
                          },
                        }}
                      ></Input>
                      <Typography
                        variant="paragraphMonoSmall"
                        sx={{
                          ...errorStyles,
                        }}
                      >
                        {errors.name && errors.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Input
                        type="email"
                        name="email"
                        onChange={handleChange}
                        value={values.email}
                        disableUnderline
                        disabled={isSubmitting}
                        height="40px"
                        placeholder="Email"
                        fullWidth
                        sx={{
                          ...commonStyles,
                          '& .MuiInput-input': {
                            fontFamily: 'Code Saver',
                          },
                        }}
                      ></Input>
                      <Typography
                        variant="paragraphMonoSmall"
                        sx={{
                          ...errorStyles,
                        }}
                      >
                        {errors.email && errors.email}
                      </Typography>
                    </Grid>
                    <Grid item xs={isDesktop ? 10 : 8}>
                      <Input
                        type="text"
                        name="address"
                        onChange={handleChange}
                        value={values.address}
                        disableUnderline
                        disabled={isSubmitting}
                        fullWidth
                        height="40px"
                        placeholder="Address"
                        sx={{
                          ...commonStyles,
                          '& .MuiInput-input': {
                            fontFamily: 'Code Saver',
                          },
                        }}
                      ></Input>
                      <Typography
                        variant="paragraphMonoSmall"
                        sx={{
                          ...errorStyles,
                        }}
                      >
                        {errors.address && errors.address}
                      </Typography>
                    </Grid>
                    <Grid item xs={isDesktop ? 2 : 4}>
                      <Input
                        type="text"
                        name="zipcode"
                        onChange={handleChange}
                        value={values.zipcode}
                        disableUnderline
                        disabled={isSubmitting}
                        fullWidth
                        height="40px"
                        placeholder="Zip Code"
                        sx={{
                          ...commonStyles,
                          '& .MuiInput-input': {
                            fontFamily: 'Code Saver',
                          },
                        }}
                      ></Input>
                      <Typography
                        variant="paragraphMonoSmall"
                        sx={{
                          ...errorStyles,
                        }}
                      >
                        {errors.zipcode && errors.zipcode}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl
                        fullWidth
                        sx={{
                          ...commonStyles,

                          '& .MuiSelect-select': {
                            color:
                              values.projectType != '0'
                                ? '#212121'
                                : 'rgba(45,69, 124, 0.45)',
                            fontFamily: 'Code Saver',
                          },
                        }}
                      >
                        <Select
                          onChange={handleChange}
                          variant="standard"
                          name="projectType"
                          disableUnderline
                          disabled={isSubmitting}
                          width="100%"
                          height="40px"
                          value={values.projectType}
                          inputProps={{
                            MenuProps: {
                              sx: {
                                borderRadius: 0,
                              },
                              MenuListProps: {
                                sx: {
                                  backgroundColor: 'cream.main',
                                  borderRadius: 0,
                                },
                              },
                            },
                          }}
                        >
                          <MenuItem key="0" value="0" sx={{ ...textStyles }}>
                            Project Type
                          </MenuItem>
                          {projectTypeOptions.map((option) => (
                            <MenuItem
                              key={option}
                              value={option}
                              sx={{ ...textStyles }}
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <Typography
                        variant="paragraphMonoSmall"
                        sx={{
                          ...errorStyles,
                        }}
                      >
                        {errors.projectType && errors.projectType}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl
                        fullWidth
                        sx={{
                          ...commonStyles,

                          '& .MuiSelect-select': {
                            color:
                              values.budget != '0'
                                ? '#212121'
                                : 'rgba(45,69, 124, 0.45)',
                            fontFamily: 'Code Saver',
                          },
                        }}
                      >
                        <Select
                          onChange={handleChange}
                          variant="standard"
                          name="budget"
                          disableUnderline
                          disabled={isSubmitting}
                          width="100%"
                          height="40px"
                          value={values.budget}
                          inputProps={{
                            MenuProps: {
                              sx: {
                                borderRadius: 0,
                              },
                              MenuListProps: {
                                sx: {
                                  backgroundColor: 'cream.main',
                                  borderRadius: 0,
                                },
                              },
                            },
                          }}
                        >
                          <MenuItem key="0" value="0" sx={{ ...textStyles }}>
                            Project Budget
                          </MenuItem>
                          {budgetOptions.map((option) => (
                            <MenuItem
                              key={option.value}
                              value={option.value}
                              sx={{ ...textStyles }}
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <Typography
                        variant="paragraphMonoSmall"
                        sx={{
                          ...errorStyles,
                        }}
                      >
                        {errors.budget && errors.budget}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Input
                        type="text"
                        name="comment"
                        onChange={handleChange}
                        value={values.comment}
                        disableUnderline
                        disabled={isSubmitting}
                        fullWidth
                        height="40px"
                        multiline
                        rows="4"
                        placeholder="Tell us more about your project? (Ex: square footage of project, where are you in the process?, when are you hoping to get started?)"
                        sx={{
                          ...commonStyles,
                          '& .MuiInput-input': {
                            fontFamily: 'Code Saver',
                          },
                        }}
                      ></Input>
                      <Typography
                        variant="paragraphMonoSmall"
                        sx={{
                          ...errorStyles,
                        }}
                      >
                        {errors.comment && errors.comment}
                      </Typography>
                    </Grid>
                    {(isSubmitting || isValidating) &&
                      !submitSuccess === true && (
                        <Box
                          width="100%"
                          sx={{
                            display: 'flex',
                            mt: '40px',
                            justifyContent: 'center',
                          }}
                        >
                          <CircularProgress />
                        </Box>
                      )}
                    {!submitting && submitSuccess !== true && (
                      <ActionButton
                        title="Send"
                        type="submit"
                        sx={{ marginLeft: '8px' }}
                      />
                    )}
                  </Grid>
                </form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Box>
      <Box backgroundColor="white">
        <Grid {...commonProps}>
          <Stack
            sx={{
              paddingTop: '40px',
              paddingBottom: '52px',
              maxWidth: '72rem',
              margin: 'auto',
              textAlign: 'center',
              paddingX: isDesktop ? '5rem' : '40px',
            }}
          >
            <Grid container spacing="15px">
              <Grid item xs={12}>
                <Typography variant="section_h1" color="olive.main">
                  Details + FAQs
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FindABuilderFAQs />
              </Grid>
            </Grid>
          </Stack>
        </Grid>
      </Box>
      <MailingList />
      <Footer />
    </>
  );
};

export default FindABuilder;
