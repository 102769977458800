import { useMediaQuery } from 'react-responsive';

import { Box, Grid, Stack, Typography } from '@mui/material';

import { ActionButton } from '../../../SharedComponents/Buttons';

import ArrowPink from '../../../Assets/Images/arrow-pink.svg';

import { useNavigate } from 'react-router-dom';
import { ExternalLinkButton } from '../../Austin';

const ListItem = ({ children }) => {
  return (
    <li>
      <Typography variant="paragraphMono" color="olive.main">
        {children}
      </Typography>
    </li>
  );
};

const HomeHealthReport = () => {
  const isDesktop = useMediaQuery({ minWidth: 1201 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const navigate = useNavigate();

  const commonProps = {
    width: '100%',
    spacing: 0,
    container: true,
    maxWidth: '72.6rem',
    margin: 'auto',
  };

  const navigateTo = (url) => () => {
    navigate(url);
  };

  const visit =
    (url, newTab = false) =>
    () => {
      if (newTab) {
        window.open(url, '_blank');
      } else {
        window.location.assign(url);
      }
    };

  return (
    <Box
      sx={{
        backgroundColor: 'blue.main',
        py: '36px',
        px: isDesktop ? '5rem' : '20px',
      }}
    >
      <Grid
        {...commonProps}
        px={isMobile ? '20px' : '36px'}
        justifyContent="center"
      >
        <Grid item xs={12} marginBottom="36px">
          <Typography variant="section_h1" color="white">
            Home Health Report
          </Typography>
        </Grid>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={12}>
            <Box sx={{ height: '100%', backgroundColor: 'cream.main' }}>
              <Stack
                spacing={2}
                sx={{
                  p: '2rem',
                }}
              >
                <Typography variant="paragraphSansLarge" color="olive.main">
                  A Matriarchy Build Home Health Report is a comprehensive
                  overview of which areas of your home need immediate attention
                  - and which problems can take a backseat.
                </Typography>
                <Typography variant="paragraphSansLarge" color="olive.main">
                  We’ll help you make a customized action plan and prioritize
                  your “to-do” list.
                </Typography>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: '100%', backgroundColor: 'cream.main' }}>
              <Stack
                spacing={2}
                sx={{
                  p: '2rem',
                }}
              >
                <Typography variant="paragraphSansLarge" color="olive.main">
                  Your custom checklist includes:
                </Typography>
                <ul style={{ listStylePosition: 'inside', paddingLeft: '0px' }}>
                  <ListItem>Grounds + Exterior Structural Review</ListItem>
                  <ListItem>Roofing + Drainage Review</ListItem>
                  <ListItem>Attic + Basement Check</ListItem>
                  <ListItem>
                    Safety Overview (Smoke Detectors, Extinguishers, Venting)
                  </ListItem>
                  <ListItem>Plumbing + Water Heater Review</ListItem>
                  <ListItem>Electrical Systems Review</ListItem>
                  <ListItem>Heating + Cooling System Review</ListItem>
                  <ListItem>Appliance + Warranty Review</ListItem>
                </ul>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HomeHealthReport;
