import { Box } from '@mui/material';
import React from 'react';
import GeneralFAQs from '../../SharedComponents/FAQ/Components/GeneralFAQs';
import MailingList from '../../SharedComponents/MailingList';
import Footer from '../../SharedComponents/Footer';
import HowItWorksHero from './Components/HowItWorksHero';
import ProjectProfile from './Components/ProjectProfile';
import AustinBanner from './Components/AustinBanner';

const HowItWorks = () => {
  return (
    <Box>
      <AustinBanner />
      <HowItWorksHero />
      <ProjectProfile />
      <GeneralFAQs />
      <MailingList />
      <Footer />
    </Box>
  );
};

export default HowItWorks;
