import { Box, Dialog } from "@mui/material";
import { useContext, useState, useEffect, useCallback } from "react";
import { UserContext } from "../../../user-context";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useMediaQuery } from "react-responsive";
import SideBar from "../SideBar";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid } from "@mui/x-data-grid";
import Footer from "../../../SharedComponents/Footer";
import {
  query,
  collection,
  getFirestore,
  doc,
  getDocs,
  deleteField,
  updateDoc,
  onSnapshot,
  orderBy
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { ActionButton } from "../../../SharedComponents/Buttons";
import Grid from "@mui/material/Unstable_Grid2/";
import NewTestimonial from "./Components/NewTestimonial";

import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

const textStyles = {
  color: "blue.main",
  fontFamily: "Code Saver",
  fontStyle: "normal",
  fontWeight: "normal",
  fontSize: "14px",
  lineHeight: "17.5px",
};


const ProSelect = (props) => {
  const [pros, setPros] = useState();
  const [proId, setProId] = useState(props.selectedPro ? props.selectedPro : 0);
  const handleChange = useCallback((event) => {
  
    setProId(event.target.value)

    props.handleProSelect(event.target.value);
  } )

  const db = getFirestore();

  let queryPros = [];
  const getPros = async () => {
    const q = query(collection(db, "pros"), orderBy("lastName"));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const pro = {
        id: doc.id,
        ...doc.data(),
      };
      queryPros.push(pro);
      if (props.selectedPro && props.selectedPro.id === pro.id) {
        setProId(pro.id);
      }
    });
    setPros(queryPros);
  };

  useEffect(() => {
    // Update the document title using the browser API
    getPros();
  }, []);

  return (
    <FormControl sx={{ width: "100%", my: "22px" }}>
   
    <Select
      size="small"
      variant="standard"
      disableUnderline
      width="100%"
      height="31px"
      label="Project Type"
      value={proId}
      onChange={handleChange}
      inputProps={{
        MenuProps: {
          sx: {
            borderRadius: 0,
          },
          MenuListProps: {
            sx: {
              backgroundColor: "cream.main",
              borderRadius: 0,
            },
          },
        },
      }}
      sx={{
        borderRadius: 0,
        outline: "none",
        border: 1,
        borderColor: "blue.main",
  
        pl: "4px",
        pt: "2px",
        ...textStyles,
      }}
    >
       <MenuItem
          sx={{ ...textStyles, backgroundColor: "cream.main" }}
          value={0}
          key={1}
        >
          <Typography variant="paragraphMono">
            {`No Pro`}
          </Typography> 
        </MenuItem>
      {pros && pros.map((pro) => (
        <MenuItem
          sx={{ ...textStyles, backgroundColor: "cream.main" }}
          value={pro.id}
          key={pro.id}
        >
          <Typography variant="paragraphMono">
            {`${pro.firstName} ${pro.lastName}`}
          </Typography> 
        </MenuItem>
      ))}
    </Select>
  </FormControl>
  )
}

const TestimonialsAdmin = (props) => {
  const isDesktop = useMediaQuery({ minWidth: 1201 });
  const isMedium = useMediaQuery({ minWidth: 901 });
  const [creatingGiftCard, setCreatingGiftCard] = useState(false);
  const [testimonials, setTestimonials] = useState([]);
  const [userType, setUserType] = useState ("user")
  const db = getFirestore();

  const user = useContext(UserContext);


  const setUserAttributes = async (id) => {
    if (user) {
      user.user
        .getIdTokenResult()
        .then((idTokenResult) => {
          console.log (idTokenResult)
          if (idTokenResult.claims.userType === "admin") {
            setUserType("admin");
          } else {
            setUserType("user");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    if (user) {
      setUserAttributes(user.uid);
    }
    console.log (user)
  }, [user]);

  const getTestimonials = async () => {
    const q = query(collection(db, "testimonials"));

    const querySnapshot = await getDocs(q);
    var unsubscribe = onSnapshot(q, (querySnapshot) => {
      let queryTestimonials = [];
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
    
        const testimonial = {
          id: doc.id,
          ...doc.data(),
        };
        queryTestimonials.push(testimonial);
      });
      setTestimonials(queryTestimonials);
    });

    return () => unsubscribe();
  };

  useEffect(() => {
    // Update the document title using the browser API
    getTestimonials();
  }, []);

  const handleClose = (event, reason) => {
    setCreatingGiftCard(false);
  };

  const handleProSelect = async (event, id) => {

    const docRef = doc(db, "testimonials", id);
    
    const docSnap = await updateDoc(docRef, { proId: event ? event : deleteField()})
  };
  const columns = [
    {
      field: "id",
      headerName: "Pro",
      flex: 1,
      renderCell: ({ row: { id, proId } }) => {
        return (
          <ProSelect selectedPro={proId} handleProSelect={(event) => handleProSelect(event, id)} />
        );
      },
    },
    {
      field: "text",
      headerName: "Text",
      flex: 3,
      renderCell: ({ row: { text } }) => {
        return (
          <Tooltip
            title={
              <Typography
                variant="paragraphMonoSmall"
                color={"white"}
                sx={{ ml: "5px", textOverflow: "ellipsis" }}
              >
                {text}
              </Typography>
            }
          >
            <Typography
              variant="paragraphMonoSmall"
              color={"blue.main"}
              sx={{ ml: "5px", textOverflow: "ellipsis" }}
            >
              {text}
            </Typography>
          </Tooltip>
        );
      },
    },

    {
      field: "displayName",
      headerName: "Written by",
      flex: 1,
    },
  ];

  return (
    <>
      <Dialog onClose={handleClose} open={creatingGiftCard}>
        <NewTestimonial onClose={handleClose} />
      </Dialog>
      <Box width="100%" minHeight="766px" height="auto">
        <Stack
          width="100%"
          minHeight="766px"
          direction={isDesktop ? "row" : undefined}
        >
          <Box width={isDesktop ? "345px" : "100%"}>
            <SideBar type={props.type} />
          </Box>
          { userType === "admin" && 
          <Box width="100%" height="auto" sx={{ mx: "44px" }}>
            <Box width="66%" sx={{ borderBottom: 1, color: "lightOlive.main" }}>
              <Typography
                sx={{
                  whiteSpace: "pre-line",
                  color: "olive.main",
                  mr: "5px",
                  mt: "66px",
                  mb: "8px",
                }}
                variant="h3"
              >
                {"Testimonials"}
              </Typography>
            </Box>
            {false &&<Grid container sx={{ py: "24px" }}>
              <Grid item xs={12} md={3}>
                <ActionButton
                  onClick={() => setCreatingGiftCard(true)}
                  backgroundColor="pink"
                  title="New Testimonial"
                  width="100%"
                /> 
              </Grid>
            </Grid> }
            <Box
              m="40px 40px 0 0"
              height={
                testimonials?.length
                  ? `${(testimonials.length + 3) * 58}px`
                  : "800px"
              }
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .name-column--cell": {},
                "& .MuiDataGrid-columnHeaders": {
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {},
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: "pink.main",
                  fontFamily: "Code Saver",
                  fontStyle: "normal",
                  fontWeight: "normal",
                  fontSize: "12px",
                  lineHeight: "145%",
                  mb: "40px",
                },
                "& .MuiTablePagination-selectLabel": {
                  fontFamily: "Code Saver",
                },
                "& .MuiSelect-select": {
                  fontFamily: "Code Saver",
                },
                "& .MuiTablePagination-displayedRows": {
                  fontFamily: "Code Saver",
                },
              }}
            >
              <DataGrid
        
              disableRowSelectionOnClick
                disableSelectionOnClick
                isRowSelectable={() => false}
                initialState={{
                  sorting: {
                    sortModel: [{ field: "lastName", sort: "asc" }],
                  },
                }}
                sx={{
                  fontFamily: "Code Saver",
                  fontStyle: "normal",
                  fontWeight: "normal",
                  fontSize: "12px",
                  lineHeight: "145%",
                  mt: "44px",
                  color: "blue.main",
                }}
                rows={testimonials}
                columns={columns}
              />
            </Box>
          </Box> }
        </Stack>
        <Footer />
      </Box>
    </>
  );
};

export default TestimonialsAdmin;
