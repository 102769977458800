import { Box, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2/';
import AboutUs from '../../Assets/Images/about-us.jpeg';
import GabeLacey from '../../Assets/Images/gabe-lacey.jpeg';
import { useMediaQuery } from 'react-responsive';
import MailingList from '../../SharedComponents/MailingList';
import Footer from '../../SharedComponents/Footer';

const Contact = () => {
  const isDesktop = useMediaQuery({ minWidth: 1201 });
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          backgroundPosition: 'top',
          backgroundImage: `url(${AboutUs})`,
          backgroundColor: 'cream.main',
        }}
      >
        <Grid
          container
          width="100%"
          maxWidth="1440px"
          mx={isPortrait ? '20px' : '44px'}
          mt="115px"
          mb="122px"
        >
          {isDesktop && (
            <Grid
              lg={1}
              width="100%"
              height="191px"
              sx={{
                border: 1,
                borderRight: 0,
                color: 'pink.main',
                background:
                  'linear-gradient(to top left,transparent 0,transparent calc(50% - 0.8px),#f7cbad 50%,transparent calc(50% + 0.8px),transparent),linear-gradient(to top right,transparent 0,transparent calc(50% - 0.8px),#f7cbad 50%,transparent calc(50% + 0.8px),transparent)',
              }}
            ></Grid>
          )}
          <Grid
            item
            xs={12}
            lg={3}
            width="100%"
            height="191px"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'white',
              border: 1,

              color: 'pink.main',
            }}
          >
            <Typography
              sx={{
                whiteSpace: 'pre-line',
                textAlign: 'center',
                my: '63px',
                pt: '10px',
                color: 'olive.main',
              }}
              display="block"
              variant="h1"
            >
              {'About Us'}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            lg={8}
            width="100%"
            height="auto"
            sx={{
              position: 'relative',
              zIndex: 1,
              ml: '-1px',
              display: 'block',
              justifyContent: 'center',
              backgroundColor: 'white',
              border: 1,
              mt: '85px',
              px: isPortrait ? '20px' : '65px',
              color: 'pink.main',
            }}
          >
            <Typography
              sx={{
                whiteSpace: 'pre-line',
                mt: '60px',

                color: 'lightOlive.main',
              }}
              display="block"
              variant="paragraphSansLarge"
            >
              {'About Us'}
            </Typography>
            <Typography
              sx={{
                whiteSpace: 'pre-line',
              }}
              display="block"
              variant="paragraphSans"
              color="blue.main"
            >
              {
                'Hi, we’re Lacey & Gabriella and we’ve been friends for over 20 years. Over the course of our friendship we’ve shared progress reports on home renovation ideas and DIY projects. We’ve also lamented about how projects just pile up because we couldn’t find the right person to tackle the job. Someone who inspired trust and authority, someone who we would feel comfortable asking a million questions and wouldn’t judge our experience level.'
              }
            </Typography>
            <Typography
              sx={{
                whiteSpace: 'pre-line',
                mt: '20px',
              }}
              display="block"
              variant="paragraphSans"
              color="blue.main"
            >
              {
                'We built Matriarchy Build because we wanted to create a space to find a diverse group of women in the renovation, repair, and build space. We wanted a space that didn’t merely showcase polished, elegant finished projects, but also showed the messy, elbow-greasy parts too. Actually, those parts felt more important to us!'
              }
            </Typography>
            <Typography
              sx={{
                whiteSpace: 'pre-line',
                mt: '20px',
              }}
              display="block"
              variant="paragraphSans"
              color="blue.main"
            >
              {
                'We had the idea to create online consultations with Pros nationwide so that wherever you are, you can find someone to talk to about what you are working on. We realized in short order that folks were excited about these Pros and wanted to hire them for their local projects. So we listened to the feedback and launched a host of local services including Handyperson help in Austin, TX, and a Pro Referral Network nationwide to match Builders with folks tackling renovations and remodels.'
              }
            </Typography>
            <Typography
              sx={{
                whiteSpace: 'pre-line',
                mt: '20px',
              }}
              display="block"
              variant="paragraphSans"
              color="blue.main"
            >
              {
                'We are passionate about this community, and we are honored that you’re along for the ride with us. Fueled by the strength, determination, and entrepreneurial spirit of our moms, we are proud to share Matriarchy Build.'
              }
            </Typography>
            <Typography
              sx={{
                whiteSpace: 'pre-line',
                mt: '20px',
              }}
              display="block"
              variant="paragraphSans"
              color="blue.main"
            >
              {'Matriarchy Build is a Queer, Latina and Woman-Owned business.'}
            </Typography>
            <Typography
              sx={{
                overflowWrap: 'anywhere',
                mt: '20px',
              }}
              display="block"
              variant="paragraphSans"
              color="blue.main"
            >
              <Typography
                color="blue.main"
                variant="paragraphSans"
                sx={{
                  whiteSpace: 'nowrap',
                }}
              >
                {'See us featured in:'}
              </Typography>
              <a
                target="_blank"
                href="https://www.texasmonthly.com/style/matriarchy-build-wants-to-save-us-from-mansplaining-contractors/"
              >
                <Typography
                  color="blue.main"
                  variant="paragraphSans"
                  sx={{
                    textDecoration: 'underline',
                    mr: '5px',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {'TX Monthly,'}
                </Typography>
              </a>
              <a
                target="_blank"
                href="https://www.inquirer.com/real-estate/women-nonbinary-home-improvement-consulting-advice-trades-site-app-20230802.html"
              >
                <Typography
                  color="blue.main"
                  variant="paragraphSans"
                  sx={{
                    textDecoration: 'underline',
                    mr: '5px',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {'The Philadelphia Inquirer,'}
                </Typography>
              </a>
              <a
                target="_blank"
                href="https://www.domino.com/content/female-contractors-matriarchy-build/"
              >
                <Typography
                  color="blue.main"
                  variant="paragraphSans"
                  sx={{
                    textDecoration: 'underline',
                    mr: '5px',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {'Domino,'}
                </Typography>
              </a>
              <a
                target="_blank"
                href="https://fortune.com/2022/10/11/female-founders-home-construction-renovation-matriarchy-build-sweeten-clare/?lhh123"
              >
                <Typography
                  color="blue.main"
                  variant="paragraphSans"
                  sx={{
                    textDecoration: 'underline',
                    mr: '5px',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {'Fortune,'}
                </Typography>
              </a>
              <a
                target="_blank"
                href="https://businessofhome.com/articles/matriarchy-build-is-making-it-easier-to-hire-tradeswomen"
              >
                <Typography
                  color="blue.main"
                  variant="paragraphSans"
                  sx={{
                    textDecoration: 'underline',
                    mr: '5px',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {'Business of Home,'}
                </Typography>
              </a>
              <a
                target="_blank"
                href="https://www.remodelista.com/posts/construction-and-diy-project-advice-from-women-in-the-building-trades-from-matriarchy/"
              >
                <Typography
                  color="blue.main"
                  variant="paragraphSans"
                  sx={{
                    textDecoration: 'underline',
                    mr: '5px',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {'Remodelista,'}
                </Typography>
              </a>
              <a
                target="_blank"
                href="https://nypost.com/2022/06/19/meet-the-women-at-the-helm-of-a-home-improvement-hub/"
              >
                <Typography
                  color="blue.main"
                  variant="paragraphSans"
                  sx={{
                    textDecoration: 'underline',
                    mr: '5px',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {'NY Post,'}
                </Typography>
              </a>
              {'and '}
              <a
                target="_blank"
                href="https://www.finehomebuilding.com/2022/07/01/find-a-female-tradesperson-with-matriarchy-build"
              >
                <Typography
                  color="blue.main"
                  variant="paragraphSans"
                  sx={{
                    textDecoration: 'underline',
                    mr: '5px',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {'Fine Homebuilding'}
                </Typography>
              </a>
              {'.'}
            </Typography>
            <Box
              width="100%"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                py: '40px',
              }}
            >
              <img src={GabeLacey} width="75%" margin="auto" />
            </Box>
            <Typography
              sx={{
                whiteSpace: 'pre-line',
                mt: '60px',

                color: 'lightOlive.main',
              }}
              display="block"
              variant="paragraphSansLarge"
            >
              {'Our Mission'}
            </Typography>
            <Typography
              sx={{
                whiteSpace: 'pre-line',
              }}
              display="block"
              variant="paragraphSans"
              color="blue.main"
            >
              {
                'Our mission is to empower folks in the build space and to amplify women working in the trades. There is already a strong community of women designing floor plans, tearing down walls and building up new structures. Our goal is to create a platform for this vibrant community to share knowledge, interact, learn new skills, and to encourage more women to gain confidence whether they’re picking up a drill for the first time or overseeing a whole home renovation.'
              }
            </Typography>
            <Typography
              sx={{
                whiteSpace: 'pre-line',
                mt: '20px',
                mb: '60px',
              }}
              display="block"
              variant="paragraphSans"
              color="blue.main"
            >
              {
                'Matriarchy Build’s long term goal is that our community will serve as a gateway to more women exploring careers in the skilled trades.'
              }
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <MailingList />
      <Footer />
    </Box>
  );
};

export default Contact;
