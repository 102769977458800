import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useMediaQuery } from 'react-responsive';
import React from 'react';
import { LinkButton } from '../../../../SharedComponents/Buttons';

const AustinBanner = () => {
  const isSmall = useMediaQuery({ maxWidth: 601 });

  return (
    <Box
      backgroundColor="lightOlive.main"
      display="flex"
      justifyContent="center"
    >
      <Stack
        maxWidth="800px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        spacing={3}
        py="30px"
        px={isSmall ? '20px' : '44px'}
      >
        <Typography color="blue.main" variant="paragraphSansLarge">
          {
            'Are you local to the Greater Austin area and looking for help? \n If so, check out our Membership Packages'
          }
        </Typography>
        <LinkButton
          linkTo="/austin"
          width="340px"
          title="Learn More"
        ></LinkButton>
      </Stack>
    </Box>
  );
};

export default AustinBanner;
