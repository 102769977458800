import { Box, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import ArrowBlue from "../../../Assets/Images/arrow-blue.svg";
import CloseButton from "../../../Assets/Images/close-button.svg";
import { blueBox } from "../../../theme";
import { ActionButton, PinkButton } from "../../../SharedComponents/Buttons";
import { useState, forwardRef } from "react";
import Backdrop from "@mui/material/Backdrop";
import { useMediaQuery } from "react-responsive";
import { styled } from "@mui/system";
import ModalUnstyled from "@mui/base/ModalUnstyled";
import TimeSelect from "../TimeSelect";
import zIndex from "@mui/material/styles/zIndex";

const Modal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FeeChart = (props) => {
  const isPortrait = useMediaQuery({ orientation: "portrait" });
  const isSmall = useMediaQuery({ maxWidth: 601 });
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => console.log("close");

  return (
    <>
      <Backdrop sx={{ color: "#fff" }} open={open}></Backdrop>
      <Box
        sx={{
          mb: "44px",
          border: 1,
          color: "blue.main",
          backgroundColor: "white",
        }}
      >
        {open && (
          <Modal open={open} onClose={handleClose}>
            <Box
              sx={{
                height: isSmall ? "100%" : "auto",
                width: "auto",
                maxWidth:"880px",
                paddingBottom: "20px",
                overflow: "auto",
                display: "block",
                backgroundColor: "white",
              }}
            >
              <Box
                width="100%"
                p="6px"
                sx={{ display: "flex", justifyContent: "right" }}
              >
                <img
                  width="30px"
                  style={{ cursor: "pointer" }}
                  src={CloseButton}
                  onClick={() => setOpen(false)}
                />
              </Box>

              <TimeSelect
                appointmentDetails={props.appointmentDetails}
                pro={props.pro}
              />
            </Box>
          </Modal>
        )}
        <Grid container width="100%">
          <Grid xs={4}>
            <Box
              height="51px"
              sx={{
                display: "flex",
                alignItems: "center",
                borderRight: 1,
                borderBottom: 1,
                color: "blue.main",
              }}
            >
              <Typography
                sx={{
                  whiteSpace: "pre-line",
                  ml: "14px",
                }}
                display="block"
                variant="paragraphMono"
                color="blue.main"
              >
                {"Duration"}
              </Typography>
            </Box>
            <Box
              height="118px"
              sx={{ borderRight: 1, borderBottom: 1, color: "blue.main" }}
            >
              <Typography
                sx={{
                  whiteSpace: "pre-line",
                  p: "14px",
                }}
                display="block"
                variant="paragraphMonoLarge"
                color="blue.main"
              >
                {`${props.appointmentDetails.type?.duration} min`}
              </Typography>
            </Box>
          </Grid>
          <Grid xs={4}>
            <Box
              height="51px"
              sx={{
                display: "flex",
                alignItems: "center",
                borderRight: 1,
                borderBottom: 1,
                color: "blue.main",
              }}
            >
              <Typography
                sx={{
                  whiteSpace: "pre-line",
                  ml: "14px",
                }}
                display="block"
                variant="paragraphMono"
                color="blue.main"
              >
                {"Price"}
              </Typography>
            </Box>
            <Box
              height="118px"
              sx={{ borderRight: 1, borderBottom: 1, color: "blue.main" }}
            >
              <Typography
                sx={{
                  whiteSpace: "pre-line",
                  p: "14px",
                }}
                display="block"
                variant="paragraphMonoLarge"
                color="blue.main"
              >
                ${props.appointmentDetails.type.price}
              </Typography>
            </Box>
          </Grid>
          <Grid xs={4}>
            <Box
              height="51px"
              sx={{
                display: "flex",
                alignItems: "center",

                borderBottom: 1,
                color: "blue.main",
              }}
            >
              <Typography
                sx={{
                  whiteSpace: "pre-line",
                  ml: "14px",
                }}
                display="block"
                variant="paragraphMono"
                color="blue.main"
              >
                {isPortrait ? "Type" : "Consultation Type"}
              </Typography>
            </Box>
            <Box
              height="118px"
              sx={{ borderRight: 0, borderBottom: 1, color: "blue.main" }}
            >
              <Typography
                sx={{
                  whiteSpace: "pre-line",
                  p: "14px",
                }}
                display="block"
                variant="paragraphMonoLarge"
                color="blue.main"
              >
                {props.appointmentDetails.type.label}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <ActionButton
          arrowColor="blue"
          sx={{
            border: 0,

            backgroundColor: "pink.main",
            color: "blue.main",
            "&:hover": {
              backgroundColor: "cream.main",
            },
          }}
          title="Book a Time"
          onClick={handleOpen}
          state={{ type: props.type, pro: props.pro }}
        />
      </Box>
    </>
  );
};

const Book = (props) => {
  const isPortrait = useMediaQuery({ orientation: "portrait" });
  return (
    <Box
      width="100%"
      height="auto"
      sx={{
        backgroundColor: "lightOlive.main",
        px: isPortrait ? "20px" : "44px",
        py: "66px",
      }}
    >
      <Grid container sx={{ mx: "auto" }}  maxWidth="1440px">
        <Grid lg={2}>
          <Box
            height="121px"
            sx={{
              ...blueBox,
              mb: isPortrait && "20px",
              mr: "20px",
              textAlign: "left",
            }}
          >
            <Typography
              sx={{
                whiteSpace: "pre-line",
                mt: "5px",
                mx: "10px",
              }}
              display="block"
              variant="h2"
              color="blue.main"
            >
              {"Book a\n Consultation"}
            </Typography>
          </Box>
        </Grid>
        <Grid xs={12} lg={10}>
          <Stack >
            {props.appointmentTypes.map((appointmentType, index) => {
              if (appointmentType.type.enabled) {
                return (
                  <FeeChart
                    key={index}
                    appointmentDetails={appointmentType}
                    pro={props.pro}
                  />
                );
              }
            })}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Book;
