import { Box, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2/";
import TermsImage from "../../Assets/Images/terms-image.jpeg";
import { useMediaQuery } from "react-responsive";
import MailingList from "../../SharedComponents/MailingList";
import Footer from "../../SharedComponents/Footer";
import TermsContent from "./TermsContent"

const Terms = () => {
  const isDesktop = useMediaQuery({ minWidth: 1201 });
  const isPortrait = useMediaQuery({ orientation: 'portrait' })

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "top",
          backgroundImage: `url(${TermsImage})`,
          backgroundColor: "cream.main",
        }}
      >
        <Grid container width="100%" maxWidth="1440px" mx={isPortrait ? "20px" : "44px"} mt="115px" mb="122px">
          {isDesktop && (
            <Grid
              lg={1}
              width="100%"
              height="191px"
              sx={{
                border: 1,
                borderRight: 0,
                color: "pink.main",
                background:
                  "linear-gradient(to top left,transparent 0,transparent calc(50% - 0.8px),#f7cbad 50%,transparent calc(50% + 0.8px),transparent),linear-gradient(to top right,transparent 0,transparent calc(50% - 0.8px),#f7cbad 50%,transparent calc(50% + 0.8px),transparent)",
              }}
            ></Grid>
          )}
          <Grid
            item
            xs={12}
            lg={3}
            width="100%"
            height="191px"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "white",
              border: 1,
             
              color: "pink.main",
            }}
          >
            <Typography
              sx={{
                whiteSpace: "pre-line",
                textAlign: "center",
                my: "63px",
                pt:"10px",
                color: "olive.main",
              }}
              display="block"
              variant="h1"
            >
              {"Terms And \n Conditions"}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            lg={8}
            width="100%"
            height="auto"
            sx={{
              position:"relative",
              zIndex:1,
              ml:"-1px",
              display: "flex",
              justifyContent: "center",
              backgroundColor: "white",
              border: 1,
              mt:"85px",
              color: "pink.main",
            }}
          >
            <TermsContent />
          </Grid>
        </Grid>
      </Box>
      <MailingList />
      <Footer />
    </Box>
  );
};

export default Terms;
