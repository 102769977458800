import { useContext, useState, forwardRef } from 'react';
import { useSearchParams, createSearchParams } from 'react-router-dom';
import { UserContext } from '../../user-context';
import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ConsultationSummary from '../../SharedComponents/ConsultationSummary';
import { useLocation } from 'react-router-dom';
import { ActionButton } from '../../SharedComponents/Buttons';
import { addDoc, getDoc } from 'firebase/firestore';
import { getFirestore, doc, onSnapshot, collection } from 'firebase/firestore';
import Grid from '@mui/material/Unstable_Grid2/';
import Input from '@mui/material/Input';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import ArrowOlive from '../../Assets/Images/arrow-olive.svg';
import Dialog from '@mui/material/Dialog';
import { CircularProgress } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Footer from '../../SharedComponents/Footer';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../App';
const commonStyles = {
  fontFamily: 'Code Saver',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '16px',
  lineHeight: '145%',
  color: 'blue.main',
  pl: '5px',
  border: 1,
  borderColor: 'lightOlive.main',
};

const YourOrder = (props) => {
  const db = getFirestore();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const userContext = useContext(UserContext);
  const user = userContext.user;
  const [note, setNote] = useState('');
  const [pro, setPro] = useState();
  const [loading, setLoading] = useState(false);
  const [promoCodeInput, setPromoCodeInput] = useState();
  const [promoCodeError, setPromoCodeError] = useState('');
  const [discountedTotal, setDiscountedTotal] = useState(-1);
  const [discount, setDiscount] = useState();
  const [emailSignUp, setEmailSignUp] = useState(true);
  const [usedGiftCard, setUsedGiftCard] = useState();
  const [usedPromoCode, setUsedPromoCode] = useState();
  const [zeroBalance, setZeroBalance] = useState(false);
  const [appointmentId, setAppointmentId] = useState();
  const [error, setError] = useState();
  const noteLabel =
    'After completing your booking you will be able to share more details and upload photos to review during your session.';
  const [appointmentDetails, setAppointmentDetails] = useState();

  const date = new Date(searchParams.get('date'));
  const proId = searchParams.get('proId');
  const typeId = searchParams.get('typeId');

  const getPro = async (id) => {
    const docRef = doc(db, 'pros', proId);
    const docSnap = await getDoc(docRef);

    setPro({ id: docSnap.id, ...docSnap.data() });
  };

  useEffect(() => {
    getPro();
  }, [proId]);

  const getAppointmentType = async () => {
    const docRef = doc(db, 'pros', proId, 'appointmentTypes', typeId);
    const docSnap = await getDoc(docRef);

    const appointmentDetails = {
      id: docSnap.id,
      type: docSnap.data(),
    };
    setAppointmentDetails(appointmentDetails);
  };
  useEffect(() => {
    getAppointmentType();
  }, [typeId]);

  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };

  const timeOptions = {
    hour: '2-digit',
    minute: '2-digit',
  };

  const startOrderListener = async (id) => {
    const unsub = onSnapshot(
      doc(db, `customers/${user?.uid}/checkout_sessions/${id}`),
      (doc) => {
        if (doc.data().url) {
          window.location.href = doc.data().url;
        } else if (doc.data().error) {
          setLoading(false);
          setError(` order number: ${id}`);
        }
      }
    );
  };

  const handleClose = () => {};
  const placeOrder = async () => {
    setError('');
    setLoading(true);

    //TODO: sign up for email
    if (emailSignUp) {
      const addEmail = httpsCallable(functions, 'addEmailToList');
      const addEmailToList = await addEmail({
        email: user.email,
        list: 'VuxPfb',
      });
    }
    const apptDocRef = await addDoc(collection(db, `appointments`), {
      userId: user.uid,
      proId: pro.id,
      user: {
        email: user.email,
        displayName: user.displayName,
      },
      pro: {
        firstName: pro.firstName,
        lastName: pro.lastName,
      },
      projectDetails: {
        clientNote: note,
      },
      ...(usedGiftCard && { giftCard: usedGiftCard }),
      ...(usedPromoCode && { promoCode: usedPromoCode }),
      startTime: date.toISOString(),
      confirmed: false,
      type: {
        ...appointmentDetails.type,
      },
    });

    setAppointmentId(apptDocRef.id);

    if (
      discountedTotal === 0 ||
      (window.location.hostname.match(/^mb-platform.*.web.app$/g) &&
        pro.id === 'gTmfjqsFDxT9jaLha3wpzOEuYuz2')
    ) {
      setZeroBalance(true);
    } else {
      const docRef = await addDoc(
        collection(db, `customers/${user.uid}/checkout_sessions`),
        {
          mode: 'payment',
          customer_email: user.email,
          payment_status: 'unpaid',
          line_items: [
            {
              name: `${appointmentDetails.type.label + ' Consultation'}
              ${` - ${appointmentDetails.type.duration} min`} with ${
                pro.firstName
              }`,
              amount:
                usedGiftCard || usedPromoCode
                  ? Math.round(discountedTotal * 100)
                  : Math.round(appointmentDetails.type.price * 100),
              currency: 'USD',

              quantity: 1,
              description: `${date.toLocaleDateString(
                'en-us',
                options
              )} ${date?.toLocaleTimeString('en-us', timeOptions)}-${new Date(
                date.getTime() + appointmentDetails.type.duration * 60000
              ).toLocaleTimeString('en-us', timeOptions)}`,
            },
          ],
          metadata: {
            proId: pro.id,
            proEmail: pro.email,
            appointmentId: apptDocRef.id,
            userEmail: user.email,
            userId: user.uid,
            ...(usedPromoCode && { promoCode: usedPromoCode.id }),
            ...(usedPromoCode && { discount: discount }),
            ...(usedPromoCode && {
              totalAmount: appointmentDetails.type.price,
            }),
            ...(usedGiftCard && { giftCardId: usedGiftCard.id }),
            ...(usedGiftCard && { discount: discount }),
            ...(usedGiftCard && { totalAmount: appointmentDetails.type.price }),
            sessionDate: date.toString(),
            ...(pro.stripeId && {
              proProfile: `https://dashboard.stripe.com/connect/accounts/${pro.stripeId}/payments`,
            }),
            ...(pro.stripeId && { proAccount: pro.stripeId }),
          },
          ...(usedGiftCard && { total_details: { amount_discount: discount } }),
          ...(usedPromoCode && {
            total_details: { amount_discount: discount },
          }),
          success_url: `${window.location.origin}/booking-success/${apptDocRef.id}`,
          cancel_url: `${window.location.origin}/checkout/?${createSearchParams(
            searchParams
          )}`,
        }
      );
      startOrderListener(docRef.id);
    }
  };

  const handlePromoCodeClick = async () => {
    const giftCardDocRef = doc(db, 'giftCards', promoCodeInput);
    const giftCardDocSnap = await getDoc(giftCardDocRef);

    const promoCodeDocRef = doc(db, 'promoCodes', promoCodeInput);
    const promoCodeDocSnap = await getDoc(promoCodeDocRef);

    if (giftCardDocSnap.exists()) {
      const giftCard = giftCardDocSnap.data();
      if (giftCard.type === 'percent') {
        if (giftCard.cardDetails.used === true) {
          setPromoCodeError('This card has already been used');
        } else if (
          appointmentDetails.type.price > giftCard.cardDetails.maximumAmount
        ) {
          setDiscount(giftCard.cardDetails.maximumAmount);
          setDiscountedTotal(
            appointmentDetails.type.price - giftCard.cardDetails.maximumAmount
          );
        } else {
          const discount = Math.ceil(
            appointmentDetails.type.price *
              (giftCard.cardDetails.percentOff / 100)
          );
          const discountedTotal = appointmentDetails.type.price - discount;
          setDiscountedTotal(discountedTotal);
          setDiscount(discount);
        }
        giftCard.cardDetails.used = true;
      } else {
        if (giftCard.cardDetails.balance === 0) {
          setPromoCodeError('This card has no remaining balance');
        } else if (
          giftCard.cardDetails.balance >= appointmentDetails.type.price
        ) {
          setDiscountedTotal(0);
          setDiscount(appointmentDetails.type.price);
          giftCard.cardDetails.balance =
            giftCard.cardDetails.balance - appointmentDetails.type.price;
        } else {
          setDiscountedTotal(
            appointmentDetails.type.price - giftCard.cardDetails.balance
          );
          setDiscount(giftCard.cardDetails.balance);
          giftCard.cardDetails.balance = 0;
        }
      }
      const usedGiftCard = {
        id: promoCodeInput,
        cardDetails: giftCard.cardDetails,
      };
      setUsedGiftCard(usedGiftCard);

      setPromoCodeError('');
    } else if (promoCodeDocSnap.exists()) {
      const promoCode = promoCodeDocSnap.data();
      if (
        (promoCode.limitUses && promoCode.timesUsed >= promoCode.limitUses) ||
        (promoCode.expires && new Date() > new Date(promoCode.expires.toDate()))
      ) {
        setPromoCodeError('This promo code has expired');
      } else {
        if (promoCode.type === 'percent') {
          if (appointmentDetails.type.price > promoCode.codeDetails.amount) {
            setDiscount(promoCode.codeDetails.maximumAmount);
            setDiscountedTotal(
              appointmentDetails.type.price -
                promoCode.codeDetails.maximumAmount
            );
          } else {
            const discount = Math.ceil(
              appointmentDetails.type.price *
                (promoCode.codeDetails.percentOff / 100)
            );

            const discountedTotal = appointmentDetails.type.price - discount;
            setDiscountedTotal(discountedTotal);
            setDiscount(discount);
          }
        } else {
          if (
            promoCode.codeDetails.promoCodeAmount >=
            appointmentDetails.type.price
          ) {
            setDiscountedTotal(0);
            setDiscount(appointmentDetails.type.price);
          } else {
            setDiscountedTotal(
              appointmentDetails.type.price -
                promoCode.codeDetails.promoCodeAmount
            );
            setDiscount(promoCode.codeDetails.promoCodeAmount);
          }
        }

        const usedPromoCode = { id: promoCodeInput, ...promoCode };
        console.log(usedPromoCode);
        setUsedPromoCode(usedPromoCode);

        setPromoCodeError('');
      }
    } else {
      setPromoCodeError('Invalid Promo Code or Gift Card Id');
      setDiscountedTotal(-1);
    }
  };

  return (
    <>
      {zeroBalance && <Navigate to={`/booking-success/${appointmentId}`} />}
      <Box
        width="100%"
        height="auto"
        sx={{
          backgroundColor: 'cream.main',
          px: '44px',
        }}
      >
        <Dialog onClose={handleClose} open={loading}>
          <Box
            sx={{
              p: '50px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress mx="auto" />
          </Box>
          <Box sx={{ mx: '44px', mb: '24px' }}>
            <Typography
              sx={{
                whiteSpace: 'pre-line',
                color: 'blue.main',
                mr: '5px',
                mb: '8px',
              }}
              variant="paragraphMono"
            >
              {'Preparing your order'}
            </Typography>
          </Box>
        </Dialog>

        <Box width="100%" maxWidth="1088px" mx="auto">
          <Box sx={{ borderBottom: 1, color: 'lightOlive.main' }}>
            <Typography
              sx={{
                whiteSpace: 'pre-line',
                color: 'olive.main',
                mr: '5px',
                pt: '87px',
                mb: '8px',
              }}
              variant="h2"
            >
              {'Your Order'}
            </Typography>
          </Box>
          <Box
            width="100%"
            height="auto"
            sx={{
              minHeight: '253px',
              borderBottom: 1,
              mb: '20px',
              color: 'lightOlive.main',
            }}
          >
            {pro && appointmentDetails ? (
              <>
                <ConsultationSummary
                  date={date}
                  appointmentDetails={appointmentDetails}
                  presentedAt="preOrder"
                  pro={pro}
                />
              </>
            ) : (
              <Box
                height="100%"
                pt="100px"
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </Box>
          <Grid container columnSpacing={4} mb="115px">
            <Grid item xs={12}>
              {promoCodeError && (
                <Typography
                  sx={{
                    whiteSpace: 'pre-line',
                    color: 'olive.main',
                    mr: '5px',
                    '&:hover': {
                      color: 'blue.main',
                    },
                  }}
                  variant="paragraphMono"
                >
                  {promoCodeError}
                </Typography>
              )}
            </Grid>
            <Grid item md={3} xs={9}>
              <Input
                type="text"
                disableUnderline
                fullWidth
                value={promoCodeInput}
                onChange={(e) => setPromoCodeInput(e.target.value)}
                height="40px"
                placeholder="Gift or Promo Code"
                sx={{
                  ...commonStyles,
                }}
              ></Input>
            </Grid>

            <Grid item xs="auto">
              <Stack direction="row" pt="4px">
                <Button
                  disableRipple
                  disableFocusRipple
                  disableElevation
                  onClick={handlePromoCodeClick}
                  sx={{
                    border: 'none',
                    margin: 0,
                    padding: 0,
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: 'transparent',
                      cursor: 'pointer',
                    },
                  }}
                >
                  <Typography
                    sx={{
                      whiteSpace: 'pre-line',
                      color: 'olive.main',
                      mr: '5px',
                      '&:hover': {
                        color: 'blue.main',
                      },
                    }}
                    variant="paragraphMono"
                  >
                    {'Apply'}
                  </Typography>
                </Button>
                <img src={ArrowOlive} />
              </Stack>
            </Grid>
            {discountedTotal != -1 && (
              <>
                <Grid item xs={12} md={7}>
                  <Box width="100%" display="flex" justifyContent="right">
                    <Typography
                      sx={{
                        whiteSpace: 'pre-line',
                        color: 'olive.main',
                        mr: '5px',
                      }}
                      variant="paragraphMono"
                    >
                      {'Total'}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} mr="20px">
                  <Box width="100%" display="flex" justifyContent="right">
                    <Typography
                      sx={{
                        whiteSpace: 'pre-line',
                        color: 'blue.main',
                        display: 'block',
                      }}
                      variant="paragraphMonoLarge"
                    >
                      {`$${discountedTotal}`}
                    </Typography>
                  </Box>
                </Grid>
              </>
            )}
          </Grid>

          <Typography
            sx={{
              whiteSpace: 'pre-line',
              color: 'olive.main',
              mb: '17px',
            }}
            variant="h2"
          >
            {'Notes for your pro'}
          </Typography>
          <Typography
            sx={{
              whiteSpace: 'pre-line',
              color: 'blue.main',
              mb: '17px',
            }}
            variant="paragraphMono"
          >
            {'Give us an overview of your project.'}
          </Typography>
          <Grid container spacing={2} mb="25px">
            <Grid item md={8} xs={12}>
              <Input
                type="text"
                name="note"
                multiline
                rows={6}
                onChange={(event) => setNote(event.target.value)}
                value={note}
                disableUnderline
                fullWidth
                height="40px"
                placeholder=""
                sx={{
                  ...commonStyles,
                }}
              ></Input>
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography
                sx={{
                  whiteSpace: 'pre-line',
                  color: 'blue.main',
                }}
                variant="paragraphMono"
              >
                {noteLabel}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={4} pb="220px">
            <Grid item xs={12}>
              <Stack direction="row">
                <Checkbox
                  type="checkbox"
                  value={emailSignUp}
                  onClick={(e) => {
                    setEmailSignUp(e.target.checked);
                  }}
                  name="emailSignUp"
                />

                <Typography
                  sx={{
                    whiteSpace: 'pre-line',
                    color: 'blue.main',
                    pl: '5px',
                    pt: '8px',
                  }}
                  display="block"
                  variant="paragraphMono"
                >
                  {'Sign up to receive news and email updates'}
                </Typography>
              </Stack>
            </Grid>
            <Grid item md={8} xs={12}>
              <Typography
                sx={{
                  whiteSpace: 'pre-line',
                  color: 'blue.main',
                  pl: '5px',
                  pt: '8px',
                }}
                display="block"
                variant="paragraphMono"
              >
                {"By placing my order, I agree to Matriarchy Build's"}
                <a href="/terms-of-use">
                  {' '}
                  <Typography
                    sx={{
                      whiteSpace: 'pre-line',
                      color: 'blue.main',
                      textDecoration: 'underline',
                    }}
                    display="inline"
                    variant="paragraphMono"
                  >
                    {'Terms of Use'}
                  </Typography>
                </a>
              </Typography>
            </Grid>
            <Grid item md={8} xs={12}>
              <ActionButton title="Place Order" onClick={() => placeOrder()} />
            </Grid>
            {error && (
              <Typography
                sx={{
                  whiteSpace: 'pre-line',
                  color: 'blue.main',
                  ml: '20px',
                  mb: '17px',
                }}
                variant="paragraphMono"
              >
                {`Something went wrong!:${error}`}
              </Typography>
            )}
          </Grid>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default YourOrder;
