import { Box, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import Stack from "@mui/material/Stack";
import Footer from "../../SharedComponents/Footer";
import SideBar from "./SideBar";
import { useMediaQuery } from "react-responsive";
import Consultations from "./Consultations";
const Account = () => {
  const isDesktop = useMediaQuery({ minWidth: 1201 });
  const isMedium = useMediaQuery({ minWidth: 901 });

  return (
    <Box minHeight="766px" sx={{ mr: isMedium ? "44px" : undefined }}>
      <Consultations type="upcoming"/>
    </Box>
  );
};

export default Account;
