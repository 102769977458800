import { Box, styled } from "@mui/material";
import { blue } from "@mui/material/colors";
import Typography from "@mui/material/Typography";
import ListItem  from "@mui/material/ListItem";
import { useMediaQuery } from "react-responsive";
export const Header = styled(Typography)(({ theme }) => ({
  '&': {
    color: theme.palette.lightOlive.main,
    display:"block",
    marginTop:"20px",
  },
})) 

export const Paragraph = styled(Typography)(({ theme }) => ({
  '&': {
    color: theme.palette.blue.main,
  },
})) 

export const ListItemStyled = styled(ListItem )(({ theme }) => ({
  '&': {
    color: theme.palette.blue.main,
    fontFamily: "Code Saver",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "145%",
      
  },
})) 


const PrivacyContent = () => {
  const isPortrait = useMediaQuery({ orientation: 'portrait' })

  return (
    <Box width="100%" px={isPortrait ? "20px" : "45px"} py="65px" >
      <Paragraph variant="paragraphMono">
        <em>Revised: March 1st, 2022</em>
      </Paragraph>

      <Header variant="paragraphSans">Scope</Header>

      <Typography variant="paragraphMono" sx={{color:"blue.main"}}>
        We at Matriarchy Build Incorporated (“Matriarchy Build,” “we,” “us,” and
        “our”) have created this Privacy Policy (this “Privacy Policy”) because
        we know that you care about how information you provide to us is used
        and shared. This Privacy Policy applies to personal information
        processed in the course of our business, including, but not limited to,{" "}
        <a href="https://matriarchybuild.com/">
          https://matriarchybuild.com/{" "}
        </a>
        (the “Website”), and our related online and offline offerings. To make
        this Privacy Policy easier to read, the Platform and our related online
        and offline offerings are collectively called the “Services.”
      </Typography>

      <Header variant="paragraphSans">Description of Users and Acceptance of Terms</Header>

      <Paragraph variant="paragraphMono">
        This Privacy Policy applies to visitors to the Website, who view only
        publicly-available content (“Visitors”); end users (“Users”), who have
        signed up to use the Services and post their own projects (each, a
        “Project”) via the Platform; and contractors, DIYers, handypeople,
        tradespeople, architects, engineers (collectively, “Members”), who have
        signed up to consult on these Projects.
      </Paragraph>

      <Paragraph variant="paragraphMono">
        By visiting our Website, Visitors are agreeing to the terms of this
        Privacy Policy and the accompanying Terms of Use.
      </Paragraph>

      <Paragraph variant="paragraphMono">
        By signing up, accessing, and/or using the Platform: (i) each User is
        agreeing to the terms of this Privacy Policy and the accompanying Terms
        of Use; and (ii) each Member is agreeing to the terms of this Privacy
        Policy and the accompanying Member Agreement.
      </Paragraph>

      <Paragraph variant="paragraphMono">
        Capitalized terms not defined in this Privacy Policy shall have the
        meaning set forth in the Terms of Use (when such term concerns Users
        and/or Visitors) or the Member Agreement (when such term concerns
        Members).
      </Paragraph>

      <Header variant="paragraphSans">Personal Information We Collect</Header>

      <Paragraph variant="paragraphMono">
        The categories of personal information we collect depend on whether you
        are a Visitor, a User, or a Member, and the requirements of applicable
        law.
      </Paragraph>

      <Paragraph variant="paragraphMono">
        <span>
          Information You Provide to Us.
        </span>
      
      </Paragraph>

      <ul>
        <ListItemStyled  variant="paragraphMono">
          User Account Information. If you create a User account with the
          Services, we will collect personal information such as your name,
          email address, phone number, city and state. We also allow you to
          provide us with information about yourself and your Project, including
          your abilities and skills, tools in your home, project budget and
          desired project outcomes.&nbsp;
        </ListItemStyled  >
        <ListItemStyled  variant="paragraphMono">
          Member Account Information. If you create a Member account with the
          Services, we will collect your name, email address, phone number, city
          and state. We will also collect information about your professional
          experience such as your employment information, license information
          (if applicable), and information about previous work you have done. In
          addition, Members may provide us with bank account information in
          order to receive payments via the Services.&nbsp;
        </ListItemStyled  >
        <ListItemStyled  variant="paragraphMono">
          Billing Information. Any electronic payments made by Users or Members
          via the Platform are processed by third-party vendors, such as Stripe.
          We do not directly collect, store, or maintain any payment card
          information provided via the Platform. All such payment card
          information is collected and processed through such third-party
          vendors pursuant to the terms and conditions of their privacy policies
          and terms of use. You can read Stripe’s Privacy Policy at:
          
        </ListItemStyled  >
        <ListItemStyled  variant="paragraphMono">
          Communications with Us. We may collect personal information from you
          such as email address, phone number, or mailing address when you
          request information about our Services, sign up for our newsletter,
          request customer or technical support, or otherwise communicate with
          us.
        </ListItemStyled  >
        <ListItemStyled  variant="paragraphMono">
          Customer Service and Support. If you call or otherwise interact with
          Matriarchy Build’s customer service and support, we may collect the
          information you provide to our representatives.
        </ListItemStyled  >
        <ListItemStyled  variant="paragraphMono">
          Interactive Features. Matriarchy Build may offer interactive features
          such as profile and Project pages, review features, commenting
          functionalities, forums, chat and messaging services, and social media
          pages. Matriarchy Build and other individuals who use our Services may
          collect the information you submit or make available through these
          interactive features. Any information shared on the public sections of
          these channels will be considered “public” and will not be subject to
          the privacy protections referenced herein. By using such interactive
          features, you assume the risk that the personal information provided
          by you may be viewed and used by third parties for any number of
          purposes.
        </ListItemStyled  >
        <ListItemStyled  variant="paragraphMono">
          Conferences, Trade Shows, and other Events. We may attend conferences,
          trade shows, and other events where we collect personal information
          from individuals who interact with or express an interest in
          Matriarchy Build and/or the Services. If you provide us with any
          information at one of these events, we will use it for the purposes
          for which it was collected.
        </ListItemStyled  >
        <ListItemStyled  variant="paragraphMono">
          Surveys. We may contact you to participate in surveys. If you decide
          to participate, you may be asked to provide certain information which
          may include personal information.
        </ListItemStyled  >
        <ListItemStyled  variant="paragraphMono">
          Registration for Sweepstakes or Contests. We may run sweepstakes and
          contests. Contact information you provide may be used to reach you
          about the sweepstakes or contest and for other promotional, marketing
          and business purposes, if permitted by law. In some jurisdictions, we
          are required to publicly share information of winners.
        </ListItemStyled  >
      </ul>

      <Paragraph variant="paragraphMono">
        <span >
          Information We Collect Through Use of the Services.
        </span>
      </Paragraph>

      <Paragraph variant="paragraphMono">
        We may collect certain information automatically when you use the
        Services. This information may include your Internet protocol (IP)
        address, user settings, hardware and processor information, camera
        resolution, NFC enabled, network type, IMEI, MAC address, Technologies
        including cookie identifiers, mobile advertising and other unique
        identifiers, mobile carrier, details about your browser, operating
        system or device, precise location information from your use of the App,
        inferred location based off your IP address, Internet service provider,
        pages that you visit before, during and after using the Services,
        information about the links you click, and other information about how
        you use the Services. Information we collect may be associated with your
        account and the devices you use.
      </Paragraph>

      <ul>
        <ListItemStyled  variant="paragraphMono">
          Cookies, Web Beacons, and Personalized Advertising. We, as well as
          third parties that provide content, advertising, or other
          functionality on the Services, may use cookies, pixel tags, local
          storage, and other technologies (“Technologies”) to automatically
          collect information through the Services. Technologies are essentially
          small data files placed on your device that allow us and our partners
          to record certain pieces of information whenever you visit or interact
          with the Services.
        </ListItemStyled  >
        <ListItemStyled  variant="paragraphMono">
          Cookies. Cookies are small text files placed in visitors’ device
          browsers to store their preferences. Most browsers allow you to block
          and delete cookies. However, if you do that, the Services may not work
          properly.
        </ListItemStyled  >
        <ListItemStyled  variant="paragraphMono">
          Pixel Tags/Web Beacons. A pixel tag (also known as a web beacon) is a
          piece of code embedded on the Services that collects information about
          users’ engagement. The use of a pixel allows us to record, for
          example, that a user has visited a particular web page or clicked on a
          particular advertisement. We may also include web beacons in e-mails
          to understand whether messages have been opened, acted on, or
          forwarded.
        </ListItemStyled  >
        <ListItemStyled  variant="paragraphMono" sx={{display:"block"}}>
          Analytics. We may also use Google Analytics and other service
          providers to collect information regarding behavior and demographics
          on our Services. For more information about Google Analytics, please
          visit{" "}
          <a href="https://www.google.com/policies/privacy/partners/">
            www.google.com/policies/privacy/partners/
          </a>
          . You can opt out of Google’s collection and processing of data
          generated by your use of the Services by going to{" "}
          <a href="https://tools.google.com/dlpage/gaoptout">
            https://tools.google.com/dlpage/gaoptout
          </a>
          .
        </ListItemStyled  >
      </ul>

      <Paragraph variant="paragraphMono">
        <span>
          Information from Other Sources.
        </span>
      </Paragraph>

      <ul>
        <ListItemStyled  variant="paragraphMono">
          Third Parties.We may obtain information about you from other sources,
          including through third-party services and other organizations. For
          example, if you access or use our Services through a third-party
          application, such as a social networking service (e.g., Facebook,
          Google, etc.) or a third-party login service, we may collect
          information about you from that third party that you have made
          available via your privacy settings. Such information may include,
          without limitation, your first and last name, username, profile
          picture, unique social media identifier and access token, and e-mail
          address. In addition, we may also obtain location information about
          you from your wireless carrier and/or certain third-party service
          providers.
        </ListItemStyled  >
        <ListItemStyled  variant="paragraphMono">
          Other Members and Users.We may receive information from other Members
          and Users about their interactions and transactions with you.
        </ListItemStyled  >
      </ul>

      <Header variant="paragraphSans">How We Use Your Information.</Header>

      <Paragraph variant="paragraphMono">
        We process personal information for a variety of business purposes,
        including:
      </Paragraph>

      <Paragraph variant="paragraphMono">To provide the Services, Products, or Information Requested.</Paragraph>

      <ul>
        <ListItemStyled  variant="paragraphMono">Fulfill our contract with you;</ListItemStyled  >
        <ListItemStyled  variant="paragraphMono">Manage your information;</ListItemStyled  >
        <ListItemStyled  variant="paragraphMono">
          Process your payment card and/or other financial information to
          facilitate your use of the Services;
        </ListItemStyled  >
        <ListItemStyled  variant="paragraphMono">Respond to questions, comments, and other requests;</ListItemStyled  >
        <ListItemStyled  variant="paragraphMono">
          Provide access to certain areas, functionalities, and features of our
          Services; and
        </ListItemStyled  >
        <ListItemStyled  variant="paragraphMono">Answer requests for customer or technical support.</ListItemStyled  >
      </ul>

      <Paragraph variant="paragraphMono">Administrative Purposes.</Paragraph>

      <ul>
        <ListItemStyled  variant="paragraphMono">
          Pursue legitimate interests, such as direct marketing, research and
          development (including marketing research), network and information
          security, and fraud prevention;
        </ListItemStyled  >
        <ListItemStyled  variant="paragraphMono">Measure interest and engagement in the Services;</ListItemStyled  >
        <ListItemStyled  variant="paragraphMono">Develop of new products and services;</ListItemStyled  >
        <ListItemStyled  variant="paragraphMono">Improve our products and Services;</ListItemStyled  >
        <ListItemStyled  variant="paragraphMono">Ensure internal quality control and safety;</ListItemStyled  >
        <ListItemStyled  variant="paragraphMono">Verify individual identity;</ListItemStyled  >
        <ListItemStyled  variant="paragraphMono">Carry out audits;</ListItemStyled  >
        <ListItemStyled  variant="paragraphMono">
          Communicate with you about activities on the Services and changes to
          our agreements;
        </ListItemStyled  >
        <ListItemStyled  variant="paragraphMono">
          Prevent and prosecute potentially prohibited or illegal activities;
        </ListItemStyled  >
        <ListItemStyled  variant="paragraphMono">Enforce our agreements; and</ListItemStyled  >
        <ListItemStyled  variant="paragraphMono">Comply with our legal obligations.</ListItemStyled  >
      </ul>

      <Paragraph variant="paragraphMono">
        Marketing Our Products and Services. We may use personal information to
        tailor and provide you with content and advertisements. We may provide
        you with these materials as permitted by applicable law.
      </Paragraph>

      <Paragraph variant="paragraphMono">
        If you have any questions about our marketing practices or if you would
        like to opt out of the use of your personal information for marketing
        purposes, you may contact us at any time as set forth below.
      </Paragraph>

      <Paragraph variant="paragraphMono">
        De-identified and Aggregated Information Use. We may use personal
        information and other information about you to create de-identified
        and/or aggregated information, such as de-identified demographic
        information, de-identified location information, de-identified or
        aggregated trends, reports, or statistics, or other analyses we create.
        De-identified and aggregated information is not personal information,
        and we may use and disclose such information in a number of ways,
        including research, internal analysis, analytics, and any other legally
        permissible purposes.
      </Paragraph>

      <Paragraph variant="paragraphMono">
        Your Communications on the Services. We may, either directly or through
        third party service providers, review, scan, or analyze your
        communications with other users exchanged via the Platform for fraud
        prevention, risk assessment, regulatory compliance, investigation, and
        customer support purposes. We may also scan, review or analyze messages
        for research and product development purposes to help consultations and
        user communications more efficient and effective, as well as to debug,
        improve and expand product offerings. We will not review, scan, or
        analyze your communications for the purpose of sending third party
        marketing messages to you. We will use automated methods to carry out
        these reviews or analyses where reasonably possible. However, from time
        to time we may have a human manually review these communications. By
        using the Services, you agree that Matriarchy Build, in its sole
        discretion, may, either directly or through third party service
        providers we engage to provide services to us, review, scan, analyze,
        and store your communications, whether done through automated means or
        manually by a human.
      </Paragraph>

      <Paragraph variant="paragraphMono">
        Sharing Content with Friends or Colleagues. Our Services may offer
        various tools and functionalities. For example, we may allow you to
        provide information about your friends through our referral services.
        Our referral services may allow you to forward or share certain content
        with a friend or colleague, such as an email inviting your friend to use
        our Services.
      </Paragraph>

      <Paragraph variant="paragraphMono">
        Technologies. Our uses of such Technologies fall into the following
        general categories:
      </Paragraph>

      <ul>
        <ListItemStyled  variant="paragraphMono">
          Operationally Necessary. This includes Technologies that allow you
          access to our Services that are required to identify irregular
          behavior, prevent fraudulent activity and improve security or that
          allow you to make use of our functions;
        </ListItemStyled  >
        <ListItemStyled  variant="paragraphMono">
          Performance Related. We may use Technologies to assess the performance
          of our Services, including as part of our analytic practices to help
          us understand how our visitors use the Services;
        </ListItemStyled  >
        <ListItemStyled  variant="paragraphMono">
          Functionality Related. We may use Technologies that allow us to offer
          you enhanced functionality when accessing or using our Services. This
          may include identifying you when you sign into our Services and
          keeping track of your specified preferences or past pages viewed;
        </ListItemStyled  >
        <ListItemStyled  variant="paragraphMono">
          Advertising or Targeting Related. We may use first-party or
          third-party Technologies to develop and deliver content, including ads
          relevant to your interests, on our Services or on third-party sites.
        </ListItemStyled  >
      </ul>

      <Paragraph variant="paragraphMono">
        Consent. Matriarchy Build may use personal information for other
        purposes that are clearly disclosed to you at the time you provide
        personal information or with your consent.
      </Paragraph>

      <Header variant="paragraphSans">
        <strong>How We Disclose Your Information.</strong>
      </Header>

      <Paragraph variant="paragraphMono">
        We may share any of the personal information we collect as set forth
        below:
      </Paragraph>

      <Paragraph variant="paragraphMono">
        Sharing with Other Members and Users. We may share personal information
        with other Members and Users of Matriarchy Build in order to provide the
        Services. For example, if a Member and a User enter into an agreement,
        we may share personal information about each party with the other. In
        addition, if you are a Member using an account that is shared by your
        organization, we may share your information with other members of that
        organization.
      </Paragraph>

      <Paragraph variant="paragraphMono">
        Service Providers. We may share any personal information we collect
        about you with our third-party service providers. The types of service
        providers to whom we entrust personal information include service
        providers for: (i) the provision of the Services; (ii) the provision of
        information, products, and other services you have requested; (iii)
        marketing and advertising; (iv) payment processing; (v) customer service
        activities; and (vi) the provision IT and related services.
      </Paragraph>

      <Paragraph variant="paragraphMono">
        Third Party Services. With your permission, third party services and/or
        applications may access your personal information. We use standard OAuth
        (open authorization) to enable you to give permission to share your
        personal information with other services and applications, such as
        Facebook and LinkedIn (e.g., when you agree to a pop-up requesting you
        to allow another application to access your account information). We
        also use OAuth to allow us to share information about you that is stored
        by us without sharing your security credentials.
      </Paragraph>

      <Paragraph variant="paragraphMono">
        Affiliates. We may share personal information with our affiliated
        entities.
      </Paragraph>

      <Paragraph variant="paragraphMono">
        Business Partners. We may provide personal information to our relevant
        business partners. For example, if a business partner refers you to our
        Services and you make a purchase, we may share your personal information
        with that business partner. We may also provide your personal
        information to our business partners to provide you with a product or
        service you have requested. In addition, we may provide personal
        information to business partners with whom we jointly offer products or
        services.
      </Paragraph>

      <Paragraph variant="paragraphMono">
        Rewards, Contests, and Promotional Programs. If you participate in our
        rewards, contests, sweepstakes, competitions, and promotional programs
        we may share your personal information with our various third-party
        promotional and marketing partners, including without limitation,
        businesses participating in our various programs.
      </Paragraph>

      <Paragraph variant="paragraphMono">
        Interest-Based or Personalized Advertising. Through our Services,
        Matriarchy Build may allow third-party advertising partners to set
        Technologies and other tracking tools to collect information regarding
        your activities and your device (e.g., your IP address, cookie
        identifiers, page(s) visited, location, time of day). These advertising
        partners may use this information (and similar information collected
        from other services) for purposes of delivering personalized
        advertisements to you when you visit third party services within their
        networks. This practice is commonly referred to as “interest-based
        advertising” or “personalized advertising.” If you prefer not to share
        your personal information with third party advertising partners, you may
        follow the instructions below.
      </Paragraph>

      <Paragraph variant="paragraphMono">
        APIs and Software Development Kits. We may use third party APIs and
        software development kits (“SDKs”) as part of the functionality of our
        Services. APIs and SDKs may allow third parties including advertising
        partners to collect your personal information to provide content that is
        more relevant to you. For more information about our use of APIs and
        SDKs, please contact us as set forth below.
      </Paragraph>

      <Paragraph variant="paragraphMono">
        Disclosures to Protect Us or Others. We may access, preserve, and
        disclose your personal information if we believe doing so is required or
        appropriate to: (i) comply with law enforcement or national security
        requests and legal process, such as a court order or subpoena; (ii)
        protect your, our or others’ rights, property, or safety; (iii) to
        collect amounts owed to us; (iv) when we believe disclosure is necessary
        or appropriate to prevent financial loss or in connection with an
        investigation or prosecution of suspected or actual illegal activity; or
        (v) if we, in good faith, believe that disclosure is otherwise necessary
        or advisable.
      </Paragraph>

      <Paragraph variant="paragraphMono">
        Merger, Sale, or Other Asset Transfers. If we are involved in a merger,
        acquisition, financing due diligence, reorganization, bankruptcy,
        receivership, purchase or sale of assets, or transition of service to
        another provider, then your information may be sold or transferred as
        part of such a transaction as permitted by law and/or contract.
      </Paragraph>

      <Header variant="paragraphSans">
        <strong>International Data Transfers.</strong>
      </Header>

      <Paragraph variant="paragraphMono">
        All information processed by us may be transferred, processed, and
        stored anywhere in the world, including but not limited to, the United
        States and other countries.
      </Paragraph>

      <Header variant="paragraphSans">
        <strong>Your Choices.</strong>
      </Header>

      <Paragraph variant="paragraphMono">
        General. You may have the right to object to or opt out of certain uses
        of your personal information. Where you have consented to the processing
        of your personal information, you may withdraw that consent at any time
        by contacting us as described below.
      </Paragraph>

      <Paragraph variant="paragraphMono">
        Email Communications. If you receive an unwanted email from us, you can
        use the unsubscribe link found at the bottom of the email to opt-out. We
        will use commercially reasonable efforts to process such requests.
        However, you should be aware that it is not always possible to
        completely remove or modify information in our subscription databases.
        In addition, you will continue to receive transaction-related emails
        regarding products or Services you have requested. We may also send you
        certain non-promotional communications regarding us and our Services,
        and you will not be able to opt out of those communications (e.g.,
        communications regarding the Services or updates to this Privacy
        Policy).
      </Paragraph>

      <Paragraph variant="paragraphMono">
        SMS Messages. You can opt-out of receiving text messages from Matriarchy
        Build by replying to a text message and stating that you wish to opt-out
        or emailing support@MatriarchyBuild.com.
      </Paragraph>

      <Paragraph variant="paragraphMono">
        Mobile Devices. We may send you push notifications through the Services.
        You may at any time opt-out from receiving these types of communications
        by changing the settings on your mobile device. We may also collect
        location-based information if you use the Services. You may opt-out of
        this collection by changing the settings on your mobile device. Please
        note that in some cases, opting out of the collection of location
        information will prohibit us from providing certain features of the
        Services.
      </Paragraph>

      <Paragraph variant="paragraphMono">
        Technologies and Personalized Advertising. If you would like to opt-out
        of the Technologies we employ on the Services, you may do so by
        blocking, disabling, or deleting them as your browser or device permits.
        Please note that cookie-based opt-outs are not effective on mobile
        applications. However, you may opt-out of personalized advertisements on
        some mobile applications by following the instructions for{" "}
        <a href="https://support.google.com/googleplay/android-developer/answer/6048248?hl=en">
          Android
        </a>{" "}
        and <a href="https://support.apple.com/en-us/HT202074">iOS</a>.
      </Paragraph>

      <Paragraph variant="paragraphMono">
        The online advertising industry also provides websites from which you
        may opt-out of receiving targeted ads from advertisers that participate
        in self-regulatory programs. You can access these, and also learn more
        about targeted advertising and consumer choice and privacy, at{" "}
        <a href="https://www.networkadvertising.org/choices/">
          www.networkadvertising.org/managing/opt_out.asp
        </a>
        ,{" "}
        <a href="https://www.youronlinechoices.eu/">
          https://www.youronlinechoices.eu/
        </a>{" "}
        and{" "}
        <a href="https://optout.aboutads.info/">www.aboutads.info/choices/</a>.
      </Paragraph>

      <Paragraph variant="paragraphMono">
        Please note you must separately opt out in each browser and on each
        device.
      </Paragraph>

      <Paragraph variant="paragraphMono">
        “Do Not Track”. Do Not Track (“DNT”) is a privacy preference that users
        can set in certain web browsers. Please note that we do not respond to
        or honor DNT signals or similar mechanisms transmitted by web browsers.
      </Paragraph>

      <Header variant="paragraphSans">
        <strong>Individual Rights in Personal Information.</strong>
      </Header>

      <Paragraph variant="paragraphMono">
        For purposes of the California Consumer Privacy Act, Matriarchy Build
        does not “sell” your personal information.
      </Paragraph>

      <Paragraph variant="paragraphMono">
        In accordance with applicable law, you may have the right to: (i)
        request confirmation of whether we are processing your personal
        information; (ii) obtain access to or a copy of your personal
        information; (iii) receive an electronic copy of personal information
        that you have provided to us, or ask us to send that information to
        another company (the “right of data portability”); (iv) restrict our
        uses of your personal information; (v) seek correction or amendment of
        inaccurate, untrue, incomplete, or improperly processed personal
        information; and (vi) request erasure of personal information held about
        you by Matriarchy Build, subject to certain exceptions prescribed by
        law. If you would like to exercise any of these rights, please contact
        us as set forth below.
      </Paragraph>

      <Paragraph variant="paragraphMono">
        We will process such requests in accordance with applicable laws. To
        protect your privacy, Matriarchy Build will take steps to verify your
        identity before fulfilling your request.
      </Paragraph>

      <Header variant="paragraphSans">
        <strong>Data Retention.</strong>
      </Header>

      <Paragraph variant="paragraphMono">
        We retain the personal information we receive as described in this
        Privacy Policy for as long as you use our Services or as necessary to
        fulfill the purpose(s) for which it was collected, provide our Services,
        resolve disputes, establish legal defenses, conduct audits, pursue
        legitimate business purposes, enforce our agreements, and comply with
        applicable laws.
      </Paragraph>

      <Header variant="paragraphSans">
        <strong>Security Of Your Information.</strong>
      </Header>

      <Paragraph variant="paragraphMono">
        We take steps to ensure that your information is treated securely and in
        accordance with this Privacy Policy. Unfortunately, the Internet cannot
        be guaranteed to be 100% secure, and we cannot ensure or warrant the
        security of any information you provide to us. To the fullest extent
        permitted by applicable law, we do not accept liability for unauthorized
        disclosure.
      </Paragraph>

      <Paragraph variant="paragraphMono">
        By using the Services or providing personal information to us, you agree
        that we may communicate with you electronically regarding security,
        privacy, and administrative issues relating to your use of the Services.
        If we learn of a security system’s breach, we may attempt to notify you
        electronically by sending a notice through the Services or by sending an
        e-mail to you.
      </Paragraph>

      <Header variant="paragraphSans">
        <strong>Third Party Websites/Applications.</strong>
      </Header>

      <Paragraph variant="paragraphMono">
        The Services may contain links to other websites/applications and other
        websites/applications may reference or link to our Services. These
        third-party services are not controlled by us. We encourage our users to
        read the privacy policies of each website and application with which
        they interact. We do not endorse, screen or approve, and are not
        responsible for the privacy practices or content of such other websites
        or applications. Visiting these other websites or applications is at
        your own risk.
      </Paragraph>

      <Header variant="paragraphSans">
        <strong>Children’s Information.</strong>
      </Header>

      <Paragraph variant="paragraphMono">
        The Services are not directed to children under 13 (or other age as
        required by local law), and we do not knowingly collect personal
        information from children. If you learn that your child has provided us
        with personal information without your consent, you may contact us as
        set forth below. If we learn that we have collected any child’s personal
        information in violation of applicable law, we will promptly take steps
        to delete such information.
      </Paragraph>

      <Header variant="paragraphSans">
        <strong>Supervisory Authority.</strong>
      </Header>

      <Paragraph variant="paragraphMono">
        If you are located in the European Economic Area, you have the right to
        lodge a complaint with a supervisory authority if you believe our
        processing of your personal information violates applicable law.
      </Paragraph>

      <Header variant="paragraphSans">
        <strong>Changes to Our Privacy Policy.</strong>
      </Header>

      <Paragraph variant="paragraphMono">
        We may revise this Privacy Policy from time to time in our sole
        discretion. If there are any material changes to this Privacy Policy, we
        will notify you as required by applicable law. You understand and agree
        that you will be deemed to have accepted the updated Privacy Policy if
        you continue to use the Services after the new Privacy Policy takes
        effect.
      </Paragraph>

      <Header variant="paragraphSans">
        <strong>Contact Us.</strong>
      </Header>

      <Paragraph variant="paragraphMono">
        If you have any questions about our privacy practices or this Privacy
        Policy, please contact us at:
      </Paragraph>

      <Paragraph variant="paragraphMono">
        <a href="mailto:support@matriarchybuild.com">
          support@matriarchybuild.com
        </a>
      </Paragraph>
    </Box>
  );
};

export default PrivacyContent;
