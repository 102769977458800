import React from 'react';
import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

const HighlightCard = (props) => {
  return (
    <Link to={`/pro/${props.pro.slug}`}>
      <Box
        width="100%"
        height="100%"
        sx={{
          border: 1,
          color: 'blue.main',
          display: 'flex',
        }}
      >
        <Stack direction="column" sx={{ ml: '0px', mt: '0px', flex: 'auto' }}>
          <Box backgroundColor="pink.main" borderBottom={1}>
            <Typography
              sx={{
                whiteSpace: 'pre-line',
                textColor: 'olive.main',
                mx: '8px',
                my: '14px',
              }}
              display="block"
              variant="h3"
            >
              {`${props.pro.firstName} \n ${props.pro.lastName}`}
            </Typography>
          </Box>
          <Box borderBottom={1}>
            <Typography
              sx={{
                whiteSpace: 'pre-line',
                color: 'olive.main',
                ml: '8px',
                minHeight: '35px',
                display: 'flex',
                alignItems: 'center',
              }}
              variant="paragraphMonoSmall"
            >
              {`${props.pro.proTrades.map((p) => p.displayName).join(', ')}`}
            </Typography>
          </Box>
          <Box
            sx={{
              height: '270px',
              overflow: 'hidden',
              borderBottom: 1,
            }}
          >
            <img
              src={props.pro.headshot}
              width="100%"
              height="100%"
              style={{ objectFit: 'cover', objectPosition: 'top' }}
            />
          </Box>

          {props.pro.featuredDescription && (
            <Box
              sx={{
                minHeight: '170px',
                p: '12px',
                backgroundColor: 'white',
                flex: 'auto',
              }}
            >
              <Stack direction="column">
                {props.pro.featuredDescription.map((d, index) => (
                  <Box
                    key={index}
                    sx={{
                      '&:not(:last-child)': { borderBottom: 1 },
                      py: '5px',
                    }}
                  >
                    <Typography
                      variant="paragraphMono"
                      sx={{
                        fontSize: '14px',
                        lineHeight: '145%',
                      }}
                    >
                      {d}
                    </Typography>
                  </Box>
                ))}
              </Stack>
            </Box>
          )}
        </Stack>
      </Box>
    </Link>
  );
};

export default HighlightCard;
