import './App.css';
import React, { useEffect, useState, useLayoutEffect, useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import HomePage from './Scenes/HomePage';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { appTheme } from './theme';
import Header from './SharedComponents/Header';
import HeaderMobile from './SharedComponents/HeaderMobile';
import DIYAndInspiration from './Scenes/DIYAndInspiration';
import FindAPro from './Scenes/FindAPro';
import HowItWorks from './Scenes/HowItWorks';
import GiftCards from './Scenes/GiftCardPage';
import ProProfile from './Scenes/ProProfile';
import { useMediaQuery } from 'react-responsive';
import FAQPage from './Scenes/FAQPage';
import Support from './Scenes/SupportPage';
import About from './Scenes/About';
import Contact from './Scenes/Contact';
import Privacy from './Scenes/Privacy';
import Terms from './Scenes/Terms';
import Account from './Scenes/Account';
import SignUp from './Scenes/Account/Components/SignUp';
import SignIn from './Scenes/Account/Components/SignIn';
import Consultations from './Scenes/Account/Consultations';
import AccountDetails from './Scenes/Account/AccountDetails';
import { UserContext } from './user-context';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { connectAuthEmulator, getAuth, onAuthStateChanged } from 'firebase/auth';
import {
  getFunctions,
  connectFunctionsEmulator
} from 'firebase/functions';
import CalendarView from './Scenes/Account/CalendarView';
import Users from './Scenes/Account/Users';
import Pros from './Scenes/Account/Pros';
import ProApply from './Scenes/ProApply';
import { getStorage } from 'firebase/storage';
import EditPro from './Scenes/Account/Pros/EditPro';
import YourOrder from './Scenes/YourOrder';
import SetSchedule from './Scenes/Account/Schedule';
import SetPricing from './Scenes/Account/Pricing';
import Success from './Scenes/YourOrder/Success';
import ConsultationDetails from './Scenes/Account/Consultations/ConsultationDetails';
import GiftCardsAdmin from './Scenes/Account/GiftCardsAdmin';
import Post from './Scenes/DIYAndInspiration/Post';
import GiftCardSuccess from './Scenes/GiftCardPage/Success';
import ForgotPassword from './Scenes/Account/Components/ForgotPassword';
import TestimonialsAdmin from './Scenes/Account/TestimonialsAdmin';
import { useLocation } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import ChangePassword from './Scenes/Account/Components/ChangePassword';
import { Navigate } from 'react-router-dom';
import ManageConsultations from './Scenes/Account/Consultations/ManageConsultations';
import VideoMeeting from './Scenes/Account/VideoMeeting';
import PromoCodes from './Scenes/Account/PromoCodes';
import FindABuilder from './Scenes/FindABuilder';
import AustinServices from './Scenes/AustinServices';
import MembershipDetails from './Scenes/MembershipDetails';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';

export const PrivateRoute = ({ children }) => {
  const currentUser = useContext(UserContext).user;

  if (currentUser) {
    return children;
  }

  return <Navigate to="/sign-in" />;
};
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY || '',
  authDomain: 'matriarchybuild.com',
  projectId: 'mb-platform',
  storageBucket: 'mb-platform.appspot.com',
  messagingSenderId: '928749178816',
  appId: '1:928749178816:web:fce86f07326c2139508225',
  measurementId: 'G-W2B4GV592T',
};

const UserProvider = UserContext.Provider;

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const storage = getStorage(app);
const functions = getFunctions(app);
if (location.hostname === "localhost") {
  const db = getFirestore();
  connectFirestoreEmulator(db, '127.0.0.1', 8081);
  connectFunctionsEmulator(functions, "localhost", 5001);
  connectAuthEmulator(auth, "http://127.0.0.1:9099");
}

export { storage };
export { functions };
export { auth };

function App() {
  const isDesktop = useMediaQuery({ minWidth: 1201 });

  const [user, setUser] = useState({ loggedIn: false });

  const location = useLocation();
  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User

        setUser({ loggedIn: true, user: user });

        return true;
      } else {
        setUser({ loggedIn: false });
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <UserProvider value={user}>
      <ThemeProvider theme={appTheme}>
        <CssBaseline />
        <div className="App">
          <ScrollToTop />
          <main className="content">
            {isDesktop ? (
              <Header sx={{ position: 'relative' }} />
            ) : (
              <HeaderMobile />
            )}
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/find-a-builder" element={<FindABuilder />} />
              <Route path="/pro/:id" element={<ProProfile />} />
              <Route path="/pro" element={<FindAPro />} />

              <Route path="/diy-inspiration" element={<DIYAndInspiration />} />
              <Route path="/diy-inspiration/:slug" element={<Post />} />
              <Route path="/how-it-works" element={<HowItWorks />} />
              <Route path="/faq" element={<FAQPage />} />
              <Route path="/gift-card" element={<GiftCards />} />
              <Route path="/gift-card/:id" element={<GiftCards />} />
              <Route
                path="/gift-card-receipt/:id"
                element={<GiftCardSuccess />}
              />
              <Route path="/austin" element={<AustinServices />} />
              <Route
                path="/membership-details"
                element={<MembershipDetails />}
              />
              <Route path="/support" element={<Support />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/privacy-terms" element={<Privacy />} />
              <Route path="/terms-of-use" element={<Terms />} />
              <Route
                path="/my-account"
                element={
                  <PrivateRoute>
                    <Account />
                  </PrivateRoute>
                }
              />

              <Route path="/sign-in" element={<SignIn />} />
              <Route path="/create-account" element={<SignUp />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/change-password" element={<ChangePassword />} />
              <Route
                path="/video-consultation/:id"
                element={<VideoMeeting />}
              ></Route>
              <Route
                path="/my-account/upcoming-consultations"
                element={
                  <PrivateRoute>
                    <Consultations type="upcoming" />
                  </PrivateRoute>
                }
              />
              <Route
                path="/my-account/manage-consultations"
                element={
                  <PrivateRoute>
                    <ManageConsultations />
                  </PrivateRoute>
                }
              />
              <Route
                path="/my-account/past-consultations"
                element={<Consultations type="past" />}
              />
              <Route
                path="/my-account/edit-account"
                element={<AccountDetails />}
              />

              <Route path="/my-account/calendar" element={<CalendarView />} />
              <Route path="/my-account/users" element={<Users />} />
              <Route path="/my-account/pros" element={<Pros />} />
              <Route path="/my-account/pros/:id" exact element={<EditPro />} />
              <Route
                path="/my-account/pros/create"
                element={
                  <PrivateRoute>
                    <EditPro />
                  </PrivateRoute>
                }
              />
              <Route
                path="/my-account/gift-cards"
                element={<GiftCardsAdmin />}
              />
              <Route path="/my-account/promo-codes" element={<PromoCodes />} />
              <Route
                path="/my-account/testimonials"
                element={<TestimonialsAdmin />}
              />
              <Route path="/apply" element={<ProApply />} />
              <Route path="/checkout" element={<YourOrder />} />
              <Route path="/booking-success/:id" element={<Success />} />
              <Route path="/my-account/schedule" element={<SetSchedule />} />
              <Route path="/my-account/pricing" element={<SetPricing />} />
              <Route
                path="/my-account/consultation-details/:id"
                element={
                  <PrivateRoute>
                    <ConsultationDetails />
                  </PrivateRoute>
                }
              />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </UserProvider>
  );
}

export default App;
