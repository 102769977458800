import { Box, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2/";
import PaintRoller from "../../Assets/Images/paint-roller.png";
import { useMediaQuery } from "react-responsive";
import MailingList from "../../SharedComponents/MailingList";
import Footer from "../../SharedComponents/Footer";

const SupportPage = () => {
  const isDesktop = useMediaQuery({ minWidth: 1201 });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          minHeight: "800px",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "top",
          backgroundImage: `url(${PaintRoller})`,
          backgroundColor: "cream.main",
        }}
      >
        <Grid
          container
          width="100%"
          maxWidth="1440px"
          mx={isPortrait ? "20px" : "44px"}
          mt="115px"
          mb="122px"
        >
          {isDesktop && (
            <Grid
              lg={1}
              width="100%"
              height="191px"
              sx={{
                border: 1,
                borderRight: 0,
                color: "pink.main",
                background:
                  "linear-gradient(to top left,transparent 0,transparent calc(50% - 0.8px),#f7cbad 50%,transparent calc(50% + 0.8px),transparent),linear-gradient(to top right,transparent 0,transparent calc(50% - 0.8px),#f7cbad 50%,transparent calc(50% + 0.8px),transparent)",
              }}
            ></Grid>
          )}
          <Grid
            item
            xs={12}
            lg={3}
            width="100%"
            height="191px"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "white",
              border: 1,

              color: "pink.main",
            }}
          >
            <Typography
              sx={{
                whiteSpace: "pre-line",
                textAlign: "center",
                my: "63px",
                pt: "10px",
                color: "olive.main",
              }}
              display="block"
              variant="h1"
            >
              {"Contact Us"}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            lg={8}
            width="100%"
            height="auto"
            sx={{
              position: "relative",
              zIndex: 1,
              ml: "-1px",
              display: "block",
              justifyContent: "center",
              backgroundColor: "white",
              border: 1,
              mt: "85px",
              px: isPortrait ? "20px" : "65px",

              color: "pink.main",
            }}
          >
            <Typography
              sx={{
                whiteSpace: "pre-line",
                mt: "60px",

                color: "lightOlive.main",
              }}
              display="block"
              variant="paragraphSansLarge"
            >
              {"Don't see your project listed"}
            </Typography>
            <Typography
              sx={{
                whiteSpace: "pre-line",
              }}
              display="block"
              variant="paragraphSans"
              color="blue.main"
            >
              {"Tell us what you want a Pro to help you with next by emailing"}
            </Typography>
            <a href="mailto:support@matriarchybuild.com">
              <Typography
                sx={{
                  whiteSpace: "pre-line",
                  "&:hover": {
                    color: "pink.main",
                  },
                }}
                display="block"
                variant="paragraphSans"
                color="blue.main"
              >
                {"support@matriarchybuild.com"}
              </Typography>
            </a>
            <Typography
              sx={{
                whiteSpace: "pre-line",
                mt: "60px",

                color: "lightOlive.main",
              }}
              display="block"
              variant="paragraphSansLarge"
            >
              {"Before + After"}
            </Typography>
            <Typography
              sx={{
                whiteSpace: "pre-line",
              }}
              display="block"
              variant="paragraphSans"
              color="blue.main"
            >
              {
                "If you’re interested in sharing your own Before and After project, submit it to"
              }
            </Typography>
            <a href="mailto:info@matriarchybuild.com">
              <Typography
                sx={{
                  whiteSpace: "pre-line",
                  "&:hover": {
                    color: "pink.main",
                  },
                }}
                display="block"
                variant="paragraphSans"
                color="blue.main"
              >
                {"info@matriarchybuild.com"}
              </Typography>
            </a>
            <Typography
              sx={{
                whiteSpace: "pre-line",
                mt: "60px",

                color: "lightOlive.main",
              }}
              display="block"
              variant="paragraphSansLarge"
            >
              {"Press + Partnerships"}
            </Typography>
            <Typography
              sx={{
                whiteSpace: "pre-line",
              }}
              display="block"
              variant="paragraphSans"
              color="blue.main"
            >
              {
                "Looking to collaborate with Matriarchy Build? Very cool. Shoot us an email at"
              }
            </Typography>
            <a href="mailto:partnerships@matriarchybuild.com">
              <Typography
                sx={{
                  whiteSpace: "pre-line",
                  "&:hover": {
                    color: "pink.main",
                  },
                  mb: "60px",
                }}
                display="block"
                variant="paragraphSans"
                color="blue.main"
              >
                {"partnerships@matriarchybuild.com"}
              </Typography>
            </a>
          </Grid>
        </Grid>
      </Box>
      <MailingList />
      <Footer />
    </Box>
  );
};

export default SupportPage;
