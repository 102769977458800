import { Box, useTheme } from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React from "react";
import { blueBox } from "../../../../../theme";
import Step1 from "../../../../../Assets/Images/step1.svg";
import Step2 from "../../../../../Assets/Images/step2.svg";
import Step3 from "../../../../../Assets/Images/step3.svg";
import Step4 from "../../../../../Assets/Images/step4.svg";
import DownArrow from "../../../../../Assets/Images/arrow-down.svg";

const HowItWorksStaticCard = (props) => {
  const step = props.step;

  return (
    <Box
      width="100%"
      height="auto"
      sx={{
        borderColor: "blue.main",
      }}
    >
      <Stack direction="row" width="100%">
        <Stack direction="row" width="50%">
          <Stack
            height="182px"
            width="68px"
            spacing={2}
            sx={{
              ...blueBox,
            }}
          >
            {step == 1 && <img src={Step1} />}
            {step == 2 && <img src={Step2} />}
            {step == 3 && <img src={Step3} />}
            {step == 4 && <img src={Step4} />}
            <img src={DownArrow} />
          </Stack>
          <Box
            height="182px"
            width="100%"
            sx={{
              border: 1,
              borderLeft: 0,
              borderColor: "blue.main",
            }}
          >
            <Typography
              sx={{
                whiteSpace: "pre-line",
                textColor: "blue.main",
                ml: "26px",
                mt: "16px",
              }}
              display="block"
              variant="h1"
              color="blue.main"
            >
              {props.title}
            </Typography>
          </Box>
          <Box
            height="182px"
            width="68px"
            spacing={2}
            sx={{
              color: "blue.main",

              border: 1,
              borderLeft: 0,
              borderRight: 0,
              background:
                "linear-gradient(to top right,transparent calc(50% - 1px),#2d457c,transparent calc(50% + 1px))",
            }}
          ></Box>
        </Stack>
        <Box width="50%" 
        height="auto"
        minHeight="315px"
         sx={{ 
          border: 1, 
          borderBottom: 0,
          pb:"22px",
          color: "blue.main"
           }}>
          <Typography
            sx={{
              whiteSpace: "pre-line",
              textColor: "blue.main",

             
              mx: "15px",
              mt: "16px",
            }}
            display="block"
            variant="paragraphSansLarge"
            color="blue.main"
          >
            {props.text}
          </Typography>
          <Typography
            sx={{
              whiteSpace: "pre-line",
              textColor: "blue.main",
              mx: "15px",
              mt: "8px",
            }}
            display="block"
            variant="paragraphMono"
            color="blue.main"
          >
            {props.subtitle}
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};

export default HowItWorksStaticCard;
