import { useMediaQuery } from 'react-responsive';

import { Box, Grid, Stack, Typography } from '@mui/material';

import { ActionButton } from '../../../SharedComponents/Buttons';

import ArrowPink from '../../../Assets/Images/arrow-pink.svg';

import { useNavigate } from 'react-router-dom';

const ListItem = ({ children }) => {
  return (
    <li>
      <Typography variant="paragraphMono" color="blue.main">
        {children}
      </Typography>
    </li>
  );
};

const HandypersonServices = () => {
  const isDesktop = useMediaQuery({ minWidth: 1201 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const navigate = useNavigate();

  const commonProps = {
    width: '100%',
    spacing: 0,
    container: true,
    maxWidth: '72.6rem',
    margin: 'auto',
  };

  const navigateTo = (url) => () => {
    navigate(url);
  };

  const visit =
    (url, newTab = false) =>
    () => {
      if (newTab) {
        window.open(url, '_blank');
      } else {
        window.location.assign(url);
      }
    };

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        py: '36px',
        px: isDesktop ? '5rem' : '20px',
      }}
    >
      <Grid
        {...commonProps}
        px={isMobile ? '20px' : '36px'}
        justifyContent="center"
      >
        <Grid item xs={12} marginBottom="36px">
          <Typography variant="section_h1" color="blue.main">
            Handyperson Services
          </Typography>
        </Grid>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={12}>
            <Box
              sx={{ height: '100%', backgroundColor: 'extraLightOlive.main' }}
            >
              <Stack
                spacing={2}
                sx={{
                  p: '2rem',
                }}
              >
                <Typography variant="paragraphSansLarge" color="blue.main">
                  Maintenance + Safety
                </Typography>
                <ul style={{ listStylePosition: 'inside', paddingLeft: '0px' }}>
                  <ListItem>Air filter replacement</ListItem>
                  <ListItem>Water filter replacement</ListItem>
                  <ListItem>Dryer vent cleaning</ListItem>
                  <ListItem>Refrigerator coil vacuuming</ListItem>
                  <ListItem>Washer/dryer cleaning and filter changes</ListItem>
                  <ListItem>Smoke / carbon monoxide detector checks</ListItem>
                  <ListItem>
                    Adjust & repair cabinet hinges, replace hardware
                  </ListItem>
                  <ListItem>Adjust a door hinge or strike plate</ListItem>
                  <ListItem>Outdoor sprinkler inspection</ListItem>
                  <ListItem>
                    Gutter inspections, seal leaking gutter seams
                  </ListItem>
                  <ListItem>
                    Exterior walk-around for water / pest signs
                  </ListItem>
                  <ListItem>Re-stain and seal a deck</ListItem>
                  <ListItem>Power wash small areas</ListItem>
                </ul>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{ height: '100%', backgroundColor: 'extraLightOlive.main' }}
            >
              <Stack
                spacing={2}
                sx={{
                  p: '2rem',
                }}
              >
                <Typography variant="paragraphSansLarge" color="blue.main">
                  Handy Tasks + Odd Jobs
                </Typography>
                <ul style={{ listStylePosition: 'inside', paddingLeft: '0px' }}>
                  <ListItem>Caulking and grouting</ListItem>
                  <ListItem>Patching a wall, paint touch-ups</ListItem>
                  <ListItem>TV hanging and installation</ListItem>
                  <ListItem>Furniture assembly</ListItem>
                  <ListItem>Hanging mirrors, shelves and artwork</ListItem>
                  <ListItem>Door and doorknob adjustment</ListItem>
                  <ListItem>Closet adjustments</ListItem>
                  <ListItem>Replacing door handles and changing locks</ListItem>
                  <ListItem>Replacing light fixtures</ListItem>
                  <ListItem>Outlet and switch replacement</ListItem>
                  <ListItem>Fix slow sinks, running toilets</ListItem>
                  <ListItem>
                    Faucet replacement, inspection for leaks, aerator cleaning
                  </ListItem>
                  <ListItem>Smart home device installation</ListItem>
                </ul>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HandypersonServices;
