import { Box, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2/";
import { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import { useMediaQuery } from "react-responsive";
import { Link, Navigate } from "react-router-dom";

import { ActionButton, LinkButton } from "../../../SharedComponents/Buttons";
const PostHeader = (props) => {
  const [backgroundImage, setBackroundImage] = useState();
  const [image, setImage] = useState();
  const [categories, setCategories] = useState();
  const isDesktop = useMediaQuery({ minWidth: 1201 });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });

  const [navigate, setNavigate] = useState(false);
  useEffect(() => {
    async function loadPostImage() {
      const response = await fetch(
        `https://mblegacy.wpengine.com/wp-json/wp/v2/media/${props.post?.featured_media}`
      );
      if (!response.ok) {
        // oups! something went wrong
        return;
      }

      const imageJson = await response.json();

      const image = imageJson.media_details.sizes.full.source_url.replace(
        "matriarchybuild.com",
        "mblegacy.wpengine.com"
      );
      setImage(image);
    }
    if (props.post) {
      loadPostImage();
    }
    if (props.post?.categories.length === 2) {
      setCategories("DIY, Inspiration");
    } else if (props.post?.categories[0].id === 1) {
      setCategories("DIY");
    } else {
      setCategories("Inspiration");
    }
  }, [props.post]);

  return (
    <Box>
      {navigate && <Navigate to={props.post.slug} />}
      <Box
        width="100%"
        height="700px"
        className="single-post__image-wrapper"
        style={{ backgroundImage: `url(${image})` }}
      >
        <Box className="single-post__featured-image" />
      </Box>
      <Box
        sx={{
          width: "100%",
          px: isPortrait ? "20px" : "44px",
        }}
      >
        <Grid
          container
          width="100%"
          maxWidth="1440px"
          mt="115px"
          mx="auto"
          sx={{
            position: "relative",
            backgroundColor: "transparent",
            zIndex: 0,
          }}
        >
          {isDesktop && (
            <Grid
              lg={1}
              width="100%"
              height="191px"
              sx={{
                border: 1,
                borderRight: 0,
                color: "pink.main",
                background:
                  "linear-gradient(to top left,transparent 0,transparent calc(50% - 0.8px),#f7cbad 50%,transparent calc(50% + 0.8px),transparent),linear-gradient(to top right,transparent 0,transparent calc(50% - 0.8px),#f7cbad 50%,transparent calc(50% + 0.8px),transparent)",
              }}
            ></Grid>
          )}
          <Grid item xs={12} lg={3} width="100%">
            <Stack>
              <Box
                height="191px"
                sx={{
                  border: 1,

                  color: "pink.main",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "white",
                }}
              >
                <Typography
                  sx={{
                    whiteSpace: "pre-line",
                    textAlign: "center",
                    my: "63px",
                    px: isPortrait ? "20px" : "44px",
                    pt: "10px",
                    color: "blue.main",
                  }}
                  display="block"
                  variant="h2"
                  dangerouslySetInnerHTML={{
                    __html: props.post?.title.rendered,
                  }}
                />
              </Box>
              {props.isHero ? (
                <ActionButton
                  borderColor="pink.main"
                  onClick={() => setNavigate(true)}
                  title="Learn More"
                />
              ) : (
                <Stack direction="row" width="100%" height="auto">
                

                  <Stack width="70%" height="99px">
                    <Box
                      height="33px"
                      sx={{
                        display: "flex",
                        border: 1,
                        borderTop: 0,
                        alignItems: "center",
                        textAlign: "left",
                        borderColor: "pink.main",
                        backgroundColor: "white",
                      }}
                    >
                    
                      <Typography
                        sx={{
                          whiteSpace: "pre-line",
                          mt: "1px",
                          ml: "7px",
                        }}
                        display="block"
                        variant="paragraphMonoSmall"
                        color={"blue.main"}
                      >
                        {"by Matriarchy Build"}
                      </Typography>
                    </Box>
                    <Box
                      height="33px"
                      sx={{
                        border: 1,
                        display: "flex",
                        alignItems: "center",
                        textAlign: "left",
                        borderTop: 0,
                        borderColor: "pink.main",
                        backgroundColor: "white",
                      }}
                    >
                      <Typography
                        sx={{
                          whiteSpace: "pre-line",
                          mt: "1px",
                          ml: "7px",
                        }}
                        display="block"
                        variant="paragraphMonoSmall"
                        color="blue.main"
                      >
                        {categories}
                      </Typography>
                    </Box>
                  </Stack>
                </Stack>
              )}
            </Stack>
          </Grid>
          <Grid item xs={12} lg={8} width="100%">
            <Stack>
              {isDesktop && (
                <Box
                  height="500px"
                  sx={{
                    position: "relative",
                    zIndex: 1,
                    ml: "-1px",

                    border: 1,

                    color: "pink.main",
                    px: "85px",
                    py: "60px",
                    background:
                      "linear-gradient(to top right,transparent 0,transparent calc(50% - 0.8px),#f7cbad 50%,transparent calc(50% + 0.8px),transparent)",
                  }}
                ></Box>
              )}
              {!props.isHero && (
                <Box
                  p={isPortrait ? "20px" : "88px"}
                  sx={{
                    ml: "-1px",
                    backgroundColor: "white",
                    border: 1,
                    borderTop: !isDesktop ? 1 : undefined,
                    color: "pink.main",
                  }}
                >
                  <Typography
                    variant={
                      isPortrait ? "paragraphSans" : "paragraphSansLarge"
                    }
                    color="blue.main"
                    sx={{
                      "& p": {
                        fontSize: "24px",
                      },
                    }}
                    dangerouslySetInnerHTML={{
                      __html: props.post?.excerpt.rendered,
                    }}
                  />
                </Box>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default PostHeader;
