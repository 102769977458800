import { useState, useEffect, useCallback } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";

import { httpsCallable } from "firebase/functions";
import { functions } from "../../App";

// needed for the style wrapper
import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import { query, collection, getFirestore, getDocs, orderBy } from "firebase/firestore";
import Grid from "@mui/material/Unstable_Grid2/";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import DownArrow from "../../Assets/Images/down-arrow.svg";

const textStyles = {
  color: "blue.main",
  fontFamily: "Code Saver",
  fontStyle: "normal",
  fontWeight: "normal",
  fontSize: "14px",
  lineHeight: "17.5px",
};

const Arrow = () => {
  return (
    <Box sx={{ mr: "7px", mt: "2px" }}>
      <img src={DownArrow} />
    </Box>
  );
};

const ProSelect = (props) => {
  const [pros, setPros] = useState();
  const handleChange = useCallback((event) => {

    props.handleProSelect(event.target.value);
  } )

  const db = getFirestore();

  let queryPros = [];
  const getPros = async () => {
    const q = query(collection(db, "pros"), orderBy("lastName"));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const pro = {
        id: doc.id,
        ...doc.data(),
      };
      queryPros.push(pro);

    });
    setPros(queryPros);
  };

  useEffect(() => {
    // Update the document title using the browser API
    getPros();
  }, []);

  return (
    <FormControl sx={{ width: "100%", my: "22px" }}>
    <InputLabel
      id="project-type-label"
      sx={{
        mt: "5px",
  
        ...textStyles,
      }}
    >
      {"Select pro to view"}
    </InputLabel>
    <Select
      size="small"
      IconComponent={Arrow}
      variant="standard"
      disableUnderline
      width="100%"
      height="31px"
      label="Project Type"
      onChange={handleChange}
      inputProps={{
        MenuProps: {
          sx: {
            borderRadius: 0,
          },
          MenuListProps: {
            sx: {
              backgroundColor: "cream.main",
              borderRadius: 0,
            },
          },
        },
      }}
      sx={{
        borderRadius: 0,
        outline: "none",
        border: 1,
        borderColor: "blue.main",
  
        pl: "4px",
        pt: "2px",
        ...textStyles,
      }}
    >
      {pros && pros.map((pro) => (
        <MenuItem
          sx={{ ...textStyles, backgroundColor: "cream.main" }}
          value={pro}
          key={pro.id}
        >
          <Typography variant="paragraphMono">
            {`${pro.firstName} ${pro.lastName}`}
          </Typography> 
        </MenuItem>
      ))}
    </Select>
  </FormControl>
  )
}

export default ProSelect;
