import { Box, Grid, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import React from 'react';
import OnlineProsLarge from '../../Assets/Images/online-pros-large.svg';
import OnlineProsSmall from '../../Assets/Images/online-pros-small.svg';
import { useMediaQuery } from 'react-responsive';
import ProsGrid from '../../SharedComponents/ProsGrid';
import Arrow from '../../Assets/Images/arrow-olive.svg';
import GridButton from '../GridButtons';
import { Link } from 'react-router-dom';

const FeaturedPros = () => {
  const isDesktop = useMediaQuery({ minWidth: 1201 });

  const pros = [
    {
      firstName: 'AJ',
      lastName: 'Jenkins',
      slug: 'aj-jenkins',
      proTrades: [
        {
          displayName: 'Plumber',
        },
      ],
      headshot:
        'https://firebasestorage.googleapis.com/v0/b/mb-platform.appspot.com/o/proImages%2Fa0294f64-19f2-4025-8336-10bf01eed3e4?alt=media&token=dc51414f-e129-4b29-ab15-4d3dee3bc15a',
      featuredDescription: [
        'General plumbing guidance',
        'How to unclog a toilet',
        'How to change a kitchen faucet',
      ],
    },
    {
      firstName: 'Maven',
      lastName: 'Construction',
      slug: 'maven-construction',
      proTrades: [
        {
          displayName: 'General Contractor',
        },
      ],
      headshot:
        'https://firebasestorage.googleapis.com/v0/b/mb-platform.appspot.com/o/proImages%2F3ffa9341-5365-4083-8dd0-d3a9e4ae0748?alt=media&token=6d899916-4323-4daf-9523-52470437c1fc',
      featuredDescription: [
        'Remodel guidance and second opinions',
        'Vetting quotes',
        'Old house renovation tips',
      ],
    },
    {
      firstName: 'Mindy',
      lastName: 'Otten-Chen',
      slug: 'mindy-otten-chen',
      proTrades: [
        {
          displayName: 'Carpenter',
        },
        {
          displayName: 'General Contractor',
        },
      ],
      headshot:
        'https://firebasestorage.googleapis.com/v0/b/mb-platform.appspot.com/o/proImages%2Fe7389c67-a3f5-43df-bc98-a9e56f5a318e?alt=media&token=8a34c72b-c186-4c3e-8735-b7d237bcfc64',
      featuredDescription: [
        'Construction process and project management',
        'Timeline and budget questions',
        'Carpentry projects and basic woodworking',
      ],
    },
  ];

  const renderProGrid = () => (
    <>
      <ProsGrid pros={pros} highlight />
    </>
  );

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          width: '100%',
          height: 'auto',
          backgroundColor: 'cream.main',
        }}
      >
        {isDesktop ? (
          <>
            <Grid
              container
              display="flex"
              justifyContent="center"
              alignItems="center"
              margin="auto"
            >
              <Grid item xs={12}>
                <Stack
                  sx={{
                    pt: '85px',
                    mx: 'auto',
                    width: isDesktop ? '570px' : '100%',
                    textAlign: 'center',
                  }}
                >
                  <Typography
                    variant="paragraphSansLarge"
                    sx={{
                      color: 'blue.main',
                      display: 'block',
                      pb: '10px',
                    }}
                  >
                    Our Pros can help you whether you’re looking to tackle a DIY
                    or repair project or just looking for construction coaching.
                  </Typography>
                  <Link to="/pro">
                    <Typography
                      variant="paragraphMono"
                      sx={{
                        color: 'olive.main',
                        cursor: 'pointer',
                      }}
                    >
                      {'Schedule an online consultation '}
                      <img src={Arrow} />
                    </Typography>
                  </Link>
                </Stack>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  flex: '0 1 auto',
                }}
              >
                <Stack
                  maxWidth="1420px"
                  sx={{
                    mx: 'auto',
                    alignItems: 'start',
                    mt: '44px',
                    mb: '88px',
                  }}
                  direction={isDesktop ? 'row' : undefined}
                >
                  <Grid container direction="column" sx={{ width: 'unset' }}>
                    <Grid item>
                      <img src={OnlineProsLarge} style={{ width: '100%' }} />
                    </Grid>
                    <GridButton
                      textColor="blue"
                      backgroundColor="cream"
                      hoverTextColor="cream"
                      hoverBackgroundColor="lightOlive"
                      linkTo="/pro"
                      linkText="View All"
                      sx={{
                        marginTop: '-1px',
                        py: '20px',
                        borderTop: 1,
                        borderColor: 'blue.main',
                      }}
                    />
                  </Grid>
                  {renderProGrid()}
                </Stack>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Grid
              container
              wrap="wrap"
              direction="row"
              spacing={4}
              mx="60px"
              my="60px"
              marginLeft="28px"
            >
              <Grid item xs={12} sm={6} flex={1}>
                <Stack
                  sx={{
                    textAlign: 'center',
                    height: '100%',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box>
                    <img src={OnlineProsSmall} style={{ width: '100%' }} />
                  </Box>
                  <Box px="40px" py="50px">
                    <Typography
                      variant="paragraphSansLarge"
                      sx={{
                        color: 'blue.main',
                        display: 'block',
                        pb: '10px',
                      }}
                    >
                      Our Pros can help you whether you’re looking to tackle a
                      DIY or repair project or just looking for construction
                      coaching.
                    </Typography>
                    <Link to="/pro">
                      <Typography
                        variant="paragraphMono"
                        sx={{
                          color: 'olive.main',
                          cursor: 'pointer',
                        }}
                      >
                        {'Schedule an online consultation '}
                        <img src={Arrow} />
                      </Typography>
                    </Link>
                  </Box>
                </Stack>
              </Grid>
              <>{renderProGrid()}</>
            </Grid>
          </>
        )}
      </Box>
    </>
  );
};

export default FeaturedPros;
