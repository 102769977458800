import { Box, useTheme } from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { getDownloadURL, ref } from "firebase/storage";
import { useEffect, useState } from "react";
import { storage } from "../../../../../App";

const ProSearchCard = (props) => {
  const [imageURL, setImageURL] = useState("");
  const [tradeList, setTradeList] = useState("");
  useEffect(() => {
    if (typeof props?.headshot != "undefined") {
      setImageURL(props.headshot);
    }
    var tradeList = "";

    if (props.profession) {
      props.profession.forEach((trade) => {
      
        if (tradeList === "") {
          tradeList = trade.displayName;
        } else {
          tradeList = tradeList.concat(", ", trade.displayName);
        }
      });
      setTradeList(tradeList);
    }
  }, []);


  return (
    <Link to={"/pro/" + props.slug}>
      <Stack>
        <Box
          width="100%"
          height="77px"
          sx={{
            backgroundColor: "white",
            border: 1,
            color: "blue.main",
          }}
        >
          <Typography
            sx={{
              whiteSpace: "pre-line",
              textColor: "blue.main",
              ml: "8px",
              mt: "14px",
            }}
            display="block"
            variant="h3"
            color="blue.main"
          >
            {props.firstName}
          </Typography>
          <Typography
            sx={{
              whiteSpace: "pre-line",
              textColor: "blue.main",
              ml: "8px",
            }}
            display="block"
            variant="h3"
            color="blue.main"
          >
            {props.lastName}
          </Typography>
        </Box>
        <Box
          width="100%"
          height="281px"
          maxHeight="281px"
          sx={{ border: 1, borderTop: 0, color: "blue.main" }}
        >
          <Box
            width="100%"
            height="100%"
            sx={{
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundImage: `url(${imageURL})`,
            }}
          ></Box>
        </Box>
        <Box height="44px" sx={{ border: 1, borderTop: 0, color: "blue.main" }}>
          <Typography
            sx={{
              whiteSpace: "pre-line",
              textColor: "blue.main",
              ml: "8px",
              mt: "12px",
              fontSize:"14px",
            }}
            display="block"
            variant="paragraphMono"
            color="olive.main"
          >
            {tradeList}
          </Typography>
        </Box>
      </Stack>
    </Link>
  );
};

export default ProSearchCard;
