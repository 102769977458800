import { useCallback, useState, useEffect } from 'react';
import { Box, useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import Filter from './Components/Filter';
import ProSearchGrid from './Components/ProSearchGrid';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import CloseButton from '../../Assets/Images/close-button.svg';
import HowItWorksCarousel from '../../SharedComponents/HowItWorksCarousel';
import Footer from '../../SharedComponents/Footer';
import Workshop from '../../Assets/Images/workshop.jpg';
import Grid from '@mui/material/Unstable_Grid2/';
import { useMediaQuery } from 'react-responsive';
import {
  query,
  collection,
  getFirestore,
  doc,
  getDocs,
  where,
  orderBy,
  limit,
} from 'firebase/firestore';

const Chip = (props) => {
  return (
    <Box
      onClick={() => props.onClick(props.label)}
      height="36px"
      width="auto"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white',
        cursor: 'pointer',
      }}
    >
      <Stack
        direction="row"
        spacing={1}
        sx={{
          display: 'flex',
          mx: '8px',
        }}
      >
        <img width="20px" style={{ cursor: 'pointer' }} src={CloseButton} />
        <Typography
          sx={{
            whiteSpace: 'pre-line',
            textAlign: 'center',
            mr: '17px',
          }}
          display="block"
          variant="paragraphMonoSmall"
          color="blue.main"
        >
          {props.label}
        </Typography>
      </Stack>
    </Box>
  );
};

function dynamicSort(property) {
  var sortOrder = 1;
  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    var result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
}

const desktopCardStyle = {
  backgroundImage: `url(${Workshop})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  width: '100%',
  padding: '5rem',
  height: '695px',
  backgroundPosition: 'center center',
};

const commonProps = {
  width: '100%',
  spacing: 0,
  container: true,
  maxWidth: '72.6rem',
  margin: 'auto',
};

const FindAPro = () => {
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [trades, setTrades] = useState([]);
  const [services, setServices] = useState([]);
  const [region, setRegion] = useState();
  const [pros, setPros] = useState();
  const [filteredPros, setFilteredPros] = useState();

  const db = getFirestore();

  const getPros = async () => {
    let queryPros = [];
    let queryOrderedPros = [];
    const q = query(collection(db, 'pros'), where('verified', '==', true));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots

      const pro = {
        id: doc.id,
        ...doc.data(),
      };

      if (pro.position && pro.position > 0) {
        queryOrderedPros.push(pro);
      } else {
        queryPros.push(pro);
      }
    });
    queryOrderedPros.sort(dynamicSort('position'));

    queryOrderedPros.forEach((pro) => {
      if (pro.position < queryPros.length) {
        queryPros.splice(pro.position - 1, 0, pro);
      } else {
        queryPros.push(pro);
      }
    });
    setPros(queryPros);
  };

  useEffect(() => {
    // Update the document title using the browser API
    getPros();
  }, []);

  const proHasService = (pro, service) => {
    var hasService = false;
    pro.proServices?.forEach((proService) => {
      if (!hasService) {
        if (proService.shortName === service) {
          hasService = true;
        }
      }
    });

    return hasService;
  };

  const proHasTrade = (pro, trade) => {
    var hasTrade = false;
    pro.proTrades?.forEach((proTrade) => {
      if (!hasTrade) {
        if (proTrade.displayName === trade) {
          hasTrade = true;
        }
      }
    });

    return hasTrade;
  };

  useEffect(() => {
    const filtered = [];
    pros?.forEach((pro) => {
      var shouldInclude = true;
      services?.forEach((service) => {
        const hasService = proHasService(pro, service);

        if (!hasService) {
          shouldInclude = false;
        }
      });
      trades?.forEach((trade) => {
        const hasTrade = proHasTrade(pro, trade);

        if (!hasTrade) {
          shouldInclude = false;
        }
      });

      if (region) {
        if (pro.regionSelection !== region) {
          shouldInclude = false;
        }
      }
      if (shouldInclude) {
        filtered.push(pro);
      }
    });

    setFilteredPros(filtered);
  }, [services, trades, region, pros]);

  const handleServiceDelete = (service) => {
    var filtered = services.filter(function (el) {
      return el != service;
    });
    setServices(filtered);
  };

  const handleTradeDelete = useCallback((trade) => {
    var filtered = trades.filter(function (el) {
      return el != trade;
    });
    setTrades(filtered);
  });
  const handleRegionDelete = useCallback((region) => {
    setRegion(undefined);
  });

  const handleServiceChange = useCallback((event) => {
    const item = event.target.value;
    var tempArray = [...services];
    if (services.indexOf(item) === -1) {
      tempArray.push(item);
    }
    setServices(tempArray);
    event.target.value = '';
  });

  const handleTradeChange = useCallback((event) => {
    const item = event.target.value;
    var tempArray = [...trades];
    if (trades.indexOf(item) === -1) {
      tempArray.push(item);
    }
    setTrades(tempArray);
  });

  const handleRegionChange = useCallback((event) => {
    setRegion(event.target.value);
  });

  return (
    <>
      <Box
        sx={{
          ...(!isMobile && { ...desktopCardStyle }),
          ...(!isMobile && { height: '525px' }),
        }}
      >
        <Grid
          {...commonProps}
          margin="0px auto"
          px={isPortrait ? '0px' : '44px'}
        >
          {isMobile && (
            <Grid item xs={12}>
              <Box sx={{ overflow: 'hidden' }}>
                <img
                  src={Workshop}
                  style={{ maxWidth: '135%', marginLeft: '-20%' }}
                />
              </Box>
            </Grid>
          )}
          <Grid item xs={12}>
            <Box
              sx={{
                backgroundColor: 'olive.main',
                textAlign: 'center',
                padding: isMobile ? '2rem' : '4rem 3.5rem',
                height: 'fit-content',
                width: isMobile ? '100%' : 'fit-content',
                maxWidth: '71rem',
                ...(isMobile && { wordBreak: 'break-word' }),
              }}
            >
              <Stack alignItems="center">
                <Typography
                  variant="section_h1"
                  color="cream.main"
                  fontSize="40px"
                  width="100%"
                >
                  How it Works
                </Typography>
                <Typography
                  variant="section_h2"
                  color="white"
                  fontSize="24px"
                  width="100%"
                  marginTop="1rem"
                >
                  Matriarchy Build offers 1:1 virtual consultations for users to
                  get expert advice. Our Pros can help you whether you’re
                  looking to tackle a DIY or repair project or just looking for
                  construction coaching. You can sort our Pros by trade or
                  project – or email us at{' '}
                  <a href="mailto:support@matriarchybuild.com">
                    <Typography
                      variant="section_h2"
                      color="pink.main"
                      fontSize="24px"
                      sx={{
                        cursor: 'pointer',
                      }}
                    >
                      support@matriarchybuild.com
                    </Typography>
                  </a>{' '}
                  if you’re looking for a recommendation.
                </Typography>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        id="test"
        width="100%"
        height="auto"
        sx={{
          backgroundColor: 'cream.main',
        }}
      >
        <Box
          width="100%"
          sx={{
            px: isPortrait ? '20px' : '44px',
            pb: '70px',
          }}
        >
          <Stack>
            <Filter
              handleServiceChange={handleServiceChange}
              handleTradeChange={handleTradeChange}
              handleRegionChange={handleRegionChange}
            />
            <Grid
              container
              maxWidth="1080px"
              mx="auto"
              mb="20px"
              spacing={2}
              sx={{ overflow: 'auto' }}
            >
              {services.map((service, index) => {
                return (
                  <Grid key={index} item xs="auto">
                    <Chip label={service} onClick={handleServiceDelete} />
                  </Grid>
                );
              })}
              {trades.map((trade, index) => {
                return (
                  <Grid key={index} item xs="auto">
                    <Chip label={trade} onClick={handleTradeDelete} />
                  </Grid>
                );
              })}

              {region != undefined && (
                <Grid item xs="auto">
                  <Chip label={region} onClick={handleRegionDelete} />
                </Grid>
              )}
            </Grid>

            <ProSearchGrid pros={filteredPros} />
          </Stack>
        </Box>
        <Box
          width="100%"
          height="auto"
          sx={{
            backgroundColor: 'blue.main',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            px: isPortrait ? '20px' : undefined,
          }}
        >
          <Box
            width="100%"
            sx={{
              display: 'inline',
              py: '75px',
              mx: isPortrait ? '20px' : '44px',
              maxWidth: '664px',
            }}
          >
            <Typography
              sx={{
                whiteSpace: 'pre-line',
                textColor: 'blue.main',
                mb: '10px',
              }}
              display="block"
              variant={isPortrait ? 'h3' : 'h2'}
              color="white"
            >
              {'Not Sure which pro to work with?'}
            </Typography>

            <Typography
              sx={{
                whiteSpace: 'pre-line',
                textColor: 'blue.main',
              }}
              display="inline"
              variant={isPortrait ? 'paragraphSans' : 'paragraphSansLarge'}
              color="white"
            >
              {'Email us at'}
            </Typography>
            <a href="mailto:support@matriarchybuild.com">
              <Typography
                sx={{
                  ml: '.5rem',
                  mr: '.5rem',
                  whiteSpace: 'pre-line',
                  textColor: 'pink.main',
                  '&:hover': {
                    color: 'white',
                  },
                }}
                display="inline"
                variant={isPortrait ? 'paragraphSans' : 'paragraphSansLarge'}
                color="pink.main"
              >
                {'support@matriarchybuild.com'}
              </Typography>
            </a>
            <Typography
              sx={{
                whiteSpace: 'pre-line',
                textColor: 'blue.main',
              }}
              display="inline"
              variant={isPortrait ? 'paragraphSans' : 'paragraphSansLarge'}
              color="white"
            >
              {
                "with a description of your project and we'll point you in the right direction. "
              }
            </Typography>
          </Box>
        </Box>
        <HowItWorksCarousel theme="olive" />
        <Footer />
      </Box>
    </>
  );
};

export default FindAPro;
