import { Box, useTheme } from "@mui/material";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import SiteMapOption from "./Components/SiteMapOption";
import Logo from "../../Assets/Images/logo-footer.svg";
import ArrowWhite from "../../Assets/Images/arrow-white.svg";
import TwitterIcon from "../../Assets/Images/twitter-icon.svg";
import Pinterest from "../../Assets/Images/pinterest.svg";
import TikTok from "../../Assets/Images/tiktok.svg";
import YouTube from "../../Assets/Images/youtube.svg";
import { useMediaQuery } from "react-responsive";
import Instagram from "../../Assets/Images/instagram.svg";
const Footer = () => {
  const isDesktop = useMediaQuery({ minWidth: 1201 });
  const isPortrait = useMediaQuery({ orientation: "portrait" });

  const message =
    "If you’d like to join our roster of Matriarchy Build Pros please click here to fill out our simple questionnaire. The Matriarchy Build roster features Pros across a variety of trades. We are always looking to connect with great folks in the build space to grow our network of Pros.";
  return (
    <Grid container width="100%" height="357px">
      <Grid
        item
        xs={12}
        lg={8}
        height="100%"
        sx={{
          display: "flex",
          alignItems: "center",

          backgroundColor: "blue.main",
          flexGrow: "100",
        }}
      >
        {isDesktop && (
          <Box sx={{ ml: "50px" }}>
            <img src={Logo} />
          </Box>
        )}
        <Stack
          width="600px"
          maxWidth="800px"
          sx={{ mx: isPortrait ? "20px" : "44px", flexGrow: 100 }}
        >
          <Typography
            sx={{
              whiteSpace: "pre-line",
              textAlign: "left",
              mb: "12px",
            }}
            display="block"
            variant="h3"
            color="white"
          >
            Apply to be a pro
          </Typography>
          <Typography
            sx={{
              whiteSpace: "pre-line",
              textAlign: "left",
              mb: "12px",
            }}
            display="block"
            variant="paragraphMono"
            color="pink.main"
          >
            {message}
          </Typography>
          <Stack
            direction="row"
            sx={{
              pt: "4px",
              textAlign: "center",
            }}
          >
            <Link to="/apply">
              <Typography
                sx={{
                  whiteSpace: "pre-line",
                  mr: "7px",
                  "&:hover": {
                    color: "pink.main",
                  },
                }}
                display="block"
                variant="paragraphMono"
                color="white"
              >
                {"Apply Today"}
              </Typography>
            </Link>
            <img src={ArrowWhite} />
          </Stack>
        </Stack>
      </Grid>
      <Grid
        item
        xs={12}
        lg={4}
        width="100%"
        height="100%"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "pink.main",
        }}
      >
        <Stack
          width="100%"
          sx={{ mt: "20px", ml: "21px", mr: isPortrait ? "21px" : "46px" }}
        >
          <SiteMapOption title="Gift Cards" linkTo="/gift-card" />
          <SiteMapOption title="FAQs" linkTo="/faq" />
          <Box
            sx={{
              flexGrow: "100",
              borderBottom: 1,
              boderColor: "blue.main",
            }}
          >
            <a href="https://shop.matriarchybuild.com" target="_blank">
              <Typography
                sx={{
                  whiteSpace: "pre-line",
                  mt: "6px",
                  mb: "4px",
                  fontSize: "14px",
                  "&:hover": {
                    color: "white",
                  },
                }}
                display="block"
                variant="paragraphMono"
                color="blue.main"
              >
                Shop MBuild Merch
              </Typography>
            </a>
          </Box>
          <SiteMapOption title="Contact + Support" linkTo="/support" />
          <SiteMapOption title="About Us" linkTo="/about" />
          <SiteMapOption title="Privacy Policy" linkTo="/privacy-terms" />
          <SiteMapOption title="Terms of Use" linkTo="/terms-of-use" noBorder />
          <Stack direction="row" sx={{ mt: "30px" }} spacing={2}>
            <a
              target="_blank"
              href="https://www.instagram.com/matriarchybuild/"
            >
              <img src={Instagram} />
            </a>
            <a target="_blank" href="https://www.tiktok.com/@matriarchybuild">
              <img src={TikTok} />
            </a>
            <a
              target="_blank"
              href="https://www.pinterest.com/04dexx5gswbh2v4on157ftn5y7pzq9/_saved/"
            >
              <img src={Pinterest} style={{ marginTop: "-2px" }} />
            </a>
            <a
              target="_blank"
              href="https://www.youtube.com/channel/UCVIsS1vBde3-csSrrLrJQ9Q"
            >
              <img src={YouTube} />
            </a>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default Footer;
