import { Box } from "@mui/material";
import { useContext, useState, useEffect } from "react";
import { UserContext } from "../../../../user-context";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useMediaQuery } from "react-responsive";
import SideBar from "../../SideBar";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import { DataGrid } from "@mui/x-data-grid";
import Footer from "../../../../SharedComponents/Footer";
import Input from "@mui/material/Input";
import { debounce } from "@mui/material/utils";

import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import {
  query,
  collection,
  getFirestore,
  doc,
  getDocs,
  getDoc,
  onSnapshot,
  where,
  updateDoc,
  orderBy,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { LinkButton } from "../../../../SharedComponents/Buttons";
import Grid from "@mui/material/Unstable_Grid2/";
import { CheckBox } from "@mui/icons-material";

const commonStyles = {
  pl: "4px",
  pt: "4px",
  mb: "12px",
  mx: "auto",
  fontFamily: "Code Saver",
  fontStyle: "normal",
  fontWeight: "normal",
  fontSize: "16px",
  lineHeight: "145%",
  color: "blue.main",
  width: "50px",
  border: 1,
  borderColor: "lightOlive.main",
};

const options = {
  weekday: "long",
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "2-digit",
  minute: "2-digit",
};

const ManageConsultations = (props) => {
  const isDesktop = useMediaQuery({ minWidth: 1201 });
  const isMedium = useMediaQuery({ minWidth: 901 });
  const [userType, setUserType] = useState("user");
  const [appointments, setAppointments] = useState([]);
  const db = getFirestore();

  const user = useContext(UserContext);

  const setUserAttributes = async (id) => {
    if (user) {
      user.user
        .getIdTokenResult()
        .then((idTokenResult) => {
          if (idTokenResult.claims.userType === "admin") {
            setUserType("admin");
          } else {
            setUserType("user");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    if (user) {
      setUserAttributes(user.uid);
    }
  }, [user]);

  const getCustomers = async (queryAppointments) => {
    var apptsWithCustomers = [];
    await Promise.all(queryAppointments.map(async (appointment) => {
      const userRef = doc(db, `customers/${appointment.userId}`);

      const userDoc = await getDoc(userRef);
  
      if (userDoc.data()) {
        if (userDoc.data().firstName && userDoc.data().lastName) {
          appointment.customerName = userDoc.data().firstName.concat(` ${userDoc.data().lastName}`);
  
        } else if (userDoc.data().displayName){
          appointment.customerName = userDoc.data().displayName;
        } else (
          console.log ("no name found for ", userDoc.data())
        )
    
        appointment.customerEmail = userDoc.data().email;
        apptsWithCustomers.push(appointment);
      }
    }));
    console.log(apptsWithCustomers);
    setAppointments([...apptsWithCustomers]);
  };
  const getAppointments = async () => {
    if (userType === "admin") {
      const q = query(
        collection(db, "appointments"),
        where("confirmed", "==", true),
        orderBy("createdAt", "desc")
      );

      const querySnapshot = await getDocs(q);
      var unsubscribe = onSnapshot(q, (querySnapshot) => {
        let queryAppointments = [];
        querySnapshot.forEach(async (doc) => {
          // doc.data() is never undefined for query doc snapshots
          const appointment = doc.data();
          if (appointment.startTime) {
            appointment.createdAt = appointment.createdAt.toDate();
            appointment.startTime = appointment.startTime;
          }
          if (appointment.giftCard) {
            if (appointment.giftCard.cardDetails.balance > 0) {
              appointment.zeroCost = true;
            } else {
              appointment.zeroCost = false;
            }
          }
         
          const appt = {
            id: doc.id,
            ...appointment,
          };
          queryAppointments.push(appt);
        });
        getCustomers(queryAppointments);
      });

      return () => unsubscribe();
    }
  };

  useEffect(() => {
    getAppointments();
  }, [userType]);

  const columns = [
    {
      field: "createdAt",
      headerName: "Booked Created",
      width: "270",
      renderCell: ({ row: { createdAt } }) => {
        return (
          <Typography variant="paragraphMonoSmall" color="blue.main">
            {new Date(createdAt).toLocaleTimeString("en-us", options)}
          </Typography>
        );
      },
    },
    {
      field: "pro",
      headerName: "Pro",
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: ({ row: { pro, id } }) => {
        return (
          <Typography variant="paragraphMonoSmall" color="blue.main">
            {`${pro.firstName} ${pro.lastName}`}
          </Typography>
        );
      },
    },
    {
      field: "customerName",
      headerName: "Customer Name",
      flex: 1,
      cellClassName: "name-column--cell",
     
    },
    {
      field: "customerEmail",
      headerName: "Customer Email",
      flex: 1,
      cellClassName: "name-column--cell",
     
    },
  
    {
      field: "startTime",
      headerName: "Appointment Time",
      width: "270",
      renderCell: ({ row: { startTime } }) => {
        return (
          <Typography
            variant="paragraphMonoSmall"
            color={
              new Date(startTime) > new Date() ? "blue.main" : "lightOlive.main"
            }
          >
            {new Date(startTime).toLocaleTimeString("en-us", options)}
          </Typography>
        );
      },
    },

    {
      field: "id",
      headerName: "Notes",
      width: "90",
      renderCell: ({ row: { id } }) => {
        return (
          <Link to={`/my-account/consultation-details/${id}`}>
            <Stack
              direction="row"
              width="80px"
              m="0 auto"
              p="5px"
              display="flex"
              justifyContent="center"
              backgroundColor="cream.main"
            >
              <Typography variant="paragraphMonoSmall" color={"blue.main"}>
                {"Notes"}
              </Typography>
            </Stack>
          </Link>
        );
      },
    },
    {
      field: "price",
      headerName: "Price",
      width: "75",
      cellClassName: "name-column--cell",
      renderCell: ({ row: { type } }) => {
        return (
          <Typography variant="paragraphMonoSmall" color="blue.main">
            {`$${type.price}`}
          </Typography>
        );
      },
    },
    {
      field: "zeroCost",
      headerName: "Free",
      width: "80",
      renderCell: ({ row: { zeroCost } }) => {
        return (
          <Typography variant="paragraphMonoSmall" color="blue.main">
            {zeroCost ? "yes" : "no"}
          </Typography>
        );
      },
    },
  ];

  return (
    <Box width="100%" minHeight="766px" height="auto">
      <Stack
        width="100%"
        minHeight="766px"
        direction={isDesktop ? "row" : undefined}
      >
        <Box width={isDesktop ? "345px" : "100%"}>
          <SideBar type={props.type} />
        </Box>
        <Box width="100%" height="auto" sx={{ mx: "44px" }}>
          <Box width="66%" sx={{ borderBottom: 1, color: "lightOlive.main" }}>
            <Typography
              sx={{
                whiteSpace: "pre-line",
                color: "olive.main",
                mr: "5px",
                mt: "66px",
                mb: "8px",
              }}
              variant="h3"
            >
              {"Manage Consulatations"}
            </Typography>
          </Box>
          <Box
            m="40px 40px 0 0"
            height={
              appointments?.length ? `${appointments.length * 58}px` : "800px"
            }
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {},
              "& .MuiDataGrid-columnHeaders": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {},
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: "pink.main",
                fontFamily: "Code Saver",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "12px",
                lineHeight: "145%",
                mb: "40px",
              },
              "& .MuiTablePagination-selectLabel": {
                fontFamily: "Code Saver",
              },
              "& .MuiSelect-select": {
                fontFamily: "Code Saver",
              },
              "& .MuiTablePagination-displayedRows": {
                fontFamily: "Code Saver",
              },
            }}
          >
            <DataGrid
              initialState={{
                sorting: {
                  sortModel: [{ field: "lastName", sort: "asc" }],
                },
              }}
              sx={{
                fontFamily: "Code Saver",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "12px",
                lineHeight: "145%",
                mt: "44px",
                color: "blue.main",
              }}
              rows={appointments}
              columns={columns}
            />
          </Box>
        </Box>
      </Stack>
      <Footer />
    </Box>
  );
};

export default ManageConsultations;
