import { Box, useTheme } from "@mui/material";
import { useContext } from "react";
import { UserContext } from "../../../user-context";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Input from "@mui/material/Input";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { Stack } from "@mui/system";
import Checkbox from "@mui/material/Checkbox";
import ArrowWhite from "../../../Assets/Images/arrow-white.svg";
import { useMediaQuery } from "react-responsive";
import MailingList from "../../../SharedComponents/MailingList";
import Footer from "../../../SharedComponents/Footer";
import { ActionButton } from "../../../SharedComponents/Buttons";
import {
  getAuth,
  GoogleAuthProvider,
  sendPasswordResetEmail,

} from "firebase/auth";
import { useEffect, useState } from "react";

const commonStyles = {
  pl: "4px",
  pt: "4px",
  mb: "12px",
  fontFamily: "Code Saver",
  fontStyle: "normal",
  fontWeight: "normal",
  fontSize: "16px",
  lineHeight: "145%",
  color: "blue.main",

  border: 1,
  borderColor: "lightOlive.main",
};


const ForgotPassword = () => {
  const isDesktop = useMediaQuery({ minWidth: 1201 });
  const isMedium = useMediaQuery({ minWidth: 901 });
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState ("");
  const [error, setError] = useState("");

  const provider = new GoogleAuthProvider();
  const navigate = useNavigate();

  const user = useContext(UserContext).user;

  const sendPasswordReset = (e) => {
    const auth = getAuth();
    auth.useDeviceLanguage();
    sendPasswordResetEmail(auth, email)
    .then(() => {
     setMessage("Reset password email sent")
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      setError(`${error}`)
    });
  };

  return (
    <Box>
      <Box
        width="100%"
        height="auto"
        sx={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "cream.main",
          py: "136px",
        }}
      >
        <Grid container width="100%" maxWidth="420px" mx="44px">
         
            <Stack width="100%" mt="68px">
              <Typography
                sx={{
                  whiteSpace: "pre-line",
                  color: "blue.main",
                  mb: "20px",
                }}
                display="block"
                variant="paragraphMono"
              >
                {"Enter your email"}
              </Typography>
              <Input
                type="text"
                disableUnderline
                fullWidth
                height="40px"
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
                sx={{
                  ...commonStyles,
                }}
              ></Input>
             
              <ActionButton title="Send Email" onClick={sendPasswordReset} />
              {error && <Typography
                sx={{
                  whiteSpace: "pre-line",
                  color: "blue.main",
                  mb: "20px",
                }}
                display="block"
                variant="paragraphMono"
              >
                {error}
              </Typography> }
              {message &&  <Typography
                sx={{
                  whiteSpace: "pre-line",
                  color: "blue.main",
                  mb: "20px",
                }}
                display="block"
                variant="paragraphMono"
              >
                {message}
              </Typography>}
            </Stack>


         
        </Grid>
      </Box>
      <MailingList />
      <Footer />
    </Box>
  );
};

export default ForgotPassword;
