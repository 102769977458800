import { Box, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2/';
import WoodCarving from '../../Assets/Images/FAQ_image.jpeg';
import GeneralFAQs from '../../SharedComponents/FAQ/Components/GeneralFAQs';
import FindABuilderFAQs from '../../SharedComponents/FAQ/Components/FindABuilderFAQs';
import { useMediaQuery } from 'react-responsive';
import MailingList from '../../SharedComponents/MailingList';
import Footer from '../../SharedComponents/Footer';
import LocalServicesFAQs from '../../SharedComponents/FAQ/Components/LocalServicesFAQs';
import DetailFAQs from '../../SharedComponents/FAQ/Components/DetailFAQs';

const commonProps = {
  width: '664px',
  margin: 'auto',
};

const headerProps = {
  variant: 'h2',
};

const Support = () => {
  const isDesktop = useMediaQuery({ minWidth: 1201 });
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'contain',
          width: '100%',

          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          backgroundPosition: 'top',
          backgroundImage: `url(${WoodCarving})`,
          backgroundColor: 'olive.main',
        }}
      >
        <Grid
          container
          width="100%"
          maxWidth="1440px"
          mx="44px"
          mt="115px"
          mb="122px"
        >
          {isDesktop && (
            <Grid
              lg={1}
              width="100%"
              height="191px"
              sx={{
                border: 1,
                borderRight: 0,
                color: 'pink.main',
                background:
                  'linear-gradient(to top left,transparent 0,transparent calc(50% - 0.8px),#f7cbad 50%,transparent calc(50% + 0.8px),transparent),linear-gradient(to top right,transparent 0,transparent calc(50% - 0.8px),#f7cbad 50%,transparent calc(50% + 0.8px),transparent)',
              }}
            ></Grid>
          )}
          <Grid
            item
            xs={12}
            lg={3}
            width="100%"
            height="191px"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'white',
              border: 1,

              color: 'pink.main',
            }}
          >
            <Typography
              sx={{
                whiteSpace: 'pre-line',
                textAlign: 'center',
                my: '63px',
                pt: '10px',
                color: 'olive.main',
              }}
              display="block"
              variant="h1"
            >
              {'FAQ'}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            lg={8}
            width="100%"
            height="auto"
            sx={{
              position: 'relative',
              zIndex: 1,
              ml: '-1px',
              display: 'flex',
              justifyContent: 'center',
              backgroundColor: 'white',
              border: 1,
              mt: '85px',
              color: 'pink.main',
            }}
          >
            <Grid container flexDirection="column">
              <Grid item xs={12} sx={{ pt: '86px' }}>
                <Box {...commonProps}>
                  <Typography {...headerProps}>
                    Online Consultations FAQs
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <GeneralFAQs noTitle="true" large />
              </Grid>
              <Grid item xs={12}>
                <Box {...commonProps}>
                  <Typography {...headerProps}>Find a Builder FAQs</Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box {...commonProps}>
                  <FindABuilderFAQs hideSection="panel3" />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box {...commonProps}>
                  <Typography {...headerProps}>Local Services FAQs</Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <DetailFAQs noTitle />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <MailingList />
      <Footer />
    </Box>
  );
};

export default Support;
