import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";

// color design tokens export
export const colors = () => ({
  pink: "#F7CBAD",
  blue: "#2D457C",
  olive: "#555433",
  lightOlive: "#BBB69B",
  cream: "#FBF8EA",
  white: "FFFFFF",
});

// mui theme settings
export const appTheme = createTheme({
  palette: {
    primary: {
      main: '#2D457C',
    },
    blue: {
      main: "#2D457C",
    },
    pink: {
      main: "#F7CBAD",
    },
    background: {
      main: "#FBF8EA",
    },
    cream: {
      main: "#FBF8EA",
    },
    olive: {
      main: "#555433",
    },
    lightOlive: {
      main: "#BBB69B",
    },
    extraLightOlive: {
      main: "#F1F0EA",
    },
  },
  typography: {
    button: {
      textTransform: 'none'
    },
    fontFamily: "BN Nautic Grotesk",
    fontSize: 12,
    h1: {
      fontFamily: "BN Nautic Grotesk",
      fontStyle: "normal",
     
      fontSize: "55px",
      lineHeight: "90%",
      letterSpacing: "0.03em",
      textTransform: "uppercase",
    },
    h2: {
      fontFamily: "BN Nautic Grotesk",
      fontStyle: "normal",
      
      fontSize: "38px",
      lineHeight: "36px",
      letterSpacing: "0.03em",
      textTransform: "uppercase",
    },
    h3: {
      fontFamily: "BN Nautic Grotesk",
      fontStyle: "normal",

      fontSize: "28px",
      lineHeight: "27px",
      letterSpacing: "0.03em",
      textTransform: "uppercase",
    },
    hero: {
      fontFamily: "BN Times Gothic",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "40px",
      lineHeight: "115%",
    },
    paragraphSans: {
      fontFamily: "BN Times Gothic",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "20px",
      lineHeight: "125%",
    },
    paragraphSansLarge: {
      fontFamily: "BN Times Gothic",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "28px",
      lineHeight: "125%",
    },
    paragraphMono: {
      fontFamily: "Code Saver",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "145%",
    },
    paragraphMonoLarge: {
      fontFamily: "Code Saver",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "36px",
      lineHeight: "135%",
    },
    paragraphMonoSmall: {
      fontFamily: "Code Saver",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "145%",
    },
    grimer: {
      fontFamily: "BN Grimer",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "44px",
      lineHeight: "145%",
    },
    grimer_small: {
      fontFamily: "BN Grimer",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "24px",
      lineHeight: "145%",
    },
    section_h1: {
      fontFamily: "BN Times Gothic",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "40px",
      lineHeight: "115%",
    },
    section_h2: {
      fontFamily: "BN Times Gothic",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "24px",
      lineHeight: "115%",
    },
    section_h3: {
      fontFamily: "BN Times Gothic",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "20px",
    },
    section_body: {

    }
  },
  components: {
    MuiDatePicker: {
      styleOverrides: {
        root: {
          backgroundColor: 'red',
        },
      },
    },
  },
});

export const blueBox = {
  border: 1,
  borderColor: "blue.main",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign:"center"
};

export const pinkBox = {
  border: 1,
  borderColor: "pink.main",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign:"center"
};

