import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Input from "@mui/material/Input";
import { pinkBox } from "../../theme";
import ArrowBlue from "../../Assets/Images/arrow-blue.svg";
import { useMediaQuery } from "react-responsive";
import { useState } from "react";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../App";

const MailingList = () => {
  const isDesktop = useMediaQuery({ minWidth: 1201 });
  const isPortrait = useMediaQuery({ orientation: "portrait" });
  const [error, setError] = useState ("")
  const [email, setEmail] = useState();
  const message =
    "Positive vibes. No mansplaining. Ever. Follow along to learn about the newest Pros on the MBuild roster, our favorite DIY + renovation tips, and stories about badass women in the trades. Let’s build the matriarchy one project at a time.";

  const addEmailToList = async () => {
    if (!email) {
      setError("Please enter an email address")
      return;
    }
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email))
    { 
      setError ("Please enter a valid email address")
      return;
    }
    const addEmail = httpsCallable(functions, "addEmailToList");
    const addEmailToList = await addEmail({
      email: email,
      list: "VuxPfb",
    });
    setError("Success!")
  };

  return (
    <Box
      width="100%"
      height="auto"
      sx={{
        display: "flex",
        justifyContent: "center",
        backgroundColor: "olive.main",
        px: isPortrait ? "20px" : "44px",
      }}
    >
      <Stack
        direction={isDesktop ? "row" : undefined}
        width="100%"
        maxWidth="1440px"
        sx={{
          mt: "81px",
          mb: "118px",
        }}
      >
        <Stack direction="row">
          {!isDesktop && (
            <Box
              width="84px"
              height="100%"
              sx={{
                border: 1,
                borderBottom: 0,
                borderRight: 0,
                color: "pink.main",
                background:
                  "linear-gradient(to top left,transparent 0,transparent calc(50% - 0.8px),#f7cbad 50%,transparent calc(50% + 0.8px),transparent),linear-gradient(to top right,transparent 0,transparent calc(50% - 0.8px),#f7cbad 50%,transparent calc(50% + 0.8px),transparent)",
              }}
            ></Box>
          )}

          <Box
            width={isDesktop ? "515px" : "100%"}
            height={isDesktop ? "auto" : "106px"}
            sx={{
              ...pinkBox,
              display: "flex",
              flexGrow: 100,
              justifyContent: "center",
              color: "pink.main",
              borderBottom: isDesktop ? 1 : 0,
            }}
          >
            <Typography
              sx={{
                whiteSpace: "pre-line",
                textAlign: "center",
              }}
              display="block"
              variant={isDesktop ? "h1" : "h2"}
              color="white"
            >
              {"Stay up to \n date with us"}
            </Typography>
          </Box>
        </Stack>
        <Box
          height="auto"
          maxWidth={isDesktop ? "770px" : undefined}
          sx={{
            ...pinkBox,
            flexGrow: "100",
            color: "pink.main",
            borderLeft: isDesktop ? 0 : 1,
          }}
        >
          <Stack
            sx={{
              mx: isPortrait ? "20px" : "38px",
              mb: "60px",
            }}
          >
            <Typography
              sx={{
                whiteSpace: "pre-line",
                textAlign: "left",
                mt: isPortrait ? "45px" : "74px",
                mb: "12px",
              }}
              display="block"
              variant={isPortrait ? "paragraphSans" : "paragraphSansLarge"}
              color="pink.main"
            >
              {message}
            </Typography>
            <Input
              type="text"
              disableUnderline
              fullWidth
              height="40px"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{
                pl: "4px",
                pt: "4px",
                mb: "12px",
                fontFamily: "Code Saver",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "16px",
                lineHeight: "145%",
                textColor: "olive.main",
                backgroundColor: "white",
                border: 1,
                borderColor: "lightOlive.main",
              }}
            ></Input>
            <button
              height="40px"
              onClick={addEmailToList}
              style={{ padding: 0, border: 0 }}
            >
              <Box
                height="40px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                // fullWidth
                color="lightOlive.main"
                sx={{
                  backgroundColor: "pink.main",
                  border: 1,
                  cursor: "pointer",
                  color: "olive.main",
                  "&:hover": {
                    backgroundColor: "cream.main",
                    color: "white",
                    fill: "white",
                  },
                }}
              >
                <Stack
                  direction="row"
                  sx={{
                    pt: "4px",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    sx={{
                      whiteSpace: "pre-line",
                      mr: "7px",
                      color: "olive.main",
                    }}
                    display="block"
                    variant="paragraphMono"
                  >
                    {"Subscribe"}
                  </Typography>
                  <img src={ArrowBlue} style={{ fill: "inherit" }} />
                </Stack>
              </Box>
            </button>
            <Typography
              sx={{
                whiteSpace: "pre-line",
                textAlign: "left",
                my: "12px",
              }}
              display="block"
              variant={"paragraphMono" }
              color="pink.main"
            >
              {error}
            </Typography>
          </Stack>
        </Box>

        {isDesktop && (
          <Box
            width="151px"
            height="100%"
            sx={{
              ...pinkBox,
              borderLeft: 0,
              background:
                "linear-gradient(to top left,transparent 0,transparent calc(50% - 0.8px),#f7cbad 50%,transparent calc(50% + 0.8px),transparent),linear-gradient(to top right,transparent 0,transparent calc(50% - 0.8px),#f7cbad 50%,transparent calc(50% + 0.8px),transparent)",
            }}
          ></Box>
        )}
      </Stack>
    </Box>
  );
};

export default MailingList;
