import { useCallback, useState } from "react";
import { Box, useTheme } from "@mui/material";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import DownArrow from "../../Assets/Images/down-arrow.svg";
import Typography from "@mui/material";
const textStyles = {
  color: "blue.main",
  fontFamily: "Code Saver",
  fontStyle: "normal",
  fontWeight: "normal",
  fontSize: "14px",
  lineHeight: "17.5px",
};

const Arrow = () => {
  return (
    <Box sx={{ mr: "7px", mt: "2px" }}>
      <img src={DownArrow} />
    </Box>
  );
};



const FilterSelect = (props) => {
  const [value, setValue] = useState("0");
  
  const handleChange = ((event) => {
    props.onChange (event);
    setValue ("0")
  })

  return (
    <Box width="100%" sx={{}}>
      <FormControl sx={{ width: "100%" }}>
        <InputLabel
          id="project-type-label"
          InputLabelProps={{
            color: "blue.main",
          }}
          sx={{
            mt: "5px",

            ...textStyles,
          }}
        >
          {props.title}
        </InputLabel>
        <Select
          size="small"
          onChange={handleChange}
          variant="standard"
          disableUnderline={true}
          width="100%"
          height="31px"
          label="Project Type"
          value={value}
          inputProps={{
            MenuProps: {
              sx: {
                borderRadius: 0,
              },
              MenuListProps: {
                sx: {
                  backgroundColor: "cream.main",
                  borderRadius: 0,
                },
              },
            },
          }}
          sx={{
            borderRadius: 0,
            outline: "none",
            border: 1,
            borderColor: "blue.main",

            pl: "4px",
            pt: "2px",
            ...textStyles,
          }}
        >
          <MenuItem key="0" value="0" sx={{ ...textStyles }}>
            Select {props.title}
          </MenuItem>
          {props.options?.map((option) => (
            <MenuItem
              key={option.id}
              value={option.name}
              sx={{ ...textStyles }}
            >
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default FilterSelect;
