import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../user-context";
import { Box, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2/";
import { Link } from "react-router-dom";
import ConsultationSummary from "../../../SharedComponents/ConsultationSummary";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { ActionButton } from "../../../SharedComponents/Buttons";
import { addDoc, setDoc, getDoc, updateDoc } from "firebase/firestore";
//import { onCustomEventPublished } from "firebase-functions/v2/eventarc";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../App";
import couponCode from "coupon-code";
import { getFirestore, doc, onSnapshot, collection } from "firebase/firestore";

function addMinutes(date, minutes) {
  return new Date(date.getTime() + minutes * 60000);
}

const GiftCardSuccess = (props) => {
  const [appointment, setAppointment] = useState();
  const [date, setDate] = useState(new Date());
  const [pro, setPro] = useState();
  const [user, setUser] = useState();
  const [type, setType] = useState();
  const [giftCardInfo, setGiftCardInfo] = useState();

  const { id } = useParams();

  const db = getFirestore();

  const sendReceiptEmail = async (cardInfo) => {
    await addDoc(collection(db, "mail"), {
      to: [cardInfo.recipientEmail],
      message: {
        subject: "Thank you for your purchase",
        html: `  <div class="entry" style="text-color:grey";>
       
        <div class="body">
        <tbody><tr>
        <td id="m_2221956471352103756m_5517837448314080125header_wrapper" style="background-color:#F7CBAD;padding:36px 48px;display:block;text-align:center">
          <a href="http:///" style="color:#2d457c;font-weight:normal;text-decoration:underline" target="_blank" data-saferedirecturl="https://matriarchybuild.com"><img src="https://firebasestorage.googleapis.com/v0/b/mb-platform.appspot.com/o/misc%2Femail-header.png?alt=media&token=a345823f-7569-483f-85e7-f5cde48d7211" style="border:none;display:inline-block;font-size:14px;font-weight:bold;height:auto;outline:none;text-decoration:none;text-transform:capitalize;vertical-align:middle;margin-right:10px;max-width:100%;margin:0" class="CToWUd" data-bit="iit"></a>
        </td>
      </tr>
    </tbody>
          <p style="margin-bottom:20px;">
          We've sent a Matriarchy Build gift code to ${cardInfo.recipientEmail}
          </p>
          <p style="font-size:40px; margin-bottom:20px;">
          ${cardInfo.amount}
          </p>
          <p style="text-decoration:underline">
          Message
          </p>
          <p>Order #:${id}</p>
          <p>
          ${cardInfo.message}
          </p>
          <p>
          They can visit 
            <a href=https://matriarchybuild.com/
            >https://matriarchybuild.com/</a> to get started
          </p>
          <p>
          We look forward to helping them tackle their next project.
          </p>
          <p>
          Lacey + Gabriella + the Pros of MBuild

          </p>
        </div>
      </div>`,
      },
    });
  };
  const sendConfirmationEmail = async (code, cardInfo) => {
    await addDoc(collection(db, "mail"), {
      to: [cardInfo.recipientEmail],
      message: {
        subject: "Your Matriarchy Build Gift Card",
        html: `  <div class="entry" style="text-color:grey";>
       
        <div class="body">
        <tbody><tr>
        <td id="m_2221956471352103756m_5517837448314080125header_wrapper" style="background-color:#F7CBAD;padding:36px 48px;display:block;text-align:center">
          <a href="http:///" style="color:#2d457c;font-weight:normal;text-decoration:underline" target="_blank" data-saferedirecturl="https://matriarchybuild.com"><img src="https://firebasestorage.googleapis.com/v0/b/mb-platform.appspot.com/o/misc%2Femail-header.png?alt=media&token=a345823f-7569-483f-85e7-f5cde48d7211" style="border:none;display:inline-block;font-size:14px;font-weight:bold;height:auto;outline:none;text-decoration:none;text-transform:capitalize;vertical-align:middle;margin-right:10px;max-width:100%;margin:0" class="CToWUd" data-bit="iit"></a>
        </td>
      </tr>
    </tbody>
          <p style="margin-bottom:20px;">
          You've received a Matriarchy Build gift card from ${cardInfo.yourName}
          </p>
          <p style="font-size:40px; margin-bottom:20px;">
          ${cardInfo.amount}
          </p>
          <p style="text-decoration:underline">Code</p>
          <p>${code}</p>
          <p style="text-decoration:underline">
          Message
          </p>
          <p>
          ${cardInfo.message}
          </p>
         
          <p>
          Visit 
            <a href=https://matriarchybuild.com/
            >https://matriarchybuild.com/</a> to get started
          </p>
          <p>
          We look forward to helping you tackle your next project.
          </p>
          <p>
          Lacey + Gabriella + the Pros of MBuild
          </p>
        </div>
      </div>`,
      },
    });
  };

  const addGiftCard = async (values) => {
    var code = couponCode.generate({ parts: 4 });
    var cardDocRef = await getDoc(doc(db, `giftCards`, code));



    while (cardDocRef.exists()) {
      code = couponCode.generate({ parts: 4 });
      cardDocRef = await getDoc(doc(db, `giftCards`, code));

    }

    var cardDetails;

    const amountString = values.amount.substr(1, values.amount.length - 1);
    const cardAmount = Math.floor (parseFloat(amountString));
   
    cardDetails = {
      initialAmount: cardAmount,
      balance: cardAmount,
    };

    const docRef = await setDoc(doc(db, `giftCards`, code), {
      type: "fixed",
      dateCreated: new Date(),
      recipientEmail: values.recipientEmail,
      senderEmail: values.senderEmail,
      cardDetails,
    });

    sendConfirmationEmail(code, values);
    sendReceiptEmail(values);
  };

  useEffect(() => {
    const getPendingGiftCard = async () => {
      const docRef = doc(db, "pendingGiftCards", id);
      const docSnap = await getDoc(docRef);

      const giftCardData = docSnap.data();
      setGiftCardInfo(giftCardData);

      if (!giftCardData.paymentConfirmed) {
        await updateDoc(docRef, { paymentConfirmed: true });
        addGiftCard(giftCardData);
      }
    };

    getPendingGiftCard();
  }, []);

  return (
    <Box
      width="100%"
      height="100vh"
      sx={{
        backgroundColor: "cream.main",
        px: "44px",
      }}
    >
      <Box width="100%" maxWidth="1088px" mx="auto">
        <Box sx={{ borderBottom: 1, color: "lightOlive.main" }}>
          <Typography
            sx={{
              whiteSpace: "pre-line",
              color: "olive.main",
              mr: "5px",
              pt: "87px",
              mb: "8px",
            }}
            variant="h2"
          >
            {"Your Receipt"}
          </Typography>
        </Box>
        {giftCardInfo && (
          <Grid container sx={{ border: 1, color: "olive.main", mt: "25px" }}>
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                backgroundColor: "cream.main",
                py: "18px",
                pl: "18px",
                color: "olive.main",
                borderRight: 1,
              }}
            >
              <Typography
                sx={{
                  whiteSpace: "pre-line",
                  color: "olive.main",
                }}
                variant="paragraphMono"
              >
               Matriarchy Build
              </Typography>
              <Typography
                sx={{
                  whiteSpace: "pre-line",
                  color: "blue.main",
                  mt: "10px",
                }}
                variant="h1"
              >
                {`GIFT \n CARD`}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                py: "18px",
                pl: "18px",
                color: "olive.main",
                borderRight: 1,
              }}
            >
              <Typography
                sx={{
                  whiteSpace: "pre-line",
                  color: "olive.main",
                  display: "block",
                }}
                variant="paragraphMono"
              >
                {`A gift card code has been sent to:`}
              </Typography>
              <Typography
                sx={{
                  whiteSpace: "pre-line",
                  color: "blue.main",
                  display: "block",
                }}
                variant="paragraphMonoLarge"
              >
                {`${giftCardInfo.recipientEmail}`}
              </Typography>
              <Typography
                sx={{
                  pt:"40px",
                  whiteSpace: "pre-line",
                  color: "olive.main",
                  display: "block",
                }}
                variant="paragraphMono"
              >
                {`Order #:${id}`}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={2}
              sx={{
                py: "18px",
                pr: "18px",
                textAlign: "right",
              }}
            >
              <Typography
                sx={{
                  whiteSpace: "pre-line",
                  color: "blue.main",
                  display: "block",
                }}
                variant="paragraphMonoLarge"
              >
                {giftCardInfo.amount}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default GiftCardSuccess;
