import { Box, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import ProSearchCard from "./Components/ProSearchCard";
import Grid from "@mui/material/Unstable_Grid2/";

const ProSearchGrid = (props) => {
  const [pros, setPros] = useState([]);
  const isSmall = useMediaQuery({ maxWidth: 601})
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })

  return (
    <Box
      width="100%"
      height="auto"
      maxWidth="1440px"
      sx={{
        mx: "auto",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid container rowSpacing={isPortrait ? 3 : 3} spacing={isSmall ? 0 : 6} width="100%">
        {props.pros?.length ? (
          props.pros?.map((pro, index) => {
            return (
              <Grid key={index} xs={12} sm={6} md={4} lg={3}>
                <ProSearchCard
                  key={pro.id}
                  firstName={pro.firstName}
                  lastName={pro.lastName}
                  headshot={pro.headshot}
                  profession={pro.proTrades}
                  proId={pro.id}
                  slug={pro.slug}
                />
              </Grid>
            );
          })
        ) : (
          <Box
            height="200px"
            width="100%"
            sx={{
              
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="paragraphMono" color="blue.main">
              No Results
            </Typography>
          </Box>
        )}
      </Grid>
    </Box>
  );
};

export default ProSearchGrid;
