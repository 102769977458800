import { Typography } from '@mui/material';

import Accordion from '../../Accordion';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const commonStyles = {
  variant: 'paragraphSans',
  color: 'blue.main',
  textAlign: 'left',
};

const linkStyles = {
  variant: 'paragraphSans',
  color: 'pink.main',
  sx: {
    cursor: 'pointer',
    '&:hover': {
      color: 'lightOlive.main',
    },
  },
};

const FindABuilderFAQs = (props) => {
  const { hideSection } = props;
  const [expanded, setExpanded] = useState('');
  const navigate = useNavigate();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const navigateTo = (url) => () => navigate(url);

  return (
    <Accordion>
      {hideSection !== 'panel1' && (
        <Accordion.Section
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
        >
          <Accordion.Summary>How It Works</Accordion.Summary>
          <Accordion.Details>
            <Typography {...commonStyles}>
              Matriarchy Build has a vetted roster of General Contractors across
              the country. Let us know more about your project and we'd be happy
              to pair you with the right builder for your timeline and budget.
            </Typography>
          </Accordion.Details>
        </Accordion.Section>
      )}
      {hideSection !== 'panel2' && (
        <Accordion.Section
          expanded={expanded === 'panel2'}
          onChange={handleChange('panel2')}
        >
          <Accordion.Summary>Pricing</Accordion.Summary>
          <Accordion.Details>
            <Typography {...commonStyles}>
              There is no charge to the customer to use Matriarchy Build's
              General Contractor Matchmaking service.
            </Typography>

            <Typography {...commonStyles}>
              {
                "If you're looking for additional guidance or construction coaching, you can always check out our Online Consultations. Details below :-)"
              }
            </Typography>
          </Accordion.Details>
        </Accordion.Section>
      )}
      {hideSection !== 'panel3' && (
        <Accordion.Section
          expanded={expanded === 'panel3'}
          onChange={handleChange('panel3')}
        >
          <Accordion.Summary>Online Consultations</Accordion.Summary>
          <Accordion.Details>
            <Typography {...commonStyles}>
              Matriarchy Build also offers nationwide 1:1 online consultations
              for folks looking for guidance around DIY and repairs or larger
              remodel projects. If you're looking for online guidance,{' '}
              <Typography {...linkStyles} onClick={navigateTo('/pro')}>
                please see our roster of virtual Pros.
              </Typography>
            </Typography>
          </Accordion.Details>
        </Accordion.Section>
      )}
    </Accordion>
  );
};

export default FindABuilderFAQs;
