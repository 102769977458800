import { useState, useContext, useEffect } from "react";
import { Box, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useMediaQuery } from "react-responsive";
import Input from "@mui/material/Input";
import SideBar from "../SideBar";
import Footer from "../../../SharedComponents/Footer";
import { ActionButton } from "../../../SharedComponents/Buttons";
import { getFirestore, updateDoc, doc, getDoc } from "firebase/firestore";
import { UserContext } from "../../../user-context";
import {
  signInWithEmailAndPassword,
  getAuth,
  updatePassword,
} from "firebase/auth";

const commonStyles = {
  pl: "4px",
  mt: "12px",
  fontFamily: "Code Saver",
  fontStyle: "normal",
  fontWeight: "normal",
  fontSize: "16px",
  lineHeight: "145%",
  color: "blue.main",

  border: 1,
  borderColor: "lightOlive.main",
};

const AccountDetails = (props) => {
  const isDesktop = useMediaQuery({ minWidth: 1201 });
  const isMedium = useMediaQuery({ minWidth: 901 });

  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [currentPassword, setCurrentPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [message, setMessage] = useState("");
  const [saved, setSaved] = useState(false);
  const [showEmailChange, setShowEmailChange] = useState(false);
  const db = getFirestore();

  const user = useContext(UserContext).user;

  const fetchUser = async () => {
    const userRef = doc(db, `customers/${user.uid}/`);
    const docSnap = await getDoc(userRef);

    if (docSnap.exists) {

      const userData = docSnap.data();
      setFirstName(userData.firstName);
      setLastName(userData.lastName);
    }
    
    user.providerData.forEach(provider => {
      if (provider.providerId === "password") {
        setShowEmailChange (true);
      }
    });
  };

  useEffect(() => {
    fetchUser();
  }, [user]);

  const updateAccountDetails = () => {
    const docRef = doc(db, "customers", user.uid);

    if (currentPassword || newPassword || confirmPassword) {
      if (!currentPassword) {
        setMessage("Current password is required");
      } else if (newPassword != confirmPassword) {
        setMessage("Passwords do not match");
      } else if (!newPassword || !confirmPassword) {
        setMessage("Please type and confirm your new password");
      } else {
        const auth = getAuth();
        auth.useDeviceLanguage();

        signInWithEmailAndPassword(auth, user.email, currentPassword)
          .then(function (user) {

            updatePassword(user.user, newPassword)
              .then(function () {
                setMessage("Password changed");
              })
              .catch(function (err) {
                console.log(err)
                setMessage("An error occured, please try again later");
              });
          })
          .catch(function (err) {
            console.log (err);
            setMessage(
              "Unable to change password. Please ensure current password is correct."
            );
          });
      }
    }
    const userRef = doc(db, `customers/${user.uid}/`);
    const docSnap = updateDoc(userRef, {
      firstName: firstName,
      lastName: lastName,
    });
    setSaved(true);
  };

  return (
    <Box width="100%" minHeight="766px">
      <Stack
        width="100%"
        minHeight="766px"
        direction={isDesktop ? "row" : undefined}
      >
        <Box width={isDesktop ? "345px" : "100%"}>
          <SideBar />
        </Box>
        <Box width="100%" sx={{ px: "44px", pb: "60px" }}>
          <Typography
            sx={{
              whiteSpace: "pre-line",
              color: "olive.main",
              mr: "5px",
              mt: "66px",
              mb: "8px",
            }}
            variant="h3"
          >
            {"Account Info"}
          </Typography>
          <Grid container columnSpacing={4}>
            <Grid item md={6} xs={12}>
              <Input
                type="text"
                disableUnderline
                fullWidth
                value={firstName}
                onChange={(event) => {
                  setSaved(false);
                  
                  setFirstName(event.target.value);
                }}
                height="40px"
                placeholder="First Name"
                sx={{
                  ...commonStyles,
                }}
              ></Input>
            </Grid>
            <Grid item md={6} xs={12}>
              <Input
                type="text"
                disableUnderline
                fullWidth
                value={lastName}
                onChange={(event) => {
                  setSaved(false);
                  setLastName(event.target.value);
                }}
                height="40px"
                placeholder="Last Name"
                sx={{
                  ...commonStyles,
                }}
              ></Input>
            </Grid>
          </Grid>
          {showEmailChange && <Typography
            sx={{
              whiteSpace: "pre-line",
              color: "olive.main",
              mr: "5px",
              mt: "66px",
              mb: "8px",
            }}
            variant="h3"
          >
            {"Password Change"}
          </Typography> }
         <Grid container columnSpacing={4}>
         {showEmailChange && <><Grid item md={6} xs={12}>
              <Input
                type="password"
                disableUnderline
                fullWidth
                value={currentPassword}
                onChange={(event) => {
                  setSaved(false);
                  setCurrentPassword(event.target.value);
                }}
                height="40px"
                placeholder="Current password (leave blank to leave unchanged)"
                sx={{
                  ...commonStyles,
                }}
              ></Input>
            </Grid>
            <Grid item md={6} xs={0}></Grid>
            <Grid item md={6} xs={12}>
              <Input
                type="password"
                disableUnderline
                fullWidth
                value={newPassword}
                onChange={(event) => {
                  setSaved(false);
                  setNewPassword(event.target.value);
                }}
                height="40px"
                placeholder="New password (leave blank to leave unchanged)"
                sx={{
                  ...commonStyles,
                }}
              ></Input>
            </Grid>
            <Grid item md={6} xs={0}></Grid>
            <Grid item md={6} xs={12}>
              <Input
                type="password"
                disableUnderline
                fullWidth
                value={confirmPassword}
                onChange={(event) => {
                  setSaved(false);
                  setConfirmPassword(event.target.value);
                }}
                height="40px"
                placeholder="Confirm new password"
                sx={{
                  ...commonStyles,
                }}
              ></Input>
            </Grid> </>}
            {message && (
              <Grid item my="20px" md={12}>
                <Typography variant="paragraphMono" color="blue.main">
                  {message}
                </Typography>
              </Grid>
            )}
            <Grid item md={12} xs={0}></Grid>
            <Grid mt="20px" item md={3} xs={12}>
              {(saved && !message) ? (
                <Typography variant="paragraphMono" color="blue.main">
                  Saved
                </Typography>
              ) : (
                <ActionButton
                  title="Save Changes"
                  sx={{ color: "blue.main", backgroundColor: "pink.main" }}
                  onClick={updateAccountDetails}
                />
              )}
            </Grid>
          </Grid>
        </Box>
      </Stack>
      <Footer />
    </Box>
  );
};

export default AccountDetails;
