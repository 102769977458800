import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, TextField, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import Input from "@mui/material/Input";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Unstable_Grid2/";
import { useMediaQuery } from "react-responsive";
import { httpsCallable } from "firebase/functions";
import { Formik, Field } from "formik";
import { ActionButton } from "../../../../SharedComponents/Buttons";
import CircularProgress from "@mui/material/CircularProgress";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { doc, getFirestore, addDoc, collection, setDoc, updateDoc } from "firebase/firestore";
import { functions } from "../../../../App";
const commonStyles = {
  pl: "4px",
  pt: "4px",
  mb: "12px",
  fontFamily: "Code Saver",
  fontStyle: "normal",
  fontWeight: "normal",
  fontSize: "16px",
  lineHeight: "145%",
  color: "blue.main",
  border: 1,
  borderColor: "lightOlive.main",
};

export const createDateEntries = async (proId) => {
  const db = getFirestore();
  setDoc(doc(db, `pros/${proId}/schedule/Monday`), {
    start: 1674140400000,
    end: 1674169200000,
    available: false,
  })
    .then((result) => {
      console.log(result);
    })
    .catch((error) => {
      console.log(error);
    });
  setDoc(doc(db, `pros/${proId}/schedule/Tuesday`), {
    start: 1674140400000,
    end: 1674169200000,
    available: false,
  })
    .then((result) => {
      console.log(result);
    })
    .catch((error) => {
      console.log(error);
    });
  setDoc(doc(db, `pros/${proId}/schedule/Wednesday`), {
    start: 1674140400000,
    end: 1674169200000,
    available: false,
  })
    .then((result) => {
      console.log(result);
    })
    .catch((error) => {
      console.log(error);
    });
  setDoc(doc(db, `pros/${proId}/schedule/Thursday`), {
    start: 1674140400000,
    end: 1674169200000,
    available: false,
  })
    .then((result) => {
      console.log(result);
    })
    .catch((error) => {
      console.log(error);
    });
  setDoc(doc(db, `pros/${proId}/schedule/Friday`), {
    start: 1674140400000,
    end: 1674169200000,
    available: false,
  })
    .then((result) => {
      console.log(result);
    })
    .catch((error) => {
      console.log(error);
    });
  setDoc(doc(db, `pros/${proId}/schedule/Saturday`), {
    start: 1674140400000,
    end: 1674169200000,
    available: false,
  })
    .then((result) => {
      console.log(result);
    })
    .catch((error) => {
      console.log(error);
    });
  setDoc(doc(db, `pros/${proId}/schedule/Sunday`), {
    start: 1674140400000,
    end: 1674169200000,
    available: false,
  })
    .then((result) => {
      console.log(result);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const createAppointmentTypes = async (proId) => {
  const db = getFirestore ();
  addDoc(collection(db, `pros/${proId}/appointmentTypes`), {
    currencyCode: "USD",
    description: "Video Consultation",
    duration: 10,
    label: "1:1",
    maxParticipants: "1",
    price: 0,
    enabled: false,
  })
    .then((result) => {
      console.log(result);
    })
    .catch((error) => {
      console.log(error);
    });
  addDoc(collection(db, `pros/${proId}/appointmentTypes`), {
    currencyCode: "USD",
    description: "Video Consultation",
    duration: 25,
    label: "1:1",
    maxParticipants: "1",
    price: 0,
    enabled: false,
  })
    .then((result) => {
      console.log(result);
    })
    .catch((error) => {
      console.log(error);
    });
  addDoc(collection(db, `pros/${proId}/appointmentTypes`), {
    currencyCode: "USD",
    description: "Video Consultation",
    duration: 55,
    label: "1:1",
    maxParticipants: "1",
    price: 0,
    enabled: false,
  })
    .then((result) => {
  
    })
    .catch((error) => {
      console.log(error);
    });
};

const AccountCreate = () => {
  const isMedium = useMediaQuery({ minWidth: 901 });
  const isDesktop = useMediaQuery({ minWidth: 1201 });
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const db = getFirestore();
  const provider = new GoogleAuthProvider();

  const auth = getAuth();

  const createAccount = () => {
    const createProUser = httpsCallable(functions, "createProUser");
    const displayName = `${firstName} ${lastName}`;
    var slug = displayName.replace(/\s+/g, "-").toLowerCase();

    slug = slug.replace(/[^a-zA-Z0-9--]+/gi, ""); 

    setLoading(true);
    createProUser({
      email: email,
      displayName: displayName,
      password: password,
    }).then((res) => {

      setDoc(doc(db, "pros", res.data.response.uid), {
        firstName: firstName,
        lastName: lastName,
        bio:"",
        email: email,
        signUpDate: new Date(),
        temporaryPassword: password,
        verified: false,
        slug: slug,
      })
        .then(() => {
          createDateEntries(res.data.response.uid);
          createAppointmentTypes(res.data.response.uid);

          const createCalendar = httpsCallable(functions, "createCalendar");

          createCalendar({
            summary: `${firstName}-${lastName}`,

            description: `${firstName}'s calendar`,
          })
            .then((result) => {
              updateDoc(doc(db, "pros", res.data.response.uid), {
                calendarId: result.data.data.id,
              })
                .then((result) => {
                  setLoading(false);
                  navigate(`/my-account/pros/`);
                })
                .catch((error) => {
                  console.log(error);
                  setLoading(false);
                });
            })
            .catch((error) => {
              setLoading(false);
              console.log(error);
            });
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    });
  };

 

  return (
    <Box height="100%" width="100%" mx="auto">
      <Grid container>
        <Grid
          item
          md={12}
          xs={12}
          sx={{
            backgroundColor: "white",
            
            p: "44px",
          }}
        >
          <Typography
            sx={{
              whiteSpace: "pre-line",
              color: "olive.main",
              mb: "20px",
            }}
            display="block"
            variant="h2"
          >
            {"Create A New Pro Account"}
          </Typography>

          <Stack width="100%">
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                email: "",
                password: "",
              }}
              validate={(values) => {
                const errors = {};
                if (!firstName) {
                  errors.firstName = "Required";
                }
                if (!lastName) {
                  errors.lastName = "Required";
                }
                if (!email) {
                  errors.email = "Required";
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)
                ) {
                  errors.email = "Invalid email address";
                }
                if (!password) {
                  errors.passWord = "Required";
                }

                return errors;
              }}
              onSubmit={(values) => {
                alert(JSON.stringify(values, null, 2));

              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit}>
                  <Input
                    type="text"
                    name="firstName"
                    onChange={(e) => setFirstName(e.target.value)}
                    onBlur={handleBlur}
                    value={firstName}
                    disableUnderline
                    fullWidth
                    height="40px"
                    placeholder="First Name"
                    sx={{
                      ...commonStyles,
                    }}
                  ></Input>
                  {errors.firstName && touched.firstName && errors.firstName}
                  <Input
                    type="text"
                    name="lastName"
                    onChange={(e) => setLastName(e.target.value)}
                    onBlur={handleBlur}
                    value={lastName}
                    disableUnderline
                    fullWidth
                    height="40px"
                    placeholder="Last Name"
                    sx={{
                      ...commonStyles,
                    }}
                  ></Input>
                  {errors.lastName && touched.lastName && errors.lastName}
                  <Input
                    type="email"
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                    onBlur={handleBlur}
                    value={email}
                    disableUnderline
                    fullWidth
                    height="40px"
                    placeholder="Email"
                    sx={{
                      ...commonStyles,
                    }}
                  ></Input>
                  {errors.email && touched.email && errors.email}
                  <Input
                    type="password"
                    name="password"
                    onChange={(e) => setPassword(e.target.value)}
                    onBlur={handleBlur}
                    value={password}
                    disableUnderline
                    fullWidth
                    height="40px"
                    placeholder="Temporary Password"
                    sx={{
                      ...commonStyles,
                    }}
                  ></Input>
                  {errors.password && touched.password && errors.password}

                  {loading ? (
                    <Box width="100%">
                      <CircularProgress sx={{ mx: "auto" }} />{" "}
                    </Box>
                  ) : (
                    <ActionButton
                      title="Create Account"
                      onClick={createAccount}
                    />
                  )}
                </form>
              )}
            </Formik>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AccountCreate;
