import { Box, CircularProgress, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2/";
import { useMediaQuery } from "react-responsive";
import styled from "@emotion/styled";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import { Formik } from "formik";
import Input from "@mui/material/Input";
import Checkbox from "@mui/material/Checkbox";
import { ActionButton } from "../../../../SharedComponents/Buttons";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import {
  addDoc,
  collection,
  getFirestore,
  onSnapshot,
  doc,
  setDoc,
  getDoc,
} from "firebase/firestore";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import couponCode from "coupon-code";
import { useEffect, useState } from "react";

const commonStyles = {
  pl: "4px",
  pt: "4px",
  mb: "12px",
  fontFamily: "Code Saver",
  fontStyle: "normal",
  fontWeight: "normal",
  fontSize: "16px",
  lineHeight: "145%",
  color: "blue.main",
  border: 1,
  borderColor: "lightOlive.main",
};

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 55,
  height: 29,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",

      "& .MuiSwitch-thumb:before": {},
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#F7CBAD",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: "#FBF8EA",
    width: 26,
    height: 26,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,

    borderRadius: 20 / 2,
  },
}));

const NewPromoCode = (props) => {
  const [submitting, setSubmitting] = useState(false);
  const isDesktop = useMediaQuery({ minWidth: 1201 });
  const [selectedAmount, setSelectedAmount] = useState("Choose an amount");
  const [fixedPrice, setFixedPrice] = useState(true);
  const [promoCodeCode, setPromoCodeCode] = useState();
  const [expirationDate, setExpirationDate] = useState(new Date());
  const [expires, setExpires] = useState(false);
  const [limitUses, setLimitUses] = useState(false);
  const expiresLabel = { inputProps: { "aria-label": "Expires" } };
  const db = getFirestore();
  const amounts = [
    { label: "Choose an amount", amount: 0 },
    { label: "$5.00", amount: 5 },
    { label: "$10.00", amount: 10 },
    { label: "$15.00", amount: 15 },
    { label: "$20.00", amount: 20 },
    { label: "$25.00", amount: 25 },
    { label: "$50.00", amount: 50 },
    { label: "$75.00", amount: 75 },
    { label: "$100.00", amount: 100 },
    { label: "$150.00", amount: 150 },
    { label: "$200.00", amount: 200 },
    { label: "$500.00", amount: 500 },
  ];

  const addPromoCode = async (values) => {
    console.log(values);
    var codeDetails;
    if (fixedPrice) {
      codeDetails = {
        promoCodeAmount: values.promoCodeAmount,
      };
    } else {
      codeDetails = {
        percentOff: values.percentOff,
        maximumAmount: values.maximumAmount,
      };
    }
    const dateMinusHours = new Date(expirationDate);
    dateMinusHours.setHours (0, 0, 0);
    const code = {  
      codeName: values.codeName,
      type: fixedPrice ? "fixed" : "percent",
      dateCreated: new Date(),
      ...(expires && {expires: new Date(dateMinusHours)}),
      ...(limitUses && {limitUses:  values.useLimit}),
      codeDetails,
      timesUsed:0,
      
    };

    console.log (code)

    const promoCodeDocRef = await setDoc(doc(db, `promoCodes`, values.codeName), {
     ...code
    });
    props.onClose();

    setSubmitting(false);
  };

  return (
    <Box p="44px">
      <Typography
        sx={{
          whiteSpace: "pre-line",
          my: "3px",
          color: "blue.main",
        }}
        display="block"
        variant="h3"
      >
        {promoCodeCode}
      </Typography>
      <Formik
        initialValues={{
          promoCodeAmount: "Choose an amount",
          codeName: "",
          useLimit:100,
        }}
        validate={(values) => {
          const errors = {};
          if (fixedPrice) {
            if (!values.promoCodeAmount) {
              errors.amount = "Required";
            } else if (values.promoCodeAmount == "Choose an amount") {
              errors.amount = "Required";
            }
          } else {
            if (!values.percentOff) {
              errors.percentOff = "Required";
            }
            if (!values.maximumAmount) {
              errors.maximumAmount = "Required";
            }
          }

          console.log(errors);
          return errors;
        }}
        onSubmit={(values) => {
          setSubmitting(true);
          addPromoCode(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid item xs={12} md={4}>
              <FormControl sx={{ width: "100%" }}>
                <Stack py="20px" direction="row">
                  <Stack>
                    <Typography variant="paragraphSans" color="blue.main">
                      {"Promo Code Name (Case sensitive/Must be unique)"}
                    </Typography>
                    <Input
                      type="text"
                      name="codeName"
                      required
                      size="small"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.codeName}
                      disabled={submitting}
                      disableUnderline
                      fullWidth
                      height="40px"
                      placeholder="(e.g) save20"
                      sx={{
                        ...commonStyles,
                      }}
                    ></Input>
                    <Stack direction="row">
                      <Typography
                        sx={{
                          whiteSpace: "pre-line",
                          my: "3px",
                          color: fixedPrice ? "lightOlive.main" : "blue.main",
                        }}
                        display="block"
                        variant="paragraphMono"
                      >
                        {"Percentage"}
                      </Typography>
                      <MaterialUISwitch
                        onChange={(e) => setFixedPrice(e.target.checked)}
                        checked={fixedPrice}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <Typography
                        sx={{
                          whiteSpace: "pre-line",
                          my: "3px",
                          color: fixedPrice ? "blue.main" : "lightOlive.main",
                        }}
                        display="block"
                        variant="paragraphMono"
                      >
                        {"Fixed Amount"}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
                {fixedPrice && (
                  <>
                    <Typography
                      sx={{
                        whiteSpace: "pre-line",
                        my: "3px",
                        color: "blue.main",
                      }}
                      display="block"
                      variant="paragraphSans"
                    >
                      {"Amount ($)"}
                    </Typography>
                    <Select
                      size="small"
                      required
                      variant="standard"
                      name="promoCodeAmount"
                      value={values.amount}
                      onChange={handleChange}
                      disableUnderline={true}
                      width="100%"
                      height="31px"
                      sx={{
                        ...commonStyles,
                      }}
                    >
                      {amounts.map((amount) => (
                        <MenuItem key={amount.amount} value={amount.amount}>
                          <Typography
                            sx={{
                              whiteSpace: "pre-line",
                              my: "3px",
                              color: "blue.main",
                            }}
                            display="block"
                            variant="paragraphMono"
                          >
                            {amount.label}
                          </Typography>
                        </MenuItem>
                      ))}
                    </Select>
                    <Box>{errors.amount && errors.amount}</Box>
                  </>
                )}
                {!fixedPrice && (
                  <>
                    <Typography
                      sx={{
                        whiteSpace: "pre-line",
                        my: "3px",
                        color: "blue.main",
                      }}
                      display="block"
                      variant="paragraphSans"
                    >
                      {"Percent Off (%)"}
                    </Typography>

                    <Input
                      size="small"
                      type="number"
                      variant="standard"
                      name="percentOff"
                      value={values.percentOff}
                      onChange={handleChange}
                      disableUnderline={true}
                      width="100%"
                      height="31px"
                      sx={{
                        ...commonStyles,
                      }}
                    ></Input>
                    <Box>{errors.percentOff && errors.percentOff}</Box>

                    <Typography
                      sx={{
                        whiteSpace: "pre-line",
                        my: "3px",
                        color: "blue.main",
                      }}
                      display="block"
                      variant="paragraphSans"
                    >
                      {"Max Amount ($)"}
                    </Typography>

                    <Input
                      size="small"
                      type="number"
                      variant="standard"
                      name="maximumAmount"
                      value={values.maximumAmount}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disableUnderline={true}
                      width="100%"
                      height="31px"
                      sx={{
                        ...commonStyles,
                      }}
                    ></Input>
                    <Box>{errors.maximumAmount && errors.maximumAmount}</Box>
                  </>
                )}
                <Stack direction="row" display="flex" alignItems="center">
                  <Checkbox
                    {...expiresLabel}
                    value={expires}
                    onChange={(e) => {
                      setExpires(e.target.checked);
                    }}
                  />
                  <Typography
                    sx={{
                      whiteSpace: "pre-line",
                      my: "3px",
                      color: "blue.main",
                    }}
                    display="block"
                    variant="paragraphSans"
                  >
                    {"Expires"}
                  </Typography>
                </Stack>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    disabled={!expires}
                    disablePast
                    value={expirationDate}
                    showToolbar={false}
                    onChange={setExpirationDate}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
                <Stack direction="row" display="flex" alignItems="center">
                  <Checkbox
                    {...expiresLabel}
                    value={limitUses}
                    onChange={(e) => {
                      setLimitUses(e.target.checked);
                    }}
                  />
                  <Typography
                    sx={{
                      whiteSpace: "pre-line",
                      my: "3px",
                      color: "blue.main",
                    }}
                    display="block"
                    variant="paragraphSans"
                  >
                    {"Limit # of uses"}
                  </Typography>
                </Stack>

                <Input
                  disabled={!limitUses}
                  size="small"
                  type="number"
                  variant="standard"
                  name="useLimit"
                  value={values.useLimit}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disableUnderline={true}
                  width="100%"
                  height="31px"
                  sx={{
                    ...commonStyles,
                  }}
                ></Input>
              </FormControl>
            </Grid>

            {submitting && (
              <Box
                width="100%"
                sx={{
                  display: "flex",
                  mt: "40px",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </Box>
            )}

            <Grid container py="44px">
              <Grid item xs={12} md={4} mdOffset={8}>
                {!submitting && <ActionButton title="Create" type="submit" />}
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default NewPromoCode;
