import { Stack, Typography, Box, Grid } from '@mui/material';
import { useMediaQuery } from 'react-responsive';
import RaySawin from '../../../Assets/Images/Ray-Sawin.jpeg';
import Texas from '../../../Assets/Images/texas.svg';
import GridButton from '../../../SharedComponents/GridButtons';

const AustinServices = () => {
  const isDesktop = useMediaQuery({ minWidth: 1201 });

  return (
    <Box
      display="flex"
      justifyContent="center"
      width="100%"
      sx={{
        zIndex: 0,
        width: '100%',
        py: '85px',
        px: '20px',
        backgroundColor: 'lightOlive.main',
      }}
    >
      <Grid container sx={{ borderColor: 'blue.main', maxWidth: '1240px' }}>
        <Grid
          item
          lg={6}
          xs={12}
          sx={{ display: 'flex', border: 1, backgroundColor: 'white' }}
        >
          <Stack
            direction="column"
            color="blue.main"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              px: isDesktop ? '120px' : '10%',
              pt: '50px',
              pb: '100px',
              textAlign: 'center',
              width: '100%',
            }}
          >
            <img src={Texas} />
            <Box pt="24px">
              <Typography
                variant="paragraphSansLarge"
                sx={{ fontSize: '40px' }}
              >
                Howdy, Austin!
              </Typography>
            </Box>
            <Box pt="40px" px="20px">
              <Typography
                variant="paragraphMono"
                sx={{ whiteSpace: 'break-spaces' }}
              >
                {
                  'Matriarchy Build offers Handyperson services to the Greater Austin area. We also offer more robust Membership Packages for folks looking for recurring services, additional support, and access to our Pro Referral network.'
                }
              </Typography>
            </Box>
          </Stack>
        </Grid>
        {isDesktop && (
          <Grid
            item
            lg={6}
            xs={12}
            sx={{
              background: `no-repeat url(${RaySawin})`,
              border: 1,
              borderLeft: 0,
              display: 'flex',
              justifyContent: 'center',
              backgroundSize: 'cover',
            }}
          />
        )}
        <GridButton
          textColor="blue"
          backgroundColor="pink"
          hoverTextColor="pink"
          hoverBackgroundColor="blue"
          linkTo="/austin"
          linkText="Find a local pro"
        />
      </Grid>
    </Box>
  );
};

export default AustinServices;
