import { useEffect, useState } from 'react';
import ArrowBlue from '../../Assets/Images/arrow-blue.svg';
import ArrowOlive from '../../Assets/Images/arrow-olive.svg';
import ArrowPink from '../../Assets/Images/arrow-pink.svg';
import ArrowWhite from '../../Assets/Images/arrow-white.svg';
import ArrowLightOlive from '../../Assets/Images/arrow-light-olive.svg';
import ArrowCream from '../../Assets/Images/arrow-cream.svg';

const Arrow = ({ color }) => {
  const [src, setSrc] = useState(null);

  const setArrow = (val) => {
    switch (val) {
      case 'blue':
        return ArrowBlue;
      case 'olive':
        return ArrowOlive;
      case 'pink':
        return ArrowPink;
      case 'white':
        return ArrowWhite;
      case 'cream':
        return ArrowCream;
      case 'lightOlive':
        return ArrowLightOlive;
      default:
        return ArrowBlue;
    }
  };

  useEffect(() => {
    setSrc(setArrow(color));
  }, [color]);

  return <img src={src} />;
};

export default Arrow;
