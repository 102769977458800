import { useContext } from "react";
import { UserContext } from "../../../user-context";
import { Box, CircularProgress, Switch } from "@mui/material";
import { useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material";
import Input from "@mui/material/Input";
import Typography from "@mui/material/Typography";
import { useMediaQuery } from "react-responsive";
import SideBar from "../SideBar";
import Footer from "../../../SharedComponents/Footer";
import { storage } from "../../../App";
import {
  getFirestore,
  doc,
  orderBy,
  getDoc,
  updateDoc,
  collection,
  query,
  getDocs,
} from "firebase/firestore";
import DownArrow from "../../../Assets/Images/down-arrow.svg";
import Grid from "@mui/material/Unstable_Grid2/";
import { useState, useEffect, useCallback } from "react";
import ProSelect from "../../../SharedComponents/ProSelect";
import { ActionButton } from "../../../SharedComponents/Buttons";
import { createAppointmentTypes } from "../Pros/EditPro/AccountCreate";

const textStyles = {
  color: "blue.main",
  fontFamily: "Code Saver",
  fontStyle: "normal",
  fontWeight: "normal",
  fontSize: "14px",
  lineHeight: "17.5px",
};

const Arrow = () => {
  return (
    <Box sx={{ mr: "7px", mt: "2px" }}>
      <img src={DownArrow} />
    </Box>
  );
};

const commonStyles = {
  pl: "4px",
  pt: "4px",
  mb: "12px",
  fontFamily: "Code Saver",
  fontStyle: "normal",
  fontWeight: "normal",
  fontSize: "16px",
  lineHeight: "145%",
  color: "blue.main",
  border: 1,
  borderColor: "lightOlive.main",
};

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 55,
  height: 29,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",

      "& .MuiSwitch-thumb:before": {},
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#F7CBAD",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: "#FBF8EA",
    width: 26,
    height: 26,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,

    borderRadius: 20 / 2,
  },
}));

const ServicePrice = (props) => {
  //const [onUpdate, setOnUpdate] = useState(props.onUpdate);
  const [enabled, setEnabled] = useState(props.appointmentType.enabled);
  const [price, setPrice] = useState(props.appointmentType.price);

  const handlePriceChange = (event) => {
    const values = {
      price: parseInt(event.target.value),
      enabled: enabled,
      duration: props.appointmentType.duration,
    };
    props.onUpdate(values);
  };
  const handleEnabledChange = (event) => {

    const values = {
      price: price,
      enabled: event.target.checked,
      duration: props.appointmentType.duration,
    };
    props.onUpdate(values);
  };

  return (
    <Grid
      container
      sx={{
        color: "lightOlive.main",
        mx: "31px",
        pt: "32px",
        mb: "8px",
      }}
    >
      <Grid
        item
        xs={1}
        sx={{
          color: "lightOlive.main",
        }}
      >
        <MaterialUISwitch
          checked={enabled}
          onChange={(event) => {
            setEnabled(event.target.checked);
            handleEnabledChange(event);
          }}
        />
      </Grid>
      <Grid item xs={6} md={3} sx={{ mb: "20px" }}>
        <Typography
          variant="paragraphMonoSmall"
          sx={{ whiteSpace: "pre-line", color: "olive.main" }}
        >
          {`1:1 Consultation - ${props.appointmentType.duration} mins`}
        </Typography>
      </Grid>
      <Grid item xs={6} md={3}>
        <Stack direction="row">
          <Typography
            variant="paragraphMono"
            sx={{
              whiteSpace: "pre-line",
              color: "olive.main",
              mt: "8px",
              mr: "5px",
            }}
          >
            $
          </Typography>
          <Input
            type="number"
            disableUnderline
            fullWidth
            onChange={(event) => {
              setPrice(parseInt(event.target.value));
              handlePriceChange(event);
            }}
            height="40px"
            placeholder="Price"
            value={price}
            sx={{
              ...commonStyles,
            }}
          ></Input>
        </Stack>
      </Grid>
    </Grid>
  );
};

const SetPricing = (props) => {
  const db = getFirestore();
  const isDesktop = useMediaQuery({ minWidth: 1201 });
  const [appointmentTypes, setAppointmentTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [savingError, setSavingError] = useState("");
  const [updated, setUpdated] = useState(false);
  const [firstLoad, setFirstLoad] = useState(false);
  const [userType, setUserType] = useState(false);
  const [pro, setPro] = useState();
  const { id } = useParams();

  var mode = "edit";

  if (id == undefined) {
    mode = "create";
  }

  const user = useContext(UserContext);

  const setUserAttributes = async (id) => {

    if (user.loggedIn == true) {
      const proRef = doc(db, `pros/${id}/`);
      const docSnap = await getDoc(proRef);

      if (docSnap.exists()) {
        const proData = docSnap.data();

        setUserType("pro");
        handleProSelect({ id: docSnap.id, ...proData });
      } else {
        user.user
          .getIdTokenResult()
          .then((idTokenResult) => {

            setUserType(idTokenResult.claims.userType);
            if (idTokenResult.claims.userType === "admin") {
              setUserType("admin");
            } else {
              setUserType("user");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };
  useEffect(() => {
    if (user.loggedIn) {
      setUserAttributes(user.user.uid);
    }
  }, [user]);

  const getPro = async () => {
    const docRef = doc(db, "pros", id);
    const docSnap = await getDoc(docRef);
    const proImagesRef = collection(db, `pros/${id}/images`);
    const q = query(proImagesRef, orderBy("duration"));
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots

    });

    setPro(docSnap.data());
  };

  const updatePro = async () => {
    const docRef = doc(db, "pros", id);
    const docSnap = await updateDoc(docRef, pro);

  };

  const handleProSelect = useCallback(async (pro) => {
    setLoading(true);
    setPro(pro);
    setFirstLoad(true);

    const proBookingsRef = collection(db, `pros/${pro.id}/appointmentTypes`);
    const q = query(proBookingsRef, orderBy("duration"));
    var querySnapshot = await getDocs(q);

    if (!querySnapshot.docs.length) {
      createAppointmentTypes(pro.id);
      querySnapshot = await getDocs(q);
    }

    var appointmentTypes = []; //= querySnapshot.docs.map((doc) => doc.data());
    querySnapshot.docs.forEach((doc, index) => {

      appointmentTypes.push({ id: doc.id, type: doc.data() });
    });

    setAppointmentTypes(appointmentTypes);
    setLoading(false);
  });

  useEffect(() => {
    if (mode == "edit") {
      getPro();
    } else {
      setPro({});
    }
  }, []);

  const onUpdate = (values) => {
    setFirstLoad(false);
    setUpdated(true);
    appointmentTypes.forEach((appointmentType) => {
      if (appointmentType.type.duration == values.duration) {
        appointmentType.type.price = values.price;
        appointmentType.type.enabled = values.enabled;
      }
    });
  };
  const saveAppointmentTypes = () => {
    setSavingError("")
    setSaving(true);

    appointmentTypes.forEach((appointmentType) => {

      if (appointmentType.type.enabled && (appointmentType.type.price === "0" ||  appointmentType.type.price === "" || appointmentType.type.price === 0)) {

        setSavingError("Price cannot be 0")
      } else {
        updateDoc(
          doc(db, `pros/${pro.id}/appointmentTypes/${appointmentType.id}`),
          appointmentType.type
        )
          .then((result) => {
       
            setSaving(false);
            setUpdated(false);
          })
          .catch((error) => {
            console.log(error);
            setSaving(false);
          });
      }

      
    });
  };
  return (
    <Box width="100%" minHeight="766px" height="auto">
      <Stack
        width="100%"
        minHeight="766px"
        direction={isDesktop ? "row" : undefined}
      >
        <Box width={isDesktop ? "345px" : "100%"}>
          <SideBar type={props.type} />
        </Box>

        <Box width="100%" height="auto" sx={{ mx: "44px" }}>
          <Box width="66%" sx={{ borderBottom: 1, color: "lightOlive.main" }}>
            <Typography
              sx={{
                whiteSpace: "pre-line",
                color: "olive.main",
                mr: "5px",
                mt: "66px",
                mb: "8px",
              }}
              variant="h3"
            >
              Set Consultation Pricing
            </Typography>
          </Box>
          {userType === "admin" && <ProSelect handleProSelect={(event) => handleProSelect(event)} /> }
          {loading ? (
            <Box
              width="100%"
              height="100px"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />{" "}
            </Box>
          ) : (
            <Box
              height="auto"
              sx={{
                my: "45px",
                mx: "auto",
                backgroundColor: "extraLightOlive.main",
                pb: "34px",
              }}
            >
              <Grid
                container
                sx={{
                  color: "lightOlive.main",
                  mx: "31px",
                  pt: "32px",
                  mb: "8px",
                  borderBottom: 1,
                }}
              >
                <Grid item xs={4}>
                  <Typography
                    variant="paragraphMonoSmall"
                    sx={{ whiteSpace: "pre-line", color: "olive.main" }}
                  >
                    Service
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={2}
                  sx={{
                    color: "lightOlive.main",
                  }}
                >
                  <Typography
                    variant="paragraphMonoSmall"
                    sx={{ whiteSpace: "pre-line", color: "olive.main" }}
                  >
                    Price
                  </Typography>
                </Grid>
              </Grid>

              {appointmentTypes &&
                appointmentTypes.map((appointmentType, index) => {
                  return (
                    <ServicePrice
                      key={index}
                      appointmentType={appointmentType.type}
                      onUpdate={(values) => onUpdate(values)}
                    />
                  );
                })}
              {!firstLoad && (
                <Box width="122px" height="60px" ml="30px">
                  {saving ? (
                    <Box
                      width="100%"
                      height="100px"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress />{" "}
                    </Box>
                  ) : updated ? (
                    <ActionButton title="Save" onClick={saveAppointmentTypes} />
                  ) : (
                    <Typography height="60px"
                    width="200px"
                      variant="paragraphMono"
                      sx={{ display: "flex",
                      alignItems: "center", whiteSpace: "pre-line", color: "blue.main" }}
                    >
                      {savingError != "" ? savingError : "Saved"}
                    </Typography>
                  )}
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Stack>
      <Footer />
    </Box>
  );
};

export default SetPricing;
