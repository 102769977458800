import { Typography, Grid } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Arrow from '../Arrow';

const GridButton = ({
  textColor,
  backgroundColor,
  hoverTextColor,
  hoverBackgroundColor,
  linkText,
  linkTo,
  lgSize = 6,
  xsSize = 12,
  sx,
  order = {},
}) => {
  const navigate = useNavigate();
  const [arrowColor, setArrowColor] = useState(textColor);

  const bgColor = backgroundColor ? `${backgroundColor}.main` : 'transparent';
  const hoverBgColor = hoverBackgroundColor
    ? `${hoverBackgroundColor}.main`
    : 'transparent';
  const color = textColor ? `${textColor}.main` : 'transparent';
  const hoverColor = hoverTextColor ? `${hoverTextColor}.main` : 'transparent';

  return (
    <Grid
      item
      lg={lgSize}
      xs={xsSize}
      order={{ ...order }}
      onClick={() => navigate(linkTo)}
      sx={{
        height: '60px',
        backgroundColor: bgColor,
        color: color,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: 1,
        borderTop: 0,
        borderColor: 'blue.main',

        '&:hover': {
          backgroundColor: hoverBgColor,
          color: hoverColor,
          cursor: 'pointer',
          borderColor: 'blue.main',

          a: {
            color: hoverColor,
          },
        },

        '& > a > span:hover': {
          backgroundColor: hoverBgColor,
          color: hoverColor,
          cursor: 'pointer',
        },

        ...sx,
      }}
      onMouseEnter={() => setArrowColor(hoverTextColor)}
      onMouseLeave={() => setArrowColor(textColor)}
    >
      <Typography
        variant="paragraphMono"
        sx={{
          '&:hover': {
            backgroundColor: hoverBgColor,
            color: hoverColor,
            cursor: 'pointer',
          },
        }}
      >
        {linkText} <Arrow color={arrowColor} />
      </Typography>
    </Grid>
  );
};

export default GridButton;
