import { Box, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
const SiteMapOption = (props) => {
  return (
    <Box
      sx={{
        flexGrow: "100",
        borderBottom: props.noBorder ? undefined : 1,
        boderColor: "blue.main",
      }}
    >
      <Link to={props.linkTo}>
        <Typography
          sx={{
            whiteSpace: "pre-line",
            mt: "6px",
            mb:"4px",
            fontSize:"14px",
            "&:hover": {
              color: "white",
            },
          }}
          display="block"
          variant="paragraphMono"
          color="blue.main"
        >
          {props.title}
        </Typography>
      </Link>
    </Box>
  );
};

export default SiteMapOption;
