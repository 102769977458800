import { useState } from 'react';
import Typography from '@mui/material/Typography';
import Input from '@mui/material/Input';
import {
  Box,
  CircularProgress,
  FormControl,
  MenuItem,
  Stack,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/';
import ToolRack from '../../Assets/Images/tool-rack.jpeg';
import { useMediaQuery } from 'react-responsive';
import MailingList from '../../SharedComponents/MailingList';
import Footer from '../../SharedComponents/Footer';
import { Formik, Field } from 'formik';
import { ActionButton } from '../../SharedComponents/Buttons';
import { Select } from '@material-ui/core';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../App';
import Check from '../../Assets/Images/check.svg';

const commonStyles = {
  pl: '4px',
  pt: '4px',
  mb: '22px',
  fontFamily: 'Code Saver',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '16px',
  lineHeight: '145%',
  color: 'blue.main',
  border: 1,
  borderColor: 'lightOlive.main',
};
const textStyles = {
  color: 'blue.main',
  fontFamily: 'Code Saver',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '14px',
  lineHeight: '17.5px',
};
const inputStyle = {
  ...commonStyles,
  '& .MuiInput-input': {
    fontFamily: 'Code Saver',
  },
};
const commonProps = {
  width: '100%',
  spacing: 0,
  container: true,
  maxWidth: '72.6rem',
  margin: 'auto',
};

const ErrorText = ({ value }) => {
  return (
    <Typography
      variant="paragraphMonoSmall"
      sx={{
        color: 'olive.main',
        height: '0px',
        overflow: 'visible',
        display: 'block',
        position: 'relative',
        top: '-20px',
      }}
    >
      {value && value}
    </Typography>
  );
};

const ProApply = () => {
  const isDesktop = useMediaQuery({ minWidth: 1201 });
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const formId = '6c6cd0a8-70fc-41a1-adb5-36417074fecf';

  const trades = [
    'General Contractor/Builder',
    'Plumber',
    'Electrician',
    'Handyperson',
    'Architect',
    'Interior Designer',
    'Carpenter',
    'Tile Setter',
    'Roofer',
    'HVAC',
    'Floor Installer',
    'Wall Treatments (Painter/Plaster Artist)',
    'Inspector',
    'Landscaper',
    'Appliance Repair',
    'Other',
  ];

  const getCookie = (name) => {
    var value = '; ' + document.cookie;
    var parts = value.split('; ' + name + '=');
    if (parts.length == 2) {
      return parts.pop().split(';').shift();
    }
  };

  const submit = async (values) => {
    const ipResult = await fetch('https://api.ipify.org?format=json');

    const ipResponse = await ipResult.json();
    const ipAddress = ipResponse.ip;
    const hubspotData = [];
    for (const [key, value] of Object.entries(values)) {
      if (key !== 'agreeToTOS') {
        hubspotData.push({
          name: key,
          value: value,
        });
      }
    }

    const hutk = getCookie('hubspotutk');

    const formData = {
      fields: hubspotData,
      submittedAt: Date.now(),
      context: {
        hutk,
        pageUri: window.location.href,
        pageName: 'Apply to be a Pro',
        ipAddress,
      },
    };

    try {
      const submitHubspotForm = httpsCallable(functions, 'submitHubspotForm');
      const response = await submitHubspotForm({
        formId,
        formData,
      });

      console.log('this is the response: ', response);
    } catch (error) {
      console.log('error: ', error);
    }

    // sign up for klayvio email list
    const addEmail = httpsCallable(functions, 'addEmailToList');
    await addEmail({
      email: values.email,
      list: 'VuxPfb', //newsletter list
    });
    setSubmitSuccess(true);
    setSubmitting(false);
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'top',
          backgroundImage: `url(${ToolRack})`,
          backgroundColor: 'cream.main',
        }}
      >
        <Grid
          container
          width="100%"
          maxWidth="1440px"
          mx="auto"
          px="44px"
          mt="115px"
          mb="122px"
          sx={{
            display: 'flex',
            alignItems: 'start',
            justifyContent: 'center',
          }}
        >
          {isDesktop && (
            <Grid
              lg={1}
              width="100%"
              height="191px"
              sx={{
                border: 1,
                borderRight: 0,
                color: 'pink.main',
                background:
                  'linear-gradient(to top left,transparent 0,transparent calc(50% - 0.8px),#f7cbad 50%,transparent calc(50% + 0.8px),transparent),linear-gradient(to top right,transparent 0,transparent calc(50% - 0.8px),#f7cbad 50%,transparent calc(50% + 0.8px),transparent)',
              }}
            ></Grid>
          )}
          <Grid
            item
            xs={12}
            lg={3}
            md={6}
            width="100%"
            height="191px"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'white',
              border: 1,
              borderRight: 0,
              color: 'pink.main',
            }}
          >
            <Typography
              sx={{
                whiteSpace: 'pre-line',
                textAlign: 'center',
                my: '63px',
                color: 'olive.main',
              }}
              display="block"
              variant="h1"
            >
              {'Apply to \n Be a Pro'}
            </Typography>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            sx={{
              backgroundColor: 'white',
              color: 'pink.main',
              border: 1,
              p: '44px',
            }}
          >
            <Typography
              sx={{
                whiteSpace: 'pre-line',
                color: 'olive.main',
                mb: '20px',
                fontSize: '24px',
              }}
              display="block"
              variant="section_h1"
            >
              {
                'Matriarchy Build is looking to connect with Pros for our local services in Austin, TX and our nationwide builder matchmaking program.'
              }
            </Typography>
            <ol>
              <li style={{ fontSize: '20px' }}>
                <Typography
                  sx={{
                    whiteSpace: 'pre-line',
                    color: 'olive.main',
                    mb: '20px',
                    fontSize: '20px',
                  }}
                  display="block"
                  variant="section_h1"
                >
                  {
                    'We have a nationwide Find a Builder platform where we match Matriarchy Build clients with General Contractors for kitchen, bath, whole home remodels and more.'
                  }
                </Typography>
              </li>
              <li style={{ fontSize: '20px' }}>
                <Typography
                  sx={{
                    whiteSpace: 'pre-line',
                    color: 'olive.main',
                    mb: '20px',
                    fontSize: '20px',
                  }}
                  display="block"
                  variant="section_h1"
                >
                  {
                    "We offer Local Services featuring Matriarchy Build Handypeople and a larger Pro Referral network. Right now we service Austin, TX, but we are always looking to learn about great folks across the country so we’re prepared as we expand."
                  }
                </Typography>
              </li>
            </ol>
          </Grid>
        </Grid>
      </Box>
      <Box backgroundColor="cream.main">
        <Grid
          {...commonProps}
          px={isDesktop ? '36px' : '20px'}
          sx={{
            maxWidth: '72.6rem',
            margin: '0 auto',
            justifyContent: 'space-between',
            flexDirection: 'column',
            gap: 2,
          }}
          py="40px"
        >
          <Grid item xs={12}>
            <Typography
              variant="h2"
              alignItems="center"
              color="olive.main"
              sx={{ pb: '20px' }}
            >
              Apply to be a Pro
            </Typography>
          </Grid>
          {submitSuccess && (
            <Stack direction="row">
              <img
                src={Check}
                style={{
                  paddingRight: '5px',
                  height: '20px',
                  marginTop: '2px',
                }}
              />

              <Typography variant="paragraphMono">
                Thanks for contacting us. We'll get back to you as soon as
                possible.
              </Typography>
            </Stack>
          )}
          <Grid item xs={12}>
            <Formik
              validateOnBlur={false}
              validateOnChange={false}
              initialValues={{
                firstname: '',
                lastname: '',
                email: '',
                city: '',
                state: '',
                zip: '',
                license_number: '',
                trade: 0,
                trade_description: '',
                has_insurance: false,
                agreeToTOS: false,
              }}
              validate={(values) => {
                const errors = {};
                if (!values.firstname) {
                  errors.firstname = 'Required';
                }
                if (!values.lastname) {
                  errors.lastname = 'Required';
                }
                if (!values.email) {
                  errors.email = 'Required';
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = 'Invalid email address';
                }
                if (!values.city) {
                  errors.city = 'Required';
                }
                if (!values.state) {
                  errors.state = 'Required';
                }
                if (!values.zip) {
                  errors.zip = 'Required';
                }
                if (!values.trade) {
                  errors.trade = 'Required';
                }
                if (
                  values.trade === 'Other' &&
                  values.trade_description === ''
                ) {
                  errors.trade_description =
                    "Must provide a description for 'Other'";
                }
                if (!values.license_number) {
                  errors.license_number = 'Required';
                }
                if (!values.agreeToTOS) {
                  errors.agreeToTOS =
                    'You must agree to the Terms of Service to submit form';
                }

                return errors;
              }}
              onSubmit={(values) => {
                setSubmitting(true);
                submit(values);
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                isValidating,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Input
                        type="text"
                        name="firstname"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.firstname}
                        disableUnderline
                        fullWidth
                        disabled={isSubmitting}
                        height="40px"
                        placeholder="First Name"
                        sx={{
                          ...inputStyle,
                        }}
                      ></Input>
                      <ErrorText value={errors.firstname} />
                    </Grid>
                    <Grid item xs={6}>
                      <Input
                        type="text"
                        name="lastname"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.lastname}
                        disableUnderline
                        fullWidth
                        disabled={isSubmitting}
                        height="40px"
                        placeholder="Last Name"
                        sx={{
                          ...inputStyle,
                        }}
                      ></Input>
                      <ErrorText value={errors.lastname} />
                    </Grid>
                    <Grid item xs={12}>
                      <Input
                        type="email"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        disableUnderline
                        fullWidth
                        disabled={isSubmitting}
                        height="40px"
                        placeholder="Email"
                        sx={{
                          ...inputStyle,
                        }}
                      ></Input>
                      <ErrorText value={errors.email} />
                    </Grid>
                    <Grid item xs={6}>
                      <Input
                        type="text"
                        name="city"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.city}
                        disableUnderline
                        fullWidth
                        disabled={isSubmitting}
                        height="40px"
                        placeholder="City"
                        sx={{
                          ...inputStyle,
                        }}
                      ></Input>
                      <ErrorText value={errors.city} />
                    </Grid>
                    <Grid item xs={3}>
                      <Input
                        type="text"
                        name="state"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.state}
                        disableUnderline
                        fullWidth
                        disabled={isSubmitting}
                        height="40px"
                        placeholder="State"
                        sx={{
                          ...inputStyle,
                        }}
                      ></Input>
                      <ErrorText value={errors.state} />
                    </Grid>
                    <Grid item xs={3}>
                      <Input
                        type="text"
                        name="zip"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.zip}
                        disableUnderline
                        fullWidth
                        disabled={isSubmitting}
                        height="40px"
                        placeholder="Zipcode"
                        sx={{
                          ...inputStyle,
                        }}
                      ></Input>
                      <ErrorText value={errors.zip} />
                    </Grid>
                    <Grid item xs={12} />
                    <Grid item xs={12}>
                      <FormControl
                        fullWidth
                        disabled={isSubmitting}
                        sx={{
                          ...inputStyle,

                          '& .MuiSelect-select': {
                            color:
                              values.trade != '0'
                                ? '#212121'
                                : 'rgba(45,69, 124, 0.45)',
                            fontFamily: 'Code Saver',
                          },
                        }}
                      >
                        <Select
                          onChange={(e) => {
                            if (
                              e.target.value !== 'Other' &&
                              values.trade_description !== ''
                            ) {
                              values.trade_description = '';
                            }
                            handleChange(e);
                          }}
                          variant="standard"
                          name="trade"
                          disableUnderline
                          disabled={isSubmitting}
                          width="100%"
                          height="40px"
                          value={values.trade}
                          inputProps={{
                            MenuProps: {
                              sx: {
                                borderRadius: 0,
                              },
                              MenuListProps: {
                                sx: {
                                  backgroundColor: 'cream.main',
                                  borderRadius: 0,
                                },
                              },
                            },
                          }}
                        >
                          <MenuItem key="0" value="0" sx={{ ...textStyles }}>
                            Trade
                          </MenuItem>
                          {trades.map((option) => (
                            <MenuItem
                              key={option}
                              value={option}
                              sx={{ ...textStyles }}
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    {values.trade === 'Other' && (
                      <Grid item xs={12}>
                        <Input
                          type="text"
                          name="trade_description"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.trade_description}
                          disableUnderline
                          fullWidth
                          disabled={isSubmitting}
                          height="40px"
                          placeholder="Trade Description"
                          sx={{
                            ...inputStyle,
                          }}
                        ></Input>
                        <ErrorText value={errors.trade_description} />
                      </Grid>
                    )}
                    <Grid item xs={6}>
                      <Input
                        type="text"
                        name="license_number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.license_number}
                        disableUnderline
                        fullWidth
                        disabled={isSubmitting}
                        height="40px"
                        placeholder="License #"
                        sx={{
                          ...inputStyle,
                        }}
                      ></Input>
                      <ErrorText value={errors.license_number} />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        sx={{
                          whiteSpace: 'pre-line',
                          color: 'blue.main',
                          pl: '20px',
                          pt: '10px',
                        }}
                        display="inline-block"
                        variant="paragraphMono"
                      >
                        {'Proof of Insurance?'}
                      </Typography>
                      <div
                        style={{
                          position: 'relative',
                          top: '2px',
                          left: '3px',
                          display: 'inline',
                        }}
                      >
                        <Field type="checkbox" name="has_insurance" />
                      </div>
                      <ErrorText value={errors.has_insurance} />
                    </Grid>
                    <Stack direction="column" mb="20px" sx={{ width: '100%' }}>
                      <Typography
                        sx={{
                          whiteSpace: 'pre-line',
                          color: 'blue.main',
                          pl: '5px',
                          pt: '2px',
                        }}
                        display="block"
                        variant="paragraphMono"
                      >
                        {'I agree to the Matriarchy Build partner pledge:'}
                      </Typography>
                      <ol>
                        <li>
                          <Typography
                            sx={{
                              whiteSpace: 'pre-line',
                              color: 'blue.main',
                              pl: '5px',
                              pt: '2px',
                            }}
                            display="block"
                            variant="paragraphMono"
                          >
                            {
                              'We value respect, communication, collaboration, and transparency.'
                            }
                          </Typography>
                        </li>
                        <li>
                          <Typography
                            sx={{
                              whiteSpace: 'pre-line',
                              color: 'blue.main',
                              pl: '5px',
                              pt: '2px',
                            }}
                            display="block"
                            variant="paragraphMono"
                          >
                            {
                              'We strive for sustainability whenever possible: good materials, responsible practices.'
                            }
                          </Typography>
                        </li>
                        <li>
                          <Typography
                            sx={{
                              whiteSpace: 'pre-line',
                              color: 'blue.main',
                              pl: '5px',
                              pt: '2px',
                            }}
                            display="block"
                            variant="paragraphMono"
                          >
                            {'No jerks! As much as possible.'}
                          </Typography>
                        </li>
                        <li>
                          <Typography
                            sx={{
                              whiteSpace: 'pre-line',
                              color: 'blue.main',
                              pl: '5px',
                              pt: '2px',
                            }}
                            display="block"
                            variant="paragraphMono"
                          >
                            {"Let's all start from a place of budget alignment"}
                          </Typography>
                        </li>
                      </ol>
                      <Stack direction="row" sx={{ mx: 'auto', mb: '20px' }}>
                        <Field type="checkbox" name="agreeToTOS" />

                        <Typography
                          sx={{
                            whiteSpace: 'pre-line',
                            color: 'blue.main',
                            pl: '5px',
                            pt: '2px',
                          }}
                          display="block"
                          variant="paragraphMono"
                        >
                          {'I Agree'}
                        </Typography>
                      </Stack>
                      {errors.agreeToTOS && errors.agreeToTOS}
                    </Stack>
                    {(isSubmitting || isValidating) &&
                      !submitSuccess === true && (
                        <Box
                          width="100%"
                          sx={{
                            display: 'flex',
                            mt: '40px',
                            justifyContent: 'center',
                          }}
                        >
                          <CircularProgress />
                        </Box>
                      )}
                    {!submitting && submitSuccess !== true && (
                      <ActionButton title="Submit" type="submit" />
                    )}
                  </Grid>
                </form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Box>
      <MailingList />
      <Footer />
    </Box>
  );
};

export default ProApply;
