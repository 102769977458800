import { Box, styled } from "@mui/material";
import { blue } from "@mui/material/colors";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import { useMediaQuery } from "react-responsive";
export const Header = styled(Typography)(({ theme }) => ({
  "&": {
    color: theme.palette.lightOlive.main,
    display: "block",
    marginTop: "20px",
  },
}));

export const Paragraph = styled(Typography)(({ theme }) => ({
  "&": {
    color: theme.palette.blue.main,
  },
}));

export const ListItemStyled = styled(ListItem)(({ theme }) => ({
  "&": {
    color: theme.palette.blue.main,
    fontFamily: "Code Saver",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "145%",
  },
}));

const TermsContent = () => {
  const isPortrait = useMediaQuery({ orientation: 'portrait' })

  return (
    <Box width="100%" px={isPortrait ? "20px": " 45px"} py="65px">
      <Paragraph variant="paragraphMono">Last updated April, 2022.</Paragraph>

      <Header variant="paragraphSans">Welcome to Matriarchy Build.</Header>
      <Paragraph variant="paragraphMono">
        We provide online community, content, and a marketplace for DIY, home
        improvement, and renovation through our website at{" "}
        <a href="https://matriarchbuild.com">
          www.matriarchybuild.com
        </a>{" "}
        (collectively, the “Services”). These Terms form a legally binding
        agreement between you, whether personally or on behalf of an entity
        (“you”) and Matriarchy Build, Inc. (“Matriarchy Build,”
        “we,” or “us”).&nbsp; By accessing the Services, you agree that you have
        read, understood, and agree to be bound by all of these Terms. If you do
        not agree, you may not use the Services.
      </Paragraph>

      <Paragraph variant="paragraphMono">
        THESE TERMS HAVE A BINDING ARBITRATION PROVISION AND A CLASS ACTION
        WAIVER PROVISION. THEY AFFECT YOUR LEGAL RIGHTS. PLEASE READ THEM.
      </Paragraph>
      <Header variant="paragraphSans">
        The Services; Working with Pros&nbsp;
      </Header>

      <Paragraph variant="paragraphMono">
        We provide a place to find, review, and connect with others online about
        home renovation. We do not provide contractor or onsite home improvement
        services directly, nor do we endorse the work of any one user or Pro. We
        are not liable for any work, payment, or any other source of liability
        between or among users, whether Pro or generally.&nbsp;
      </Paragraph>

      <Paragraph variant="paragraphMono">
        The Services may allow you to interact with Pros, who may offer their
        services and consultation through our site. While we may consider
        experience levels and other aspects of Pros on our site and allow Pros
        and users to profile their work, we do not check and are not responsible
        for the license statuses, regulatory or other compliance, identity, or
        background of Pros or users. We are not a party to any agreements you
        may separately enter into with another user or a Pro.&nbsp;
      </Paragraph>

      <Paragraph variant="paragraphMono">
        If you are a Pro, these Terms apply to you, as well as the Special Terms
        for Pro Members (item 22 below),&nbsp;&nbsp;&nbsp;
      </Paragraph>

      <Paragraph variant="paragraphMono">
        We may also allow the posting of reviews or commentary. We have no
        obligation to screen or to delete reviews, nor do we endorse or have any
        liability for anything they say.&nbsp;
      </Paragraph>

      <Header variant="paragraphSans">
 Updates to these Terms
      </Header>

      <Paragraph variant="paragraphMono">
        We reserve the right to modify these Terms. We will let you know of
        changes by updating the “Last Updated” date above. Using the Services
        after we post an update means you accept the changes. We might also post
        and make you aware of additional terms, such as Community Guidelines, on
        the Services from time to time. Those additional terms are incorporated
        by reference to this Agreement.
      </Paragraph>
      <Header variant="paragraphSans">
        Intellectual Property Rights; Our License to You
      </Header>

      <Paragraph variant="paragraphMono">
        Unless otherwise expressly indicated, the Services and Content are our
        intellectual property. Materials, text, graphics, code, databases,
        functionality, software, designs, audio, and video on the Services
        (“Content”) and trademarks, service marks, and logos (the “Marks”) are
        owned and controlled by us and/or licensed to us by others. We reserve
        all rights. Except as expressly provided, no part of the Services and no
        Content or Marks may be copied, reproduced, aggregated, republished,
        uploaded, posted, publicly displayed, encoded, translated, transmitted,
        distributed, sold, licensed, or otherwise exploited for any commercial
        purpose whatsoever, without our express prior written permission.
      </Paragraph>

      <Paragraph variant="paragraphMono">
        If you are eligible to use the Services and subject to your compliance
        with these Terms, we grant you a limited license to access and use the
        Services and to download or print a copy of any portion of the Content
        for your personal, non-commercial use. We reserve all rights not
        expressly granted to you in and to the Services, the Content, and the
        Marks.
      </Paragraph>
      <Header variant="paragraphSans">
      Account &amp; Registration&nbsp;
          </Header>
      <Paragraph variant="paragraphMono">
        We may require you to register with or create an account to use some of
        the Services. You agree to keep your password confidential and will be
        responsible for all use of your account and password. In creating and
        using the Services and/or your account, you represent and warrant
        that:&nbsp;
      </Paragraph>

      <ul>
        <ListItemStyled>
          You will submit only true, accurate, current, and complete account
          information;
        </ListItemStyled>
        <ListItemStyled>
          You’ll keep the information current and accurate as necessary;
        </ListItemStyled>
        <ListItemStyled>
          You are legally able to and do agree to comply with these Terms;
        </ListItemStyled>
        <ListItemStyled>
          You are age 18 or older and not a minor in the jurisdiction in which
          you reside
        </ListItemStyled>
        <ListItemStyled>
          You won’t use the Services for any illegal or unauthorized purpose;
          and
        </ListItemStyled>
        <ListItemStyled>
          You will not use the Services to violate or in a way that violates any
          applicable law or regulation.
        </ListItemStyled>
      </ul>

      <Paragraph variant="paragraphMono">
        If you violate these restrictions or otherwise violate these Terms, we
        have the right to suspend or terminate your account and refuse any
        current or future use of the Services or any portion of them.&nbsp;
      </Paragraph>

      <Header variant="paragraphSans">
        Guidelines&nbsp;
      </Header>

      <Paragraph variant="paragraphMono">You agree not to:</Paragraph>

      <ul>
        <ListItemStyled>
          Upload inappropriate, harassing, or objectionable content;&nbsp;
        </ListItemStyled>
        <ListItemStyled>
          Systematically retrieve data or other content from the Services to
          create or compile, directly or indirectly, a collection, compilation,
          database, or directory without written permission from us.
        </ListItemStyled>
        <ListItemStyled>
          Make any unauthorized use of the Services, including collecting
          usernames and/or email addresses of users by electronic or other means
          for the purpose of sending unsolicited email, or creating user
          accounts by automated means or under false pretenses.
        </ListItemStyled>
        <ListItemStyled>
          Use the Services for any unlawful purpose or as prohibited by these
          Terms;
        </ListItemStyled>
        <ListItemStyled>
          Damage, disable, overburden, interfere with, or impair the Services;
        </ListItemStyled>
        <ListItemStyled>
          Attempt to gain unauthorized access to the Services or other users’
          accounts or game or attempt to game the Services for any reason;
        </ListItemStyled>
        <ListItemStyled>
          Use any robot or other automated means to access the Services without
          our express written permission, or bypass or disable any access,
          copyright protections, or security measures;&nbsp;
        </ListItemStyled>
        <ListItemStyled>
          Engage in fraud, deceit, impersonation, or false or deceptive
          advertising or marketing;&nbsp;
        </ListItemStyled>
        <ListItemStyled>
          Modify, adapt, translate or create derivative works based upon any of
          the Services;
        </ListItemStyled>
        <ListItemStyled>
          Reverse engineer, decompile, disassemble or otherwise attempt to
          discover the source code of the Services, except to the extent
          expressly permitted by applicable law notwithstanding this limitation;
        </ListItemStyled>
        <ListItemStyled>
          Rent, lease, loan, resell, sublicense, distribute or otherwise
          transfer any of the Services to any third party or similar services
          for any third party; or use the Services for any purpose other than
          your own internal business or personal use;&nbsp;
        </ListItemStyled>
        <ListItemStyled>
          Access the Services if you are a direct competitor of ours, except
          with our prior written consent, or for any other competitive purposes;
        </ListItemStyled>
        <ListItemStyled>
          Introduce viruses, Trojan horses, spyware, or any other elements that
          could interrupt or harm us or the Services;
        </ListItemStyled>
        <ListItemStyled>
          use the Services to advertise or offer goods and services other than
          as provided through the Services;
        </ListItemStyled>
        <ListItemStyled>
          use any information obtained from the Services in order to harass,
          abuse, or harm another person;
        </ListItemStyled>
        <ListItemStyled>
          harass, annoy, intimidate, disparage, or threaten any of our users,
          employees or agents, or engage in activities that encourage others to
          do so, e.g., brigading; or
        </ListItemStyled>
        <ListItemStyled>
          delete copyright or other proprietary rights notice from any Content.
        </ListItemStyled>
      </ul>

      <Paragraph variant="paragraphMono">
        Using the Services in a way that violates these Guidelines or other
        parts of the Terms may result in, among other things, us terminating or
        suspending your ability to use the Services and/or legal action.
      </Paragraph>

      <Header variant="paragraphSans">
        Your Contributions
      </Header>

      <Paragraph variant="paragraphMono">
        The Services may offer chat or message boards, online forums, direct
        messages, and other functionality. You might also be able to create,
        submit, post, display, transmit, perform, publish, distribute, or
        broadcast content and materials through the Services, like text,
        writings, video, audio, photographs, graphics, comments, suggestions, or
        personal information or other material – in many forms like profile
        pictures of a space, featured pages, inspiration, Pro pages, and reviews
        (all the things you post or upload are “Contributions”).&nbsp;
      </Paragraph>

      <Paragraph variant="paragraphMono">
        You own and retain full ownership of your Contributions and any
        intellectual property in it. While we do not assert any ownership over
        Contributions, when you create or make available Contributions, you
        automatically grant, and affirm that you have the right to grant, to us
        an unlimited, irrevocable, perpetual, non-exclusive, transferable,
        royalty-free, fully-paid, worldwide right, and license to host, use,
        copy, reproduce, disclose, sell, resell, publish, store, cache, perform,
        display, reformat, translate, transmit, excerpt (in whole or in part),
        and distribute Contributions (including where applicable your image and
        voice). This is for any purpose, commercial, advertising, or otherwise,
        and to prepare derivative works of, or incorporate into other works,
        such Contributions. The use and distribution may occur in any media
        formats and through any media channels for all time. This license
        includes our use of your name and any of trademarks. You waive moral
        rights in your Contributions, and you warrant that moral rights have not
        otherwise been asserted in your Contributions. Depending on where you
        post, we may make your Contributions viewable by other users of the
        Services and through third-party websites and in other media.&nbsp;
      </Paragraph>

      <Paragraph variant="paragraphMono">
        As to your Contributions, you represent and warrant that:
      </Paragraph>

      <ol>
        <ListItemStyled>
          your Contributions do not and will not infringe the proprietary and
          intellectual property rights of others, including their copyright,
          patent, trademark, trade secret, or moral rights&nbsp;
        </ListItemStyled>
        <ListItemStyled>
          you are the creator and owner of or have the necessary licenses,
          rights, consents, releases, and permissions to use and to authorize
          us, the Services, and other users of the Services to use your
          Contributions in any manner contemplated by the Services and these
          Terms and Conditions.
        </ListItemStyled>
        <ListItemStyled>
          you have the written consent, release, and/or permission of each and
          every identifiable individual person in your Contributions to use the
          name or likeness of each and every such identifiable individual person
          to enable inclusion and use of your Contributions in any manner
          contemplated by the Services and these Terms and Conditions.
        </ListItemStyled>
        <ListItemStyled>
          your Contributions are not false, inaccurate, or misleading.
        </ListItemStyled>
        <ListItemStyled>
          your Contributions do not violate these Terms, for example, because
          they violate others’ copyrights, or are harassing or libelous, violate
          the privacy or publicity rights of any third party, or violate the
          law, or in each case link to material that does so.
        </ListItemStyled>
      </ol>

      <Paragraph variant="paragraphMono">
        You are solely responsible for your Contributions. You grant us the
        right (1) to edit, redact, or otherwise change any Contributions; (2) to
        post Contributions in other locations within and beyond the Services;
        and (3) to pre-screen or delete Contributions anytime and for any
        reason, without notice.
      </Paragraph>

      <Paragraph variant="paragraphMono">
        Note that any questions, comments, suggestions, ideas, feedback, or
        other information regarding the Services that you submit to us
        (“Feedback”) are non-confidential and become our sole property. We own
        exclusive rights, including all intellectual property rights, to the
        Feedback, and can use and disseminate it for any purpose without
        acknowledgment or compensation to you.&nbsp;
      </Paragraph>

      <Header variant="paragraphSans">
        Third-Party Account Linking; Third-Party Services; Advertising
      </Header>

      <Paragraph variant="paragraphMono">
        We may offer you the ability to link third-party accounts logging into
        our Services through third-party Services or otherwise integrating your
        accounts with others’ services. By using such integrations you agree
        that we may access, make available, and store (if applicable) any
        content or contacts that you share with us through such integrations.
        Depending on the accounts you integrate and their privacy settings,
        content from those accounts may be available on and through your account
        on the Services. You may deactivate the connection between your account
        on the Services and your Third-Party Accounts at any time. We are not a
        party to your relationship with such third parties, and their terms of
        service and privacy policies apply to the data you share with
        them.&nbsp;
      </Paragraph>

      <Paragraph variant="paragraphMono">
        We might also link out to other sites (“Third-Party Websites”) as well
        as articles, photographs, text, graphics, pictures, designs, music,
        sound, video, information, applications, software, and other content or
        items belonging to or originating from third parties (“Third-Party
        Content”). We are not responsible for and do not endorse Third-Party
        Websites and Third-Party Content or any of their offerings.&nbsp;
      </Paragraph>

      <Paragraph variant="paragraphMono">
        We allow advertisements. If you are an advertiser, Pro, or other
        provider, you take full responsibility for any advertisements you place
        on the Services and any services you provide or products you sell
        through the Services or to users of them.&nbsp;
      </Paragraph>

      <Header variant="paragraphSans">
        Service Changes and Management of the Services
      </Header>

      <Paragraph variant="paragraphMono">
        We have the right, but not the obligation, to&nbsp;(1) monitor the
        Services; (2) take legal action against anyone who, in our discretion,
        violates the law or these Terms; (3) limit the availability of or
        disable Contributions or the Services; (4) manage, change, or
        discontinue the Services or remove any Content or Contributions from
        them, in each case at any time and for any reason at our discretion and
        without notice.&nbsp;
      </Paragraph>

      <Paragraph variant="paragraphMono">
        We have no obligation to update any of our Services or information
        within them. Prices and services may change. We will not be liable to
        you or any third party for any modification, price change, suspension,
        or discontinuance of the Services. The Services may not be always
        available and quantities or offerings from Pros or others may be
        limited. We may experience problems or need to perform maintenance
        related to the Services, resulting in interruptions, delays, or errors.
        We have no liability whatsoever for any loss, damage, or inconvenience
        caused by your inability to access or use the Services. The Services
        might have typographical errors, inaccuracies, or omissions, including
        descriptions, pricing, availability, and various other
        information.&nbsp;
      </Paragraph>

      <Header variant="paragraphSans">
        Privacy and Data&nbsp;
      </Header>

      <Paragraph variant="paragraphMono">
        We care about data privacy and security. Please review our Privacy
        Policy at www.matriarchybuild.com/privacy-terms. By using the Services,
        you agree to be bound by our Privacy Policy, which is incorporated into
        these Terms. The Services are hosted in the United States. If you access
        the Services from outside the United States, then through your continued
        use of the Services, you are transferring your data to the United
        States, and you expressly consent to have your data transferred to and
        processed in the United States.&nbsp; The Services are not intended for
        or directed to children. Therefore, in accordance with the U.S.
        Children’s Online Privacy Protection Act, if we receive actual knowledge
        that anyone under the age of 13 has provided personal information to us
        without the requisite and verifiable parental consent, we will delete
        that information from the Services as quickly as is reasonably
        practical.
      </Paragraph>

      <Paragraph variant="paragraphMono">
        We will maintain certain data that you transmit to the Services for the
        purpose of managing the Services, as well as data relating to your use
        of the Services. You are solely responsible for all data that you
        transmit or that relates to any activity you have undertaken using the
        Services.&nbsp; You agree that we shall have no liability to you for any
        loss or corruption of any such data, and you hereby waive any right of
        action against us arising from any such loss or corruption of such data.
      </Paragraph>

      <Paragraph variant="paragraphMono">
        Also, the Services may request consent to obtain and use your device’s
        geolocation. When you provide a phone number to us, you consent to
        receive text messages (including text messages sent via an automatic
        telephone dialing system) at that phone number. You can opt out of
        receiving text messages anytime by contacting supporting
        @matriarchybuild.com.&nbsp;
      </Paragraph>

      <Header variant="paragraphSans">
        DMCA&nbsp;
      </Header>

      <Paragraph variant="paragraphMono">
        We respect the intellectual property rights of others. If you believe
        that your copyright has been infringed by a third party on our Services,
        then please follow this take-down procedure. Our policy is to respond to
        notices of alleged infringement that comply with the Digital Millennium
        Copyright Act (the “DMCA”). To be effective, your notice of an
        infringement claim must be signed by you and otherwise comply with the
        detailed requirements set forth in the DMCA including that you have a
        good faith belief that your content is infringed. We encourage you to
        review them (see 17 U.S.C. § 512(c)(3)) before sending your claim. To
        send us a DMCA notification of alleged infringement of intellectual
        property rights, contact our Designated Agent at:
      </Paragraph>

      <Paragraph variant="paragraphMono">Attn: Copyright Agent</Paragraph>

      <Paragraph variant="paragraphMono">
        Matriarchy Build, Inc.&nbsp;
      </Paragraph>

      <Paragraph variant="paragraphMono">2404 Ware Rd.&nbsp;</Paragraph>

      <Paragraph variant="paragraphMono">Austin, TX 78741</Paragraph>

      <Paragraph variant="paragraphMono">
        Email:
        <a href="mailto:info@matriarchybuild.com">info@matriarchybuild.com</a>
      </Paragraph>

      <Paragraph variant="paragraphMono">
        When we receive a notice of claimed infringement, we will follow the
        procedures outlined in the DMCA to resolve the claim between the
        notifying party and the alleged infringer who provided the content at
        issue and may issue a counter notification to you.&nbsp;
      </Paragraph>

      <Header variant="paragraphSans">
        Term and Termination
      </Header>

      <Paragraph variant="paragraphMono">
        These Terms and Conditions remain in full force and effect while you use
        the Services. We reserve the right to, in our sole discretion and
        without notice or liability, deny access to and use of the services
        (including blocking IP addresses), to any person for any reason. We may
        terminate your use or participation in the services or delete any
        content or information at any time, without warning, in our sole
        discretion. If we terminate or suspend your account for any reason, you
        are prohibited from registering and creating a new account under your
        name, a fake or borrowed name, or the name of any third party, even if
        you may be acting on behalf of the third party. In addition to
        terminating or suspending your account, we reserve the right to take
        appropriate legal action, including without limitation pursuing civil,
        criminal, and injunctive redress.
      </Paragraph>

      <Header variant="paragraphSans">
        Governing Law; Prohibited Jurisdictions
      </Header>

      <Paragraph variant="paragraphMono">
        These Terms and your use of the Services are governed by and construed
        in accordance with the laws of the State of Delaware 
        applicable to agreements made and to be entirely performed within the
        State of Delaware, without regard to its conflict of law
        principles.&nbsp;
      </Paragraph>

      <Paragraph variant="paragraphMono">
        The Services are not for distribution in any jurisdiction or country
        where such distribution or use would be contrary to law or regulation.
        Accordingly, those persons who choose to access the Services from other
        locations do so on their own initiative and are solely responsible for
        compliance with local laws, if and to the extent local laws are
        applicable.&nbsp;
      </Paragraph>

      <Header variant="paragraphSans">
        Disputes; Arbitration
      </Header>

      <Paragraph variant="paragraphMono">
        THIS SECTION HAS A MANDATORY AND BINDING ARBITRATION PROVISION. READ IT
        CAREFULLY.&nbsp;
      </Paragraph>

      <Paragraph variant="paragraphMono">
        If you have a dispute with us, you agree to first try to resolve it by
        contacting us at{" "}
        <a href="mailto:info@matriarchybuild.com">info@matriarchybuild.com</a>.
        If we are unable to come to a resolution directly, any dispute or claim
        by you relating in any way to the Services or these Terms will be
        resolved by binding arbitration, rather than in courts, unless your
        claim qualifies for small claims court or a proceeding commenced by
        either party seeking an injunction, a restraining order, or any other
        equitable remedy. This arbitration agreement is governed by the Federal
        Arbitration Act (FAA).
      </Paragraph>

      <Paragraph variant="paragraphMono">
        To initiate arbitration, you can (or we can) demand that the dispute be
        resolved by binding arbitration administered by the American Arbitration
        Association in accordance with its Consumer Arbitration Rules available
        at www.adr.org. If AAA is unavailable, and if you and Matriarchy Build
        cannot agree on a substitute, then either you or Matriarchy Build may
        request that a court appoint a substitute. The rules in this arbitration
        agreement will be followed if there is a disagreement between the
        agreement and the arbitration forum’s procedures. Judgment on any award
        rendered in any such arbitration may be entered in any court having
        jurisdiction. Any such arbitration must be conducted by one arbitrator
        and must be conducted in Austin, Texas, or another mutually agreed
        location.&nbsp;
      </Paragraph>

      <Header variant="paragraphSans">
        Class Waiver &amp; Waiver of Jury Trial
      </Header>

      <Paragraph variant="paragraphMono">
        YOU AND MATRIARCHY BUILD MAY EACH BRING CLAIMS AGAINST THE OTHER ONLY IN
        AN INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY
        PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. CLAIMS OF TWO OR MORE
        PERSONS MAY NOT BE JOINED OR CONSOLIDATED IN THE SAME ARBITRATION UNLESS
        THEY ARISE FROM THE SAME TRANSACTION.
      </Paragraph>

      <Paragraph variant="paragraphMono">
        YOU AND MATRIARCHY BUILD HEREBY WAIVE YOUR RESPECTIVE RIGHT TO A TRIAL
        BY JURY IN ANY PROCEEDINGS ARISING OUT OF THE SERVICES OR THESE TERMS.
      </Paragraph>

      <Header variant="paragraphSans">
        DISCLAIMER AND INJURY WAIVER
      </Header>

      <Paragraph variant="paragraphMono">
        THROUGH THE SERVICES, YOU MAY RECEIVE SUPPORT, VIDEOS, GUIDANCE AND
        OTHER COMMUNICATIONS RELATED TO HOME IMPROVEMENT. HOME IMPROVEMENT AND
        CONSTRUCTION ARE REAL-WORLD ACTIVITIES WITH REAL-WORLD RISKS. YOU
        UNDERSTAND THOSE RISKS AND THE POSSIBILITY OF HARM OR INJURY AND THAT WE
        ARE NOT RESPONSIBLE FOR THAT RISK AS OUTLINED IN THIS AGREEMENT.
      </Paragraph>

      <Paragraph variant="paragraphMono">
        THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOUR USE
        OF THE SERVICES AND ACTIONS YOU TAKE RELATED TO THEM WILL BE AT YOUR
        SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL
        WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR
        USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
        WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR
        COMPLETENESS OF THE SERVICES’ CONTENT, OTHER USERS, PROS, OR THE CONTENT
        OF ANY WEBSITES LINKED TO THE SERVICES AND WE (AND OTHER USERS OR PROS
        WITH WHOM YOU MAY INTERACT ON THE SERVICES AND/OR AGAINST WHOM YOU SEEK
        TO MAKE A CLAIM BASED ON THE SERVICES) WILL ASSUME NO LIABILITY OR
        RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT
        AND MATERIALS WITHIN OR LINKED FROM THE SERVICES, (2) PERSONAL INJURY OR
        PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO
        AND USE OF THE SERVICES, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR
        SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL
        INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF
        TRANSMISSION TO OR FROM THE SERVICES, (5) ANY BUGS, VIRUSES, TROJAN
        HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICES
        BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT
        AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT
        OF&nbsp; ITS&nbsp; USE. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
        RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A
        THIRD PARTY INCLUDING ANY PROS OR NON PRO USERS THROUGH THE
        SERVICES.&nbsp;
      </Paragraph>

      <Header variant="paragraphSans">
        LIMITATION OF LIABILITY
      </Header>

      <Paragraph variant="paragraphMono">
        PLEASE READ THIS SECTION CAREFULLY BECAUSE IT LIMITS OUR AND PROS’&nbsp;
        LIABILITY TO YOU. IN NO EVENT WILL WE OR OUR EMPLOYEES, DIRECTORS,
        AGENTS, OR PROS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT,
        INDIRECT, EXEMPLARY, CONSEQUENTIAL, INCIDENTAL, SPECIAL, OR PUNITIVE
        DAMAGES, INCLUDING LOST REVENUE, DATA LOSS, LOST PROFIT, OR OTHER
        DAMAGES ARISING FROM YOUR USE OF THE SERVICES, EVEN IF WE HAVE BEEN
        ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.&nbsp;
      </Paragraph>

      <Paragraph variant="paragraphMono">
        NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THESE TERMS, OUR LIABILITY
        TO YOU (AND OUR PROS LIABILITY TO YOU ARISING FROM THE SERVICES) FOR ANY
        CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL
        TIMES BE LIMITED TO THE LESSER OF THE AMOUNT PAID, IF ANY, BY YOU TO US
        DURING THE 12-MONTH PERIOD PRIOR TO ANY CAUSE OF ACTION ARISING OR $100.
        YOU UNDERSTAND THAT WHERE YOU INTERACT WITH PROS THROUGH THE SERVICES
        (FOR EXAMPLE, IF YOU HAVE A VIDEO CALL), THEIR LIABILITY TO YOU IS
        LIMITED BY THIS PROVISION.
      </Paragraph>

      <Paragraph variant="paragraphMono">
        IF YOUR STATE DOES NOT ALLOW EXCLUSION OF CERTAIN WARRANTIES OR
        LIMITATION OF LIABILITY, THE ABOVE LIMITATIONS OR EXCLUSIONS MAY NOT
        APPLY TO YOU; IN THAT CASE, OUR LIABILITY SHALL BE LIMITED TO THE
        GREATEST EXTENT PERMITTED BY LAW.&nbsp;
      </Paragraph>

      <Paragraph variant="paragraphMono">
        (15) Injury waiver. Through this site and its support team, Matriarchy
        Build offers instructions and guidance for home improvement projects.
        You acknowledge home improvement involves inherent risks, dangers and
        hazards which may result in injury, including death. You agree to
        indemnify and hold harmless Matriarchy Build from and against any and
        all claims for personal injuries or damages of any kind arising from use
        of its products and services. You should use your best judgment and
        exercise caution where appropriate. Your use of this Site and any other
        education provided by Matriarchy Build are entirely at your own
        risk.&nbsp;
      </Paragraph>

      <Header variant="paragraphSans">
        INDEMNIFICATION
      </Header>

      <Paragraph variant="paragraphMono">
        You agree to defend, indemnify, and hold us harmless, including our
        subsidiaries, affiliates, and all of our respective officers, agents,
        partners, and employees, from and against any loss, damage, liability,
        claim, or demand, including reasonable attorneys’ fees and expenses,
        made by any third party due to or arising out of: (1) your
        Contributions; (2) use of the Services; (3) breach of these Terms and/or
        the representations and warranties set forth in them; (5) your violation
        of the rights of a third party, including but not limited to
        intellectual property rights; or (6) any overt harmful act toward any
        other user of the Services or with whom you connected via the Services
        (collectively, “Claims”).&nbsp; The preceding indemnity obligation on
        you also applies to Pros to the extent that Claims arise from
        interactions with Pros on or through the Services.
      </Paragraph>

      <Paragraph variant="paragraphMono">
        Notwithstanding the foregoing, we reserve the right, at your expense, to
        assume the exclusive defense and control of any matter for which you are
        required to indemnify us, and you agree to cooperate, at your expense,
        with our defense of such claims. We will use reasonable efforts to
        notify you of any such claim, action, or proceeding which is subject to
        this indemnification upon becoming aware of it.&nbsp;
      </Paragraph>

      <Header variant="paragraphSans">
        California users and residents
      </Header>

      <Paragraph variant="paragraphMono">
        If any complaint with us is not satisfactorily resolved, you can contact
        the Complaint Assistance Unit of the Division of Consumer Services of
        the California Department of Consumer Affairs in writing at 1625 North
        Market Blvd., Suite N 112, Sacramento, California 95834 or by telephone
        at (800) 952-5210 or (916) 445-1254.
      </Paragraph>

      <Header variant="paragraphSans">
        Website Accessibility Notice
      </Header>

      <Paragraph variant="paragraphMono">
        While we strive to adhere to the accepted guidelines and standards for
        accessibility and usability, some of our pages may not be compliant with
        Web Content Accessibility Guidelines (WCAG) as published by the Web
        Accessibility Initiative (WAI) of the World Wide Web Consortium (W3C).
      </Paragraph>

      <Paragraph variant="paragraphMono">
        If, because of a disability, you are unable to access our Service, have
        questions related to the accessibility of our Service, and/or would like
        to report barriers to accessing our Service or request a transcribed
        copy or assistance reading it, please email info@matriarchybuild.com.
      </Paragraph>

      <Header variant="paragraphSans">
        Miscellaneous
      </Header>

      <Paragraph variant="paragraphMono">
        These Terms (and any policies or operating rules posted by us on the
        Services as noted) constitute the entire agreement and understanding
        between you and us. Our failure to exercise or enforce any right or
        provision of these Terms shall not operate as a waiver of such right or
        provision.&nbsp;
      </Paragraph>

      <Paragraph variant="paragraphMono">
        These Terms operate to the fullest extent permissible by law. We may
        assign any or all of our rights and obligations to others at any time.
        We shall not be responsible or liable for any loss, damage, delay, or
        failure to act caused by any cause beyond our reasonable control.&nbsp;
      </Paragraph>

      <Paragraph variant="paragraphMono">
        If any provision or part of a provision of these Terms is determined to
        be unlawful, void, or unenforceable, that provision or part of the
        provision is deemed severable from these Terms and does not affect the
        validity and enforceability of any remaining provisions.&nbsp;
      </Paragraph>

      <Paragraph variant="paragraphMono">
        There is no joint venture, partnership, employment or agency
        relationship created between you and us as a result of these Terms or
        use of the Services. You agree that these Terms will not be construed
        against us by virtue of having drafted them.
      </Paragraph>

      <Header variant="paragraphSans">
        Contact Us
      </Header>

      <Paragraph variant="paragraphMono">
        To resolve a complaint regarding the Services or to receive further
        information regarding use of the Services, please contact us at:
        &nbsp;
      </Paragraph>

      <Paragraph variant="paragraphMono">Matriarchy Build, Inc.</Paragraph>

      <Paragraph variant="paragraphMono">2404 Ware Rd.</Paragraph>

      <Paragraph variant="paragraphMono">Austin, TX 78741-5719</Paragraph>

      <Paragraph variant="paragraphMono">
        <a href="mailto:info@matriarchybuild.com">info@matriarchybuild.com</a>
        &nbsp;&nbsp;
      </Paragraph>

      <Header variant="paragraphSans">
        Special Terms for Pros&nbsp;
      </Header>

      <Paragraph variant="paragraphMono">
        By registering or using the Services to offer, post, or provide
        services, Pros represent and warrant that they, and their employees,
        agents, contractors, and subcontractors or anyone else who may be
        involved in their provision of services through Matriarchy Build, follow
        the laws and regulations they are subject to, wherever they offer their
        Pro services and in relation to the specific job or user interaction
        they are having through the Services. That includes being fully
        qualified and experienced, as well as being licensed, certified, bonded,
        and insured, as required. Pros also specifically agree to comply with
        privacy laws like the California Consumer Privacy Act if it applies to
        them, in their use any personal information obtained through us.
      </Paragraph>

      <Paragraph variant="paragraphMono">
        Pros understand and agree that by using the Services, they receive only
        the ability to use the Services to access and communicate with users.
        For example, non-Pro users may use the Platform to intentionally
        interact with Pros.&nbsp;
      </Paragraph>

      <Paragraph variant="paragraphMono">
        Pros understand and agree that they are users of Matriarchy Build, and
        not employees, partners, joint venturers, or agents. Pros set or confirm
        their own prices and determine their own work schedule, and Matriarchy
        Build does not control their schedule or when or how they interact on
        the Services once connected with interested users.
      </Paragraph>

      <Paragraph variant="paragraphMono">
        Portions of these terms were created using{" "}
        <a href="http://www.termly.com/">Termly</a>.
      </Paragraph>
    </Box>
  );
};

export default TermsContent;
