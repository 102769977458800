import { Box, Dialog } from "@mui/material";
import { useContext, useState, useEffect } from "react";
import { UserContext } from "../../../user-context";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useMediaQuery } from "react-responsive";
import SideBar from "../SideBar";
import { Link } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import Footer from "../../../SharedComponents/Footer";
import Button from "@mui/material/Button";
import {
  query,
  collection,
  getFirestore,
  doc,
  getDocs,
  deleteDoc,
  orderBy,
  limit,
  onSnapshot,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { ActionButton } from "../../../SharedComponents/Buttons";
import Grid from "@mui/material/Unstable_Grid2/";
import NewPromoCode from "./Components/NewPromoCode";

const PromoCodes = (props) => {
  const isDesktop = useMediaQuery({ minWidth: 1201 });
  const isMedium = useMediaQuery({ minWidth: 901 });
  const [creatingPromoCode, setCreatingPromoCode] = useState(false);
  const [promoCodes, setPromoCodes] = useState([]);
  const [userType, setUserType] = useState("user");
  const db = getFirestore();

  const user = useContext(UserContext);

  const setUserAttributes = async (id) => {
    if (user) {
      user.user
        .getIdTokenResult()
        .then((idTokenResult) => {
          if (idTokenResult.claims.userType === "admin") {
            setUserType("admin");
          } else {
            setUserType("user");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    if (user) {
      setUserAttributes(user.uid);
    }
  }, [user]);

  const getPromoCodes = async () => {
    const q = query(collection(db, "promoCodes"), orderBy("dateCreated"));

    const querySnapshot = await getDocs(q);
    var unsubscribe = onSnapshot(q, (querySnapshot) => {
      let queryPromoCodes = [];
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots

        const promoCode = {
          id: doc.id,
          ...doc.data(),
        };
        queryPromoCodes.push(promoCode);
      });
      setPromoCodes(queryPromoCodes);
    });

    return () => unsubscribe();
  };

  useEffect(() => {
    // Update the document title using the browser API
    getPromoCodes();
  }, []);

  const handleClose = (event, reason) => {
    setCreatingPromoCode(false);
  };

  const columns = [
    {
      field: "id",
      headerName: "Id",
      flex: 1,
      renderCell: ({ row: { id } }) => {
        return (
          <Typography
            variant="paragraphMonoSmall"
            color={"blue.main"}
            sx={{ ml: "5px" }}
          >
            {id}
          </Typography>
        );
      },
    },
    {
      field: "codeDetails",
      headerName: "Code Details",
      flex: 3,
      renderCell: ({ row: { codeDetails, type } }) => {
        if (type === "percent") {
          return (
            <Typography
              variant="paragraphMonoSmall"
              color={"blue.main"}
              sx={{ ml: "5px" }}
            >
              {`Percent Off: ${codeDetails.percentOff} Max: ${codeDetails.maximumAmount} `}
            </Typography>
          );
        } else {
          return (
            <Typography
              variant="paragraphMonoSmall"
              color={"blue.main"}
              sx={{ ml: "5px" }}
            >
              {`Value: ${codeDetails.promoCodeAmount}`}
            </Typography>
          );
        }
      },
    },
    {
      field: "timesUsed",
      headerName: "Times Used",
      flex: 1,
      renderCell: ({ row: { timesUsed } }) => {
        return (
          <Typography
            variant="paragraphMonoSmall"
            color={"blue.main"}
            sx={{ ml: "5px" }}
          >
            {timesUsed}
          </Typography>
        );
      },
    },
    {
      field: "limitUses",
      headerName: "Use # Limit",
      flex: 1,
      renderCell: ({ row: { limitUses } }) => {
        return (
          <Typography
            variant="paragraphMonoSmall"
            color={"blue.main"}
            sx={{ ml: "5px" }}
          >
            {limitUses}
          </Typography>
        );
      },
    },
    {
      field: "expires",
      headerName: "Expires",
      flex: 1,
      renderCell: ({ row: { expires } }) => {
        return (
          expires &&
          <Typography
            variant="paragraphMonoSmall"
            color={"blue.main"}
            sx={{ ml: "5px" }}
          >
            {new Date(expires.toDate()).toDateString()}
          </Typography>
        );
      },
    },
    {
     
      headerName: "Delete",
      flex: 1,
      renderCell: ({ row: { id } }) => {
        return (
          <Button>
            <Stack
              direction="row"
              width="80px"
              m="0 auto"
              p="5px"
              display="flex"
              justifyContent="center"
              backgroundColor="cream.main"
              onClick={() => deletePromoCode (id)}
            >
              <Typography variant="paragraphMonoSmall" color={"blue.main"}>
                {"Delete"}
              </Typography>
            </Stack>
          </Button>
        );
      },
    },
  ];

  const deletePromoCode = async (id) => {

    await deleteDoc(doc(db, "promoCodes", id));
  }

  return (
    <>
      <Dialog onClose={handleClose} open={creatingPromoCode}>
        <NewPromoCode onClose={handleClose} />
      </Dialog>
      <Box width="100%" minHeight="766px" height="auto">
        <Stack
          width="100%"
          minHeight="766px"
          direction={isDesktop ? "row" : undefined}
        >
          <Box width={isDesktop ? "345px" : "100%"}>
            <SideBar type={props.type} />
          </Box>
          {userType === "admin" && (
            <Box width="100%" height="auto" sx={{ mx: "44px" }}>
              <Box
                width="66%"
                sx={{ borderBottom: 1, color: "lightOlive.main" }}
              >
                <Typography
                  sx={{
                    whiteSpace: "pre-line",
                    color: "olive.main",
                    mr: "5px",
                    mt: "66px",
                    mb: "8px",
                  }}
                  variant="h3"
                >
                  {"Promo Codes"}
                </Typography>
              </Box>
              <Grid container sx={{ py: "24px" }}>
                <Grid item xs={12} md={3}>
                  <ActionButton
                    onClick={() => setCreatingPromoCode(true)}
                    backgroundColor="pink"
                    title="New Promo Code"
                    width="100%"
                  />
                </Grid>
              </Grid>
              <Box
                m="40px 40px 0 0"
                height={
                  promoCodes?.length
                    ? `${(promoCodes.length + 3) * 58}px`
                    : "800px"
                }
                sx={{
                  "& .MuiDataGrid-root": {
                    border: "none",
                  },
                  "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                  },
                  "& .name-column--cell": {},
                  "& .MuiDataGrid-columnHeaders": {
                    borderBottom: "none",
                  },
                  "& .MuiDataGrid-virtualScroller": {},
                  "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                    backgroundColor: "pink.main",
                    fontFamily: "Code Saver",
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontSize: "12px",
                    lineHeight: "145%",
                    mb: "40px",
                  },
                  "& .MuiTablePagination-selectLabel": {
                    fontFamily: "Code Saver",
                  },
                  "& .MuiSelect-select": {
                    fontFamily: "Code Saver",
                  },
                  "& .MuiTablePagination-displayedRows": {
                    fontFamily: "Code Saver",
                  },
                }}
              >
                <DataGrid
                  initialState={{
                    sorting: {
                      sortModel: [{ field: "lastName", sort: "asc" }],
                    },
                  }}
                  sx={{
                    fontFamily: "Code Saver",
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontSize: "12px",
                    lineHeight: "145%",
                    mt: "44px",
                    color: "blue.main",
                  }}
                  rows={promoCodes}
                  columns={columns}
                />
              </Box>
            </Box>
          )}
        </Stack>
        <Footer />
      </Box>
    </>
  );
};

export default PromoCodes;
