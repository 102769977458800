import { Box, useTheme } from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React from "react";
import { blueBox } from "../../../theme";
import Step1 from "../../../Assets/Images/step1.svg";
import Step2 from "../../../Assets/Images/step2.svg";
import Step3 from "../../../Assets/Images/step3.svg";
import Step4 from "../../../Assets/Images/step4.svg";
import DownArrow from "../../../Assets/Images/arrow-down.svg";

const HowItWorksCard = (props) => {
  const step = props.step;

  return (
    <React.Fragment>
      <Box
        width="492px"  
        height="374px" 
        sx={{
          border: 1,
          borderRight: step === 4 ? "1" : "0",
          borderColor: "blue.main",
          backgroundColor: props.activePage === step ? props.backgroundColor : "",

        }}
      >
        <Stack direction="row">
          <Stack
            height="187px"
            width="68px"
            spacing={2}
            sx={{
              ...blueBox,
              borderTop: 0,
              borderLeft: 0,
            }}
          >
            { step == 1 && <img src={Step1} /> }
            { step == 2 && <img src={Step2} /> }
            { step == 3 && <img src={Step3} /> }
            { step == 4 && <img src={Step4} /> }
            <img src={DownArrow} />
          </Stack>
          <Box
            height="187px"
            width="100%"
            sx={{
              borderBottom: 1,
              borderColor: "blue.main",
            }}
          >
            <Typography
              sx={{
                whiteSpace: "pre-line",
                textColor: "blue.main",
                ml: "26px",
                mt: "16px",
                
              }}
              display="block"
              variant="h1"
              color="blue.main"
            >
              {props.title}
            </Typography>
          </Box>
        </Stack>
        <Stack direction="row">
          <Box
            height="187px"
            width="100%"
            sx={{
              borderBottom: 0,
              borderColor: "blue.main",
            }}
          >
            <Typography
              sx={{
                whiteSpace: "pre-line",
                textColor: "blue.main",
                ml: "31px",
                mt: "27px",
              }}
              display="block"
              variant="paragraphSansLarge"
              color="blue.main"
            >
              {props.text}
            </Typography>
          </Box>
          <Box
            height="186px"
            width="68px"
            spacing={2}
            
            sx={{
              color:"blue.main",
              borderTop: 0,
              borderLeft: 1,
              background: "linear-gradient(to top right,transparent calc(50% - 1px),#2d457c,transparent calc(50% + 1px))",
            }}
          >
          </Box>
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default HowItWorksCard;
