import { Box, useTheme } from '@mui/material';
import * as React from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Quote from '../../Assets/Images/quote.svg';
import BlueArrow from '../../Assets/Images/arrow-blue.svg';
import OliveArrowLeft from '../../Assets/Images/arrow-olive-left.svg';
import OliveArrow from '../../Assets/Images/arrow-olive.svg';
import { ButtonUnstyled } from '@mui/base';
import { blueBox } from '../../theme';
import { useMediaQuery } from 'react-responsive';
import { collection, query, getDocs, getFirestore } from 'firebase/firestore';
import { useEffect, useState, useRef } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';

const QuoteBlock = (props) => {
  const isDesktop = useMediaQuery({ minWidth: 1201 });
  const isSmall = useMediaQuery({ maxWidth: 601 });

  const placeholder =
    'Proin auctor nisi quis dui imperdiet rutrum. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Cras feugiat tortor risus, eget faucibus mauris posuere eget.';
  return (
    <Stack>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mt: '123px',
          mb: '40px',
        }}
      >
        <img src={Quote} />
      </Box>

      <Typography
        sx={{
          whiteSpace: 'pre-line',
          mb: '31px',
          mx: isSmall ? '20px' : '115px',
          textAlign: 'center',
        }}
        display="block"
        variant={isDesktop ? 'paragraphMonoLarge' : 'paragraphMono'}
        color="blue.main"
      >
        {props.text}
      </Typography>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          ml: '-20px',
          mb: '148px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <img src={BlueArrow} />
        <Typography
          sx={{
            whiteSpace: 'pre-line',
            textAlign: 'center',
          }}
          display="block"
          variant="h3"
          color="olive.main"
        >
          {props.name}
        </Typography>
      </Stack>
    </Stack>
  );
};
const Testimonials = (props) => {
  const db = getFirestore();
  const [testimonials, setTestimonials] = useState([]);

  const getTestimonials = async () => {
    const testimonialsRef = collection(db, 'testimonials');
    const q = query(testimonialsRef);
    const querySnapshot = await getDocs(q);

    const allTestimonials = [];
    const proTestimonials = [];
    const localTestimonials = [];

    querySnapshot.forEach((doc) => {
      if (doc.data().proId && doc.data().proId === props.proId) {
        proTestimonials.push(doc.data());
      } else if (props.isLocalServices && doc.data().isLocalServices) {
        localTestimonials.push(doc.data());
      } else {
        allTestimonials.push(doc.data());
      }
    });

    if (props.isLocalServices) {
      setTestimonials(localTestimonials);
    } else {
      if (proTestimonials.length) {
        setTestimonials(proTestimonials);
      } else {
        setTestimonials(allTestimonials);
      }
    }
  };

  const sliderRef = useRef();
  var settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    getTestimonials();
  }, [props.proId, props.isLocalServices]);

  return (
    <Box
      width="100%"
      height="auto"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'cream.main',
      }}
    >
      <Box
        maxWidth="1220px"
        width="100%"
        height="100%"
        sx={{
          border: 1,
          borderTop: 0,
          borderColor: 'lightOlive.main',
        }}
      >
        <Stack
          width="100%"
          height="auto"
          direction="row"
          sx={{
            backgroundColor: 'white',
          }}
        >
          <Box
            width="40px"
            height="33px"
            onClick={(event) => {
              sliderRef.current.slickPrev();
            }}
            sx={{
              ...blueBox,
              cursor: 'pointer',
              borderColor: 'lightOlive.main',
              borderLeft: 0,
              '&:hover': {
                backgroundColor: 'pink.main',
              },
            }}
          >
            <img src={OliveArrowLeft} />
          </Box>

          <Box
            height="33px"
            sx={{
              flexGrow: '100',
              ...blueBox,
              borderColor: 'lightOlive.main',
              borderLeft: 0,
            }}
          >
            <Typography
              sx={{
                whiteSpace: 'pre-line',
                textAlign: 'center',
              }}
              display="block"
              variant="paragraphMono"
              color="olive.main"
            >
              Testimonials
            </Typography>
          </Box>
          <Box
            width="40px"
            height="33px"
            onClick={(event) => {
              sliderRef.current.slickNext();
            }}
            sx={{
              ...blueBox,
              cursor: 'pointer',
              borderColor: 'lightOlive.main',
              borderLeft: 0,
              borderRight: 0,
              '&:hover': {
                backgroundColor: 'pink.main',
              },
            }}
          >
            <img src={OliveArrow} />
          </Box>
        </Stack>
        <Slider ref={sliderRef} {...settings}>
          {testimonials.map((testimonial, index) => {
            return (
              <QuoteBlock
                key={index}
                name={testimonial.displayName}
                text={testimonial.text}
              />
            );
          })}
        </Slider>
      </Box>
    </Box>
  );
};

export default Testimonials;
