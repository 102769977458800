import { useState, useContext, useEffect } from 'react';
import { UserContext } from '../../../user-context';
import { Navigate } from 'react-router-dom';
import { Box, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Input from '@mui/material/Input';

import { Stack } from '@mui/system';

import { useMediaQuery } from 'react-responsive';
import MailingList from '../../../SharedComponents/MailingList';
import Footer from '../../../SharedComponents/Footer';

import { ActionButton } from '../../../SharedComponents/Buttons';
import {
  setDoc,
  doc,
  getFirestore,
  addDoc,
  collection,
} from 'firebase/firestore';
import {
  getAuth,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithRedirect,
  signInWithPopup,
} from 'firebase/auth';
import { Formik, Field } from 'formik';
import Checkbox from '@mui/material/Checkbox';

const commonStyles = {
  pl: '4px',
  pt: '4px',
  mb: '12px',
  fontFamily: 'Code Saver',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '16px',
  lineHeight: '145%',
  color: 'blue.main',

  border: 1,
  borderColor: 'lightOlive.main',
};

const SignUp = () => {
  const isDesktop = useMediaQuery({ minWidth: 1201 });
  const isMedium = useMediaQuery({ minWidth: 901 });
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [agreedToTos, setAgreedToTos] = useState(false);
  const [isPro, setIsPro] = useState(false);
  const provider = new GoogleAuthProvider();
  const [loggedIn, setLoggedIn] = useState(false);
  const auth = getAuth();

  const db = getFirestore();

  const sendConfirmationEmail = async (email) => {
    await addDoc(collection(db, 'mail'), {
      to: [email],
      message: {
        subject: 'Welcome to Matriarchy Build',
        html: `  <div class="entry" style="text-color:grey";>
       
        <div class="body">
        <tbody><tr>
        <td id="m_2221956471352103756m_5517837448314080125header_wrapper" style="background-color:#F7CBAD;padding:36px 48px;display:block;text-align:center">
          <a href="http:///" style="color:#2d457c;font-weight:normal;text-decoration:underline" target="_blank" data-saferedirecturl="https://matriarchybuild.com"><img src="https://firebasestorage.googleapis.com/v0/b/mb-platform.appspot.com/o/misc%2Femail-header.png?alt=media&token=a345823f-7569-483f-85e7-f5cde48d7211" style="border:none;display:inline-block;font-size:14px;font-weight:bold;height:auto;outline:none;text-decoration:none;text-transform:capitalize;vertical-align:middle;margin-right:10px;max-width:100%;margin:0" class="CToWUd" data-bit="iit"></a>
        </td>
      </tr>
    </tbody>
    <h2 style="margin-bottom:20px;">Hi, ${firstName}</h2>
          <p style="margin-bottom:20px;">
          Thanks for creating an account on Matriarchy Build. We’re built by women, built for everyone. Get ready to Build the Matriarchy one renovation project at a time!
          </p>
          <p>
          You can access your account to view orders, change your password, and more at: 
            <a href=https://matriarchybuild.com/my-account/
            >https://matriarchybuild.com/my-account/</a> 
          </p>
          <p>
          We look forward to helping you tackle your next project.

          </p>
          <p>
          Lacey + Gabriella + the Pros of MBuild

          </p>
        </div>
      </div>`,
      },
    });
  };
  const signUpWithEmail = () => {
    if (
      firstName === '' ||
      lastName === '' ||
      agreedToTos === false ||
      email === '' ||
      password === ''
    ) {
      return;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      return;
    }
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in

        const user = userCredential.user;

        setDoc(doc(db, 'customers', userCredential.user.uid), {
          firstName: firstName,
          lastName: lastName,
          email: email,
          signUpDate: new Date(),
        }).then((userDoc) => {
          sendConfirmationEmail(email);
        });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;

        // ..
      });
  };

  const signInWithGoogle = () => {
    if (firstName === '' || lastName === '' || agreedToTos === false) {
      return;
    }

    const auth = getAuth();
    auth.useDeviceLanguage();

    signInWithPopup(auth, provider)
      .then((result) => {
        // The signed-in user info.
        const user = result.user;
        setDoc(doc(db, 'customers', result.user.uid), {
          firstName: firstName,
          lastName: lastName,
          email: result.user.email,
          signUpDate: new Date(),
        }).then((userDoc) => {
          sendConfirmationEmail(result.user.email);
          //navigate(`/my-account/pros/${userCredential.user.uid}`);
        });
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  };

  const user = useContext(UserContext).user;

  const setUserAttributes = async (user) => {
    if (user) {
      setLoggedIn(true);
    }
  };

  useEffect(() => {
    if (user) {
      setUserAttributes(user);
    }
  }, [user]);

  return (
    <>
      {loggedIn && <Navigate to="/my-account" />}
      <Box>
        <Box
          width="100%"
          height="auto"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: 'cream.main',
            py: '136px',
          }}
        >
          <Grid
            container
            width="100%"
            maxWidth="1220px"
            mx={isPortrait ? '20px' : '44px'}
          >
            <Grid
              item
              md={6}
              xs={12}
              sx={{
                borderRight: isMedium ? 1 : 0,

                color: 'blue.main',
                pr: isMedium ? '30px' : undefined,
              }}
            >
              <Typography
                sx={{
                  whiteSpace: 'pre-line',
                  color: 'olive.main',
                  mb: '20px',
                }}
                display="block"
                variant="h2"
              >
                {'Create Your Account'}
              </Typography>

              <Stack width="100%">
                <Formik
                  initialValues={{
                    firstName: '',
                    lastName: '',
                    email: '',
                    password: '',
                    agreedToTOS: false,
                  }}
                  validate={() => {
                    const errors = {};
                    if (!firstName) {
                      errors.firstName = 'Required';
                    }
                    if (!lastName) {
                      errors.lastName = 'Required';
                    }
                    if (!email) {
                      errors.email = 'Required';
                    } else if (
                      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)
                    ) {
                      errors.email = 'Invalid email address';
                    }
                    if (!password) {
                      errors.passWord = 'Required';
                    }
                    if (!agreedToTos) {
                      errors.agreedToTos = 'Required';
                    }

                    return errors;
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Input
                        type="text"
                        name="firstName"
                        onChange={(e) => setFirstName(e.target.value)}
                        onBlur={handleBlur}
                        value={firstName}
                        disableUnderline
                        fullWidth
                        height="40px"
                        placeholder="First Name"
                        sx={{
                          ...commonStyles,
                        }}
                      ></Input>
                      {errors.firstName &&
                        touched.firstName &&
                        errors.firstName}
                      <Input
                        type="text"
                        name="lastName"
                        onChange={(e) => setLastName(e.target.value)}
                        onBlur={handleBlur}
                        value={lastName}
                        disableUnderline
                        fullWidth
                        height="40px"
                        placeholder="Last Name"
                        sx={{
                          ...commonStyles,
                        }}
                      ></Input>
                      {errors.lastName && touched.lastName && errors.lastName}
                      <Stack direction="row" mb="20px">
                        <Checkbox
                          type="checkbox"
                          value={agreedToTos}
                          onClick={(e) => {
                            setAgreedToTos(e.target.checked);
                          }}
                          name="agreedToTOS"
                        />
                        {errors.agreedToTOS && errors.agreedTOS}
                        <Typography
                          sx={{
                            whiteSpace: 'pre-line',
                            color: 'blue.main',
                            pl: '5px',
                            pt: '8px',
                          }}
                          display="block"
                          variant="paragraphMono"
                        >
                          {"I agree to Matriarchy Build's"}
                          <a href="/terms-of-use">
                            {' '}
                            <Typography
                              sx={{
                                whiteSpace: 'pre-line',
                                color: 'blue.main',
                              }}
                              display="inline"
                              variant="paragraphMono"
                            >
                              {'Terms of Use'}
                            </Typography>
                          </a>
                        </Typography>
                      </Stack>
                      <ActionButton
                        title="Sign Up With Google"
                        onClick={signInWithGoogle}
                      />
                      <Typography
                        sx={{
                          whiteSpace: 'pre-line',
                          color: 'blue.main',
                          mb: '20px',
                          mt: '22px',
                        }}
                        display="block"
                        variant="paragraphMono"
                      >
                        {'or with email'}
                      </Typography>
                      <Input
                        type="email"
                        name="email"
                        onChange={(e) => setEmail(e.target.value)}
                        onBlur={handleBlur}
                        value={email}
                        disableUnderline
                        fullWidth
                        height="40px"
                        placeholder="Email"
                        sx={{
                          ...commonStyles,
                        }}
                      ></Input>
                      {errors.email && touched.email && errors.email}
                      <Input
                        type="password"
                        name="password"
                        onChange={(e) => setPassword(e.target.value)}
                        onBlur={handleBlur}
                        value={password}
                        disableUnderline
                        fullWidth
                        height="40px"
                        placeholder="Password"
                        sx={{
                          ...commonStyles,
                        }}
                      ></Input>
                      {errors.password && touched.password && errors.password}

                      <ActionButton
                        title="Create Account"
                        onClick={signUpWithEmail}
                      />
                    </form>
                  )}
                </Formik>
              </Stack>
            </Grid>
            <Grid
              md={6}
              sx={{
                pl: isMedium ? '30px' : undefined,
                mt: !isMedium ? '40px' : undefined,
              }}
            >
              <Typography
                sx={{
                  whiteSpace: 'pre-line',
                  color: 'olive.main',
                  mb: '20px',
                }}
                display="block"
                variant="h2"
              >
                {'Are You a Pro?'}
              </Typography>
              <Typography
                sx={{
                  whiteSpace: 'pre-line',
                  color: 'olive.main',
                  mb: '20px',
                }}
                display="block"
                variant="paragraphMono"
              >
                {
                  'If you’d like to join our roster of Matriarchy Build Pros please click here to fill out our simple questionnaire. The Matriarchy Build roster features Pros that are available for 1:1 video consultations with folks tackling DIY and home renovation projects.'
                }
              </Typography>
              <ActionButton
                onClick={() => setIsPro(true)}
                title="Apply to Be a Pro"
                linkTo="/pro-apply"
              />
            </Grid>
          </Grid>
        </Box>
        {isPro && <Navigate to="/apply" />}
        <MailingList />
        <Footer />
      </Box>
    </>
  );
};

export default SignUp;
