import { Box, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2/";
import PaintRoller from "../../Assets/Images/support-image.jpeg";
import { useMediaQuery } from "react-responsive";
import MailingList from "../../SharedComponents/MailingList";
import Footer from "../../SharedComponents/Footer";
import { Link } from "react-router-dom";
const SupportPage = () => {
  const isDesktop = useMediaQuery({ minWidth: 1201 });
  const isPortrait = useMediaQuery({ orientation: 'portrait' })

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "top",
          backgroundImage: `url(${PaintRoller})`,
          backgroundColor: "cream.main",
        }}
      >
        <Grid
          container
          width="100%"
          maxWidth="1440px"
          mx={isPortrait ? "20px" : "44px"}
          mt="115px"
          mb="122px"
        >
          {isDesktop && (
            <Grid
              lg={1}
              width="100%"
              height="191px"
              sx={{
                border: 1,
                borderRight: 0,
                color: "pink.main",
                background:
                  "linear-gradient(to top left,transparent 0,transparent calc(50% - 0.8px),#f7cbad 50%,transparent calc(50% + 0.8px),transparent),linear-gradient(to top right,transparent 0,transparent calc(50% - 0.8px),#f7cbad 50%,transparent calc(50% + 0.8px),transparent)",
              }}
            ></Grid>
          )}
          <Grid
            item
            xs={12}
            lg={3}
            width="100%"
            height="191px"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "white",
              border: 1,
           
              color: "pink.main",
            }}
          >
            <Typography
              sx={{
                whiteSpace: "pre-line",
                textAlign: "center",
                my: "63px",
                pt:"10px",
                color: "olive.main",
              }}
              display="block"
              variant="h1"
            >
              {"Support"}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            lg={8}
            width="100%"
            height="auto"
            sx={{
              position:"relative",
              zIndex:1,
              ml:"-1px",
              backgroundColor: "white",
              border: 1,
              mt: "85px",
              color: "pink.main",
              px: isPortrait ? "20px" : "85px",
              py:"60px",
            }}
          >
            <Typography
              sx={{
                whiteSpace: "pre-line",
                mt: "60px",
              }}
              display="inline"
              variant="paragraphSans"
              color="blue.main"
            >
              {
                "Not sure which Pro to work with? You can always email us at "
                
              }
            </Typography>
            <a href="mailto:support@matriarchybuild.com">
                <Typography
                  sx={{
                    whiteSpace: "pre-line",
                    textColor: "pink.main",
                    "&:hover": {
                      color: "lightOlive.main",
                    },
                  }}
                  display="inline"
                  variant="paragraphSans"
                  color="pink.main"
                >
                  {"support@matriarchybuild.com "}
                </Typography>
              </a>
              <Typography
              sx={{
                whiteSpace: "pre-line",
                mt: "60px",
              }}
              display="inline"
              variant="paragraphSans"
              color="blue.main"
            >
              {
                "with a description of your project and we’ll point you in the right direction."
              }
            </Typography>
            <Box sx={{ mt: "20px" }}>
              <Typography
                sx={{
                  whiteSpace: "pre-line",
                }}
                display="inline"
                variant="paragraphSans"
                color="blue.main"
              >
                {"Our FAQs about consultations can be found "}
              </Typography>
              <Link to="/faq">
                <Typography
                  sx={{
                    whiteSpace: "pre-line",
                    textColor: "pink.main",
                    "&:hover": {
                      color: "lightOlive.main",
                    },
                  }}
                  display="inline"
                  variant="paragraphSans"
                  color="pink.main"
                >
                  {"here"}
                </Typography>
              </Link>
            </Box>

            <Box display="block" sx={{ mt: "20px" }}>
              <Typography
                sx={{
                  whiteSpace: "pre-line",
                  mt: "20px",
                }}
                display="inline"
                variant="paragraphSans"
                color="blue.main"
              >
                {"If you have questions about our site, email us at "}
              </Typography>
              <a href="mailto:support@matriarchybuild.com">
                <Typography
                  sx={{
                    whiteSpace: "pre-line",
                    textColor: "pink.main",
                    "&:hover": {
                      color: "lightOlive.main",
                    },
                  }}
                  display="inline"
                  variant="paragraphSans"
                  color="pink.main"
                >
                  {"support@matriarchybuild.com"}
                </Typography>
              </a>
            </Box>

            <Typography
              sx={{
                whiteSpace: "pre-line",

                mt: "20px",
                
              }}
              display="block"
              variant="paragraphSans"
              color="blue.main"
            >
              {"You can apply to be a Pro "}
              <Link to="/apply">
                <Typography
                  sx={{
                    whiteSpace: "pre-line",
                    textColor: "pink.main", 
                    "&:hover": {
                      color: "lightOlive.main",
                    },
                  }}
                  display="inline"
                  variant="paragraphSans"
                  color="pink.main"
                >
                  {" here."}
                </Typography>
              </Link>
            </Typography>
           
          </Grid>
        </Grid>
      </Box>
      <MailingList />
      <Footer />
    </Box>
  );
};

export default SupportPage;
