import { Box, useTheme } from '@mui/material';
import { colors, pinkBox } from '../../theme';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import ButtonUnstyled from '@mui/base/ButtonUnstyled';
import IconButton from '@mui/material/IconButton';
import React, { useState, useContext, useRef } from 'react';
import Input from '@mui/material/Input';
import Stack from '@mui/material/Stack';
import Logo from '../../Assets/Images/logo.svg';
import Search from '../../Assets/Images/search.svg';
import MenuOpen from '../../Assets/Images/menu-open.svg';
import MenuClose from '../../Assets/Images/menu-close.svg';
import { Link } from 'react-router-dom';
import ArrowPink from '../../Assets/Images/arrow-pink.svg';
import { motion } from 'framer-motion';
import { UserContext } from '../../user-context';
import styled from '@emotion/styled';
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';
import Button from '@mui/material/Button';
import {
  InstantSearch,
  Hits,
  SearchBox,
  Pagination,
  Highlight,
  ClearRefinements,
  RefinementList,
  Configure,
  connectStateResults,
} from 'react-instantsearch-dom';
export const StyleWrapper = styled.div`
  .ais-SearchBox {
    width: 100%;
  }
  .ais-SearchBox-resetIcon {
    display: none;
  }
  .ais-SearchBox-submit {
    display: none;
  }
  .ais-SearchBox-reset {
    display: none;
  }
  .ais-SearchBox-input {

    padding-left: 4px;
    padding-top: 2px;
    font-color white;
    height: 30px;
    width: 100%;
    border:1px;
    color: white;
    font-family: "Code Saver";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17.5px;
    background-color: #2D457C;
    outline-width: 0;
    outline: none;

    
  }
  .ais-Hits-list {
    list-style-type:none;
    margin: 0;
    padding:0;
  }
}
`;
const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    nodes: [
      {
        host: 'u36otxk1qf4rmvybp-1.a1.typesense.net',
        port: '443',
        protocol: 'https',
      },
    ],
    apiKey: 'UpQOXUteZrmhmnNZSlWjr5nEDIelZchq',
  },
  additionalSearchParameters: {
    query_by: 'firstName,lastName,bio,email',
    filter_by: `verified:${true}`,
  },
});

const commonStyles = {
  border: 1,
  color: 'pink.main',
  borderColor: 'pink.main',
  display: 'flex',
  pt: '6px',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
};

const StyledButton = styled(ButtonUnstyled)(
  () => `

  transition: all 150ms ease;
  cursor: pointer;

  height: 32px;
  width: 32px;
  background: none;
  padding: 0;
  margin: 0;
  `
);
const HeaderMobile = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const user = useContext(UserContext);
  const [searchState, setSearchState] = useState({});
  let navigate = useNavigate();

  const [prevQuery, setPrevQuery] = useState();

  const onSearchStateChange = (searchState) => {
    if (searchState.query != prevQuery) {
      setSearchState({ ...searchState });
      setPrevQuery(searchState.query);
    }
  };

  const navigateToPro = (id) => {
    setSearchState({
      ...searchState,
      query: '',
    });
    navigate(`/pro/${id}`);
  };

  const Results = connectStateResults(
    ({ searchState }) =>
      searchState &&
      searchState.query && (
        <StyleWrapper>
          <Box
            sx={{
              position: 'relative',
              zIndex: 5,
              backgroundColor: 'white',
              boxShadow: 4,
            }}
          >
            <Box
              sx={{
                mx: '20px',
                borderBottom: 1,
                color: 'lightOlive.main',
              }}
            >
              <Typography
                pt="10px"
                pb="5px"
                sx={{
                  whiteSpace: 'pre-line',
                }}
                display="block"
                color="olive.main"
                variant="paragraphMonoSmall"
              >
                {'Pros'}
              </Typography>
            </Box>

            <Configure hitsPerPage={4} clickAnalytics={true} />
            <Hits hitComponent={Hit} />
          </Box>
        </StyleWrapper>
      )
  );

  function Hit(props) {
    var tradeList = '';

    props.hit.proTrades?.forEach((trade) => {
      if (tradeList === '') {
        tradeList = trade.displayName;
      } else {
        tradeList = tradeList.concat(', ', trade.displayName);
      }
    });

    return (
      <Box
        onClick={() => navigateToPro(props.hit.slug)}
        height="72px"
        id="header"
        sx={{
          borderBottom: 1,
          color: 'lightOlive.main',
          backgroundColor: 'white',
          mx: '20px',
          pt: '8px',
          position: 'relative',

          zIndex: 7,
          '&:hover': {
            backgroundcolor: 'pink.main',
          },
        }}
      >
        <img
          width="55px"
          height="55px"
          src={props.hit.headshot}
          align="left"
          alt={props.hit.firstName}
          style={{ marginRight: '10px', objectFit: 'cover' }}
        />
        <Stack direction="row">
          <Typography
            sx={{
              whiteSpace: 'pre-line',
            }}
            display="block"
            color="blue.main"
            variant="h3"
            fontSize="24px"
            width="auto"
            cursor="pointer"
          >
            {`${props.hit.firstName} ${props.hit.lastName}`}
          </Typography>
          <Box
            width="100%"
            sx={{ mt: '10px', display: 'flex', justifyContent: 'right' }}
          >
            <Typography
              display="block"
              color="olive.main"
              variant="paragraphMono"
            >
              {tradeList}
            </Typography>
          </Box>
        </Stack>
      </Box>
    );
  }

  return (
    <Box id="header">
      <Box width="100%" sx={{ backgroundColor: 'blue.main', px: '20px' }}>
        <Stack
          direction="row"
          sx={{
            display: 'flex',
            flexGrow: '100',
            pt: '11px',
            pb: '13px',
          }}
        >
          <Stack
            direction="row"
            width="100%"
            height="34px"
            sx={{
              mt: '23px',
              mb: '12px',
            }}
          >
            <Button
              width="24px"
              onClick={() => {
                setMenuOpen(!menuOpen);
              }}
              sx={{ borderRadius: 0, ...pinkBox }}
            >
              {menuOpen ? <img src={MenuClose} /> : <img src={MenuOpen} />}
            </Button>
            <InstantSearch
              indexName="pros"
              searchClient={typesenseInstantsearchAdapter.searchClient}
              searchState={searchState}
              onSearchStateChange={(state) => onSearchStateChange(state)}
            >
              <Box width="100%">
                <Box
                  width="100%"
                  sx={{
                    pl: '4px',
                    pt: '2px',
                    ml: '10px',
                    color: 'white',
                    fontFamily: 'Code Saver',
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '14px',
                    lineHeight: '17.5px',
                    backgroundColor: 'blue.main',
                    border: 1,
                    borderRight: 0,
                    borderColor: 'pink.main',
                  }}
                >
                  <StyleWrapper>
                    <SearchBox />
                  </StyleWrapper>
                </Box>
                <Results />
              </Box>
            </InstantSearch>

            <Box
              width="34px"
              height="34px"
              sx={{
                ...pinkBox,
                borderLeft: 0,
              }}
            >
              <img src={Search} />
            </Box>
          </Stack>
        </Stack>
      </Box>
      <motion.div
        style={{ overflow: 'hidden' }}
        initial={{ height: 0 }}
        animate={{ height: menuOpen ? 'auto' : 0, visibile: menuOpen ? 1 : 0 }}
      >
        <Stack
          width="100%"
          height="auto"
          alignItems="stretch"
          sx={{
            backgroundColor: 'olive.main',
            pl: '20px',
            pt: '12px',
            color: 'white',
          }}
        >
          <Link onClick={() => setMenuOpen(false)} to="/pro">
            <Typography
              sx={{
                whiteSpace: 'pre-line',
                textColor: 'pink.main',
                mt: '9px',
                '&:hover': {
                  color: 'white',
                },
              }}
              display="block"
              color="pink.main"
              variant="h2"
            >
              {'Online Consultations'}
            </Typography>
          </Link>
          <Link onClick={() => setMenuOpen(false)} to="/find-a-builder">
            <Typography
              sx={{
                whiteSpace: 'pre-line',
                textColor: 'pink.main',
                mt: '9px',
                '&:hover': {
                  color: 'white',
                },
              }}
              display="block"
              color="pink.main"
              variant="h2"
            >
              {'Find a Builder'}
            </Typography>
          </Link>
          <Link onClick={() => setMenuOpen(false)} to="/austin">
            <Typography
              sx={{
                whiteSpace: 'pre-line',
                textColor: 'pink.main',
                mt: '9px',
                '&:hover': {
                  color: 'white',
                },
              }}
              display="block"
              color="pink.main"
              variant="h2"
            >
              {'Local Services'}
            </Typography>
          </Link>
          <Link onClick={() => setMenuOpen(false)} to="/diy-inspiration">
            <Typography
              sx={{
                whiteSpace: 'pre-line',
                textColor: 'pink.main',
                mt: '9px',
                '&:hover': {
                  color: 'white',
                },
              }}
              display="block"
              color="pink.main"
              variant="h2"
            >
              {'DIY & Inspiration'}
            </Typography>
          </Link>

          <Box sx={{ mt: '8px', borderBottom: 1, color: 'white' }} />
        </Stack>
        <Stack
          width="100%"
          height="auto"
          alignItems="stretch"
          sx={{
            backgroundColor: 'olive.main',
            pl: '20px',
            py: '8px',
          }}
        >
          {user.loggedIn ? (
            <Link onClick={() => setMenuOpen(false)} to="/my-account">
              <Stack
                direction="row"
                sx={{
                  pt: '4px',
                  textAlign: 'center',
                }}
              >
                <Typography
                  sx={{
                    whiteSpace: 'pre-line',
                    mr: '7px',
                  }}
                  display="block"
                  variant="paragraphMono"
                  color="white"
                >
                  {'My Account'}
                </Typography>
                <img src={ArrowPink} />
              </Stack>
            </Link>
          ) : (
            <Box>
              <Link onClick={() => setMenuOpen(false)} to="/sign-in">
                <Stack
                  direction="row"
                  sx={{
                    pt: '4px',
                    textAlign: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      whiteSpace: 'pre-line',
                      mr: '7px',
                    }}
                    display="block"
                    variant="paragraphMono"
                    color="white"
                  >
                    {'Sign In'}
                  </Typography>
                  <img src={ArrowPink} />
                </Stack>
              </Link>
              <Link onClick={() => setMenuOpen(false)} to="/create-account">
                <Stack
                  direction="row"
                  sx={{
                    pt: '4px',
                    textAlign: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      whiteSpace: 'pre-line',
                      mr: '7px',
                    }}
                    display="block"
                    variant="paragraphMono"
                    color="white"
                  >
                    {'Sign Up'}
                  </Typography>
                  <img src={ArrowPink} />
                </Stack>
              </Link>
            </Box>
          )}
        </Stack>
      </motion.div>
      <Box
        width="100%"
        height="182px"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'pink.main',
          p: '44px',
        }}
      >
        <Link onClick={() => setMenuOpen(false)} to="/">
          <img src={Logo} width="100%" style={{ objectFit: 'cover' }} />
        </Link>
      </Box>
    </Box>
  );
};

export default HeaderMobile;
