import { Box, useTheme } from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ButtonUnstyled from "@mui/base/ButtonUnstyled";
import { blueBox } from "../../theme";
import { styled } from "@mui/system";
import { useState } from "react";
import React from "react";
import ArrowLeft from "../../Assets/Images/arrow-button-left.svg";
import ArrowRight from "../../Assets/Images/arrow-button-right.svg";
import HowItWorksCard from "./Components/HowItWorksCard";
import HowItWorksCardMobile from "./Components/HowItWorksCardMobile";
import { motion } from "framer-motion";
import { useMediaQuery } from "react-responsive";

const Button = styled(ButtonUnstyled)(
  ({ theme }) => `

  transition: all 150ms ease;
  cursor: pointer;
  border: none;
  height: 32px ;

  `
);

const HowItWorksCarousel = (props) => {
  const isDesktop = useMediaQuery({ minWidth: 1201 });
  const isPortrait = useMediaQuery({ orientation: 'portrait' })

  const [activePage, setActivePage] = useState(1);
  const offsetMult = isDesktop ? 492 : 283;

  let offsetX = -(activePage - 1) * offsetMult;

  const titleStep1 = "Search";
  const titleStep2 = "Book";
  const titleStep3 = "Talk";
  const titleStep4 = "Build";

  const textStep1 =
    "Search our directory of Pros based on the type of project you're looking to tackle.";
  const textStep2 =
    "Book a 1:1 consultation with a Pro. Choose a date on their calendar based on your timeline and budget.";
  const textStep3 =
    "Enjoy your 1:1 video consultation with a Pro to learn how to take on your project with confidence.";
  const textStep4 =
    "Start tackling your project with confidence. Book follow-up sessions as necessary.";

  return (
    <React.Fragment>
      <Box
        width="100%"
        height="auto"
        sx={{
          pt: "117px",
          pb: "148px",

          backgroundColor:
            props.theme === "olive" ? "lightOlive.main" : "pink.main",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Stack direction={isDesktop ? "row" : undefined} width="2500px">
          <Stack
            width="343px"
            sx={{ 
              pl: isPortrait ? "20px" : "44px",  
              backgroundColor: props.theme === "olive" ?  "lightOlive.main" : "pink.main",
            zIndex: 1 
          }}
          >
            <Stack direction="row" component="div">
              <Box
                width={isPortrait ? "121px" : "172px"}
                height="106px"
                color="blue.main"
                sx={{
                  border: 1,
                  borderBottom: isDesktop ? 1 : 0,
                  background:
                    "linear-gradient(to top right,transparent calc(50% - 1px),#2d457c,transparent calc(50% + 1px))",
                }}
              ></Box>
              <Box
                width={isPortrait ? "121px" : "172px"}
                height="106px"
                color="blue.main"
                sx={{
                  ...blueBox,
                  pt: "6px",
                  borderLeft: 0,
                  borderBottom: isDesktop ? 1 : 0,
                  borderRight: isDesktop ? 0 : 1,
                }}
              >
                <Typography
                  sx={{
                    whiteSpace: "pre-line",
                    textColor: "blue.main",
                    
                  }}
                  display="block"
                  variant="h2"
                  
                >
                  {"How it\n works"}
                </Typography>
              </Box>
              {!isDesktop && (
                <Stack spacing={0}>
                  <Button
                    onClick={() => {
                      if (activePage > 1) {
                        setActivePage(activePage - 1);
                      }
                    }}
                    width="39px"
                    height="32px"
                    sx={{
                      ...blueBox,
                      borderLeft: 0,
                      borderRadius: 0,
                      borderColor: "blue.main",
                      backgroundColor: props.theme === "olive" ?  "lightOlive.main" : "pink.main",
                      zIndex: 1,
                      "&:hover": {
                        backgroundColor: "white",
                      },
                    }}
                  >
                    <img src={ArrowRight} />
                  </Button>
                  <Button
                    onClick={() => {
                      if (activePage < 4) {
                        setActivePage(activePage + 1);
                      }
                    }}
                    width="39px"
                    height="32px"
                    sx={{
                      ...blueBox,
                      borderTop: 0,
                      borderLeft: 0,

                      borderColor: "blue.main",
                      backgroundColor: props.theme === "olive" ?  "lightOlive.main" : "pink.main",
                      zIndex: 1,
                      "&:hover": {
                        backgroundColor: "white",
                      },
                    }}
                  >
                    <img src={ArrowLeft} />
                  </Button>
                </Stack>
              )}
            </Stack>
            {isDesktop && (
              <Stack direction="row" spacing={0}>
                <Button
                  onClick={() => {
                    if (activePage > 1) {
                      setActivePage(activePage - 1);
                    }
                  }}
                  width="39px"
                  height="32px"
                  sx={{
                    ...blueBox,
                    borderTop: 0,
                    borderRadius: 0,
                    borderColor: "blue.main",
                    backgroundColor: props.theme === "olive" ?  "lightOlive.main" : "pink.main",
                    zIndex: 1,
                    "&:hover": {
                      backgroundColor: "white",
                    },
                  }}
                >
                  <img src={ArrowRight} />
                </Button>
                <Button
                  onClick={() => {
                    if (activePage < 4) {
                      setActivePage(activePage + 1);
                    }
                  }}
                  width="39px"
                  height="32px"
                  sx={{
                    ...blueBox,
                    borderTop: 0,
                    borderLeft: 0,
                    borderRight: isDesktop ? 1 : 0,
                    borderColor: "blue.main",
                    backgroundColor: props.theme === "olive" ?  "lightOlive.main" : "pink.main",
                    zIndex: 1,
                    "&:hover": {
                      backgroundColor: "white",
                    },
                  }}
                >
                  <img src={ArrowLeft} />
                </Button>
              </Stack>
            )}
          </Stack>
          {isDesktop ? (
            <motion.div
              style={{ display: "flex", overflow: "hidden" }}
              transition={{ ease: "easeOut", duration: 0.5 }}
              animate={{ x: offsetX }}
            >
              <HowItWorksCard
                backgroundColor={
                  props.theme === "olive" ? "cream.main" : "lightOlive.main"
                }
                step={1}
                title="Search"
                text={textStep1}
                activePage={activePage}
              />
              <HowItWorksCard
                backgroundColor={
                  props.theme === "olive" ? "cream.main" : "lightOlive.main"
                }
                step={2}
                title="Book"
                text={textStep2}
                activePage={activePage}
              />
              <HowItWorksCard
                backgroundColor={
                  props.theme === "olive" ? "cream.main" : "lightOlive.main"
                }
                step={3}
                title="Talk"
                text={textStep3}
                activePage={activePage}
              />
              <HowItWorksCard
                backgroundColor={
                  props.theme === "olive" ? "cream.main" : "lightOlive.main"
                }
                step={4}
                title="Build"
                text={textStep4}
                activePage={activePage}
              />
            </motion.div>
          ) : (
            <Box
              sx={{
               
                marginLeft: isPortrait ? "20px" : "44px", 
                overflow: "hidden",
              }}
            >
              <motion.div
                style={{ display: "flex", overflow: "hidden" }}
                transition={{ ease: "easeOut", duration: 0.5 }}
                animate={{ x: offsetX }}
              >
                <HowItWorksCardMobile
                  backgroundColor={
                    props.theme === "olive" ? "cream.main" : "lightOlive.main"
                  }
                  step={1}
                  title="Search"
                  text={textStep1}
                  activePage={activePage}
                />
                <HowItWorksCardMobile
                  backgroundColor={
                    props.theme === "olive" ? "cream.main" : "lightOlive.main"
                  }
                  step={2}
                  title="Book"
                  text={textStep2}
                  activePage={activePage}
                />
                <HowItWorksCardMobile
                  backgroundColor={
                    props.theme === "olive" ? "cream.main" : "lightOlive.main"
                  }
                  step={3}
                  title="Talk"
                  text={textStep3}
                  activePage={activePage}
                />
                <HowItWorksCardMobile
                  backgroundColor={
                    props.theme === "olive" ? "cream.main" : "lightOlive.main"
                  }
                  step={4}
                  title="Build"
                  text={textStep4}
                  activePage={activePage}
                />
              </motion.div>
            </Box>
          )}
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default HowItWorksCarousel;
