import { Box, useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import scaffoldingBackground from '../../../Assets/Images/roselyn-tirado-GDWmu0bFfS4-unsplash.jpg';
import { useMediaQuery } from 'react-responsive';
import orGraphic from '../../../Assets/Images/or-graphic.svg';
import GridButton from '../../../SharedComponents/GridButtons';

const blockHeadingText = {
  sx: {
    whiteSpace: 'pre-line',
    textAlign: 'center',
  },
  display: 'block',
  variant: 'h1',
  color: 'blue.main',
  fontSize: '38px',
};

const blockSubtitleText = {
  sx: {
    whiteSpace: 'pre-line',
    textAlign: 'center',
  },
  display: 'block',
  variant: 'paragraphSans',
  color: 'blue.main',
};

const Hero = () => {
  const isDesktop = useMediaQuery({ minWidth: 1201 });
  const isPortrait = useMediaQuery({ orientation: 'portrait' });
  const smallMobile = useMediaQuery({ maxWidth: 414 });

  const blockBoxStyling = {
    lg: 4,
    xs: 12,
    height: 'auto',
    justifyContent: 'center',
    color: 'blue.main',
    item: true,
    sx: {
      backgroundColor: 'cream.main',
      borderLeft: 1,
      borderBottom: 1,
      ...(isDesktop && {
        '&:not(:nth-child(5))::after': {
          content: `url(${orGraphic})`,
          overflow: 'visible',
          zIndex: '10',
          top: '-125px',
          left: 'calc(100% - 18px)',
          position: 'relative',
        },
      }),
    },
  };

  const infoStyling = {
    whiteSpace: 'pre-line',
    textAlign: 'center',
    px: smallMobile ? '10%' : '83px',
    pt: smallMobile ? '10%' : '56px',
    pb: smallMobile ? '10%' : isDesktop ? '10px' : '45px',
    width: '100%',
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      sx={{
        zIndex: 0,
        width: '100%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundImage: `url(${scaffoldingBackground})`,
        pt: '100px',
        pb: isDesktop ? '100px' : '50px',
        px: isPortrait ? '20px' : undefined,
      }}
    >
      <Grid container maxWidth="1440px" sx={{ mx: isDesktop ? '44px' : '0' }}>
        {isDesktop && (
          <Grid
            lg={4}
            order={{ xs: 0, lg: 1 }}
            item
            height="auto"
            sx={{
              border: 1,
              borderRight: 0,
              borderColor: 'blue.main',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center',
              backgroundSize: '100% 100%, auto',
              background:
                'linear-gradient(to top right,transparent calc(50% - 1px),#2d457c,transparent calc(50% + 1px))',
            }}
          ></Grid>
        )}
        <Grid
          lg={8}
          xs={12}
          height="auto"
          justifyContent="center"
          color="white"
          item
          order={{ xs: 1, lg: 2 }}
          sx={{
            backgroundColor: 'olive.main',
            border: 1,
            borderColor: 'blue.main',
          }}
        >
          <Typography
            sx={{
              ...infoStyling,
              pb: smallMobile ? '10%' : '45px',
            }}
            display="block"
            variant="hero"
          >
            {'Meet your new home services BFF'}
          </Typography>
        </Grid>
        <Grid
          {...blockBoxStyling}
          borderRight={isDesktop ? 0 : 1}
          order={{ xs: 2, lg: 3 }}
        >
          <Stack
            spacing={2}
            sx={{
              ...infoStyling,
            }}
          >
            <Typography {...blockHeadingText}>
              {'Get expert advice from anywhere.'}
            </Typography>
            <Typography {...blockSubtitleText}>
              {
                'From DIY support and repairs, to construction coaching and vetting quotes with a click of a button.'
              }
            </Typography>
          </Stack>
        </Grid>
        <Grid
          {...blockBoxStyling}
          borderRight={isDesktop ? 0 : 1}
          order={{ xs: 4, lg: 4 }}
        >
          <Stack spacing={2} sx={{ ...infoStyling }}>
            <Typography {...blockHeadingText}>
              {'Let us play matchmaker.'}
            </Typography>
            <Typography {...blockSubtitleText}>
              {
                "We'll connect you to one of your Matrarichy Build approved builders for your next kitchen, bath, or full home remodel."
              }
            </Typography>
          </Stack>
        </Grid>
        <Grid {...blockBoxStyling} borderRight={1} order={{ xs: 6, lg: 5 }}>
          <Stack spacing={2} sx={{ ...infoStyling }}>
            <Typography {...blockHeadingText}>
              {'Find services in Greater Austin'}
            </Typography>
            <Typography {...blockSubtitleText}>
              {
                'Now serving Austin with Handyperson services and a Pro Referral network. Become a member for extra perks.'
              }
            </Typography>
          </Stack>
        </Grid>
        <GridButton
          lgSize={4}
          xsSize={12}
          linkTo="/pro"
          textColor="blue"
          backgroundColor="pink"
          hoverTextColor="pink"
          hoverBackgroundColor="blue"
          linkText="Schedule an online consultation"
          sx={{
            borderRight: isDesktop ? 0 : 1,
          }}
          order={{ xs: 3, lg: 6 }}
        />
        <GridButton
          lgSize={4}
          xsSize={12}
          linkTo="/find-a-builder"
          textColor="blue"
          backgroundColor="pink"
          hoverTextColor="pink"
          hoverBackgroundColor="blue"
          linkText="Connect me with a builder"
          sx={{
            borderRight: isDesktop ? 0 : 1,
          }}
          order={{ xs: 5, lg: 7 }}
        />
        <GridButton
          lgSize={4}
          xsSize={12}
          linkTo="/austin"
          textColor="blue"
          backgroundColor="pink"
          hoverTextColor="pink"
          hoverBackgroundColor="blue"
          linkText="Find a local pro"
          order={{ xs: 7, lg: 8 }}
        />
      </Grid>
    </Box>
  );
};

export default Hero;
