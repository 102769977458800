import { useEffect, useState } from 'react';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ArrowBlue from '../../../Assets/Images/arrow-blue.svg';
import ContentGrid from '../../../SharedComponents/ContentGrid';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
const Inspiration = () => {
  const isDesktop = useMediaQuery({ minWidth: 1201 });
  const isPortrait = useMediaQuery({ orientation: 'portrait' });

  const [featuredPosts, setFeaturedPosts] = useState([]);

  const db = getFirestore();

  useEffect(() => {
    const getPosts = async () => {
      const posts = [];

      var docRef = doc(db, 'blog', 'feature1');
      var docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
      } else {
        // doc.data() will be undefined in this case
        console.log('No such document!');
      }
      var response = await fetch(
        `https://mblegacy.wpengine.com/wp-json/wp/v2/posts?slug=${
          docSnap.data().slug
        }`
      );
      if (!response.ok) {
        // oops! something went wrong
        return;
      }
      const feature1 = await response.json();
      posts.push(feature1[0]);

      docRef = doc(db, 'blog', 'feature2');
      docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
      } else {
        // doc.data() will be undefined in this case
        console.log('No such document!');
      }
      response = await fetch(
        `https://mblegacy.wpengine.com/wp-json/wp/v2/posts?slug=${
          docSnap.data().slug
        }`
      );
      if (!response.ok) {
        // oops! something went wrong
        return;
      }
      const feature2 = await response.json();
      posts.push(feature2[0]);

      docRef = doc(db, 'blog', 'feature3');
      docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
      } else {
        // doc.data() will be undefined in this case
        console.log('No such document!');
      }
      response = await fetch(
        `https://mblegacy.wpengine.com/wp-json/wp/v2/posts?slug=${
          docSnap.data().slug
        }`
      );
      if (!response.ok) {
        // oops! something went wrong
        return;
      }
      const feature3 = await response.json();
      posts.push(feature3[0]);

      setFeaturedPosts(posts);
    };

    getPosts();
  }, []);
  return (
    <Box
      display="flex"
      width="100%"
      height="auto"
      justifyContent="center"
      sx={{
        backgroundColor: 'blue.main',
        pt: '155px',
        pb: '137px',
        px: isPortrait ? '20px' : '44px',
      }}
    >
      <Stack width="100%" maxWidth="1220px">
        <Stack direction={isDesktop ? 'row' : undefined}>
          <Box
            width={isDesktop ? '50%' : '100%'}
            height="269px"
            color="lightOlive.main"
            sx={{
              border: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              borderColor: 'pink.main',
            }}
          >
            <Typography
              sx={{
                whiteSpace: 'pre-line',
                textColor: 'blue.main',
                ml: '26px',
                mt: '16px',
              }}
              display="block"
              variant="h1"
              color="pink.main"
            >
              {'DIY + \n inspiration'}
            </Typography>
          </Box>
          <Stack width={isDesktop ? '50%' : '100%'}>
            {isDesktop && (
              <Box
                width="100%"
                height="209px"
                color="lightOlive.main"
                sx={{
                  border: 1,
                  borderLeft: 0,
                  borderColor: 'pink.main',
                  background:
                    'linear-gradient(to bottom right ,#2d457c calc(50% - 1px),#F7CBAD,#2d457c calc(50% + 1px))',
                }}
              ></Box>
            )}
            <Link to="/diy-inspiration">
              <Box
                width="100%"
                height="60px"
                color="lightOlive.main"
                sx={{
                  backgroundColor: 'pink.main',
                  border: 1,
                  borderLeft: 0,
                  borderColor: 'pink.main',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  '&:hover': {
                    backgroundColor: 'cream.main',
                  },
                }}
              >
                <Stack
                  direction="row"
                  sx={{
                    pt: '4px',
                    textAlign: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      whiteSpace: 'pre-line',
                      mr: '7px',
                    }}
                    display="block"
                    variant="paragraphMono"
                    color="olive.main"
                  >
                    {'View All Posts'}
                  </Typography>
                  <img src={ArrowBlue} />
                </Stack>
              </Box>
            </Link>
          </Stack>
        </Stack>
        {featuredPosts.length && <ContentGrid featuredPosts={featuredPosts} />}
      </Stack>
    </Box>
  );
};

export default Inspiration;
