import Hero from './Components/Hero';
import FeaturedIn from './Components/FeaturedIn';
import GiftCard from './Components/GiftCard';
import React from 'react';
import HowItWorksCarousel from '../../SharedComponents/HowItWorksCarousel';
import FeaturedPros from '../../SharedComponents/FeaturedPros';
import Inspiration from './Components/Inspiration';
import Testimonials from '../../SharedComponents/Testimonials';
import MailingList from '../../SharedComponents/MailingList';
import Footer from '../../SharedComponents/Footer';
import MeetUs from './Components/MeetUs';
import AustinServices from './Components/AustinServices';

const HomePage = () => {
  return (
    <React.Fragment>
      <Hero />
      <FeaturedIn />
      <MeetUs />
      <FeaturedPros />
      <AustinServices />
      <Testimonials />
      <Inspiration />
      <GiftCard />
      <MailingList />
      <Footer />
    </React.Fragment>
  );
};

export default HomePage;
