import { Box, useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ArrowWhite from '../../Assets/Images/arrow-white.svg';
import ArrowBlue from '../../Assets/Images/arrow-blue.svg';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';

const ActionButton = (props) => {
  return (
    <Button {...props} fullWidth disableRipple sx={{ m: 0, p: 0 }}>
      <Box
        height="58px"
        width="100%"
        sx={{
          backgroundColor: 'blue.main',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: 1,
          borderColor: props.borderColor
            ? props.borderColor
            : 'lightOlive.main',
          color: 'pink.main',
          '&:hover': {
            backgroundColor: 'lightOlive.main',
            color: 'blue.main',
            borderColor: props.borderColor ? props.borderColor : 'blue.main',
          },
          ...props.sx,
        }}
      >
        <Stack
          width="100%"
          direction="row"
          sx={{
            display: 'flex',
            justifyContent: 'center',

            textAlign: 'center',
          }}
        >
          <Typography
            sx={{
              whiteSpace: 'pre-line',
              mr: '7px',
            }}
            display="block"
            variant="paragraphMono"
            color="inherit"
          >
            {props.title}
          </Typography>
          {props?.arrowColor == 'blue' ? (
            <img src={ArrowBlue} />
          ) : (
            <img src={ArrowWhite} />
          )}
        </Stack>
      </Box>
    </Button>
  );
};

export { ActionButton };

const LinkButton = (props) => {
  return (
    <Link to={props.linkTo} state={{ ...props.state }}>
      <Box
        height="58px"
        width="100%"
        sx={{
          backgroundColor: 'pink.main',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: 1,
          borderColor: 'lightOlive.main',
          color: 'blue.main',
          '&:hover': {
            backgroundColor: 'lightOlive.main',
            color: 'blue.main',
            borderColor: 'blue.main',
          },
          ...props.sx,
        }}
        {...props}
      >
        <Stack
          width="100%"
          direction="row"
          sx={{
            display: 'flex',
            justifyContent: 'center',

            textAlign: 'center',
          }}
        >
          {(props.reverse && props?.arrowColor) == 'blue' ? (
            <img
              style={{ marginRight: '8px', transform: 'scaleX(-1)' }}
              src={ArrowBlue}
            />
          ) : (
            props.reverse && (
              <img
                style={{ marginRight: '8px', transform: 'scaleX(-1)' }}
                src={ArrowWhite}
              />
            )
          )}

          <Typography
            sx={{
              whiteSpace: 'pre-line',
              mr: '7px',
            }}
            display="block"
            variant="paragraphMono"
            color="inherit"
          >
            {props.title}
          </Typography>

          {!props?.reverse && props?.arrowColor == 'blue' ? (
            <img src={ArrowBlue} />
          ) : (
            !props?.reverse && <img src={ArrowWhite} />
          )}
        </Stack>
      </Box>
    </Link>
  );
};

export { LinkButton };
