import { useRef } from 'react';
import { Box, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { blueBox } from '../../theme';
import { styled } from '@mui/system';
import HeadshotPlaceholder from '../../Assets/Images/headshot-placeholder-large.jpeg';
import Insta from '../../Assets/Images/instagram.svg';
import Link from '../../Assets/Images/link.svg';
import TikTok from '../../Assets/Images/tiktok.svg';
import YouTube from '../../Assets/Images/youtube.svg';
import Facebook from '../../Assets/Images/facebook.svg';
import ArrowLeft from '../../Assets/Images/arrow-button-right.svg';
import Arrow from '../../Assets/Images/arrow-button-left.svg';
import React from 'react';
import Book from './Components/Book';
import Testimonials from '../../SharedComponents/Testimonials';
import GeneralFAQs from '../../SharedComponents/FAQ/Components/GeneralFAQs';
import FeaturedPros from '../../SharedComponents/FeaturedPros';
import MailingList from '../../SharedComponents/MailingList';
import Footer from '../../SharedComponents/Footer';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom';
import ButtonUnstyled from '@mui/base/ButtonUnstyled';
import { useContext, useState, useEffect } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';

import {
  getFirestore,
  doc,
  where,
  collection,
  query,
  getDocs,
  orderBy,
} from 'firebase/firestore';

import { getDownloadURL, ref } from 'firebase/storage';

const Button = styled(ButtonUnstyled)(
  ({ theme }) => `

  transition: all 150ms ease;
  cursor: pointer;
  border: none;
  height: 32px ;

  `
);

const ProProfile = (props) => {
  const db = getFirestore();
  const isDesktop = useMediaQuery({ minWidth: 1201 });
  const isMedium = useMediaQuery({ minWidth: 901 });
  const isPortrait = useMediaQuery({ orientation: 'portrait' });
  const [pro, setPro] = useState();
  const [imageURL, setImageURL] = useState('');
  const [images, setImages] = useState([]);
  const [appointmentTypes, setAppointmentTypes] = useState([]);
  const [tradeList, setTradeList] = useState('');
  const [currentSlide, setCurrentSlide] = useState(0);
  const service =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.';

  const { id } = useParams();

  const sliderRef = useRef();

  var settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    centerMode: true,
    centerPadding: '0px',
  };
  const { movieID } = useParams();

  useEffect(() => {
    getPro();
  }, [id]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant', // Optional if you want to skip the scrolling animation
    });
  }, []);

  const getPro = async () => {
    const slugQuery = query(collection(db, 'pros'), where('slug', '==', id));

    const proSnapshot = await getDocs(slugQuery);

    var proData;
    var proId;
    proSnapshot.forEach((doc) => {
      proData = doc.data();
      proId = doc.id;
    });

    var tradeList = '';

    proData.proTrades?.forEach((trade) => {
      if (tradeList === '') {
        tradeList = trade.displayName;
      } else {
        tradeList = tradeList.concat(', ', trade.displayName);
      }
    });
    setTradeList(tradeList);

    console.log(proId);
    setPro({ id: proId, ...proData }); //pro

    const proImagesRef = collection(db, `pros/${proId}/images`);
    var q = query(proImagesRef);
    var querySnapshot = await getDocs(q);
    var images = [];

    querySnapshot.docs.map((doc) => {
      images.push(doc.data().imageURL);
    });

    setImages(images);

    const proBookingsRef = collection(db, `pros/${proId}/appointmentTypes`);
    q = query(proBookingsRef, orderBy('duration'));
    querySnapshot = await getDocs(q);

    const appointmentTypes = [];
    querySnapshot.docs.forEach((doc, index) => {
      appointmentTypes.push({ id: doc.id, type: doc.data() });
    });

    setAppointmentTypes(appointmentTypes);
  };

  useEffect(() => {
    // Update the document title using the browser API
    getPro();
  }, []);
  return (
    <React.Fragment>
      <Box
        width="100%"
        height="auto"
        sx={{
          backgroundColor: 'cream.main',
          pt: '63px',
          pb: '130px',
          px: isPortrait ? '20px' : '44px',
        }}
      >
        <Grid container maxWidth="1440px" sx={{ m: 'auto' }}>
          <Grid
            height="239px"
            lgOffset={4}
            xs={12}
            lg={4.5}
            sx={{ ...blueBox, borderBottom: 0 }}
          >
            <Box>
              <Stack>
                <Typography
                  sx={{
                    whiteSpace: 'pre-line',
                    mt: '7px',
                  }}
                  display="block"
                  variant="h1"
                  color="blue.main"
                >
                  {pro && pro.firstName}
                </Typography>
                <Typography
                  sx={{
                    whiteSpace: 'pre-line',
                    mt: '7px',
                  }}
                  display="block"
                  variant="h1"
                  color="blue.main"
                >
                  {pro && pro.lastName}
                </Typography>
                <Typography
                  sx={{
                    whiteSpace: 'pre-line',
                    mt: '7px',
                  }}
                  display="block"
                  variant="paragraphMono"
                  color="olive.main"
                >
                  {pro && pro.pronouns}
                </Typography>
              </Stack>
            </Box>
          </Grid>
          <Grid xs={12} lg={3.5}>
            <Stack width="100%">
              <Box
                height="60px"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  border: 1,
                  borderLeft: isDesktop ? 0 : 1,
                  color: 'blue.main',
                }}
              >
                <Typography
                  sx={{
                    whiteSpace: 'pre-line',
                    textColor: 'blue.main',
                    ml: '21px',
                  }}
                  display="block"
                  variant="paragraphMonoSmall"
                  color="olive.main"
                >
                  {tradeList}
                </Typography>
              </Box>
              <Box
                height="60px"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  border: 1,
                  borderLeft: isDesktop ? 0 : 1,
                  borderTop: 0,
                  color: 'blue.main',
                }}
              >
                <Typography
                  sx={{
                    whiteSpace: 'pre-line',
                    textColor: 'blue.main',
                    ml: '21px',
                    fontSize: '14px',
                  }}
                  display="block"
                  variant="paragraphMono"
                  color="olive.main"
                >
                  {pro?.location?.label}
                </Typography>
              </Box>
              <Box
                height="60px"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  border: 1,
                  borderLeft: isDesktop ? 0 : 1,
                  borderTop: 0,
                  color: 'blue.main',
                  pl: '21px',
                  pt: '5px',
                }}
              >
                <Stack direction="row" spacing={2}>
                  {pro?.website && (
                    <a
                      target="_blank"
                      href={pro.website}
                      style={{ paddingTop: '1px' }}
                    >
                      <img src={Link} />
                    </a>
                  )}
                  {pro?.instagram && (
                    <a target="_blank" href={pro.instagram}>
                      <img src={Insta} />
                    </a>
                  )}
                  {pro?.youTube && (
                    <a
                      target="_blank"
                      href={pro.youTube}
                      style={{ paddingTop: '1px' }}
                    >
                      <img src={YouTube} />
                    </a>
                  )}
                  {pro?.tikTok && (
                    <a target="_blank" href={pro.tikTok}>
                      <img src={TikTok} />
                    </a>
                  )}
                  {pro?.facebook && (
                    <a target="_blank" href={pro.facebook}>
                      <img src={Facebook} />
                    </a>
                  )}
                </Stack>
              </Box>
            </Stack>
          </Grid>
        </Grid>

        <Grid container maxWidth="1440px" sx={{ m: 'auto' }}>
          <Grid
            item
            xs={12}
            md={6}
            lg={4}
            height="auto"
            sx={{
              overflow: 'hidden',
            }}
          >
            <Box
              maxHeight="480px"
              sx={{
                borderLeft: 1,
                borderRight: isDesktop ? 0 : 1,
                borderTop: isDesktop ? 1 : 0,
                borderBottom: 1,
              }}
            >
              <Slider
                height="477px"
                ref={sliderRef}
                {...settings}
                style={{
                  '.slider': {
                    width: '100%',
                    height: 'auto',
                    objectFit: 'cover',
                  },
                }}
              >
                <div style={{ height: '479px', width: '100%' }}>
                  <img
                    src={pro?.headshot}
                    height="100%"
                    width="100%"
                    style={{
                      objectFit: 'cover',
                      height: isDesktop ? '478px' : '479px',
                      width: '100%',
                      objectFit: 'cover',
                    }}
                  />
                </div>
                {images.map((url, index) => {
                  return (
                    <div key={index} style={{ width: '100%' }}>
                      <img
                        height="100%"
                        width="100%"
                        style={{
                          objectFit: 'cover',
                          height: isDesktop ? '478px' : '479px',
                          width: '100%',
                          objectFit: 'cover',
                        }}
                        src={url}
                      />
                    </div>
                  );
                })}
              </Slider>
            </Box>
            <Stack
              direction="row"
              width="100%"
              justifyContent="right"
              alignItems="flex-end"
            >
              <Box
                width="40px"
                height="33px"
                onClick={(event) => {
                  sliderRef.current.slickPrev();
                }}
                sx={{
                  ...blueBox,
                  borderTop: 0,
                  borderBottom: !isDesktop ? 0 : 1,
                  borderColor: 'blue.main',
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: 'white',
                  },
                }}
              >
                <img src={ArrowLeft} />
              </Box>
              <Box
                width="40px"
                height="33px"
                onClick={(event) => {
                  sliderRef.current.slickNext();
                }}
                sx={{
                  ...blueBox,
                  borderColor: 'blue.main',
                  borderLeft: 0,
                  borderTop: 0,
                  borderRight: isDesktop ? 0 : 1,
                  borderBottom: !isDesktop ? 0 : 1,
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: 'white',
                  },
                }}
              >
                <img src={Arrow} />
              </Box>
            </Stack>
          </Grid>

          <Grid
            container
            lg={8}
            xs={12}
            sx={{
              border: 1,
              color: 'blue.main',
              backgroundColor: 'white',
              px: ' 32px',
              pb: '32px',
            }}
          >
            <Grid md={7} sx={{ pr: isMedium ? '36px' : undefined }}>
              <Stack height="auto" sx={{ mt: '45px' }}>
                <Box
                  height="30px"
                  width="100%"
                  sx={{
                    border: 0,
                    borderBottom: 1,
                    color: 'lightOlive.main',
                    mb: '3px',
                  }}
                >
                  <Typography
                    sx={{
                      whiteSpace: 'pre-line',
                      textColor: 'blue.main',
                    }}
                    display="block"
                    variant="h3"
                    color="lightOlive.main"
                  >
                    {'services'}
                  </Typography>
                </Box>
                {pro?.proServices?.map((service, index) => {
                  return (
                    <Box
                      key={index}
                      height="auto"
                      width="100%"
                      sx={{
                        py: '5px',
                        border: 0,
                        borderBottom: 1,
                        color: 'lightOlive.main',
                      }}
                    >
                      <Typography
                        sx={{
                          whiteSpace: 'pre-line',
                          textColor: 'blue.main',
                        }}
                        display="block"
                        variant="paragraphSans"
                        color="blue.main"
                      >
                        {service.displayName}
                      </Typography>
                    </Box>
                  );
                })}
              </Stack>
            </Grid>
            <Grid sm={12} md={5}>
              <Box
                width="100%"
                sx={{
                  border: 0,

                  color: 'lightOlive.main',
                }}
              >
                <Stack sx={{ mt: '45px' }}>
                  <Box
                    width="100%"
                    sx={{
                      border: 0,

                      color: 'lightOlive.main',
                    }}
                  >
                    {isMedium && (
                      <Box
                        height="30px"
                        width="100%"
                        sx={{
                          border: 0,
                          borderBottom: 1,
                          color: 'lightOlive.main',
                        }}
                      >
                        <Typography
                          sx={{
                            whiteSpace: 'pre-line',
                            textColor: 'blue.main',
                          }}
                          display="block"
                          variant="h3"
                          color="lightOlive.main"
                        >
                          {'Bio'}
                        </Typography>
                      </Box>
                    )}
                    <Typography
                      sx={{
                        whiteSpace: 'pre-line',
                        textColor: 'blue.main',
                        pt: '8px',
                      }}
                      display="block"
                      variant="paragraphMono"
                      color="blue.main"
                    >
                      {pro && pro.bio}
                    </Typography>
                  </Box>
                </Stack>
              </Box>
            </Grid>
          </Grid>
          {isDesktop && <Grid item xs={7.35}></Grid>}
        </Grid>
      </Box>
      <Book appointmentTypes={appointmentTypes} pro={pro} />
      <Testimonials proId={pro?.id} />
      <GeneralFAQs />
      <FeaturedPros />
      <MailingList />
      <Footer />
    </React.Fragment>
  );
};

export default ProProfile;
