import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import GiftCardImg from '../../../Assets/Images/gift-card.svg';
import { useMediaQuery } from 'react-responsive';
import GridButton from '../../../SharedComponents/GridButtons';

const GiftCard = () => {
  const isDesktop = useMediaQuery({ minWidth: 1201 });
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{
        width: '100%',
        py: '80px',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundColor: 'lightOlive.main',
      }}
    >
      <Stack
        maxWidth="1030px"
        height="auto"
        sx={{ mx: isDesktop ? '216px' : isPortrait ? '20px' : '44px' }}
      >
        <Grid container>
          <Grid
            xs={12}
            md={6}
            display="flex"
            height="auto"
            maxHeight="314px"
            justifyContent="center"
            sx={{
              backgroundColor: 'pink.main',
              border: 1,
              borderRight: 0,
              color: 'blue.main',
            }}
          >
            <img width="100%" height="100%" src={GiftCardImg} />
          </Grid>

          <Grid
            xs={12}
            md={6}
            display="flex"
            height="auto"
            justifyContent="center"
            sx={{
              backgroundColor: 'white',
              border: 1,
              borderLeft: 1,
              borderTop: isDesktop ? 1 : 0,
              color: 'blue.main',
            }}
          >
            <Stack
              spacing={2}
              sx={{ px: isPortrait ? '20px' : '105px', pt: '89px', pb: '85px' }}
            >
              <Typography
                sx={{
                  whiteSpace: 'pre-line',
                  textAlign: 'center',
                }}
                display="block"
                variant={isPortrait ? 'paragraphSansLarge' : 'hero'}
                color="blue.main"
              >
                {'Give the gift of a Pro in their back pocket.'}
              </Typography>
            </Stack>
          </Grid>
          <Grid md={6} xs={0} item />
          <Grid md={6} xs={12} item>
            <GridButton
              linkTo="/gift-card"
              linkText="Buy a Gift Card"
              textColor="blue"
              backgroundColor="cream"
              hoverTextColor="olive"
              hoverBackgroundColor="pink"
              sx={{
                width: '100%',
              }}
            />
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
};

export default GiftCard;
