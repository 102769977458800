import { Box, Grid } from '@mui/material';
import React from 'react';
import ContentCard from './Components/ContentCard';

const ContentGrid = (props) => {
  return (
    <Box sx={{ display: 'block' }}>
      <Grid
        container
        spacing={2}
        sx={{
          mt: '16px',
        }}
      >
        <Grid width="100%" item sm={12} md={6} lg={4}>
          <ContentCard post={props.featuredPosts[0]} hideByline />
        </Grid>
        <Grid width="100%" item sm={12} md={6} lg={4}>
          <ContentCard post={props.featuredPosts[1]} hideByline />
        </Grid>
        <Grid width="100%" item sm={12} md={6} lg={4}>
          <ContentCard post={props.featuredPosts[2]} hideByline />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContentGrid;
