import { useState, useContext, useEffect } from "react";
import { Box, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useMediaQuery } from "react-responsive";
import Input from "@mui/material/Input";
import SideBar from "../SideBar";
import Footer from "../../../SharedComponents/Footer";
import { ActionButton } from "../../../SharedComponents/Buttons";
import { Navigate } from "react-router-dom";
import {
  getFirestore,
  updateDoc,
  doc,
  getDoc,
  deleteField,
} from "firebase/firestore";
import { UserContext } from "../../../user-context";
import {
  signInWithEmailAndPassword,
  getAuth,
  updatePassword,
} from "firebase/auth";

const commonStyles = {
  pl: "4px",
  mt: "12px",
  fontFamily: "Code Saver",
  fontStyle: "normal",
  fontWeight: "normal",
  fontSize: "16px",
  lineHeight: "145%",
  color: "blue.main",

  border: 1,
  borderColor: "lightOlive.main",
};

const ChangePassword = (props) => {
  const isDesktop = useMediaQuery({ minWidth: 1201 });
  const isMedium = useMediaQuery({ minWidth: 901 });

  const [currentPassword, setCurrentPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [message, setMessage] = useState("");
  const [saved, setSaved] = useState(false);
  const [proId, setProId] = useState();
  const db = getFirestore();

  const user = useContext(UserContext).user;

  const fetchPro = async () => {
    const proRef = doc(db, `pros/${user.uid}/`);
    const docSnap = await getDoc(proRef);

    if (docSnap.exists()) {
      setProId(docSnap.id);
    }
  };

  useEffect(() => {
    fetchPro();
  }, [user]);

  const updateAccountDetails = () => {
    const docRef = doc(db, "customers", user.uid);

    if (currentPassword || newPassword || confirmPassword) {
      if (!currentPassword) {
        setMessage("Temporary password is required");
      } else if (newPassword != confirmPassword) {
        setMessage("Passwords do not match");
      } else if (!newPassword || !confirmPassword) {
        setMessage("Please type and confirm your new password");
      } else {
        const auth = getAuth();
        auth.useDeviceLanguage();

        signInWithEmailAndPassword(auth, user.email, currentPassword)
          .then(function (user) {

            updatePassword(user.user, newPassword)
              .then(function () {
                setMessage("Password changed");
                const proRef = doc(db, "pros", proId);

                // Remove the 'capital' field from the document
                updateDoc(proRef, {
                  temporaryPassword: deleteField(),
                }).then(() => setSaved(true));
              })
              .catch(function (err) {
                console.log(err);
                setMessage("An error occured, please try again later");
              });
          })
          .catch(function (err) {
            console.log(err);
            setMessage(
              "Unable to change password. Please ensure current password is correct."
            );
          });
      }
    }
  };

  return (
    <>
   {saved && <Navigate to="/my-account" />}
    <Box width="100%" minHeight="766px">
      <Stack
        width="100%"
        minHeight="766px"
        direction={isDesktop ? "row" : undefined}
      >
        <Box width="100%" sx={{ px: "44px", pb: "60px" }}>
          <Typography
            sx={{
              whiteSpace: "pre-line",
              color: "olive.main",
              mr: "5px",
              mt: "66px",
              mb: "8px",
            }}
            variant="h3"
          >
            {"Please change your password"}
          </Typography>
          <Grid container columnSpacing={4}></Grid>

          <Grid container columnSpacing={4}>
            <Grid item md={6} xs={12}>
              <Input
                type="password"
                disableUnderline
                fullWidth
                value={currentPassword}
                onChange={(event) => {
                  setSaved(false);
                  setCurrentPassword(event.target.value);
                }}
                height="40px"
                placeholder="Temporary password"
                sx={{
                  ...commonStyles,
                }}
              ></Input>
            </Grid>
            <Grid item md={6} xs={0}></Grid>
            <Grid item md={6} xs={12}>
              <Input
                type="password"
                disableUnderline
                fullWidth
                value={newPassword}
                onChange={(event) => {
                  setSaved(false);
                  setNewPassword(event.target.value);
                }}
                height="40px"
                placeholder="New password "
                sx={{
                  ...commonStyles,
                }}
              ></Input>
            </Grid>
            <Grid item md={6} xs={0}></Grid>
            <Grid item md={6} xs={12}>
              <Input
                type="password"
                disableUnderline
                fullWidth
                value={confirmPassword}
                onChange={(event) => {
                  setSaved(false);
                  setConfirmPassword(event.target.value);
                }}
                height="40px"
                placeholder="Confirm new password"
                sx={{
                  ...commonStyles,
                }}
              ></Input>
            </Grid>
            {message && (
              <Grid item my="20px" md={12}>
                <Typography variant="paragraphMono" color="blue.main">
                  {message}
                </Typography>
              </Grid>
            )}
            <Grid item md={12} xs={0}></Grid>
            <Grid mt="20px" item md={3} xs={12}>
              
                <ActionButton
                  title="Save Changes"
                  sx={{ color: "blue.main", backgroundColor: "pink.main" }}
                  onClick={updateAccountDetails}
                />
              
            </Grid>
          </Grid>
        </Box>
      </Stack>
      <Footer />
    </Box>
    </>
  );
};

export default ChangePassword;
