import { Typography } from '@mui/material';

import Accordion from '../../../SharedComponents/Accordion';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const commonStyles = {
  variant: 'paragraphSans',
  color: 'blue.main',
  textAlign: 'left',
};

const linkStyles = {
  variant: 'paragraphSans',
  color: 'pink.main',
  sx: {
    cursor: 'pointer',
    '&:hover': {
      color: 'lightOlive.main',
    },
  },
};

const LocalServicesFAQs = () => {
  const [expanded, setExpanded] = useState('');
  const navigate = useNavigate();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const navigateTo = (url) => () => navigate(url);

  const visit =
    (url, newTab = false) =>
    () => {
      if (newTab) {
        window.open(url, '_blank');
      } else {
        window.location.assign(url);
      }
    };

  return (
    <Accordion>
      <Accordion.Section
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
      >
        <Accordion.Summary>How It Works</Accordion.Summary>
        <Accordion.Details>
          <ul
            style={{
              listStylePosition: 'outside',
              textAlign: 'left',
            }}
          >
            <li>
              <Typography {...commonStyles}>
                Matriarchy Build offers Handyperson services to the Greater
                Austin area.
              </Typography>
            </li>
            <li>
              <Typography {...commonStyles}>
                We also offer more robust{' '}
                <Typography
                  {...linkStyles}
                  onClick={navigateTo('/membership-details')}
                >
                  Membership Packages
                </Typography>{' '}
                for folks looking for recurring services, additional support,
                and access to our Pro Referral network.
              </Typography>
            </li>
          </ul>
        </Accordion.Details>
      </Accordion.Section>
      <Accordion.Section
        expanded={expanded === 'panel2'}
        onChange={handleChange('panel2')}
      >
        <Accordion.Summary>Pricing + Services</Accordion.Summary>
        <Accordion.Details>
          <Typography {...commonStyles}>Pricing</Typography>
          <ul
            style={{
              listStylePosition: 'outside',
              textAlign: 'left',
            }}
          >
            <li>
              <Typography {...commonStyles}>
                Hire Matriarchy Build Handypeople ($100/hr Member pricing,
                $125/hr Non-Member pricing)
              </Typography>
            </li>
            <li>
              <Typography {...commonStyles}>
                Schedule a Home Health Report ($250 Member pricing, $315
                Non-Member pricing)
              </Typography>
            </li>
            <li>
              <Typography
                {...linkStyles}
                onClick={navigateTo('/membership-details')}
              >
                See Membership Perks for additional details and Member pricing.
              </Typography>
            </li>
          </ul>
          <Typography {...commonStyles}>Services</Typography>
          <ul
            style={{
              listStylePosition: 'outside',
              textAlign: 'left',
            }}
          >
            <li>
              <Typography {...commonStyles}>
                Maintenance and safety repairs, handy tasks, odd jobs and small
                fixes.
              </Typography>
            </li>
            <li>
              <Typography {...commonStyles}>
                Additional services include: Home Health Report and Pro
                Matchmaker Services.
              </Typography>
            </li>
            <li>
              <Typography {...commonStyles}>
                You can learn more about our full offering in our{' '}
                <Typography
                  {...linkStyles}
                  onClick={navigateTo('/membership-details')}
                >
                  Membership Details
                </Typography>
              </Typography>
            </li>
          </ul>
        </Accordion.Details>
      </Accordion.Section>
      <Accordion.Section
        expanded={expanded === 'panel3'}
        onChange={handleChange('panel3')}
      >
        <Accordion.Summary>Membership Perks</Accordion.Summary>
        <Accordion.Details>
          <Typography {...commonStyles}>Basic Membership</Typography>
          <ul
            style={{
              listStylePosition: 'outside',
              textAlign: 'left',
            }}
          >
            <li>
              <Typography {...commonStyles}>
                Hire Matriarchy Build Handypeople for $100/hr (Member Rate)
              </Typography>
            </li>
            <li>
              <Typography {...commonStyles}>
                Schedule a Home Health Report for $250 (Member Rate)
              </Typography>
            </li>
            <li>
              <Typography {...commonStyles}>
                Unlimited Access to Pro Referral Network
              </Typography>
            </li>
            <li>
              <Typography
                {...linkStyles}
                onClick={visit(
                  'https://buy.stripe.com/7sI7t90cEeFs62Y001',
                  true
                )}
              >
                Sign up for $100/year
              </Typography>
            </li>
          </ul>
          <Typography {...commonStyles}>Home Health Membership</Typography>
          <ul style={{ listStylePosition: 'outside', textAlign: 'left' }}>
            <li>
              <Typography {...commonStyles}>
                Hire Matriarchy Build Handypeople for $100/hr (Member Rate)
              </Typography>
            </li>
            <li>
              <Typography {...commonStyles}>
                Matriarchy Build Home Health Report ($315 value)
              </Typography>
            </li>
            <li>
              <Typography {...commonStyles}>
                1 Hour Consultation w/ MB General Contractor to Review Home
                Health Report ($150 value)
              </Typography>
            </li>
            <li>
              <Typography {...commonStyles}>
                Unlimited Access to Pro Referral Network
              </Typography>
            </li>
            <li>
              <Typography
                {...linkStyles}
                onClick={visit(
                  'https://buy.stripe.com/00gbJpaRi68W3UQ28l',
                  true
                )}
              >
                Sign up for $500/year
              </Typography>
            </li>
          </ul>
        </Accordion.Details>
      </Accordion.Section>
      <Accordion.Section
        expanded={expanded === 'panel4'}
        onChange={handleChange('panel4')}
      >
        <Accordion.Summary>Online Consultations</Accordion.Summary>
        <Accordion.Details>
          <Typography {...commonStyles}>
            Matriarchy Build also offers nationwide 1:1 online consultations for
            folks looking for guidance around DIY and repairs or larger remodel
            projects. If you're looking for online guidance,{' '}
            <Typography {...linkStyles} onClick={navigateTo('/pro')}>
              please see our roster of virtual Pros.
            </Typography>
          </Typography>
        </Accordion.Details>
      </Accordion.Section>
    </Accordion>
  );
};

export default LocalServicesFAQs;
