import { Box, useTheme, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { blueBox } from '../../theme';
import ProCard from './Components/ProCard';
import ArrowOlive from '../../Assets/Images/arrow-olive.svg';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import HighlightCard from './Components/HighlightCard';

const ProsGrid = (props) => {
  const isDesktop = useMediaQuery({ minWidth: 1201 });

  const gridSizes = props.highlight
    ? {
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
      }
    : {
        sm: 12,
        md: 6,
        lg: 4,
      };

  const renderInnerElements = () => (
    <>
      {props.pros.map((pro, index) => {
        return (
          <Grid key={index} item {...gridSizes}>
            {props.highlight ? (
              <HighlightCard pro={pro} />
            ) : (
              <ProCard pro={pro} />
            )}
          </Grid>
        );
      })}
      {props.viewMore && (
        <Grid width="100%" item sm={12} md={6} lg={4} sx={{}}>
          <Link to="/pro">
            <Box
              width="100%"
              height="60px"
              sx={{
                ...blueBox,
              }}
            >
              <Stack
                direction="row"
                width="100%"
                sx={{ display: 'flex', justifyContent: 'center', pt: '4px' }}
              >
                <Typography
                  sx={{
                    whiteSpace: 'pre-line',
                    textColor: 'blue.main',
                    mr: '7px',
                  }}
                  display="block"
                  variant="paragraphMono"
                  color="blue.main"
                >
                  {'View All Pros'}
                </Typography>
                <img src={ArrowOlive} />
              </Stack>
            </Box>
          </Link>
        </Grid>
      )}
    </>
  );

  return (
    <>
      {isDesktop ? (
        <Grid
          container={isDesktop}
          maxWidth="1020px"
          spacing={2}
          sx={{
            ml: isDesktop ? '12px' : undefined,
          }}
          item={!isDesktop}
        >
          {renderInnerElements()}
        </Grid>
      ) : (
        <>{renderInnerElements()}</>
      )}
    </>
  );
};

export default ProsGrid;
