import { forwardRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Box, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ArrowOlive from '../../Assets/Images/arrow-olive.svg';
import Button from '@mui/material/Button';
import clsx from 'clsx';
import { styled } from '@mui/system';
import ModalUnstyled from '@mui/base/ModalUnstyled';
import TimeSelect from '../../Scenes/ProProfile/TimeSelect';
import CloseButton from '../../Assets/Images/close-button.svg';
import MeetIcon from '../../Assets/Images/google-meet-96.png';
import Backdrop from '@mui/material/Backdrop';

const Modal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const style = (theme) => ({
  width: 836,
  height: 668,
  bgcolor: theme.palette.mode === 'dark' ? '#0A1929' : 'white',
  border: '1px solid currentColor',
});

function addMinutes(date, minutes) {
  return new Date(date.getTime() + minutes * 60000);
}

const ConsultationSummary = (props) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };
  const timeOptions = {
    hour: '2-digit',
    minute: '2-digit',
  };

  const endDate = addMinutes(
    props.date,
    props.appointmentDetails?.type.duration
  );

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      ></Backdrop>

      <Grid container sx={{ border: 1, color: 'olive.main', mt: '25px' }}>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            backgroundColor: 'cream.main',
            py: '18px',
            pl: '18px',
            color: 'olive.main',
            borderRight: 1,
          }}
        >
          {true && (
            <Modal
              aria-labelledby="unstyled-modal-title"
              aria-describedby="unstyled-modal-description"
              open={open}
              onClose={handleClose}
            >
              <Box sx={style}>
                <Box
                  width="100%"
                  p="3px"
                  sx={{ display: 'flex', justifyContent: 'right' }}
                >
                  <img
                    width="30px"
                    style={{ cursor: 'pointer' }}
                    src={CloseButton}
                    onClick={() => setOpen(false)}
                  />
                </Box>

                <TimeSelect
                  appointmentDetails={props?.appointmentDetails}
                  pro={props?.pro}
                />
              </Box>
            </Modal>
          )}
          <Typography
            sx={{
              whiteSpace: 'pre-line',
              color: 'olive.main',
            }}
            variant="paragraphMono"
          >
            {props.appointmentDetails?.type?.label + ' Consultation'}
            {` - ${props.appointmentDetails?.type?.duration} min`}
          </Typography>
          {props.presentedAt === 'account' ? (
            <Link to={`/my-account/consultation-details/${props.id}`}>
              <Typography
                sx={{
                  whiteSpace: 'pre-line',
                  color: 'blue.main',
                  mt: '10px',
                }}
                variant="h1"
              >
                {props.pro?.firstName + '\n'}
                {props.pro?.lastName}
              </Typography>
            </Link>
          ) : (
            <>
              <Typography
                sx={{
                  whiteSpace: 'pre-line',
                  color: 'blue.main',
                  mt: '10px',
                }}
                variant="h1"
              >
                {props.pro?.firstName + '\n'}
                {props.pro?.lastName}
              </Typography>
              <Typography
                sx={{
                  whiteSpace: 'pre-line',
                  color: 'olive.main',
                }}
                variant="paragraphMono"
              >
                {props.pro?.profession}
              </Typography>{' '}
            </>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            py: '18px',
            pl: '18px',
            color: 'olive.main',
            borderRight: 1,
          }}
        >
          <Typography
            sx={{
              whiteSpace: 'pre-line',
              color: 'blue.main',
              display: 'block',
            }}
            variant="paragraphMonoLarge"
          >
            {props.date?.toLocaleDateString('en-us', options)}
          </Typography>
          <Stack direction="row">
            <Typography
              sx={{
                whiteSpace: 'pre-line',
                color: 'blue.main',
              }}
              variant="paragraphMonoLarge"
            >
              {props.date?.toLocaleTimeString('en-us', timeOptions)}
            </Typography>
            <Typography
              sx={{
                whiteSpace: 'pre-line',
                color: 'blue.main',
              }}
              variant="paragraphMonoLarge"
            >
              {' - '}
            </Typography>
            <Typography
              sx={{
                whiteSpace: 'pre-line',
                color: 'blue.main',
              }}
              variant="paragraphMonoLarge"
            >
              {endDate.toLocaleTimeString('en-us', timeOptions)}
            </Typography>
          </Stack>

          <Stack
            direction="row"
            sx={{
              pt: '4px',
              textAlign: 'center',
            }}
          >
            {props.presentedAt === 'preOrder' && (
              <Button
                disableRipple
                disableFocusRipple
                disableElevation
                onClick={() => setOpen(true)}
                sx={{
                  border: 'none',
                  margin: 0,
                  padding: 0,
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                }}
              >
                <Typography
                  sx={{
                    whiteSpace: 'pre-line',
                    color: 'olive.main',
                    mr: '5px',
                    '&:hover': {
                      color: 'blue.main',
                    },
                  }}
                  variant="paragraphMono"
                >
                  {'Change Time'}
                </Typography>
              </Button>
            )}

            {props.presentedAt === 'account' && (
              <Link to={`/my-account/consultation-details/${props.id}`}>
                <Typography
                  sx={{
                    whiteSpace: 'pre-line',
                    color: 'olive.main',
                    mr: '5px',
                    '&:hover': {
                      color: 'blue.main',
                    },
                  }}
                  variant="paragraphMono"
                >
                  {'View Details'}
                </Typography>
              </Link>
            )}

            {props.presentedAt === 'confirmation' && (
              <Link to={`/my-account/consultation-details/${props.id}`}>
                <Typography
                  sx={{
                    whiteSpace: 'pre-line',
                    color: 'olive.main',
                    mr: '5px',
                    '&:hover': {
                      color: 'blue.main',
                    },
                  }}
                  variant="paragraphMono"
                >
                  {'Add Details'}
                </Typography>
              </Link>
            )}

            <img src={ArrowOlive} />
          </Stack>
          {props.presentedAt === 'account' && (
            <Stack direction="row">
              <a
                style={{ paddingTop: '22px' }}
                target="_blank"
                href={
                  props.appointmentDetails?.calendarInfo.conferenceData
                    .entryPoints[0].uri
                }
              >
                <Typography
                  sx={{
                    whiteSpace: 'pre-line',
                    color: 'olive.main',
                    pt: '22px',
                    mr: '15px',
                    '&:hover': {
                      color: 'blue.main',
                    },
                  }}
                  variant="paragraphMono"
                >
                  {'Your video link'}
                </Typography>
              </a>
            </Stack>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={2}
          sx={{
            py: '18px',
            pr: '18px',
            textAlign: 'right',
          }}
        >
          <Typography
            sx={{
              whiteSpace: 'pre-line',
              color: 'blue.main',
              display: 'block',
            }}
            variant="paragraphMonoLarge"
          >
            {'$' + props.appointmentDetails?.type?.price}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default ConsultationSummary;
