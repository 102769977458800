import { Box } from "@mui/material";
import { useState, useContext, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useMediaQuery } from "react-responsive";
import SideBar from "../SideBar";
import { useLocation } from "react-router-dom";
import ConsultationSummary from "../../../SharedComponents/ConsultationSummary";
import Footer from "../../../SharedComponents/Footer";
import { UserContext } from "../../../user-context";

import {
  getFirestore,
  where,
  collection,
  query,
  doc,
  getDoc,
  getDocs,
  orderBy,
} from "firebase/firestore";

const Consultations = (props) => {
  const isDesktop = useMediaQuery({ minWidth: 1201 });
  const isMedium = useMediaQuery({ minWidth: 901 });
  const [appointments, setAppointments] = useState([]);
  const [userType, setUserType] = useState();
  const location = useLocation();
  const [pro, setPro] = useState();
  const { state } = location;

  const db = getFirestore();

  const user = useContext(UserContext).user;

  const setUserAttributes = async (id) => {
    if (user) {
      const proRef = doc(db, `pros/${id}/`);
      const docSnap = await getDoc(proRef);

      if (docSnap.exists()) {
        const proData = docSnap.data();

        setUserType("pro");
        setPro({ id: docSnap.id, ...proData });
      } else {
        user
          .getIdTokenResult()
          .then((idTokenResult) => {
            if (idTokenResult.claims.userType === "admin") {
              setUserType("admin");
            } else {
              setUserType("user");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  useEffect(() => {
    if (user) {
      setUserAttributes(user.uid);
    }
  }, [user]);

  const getAppointments = async () => {
    const proBookingsRef = collection(db, `appointments`);

    const switchChar = props.type == "upcoming" ? ">" : "<";

    const now = new Date();

    var q;

    if (userType === "pro") {

      q = query(
        proBookingsRef,
        where("proId", "==", pro.id),
        where("confirmed", "==", true)
      );
    } else if (userType === "admin") {
      q = query(
        proBookingsRef,
        where("confirmed", "==", true)

      );
  
    } else {

      q = query(
        proBookingsRef,
        where("userId", "==", user.uid),
        where("confirmed", "==", true)
      );
    }

    const querySnapshot = await getDocs(q);

    var appointments = [];
    querySnapshot.docs.forEach((doc, index) => {
      if (props.type == "upcoming") {
        if (new Date(doc.data().startTime) > now) {
  
          appointments.push({ id: doc.id, appointmentDetails: doc.data() });
        }
      } else {
        if (new Date(doc.data().startTime) < now) {
      
          appointments.push({ id: doc.id, appointmentDetails: doc.data() });
        }
      }
    });
    console.log (appointments)
    setAppointments(appointments);
  };

  useEffect(() => {
    getAppointments();
  }, [props, userType, pro]);

  return (
    <Box width="100%" minHeight="766px">
      <Stack
        width="100%"
        minHeight="766px"
        direction={isDesktop ? "row" : undefined}
      >
        <Box width={isDesktop ? "345px" : "100%"}>
          <SideBar type={props.type} />
        </Box>
        <Box width="100%" sx={{ pb: "30px", mx: "44px" }}>
          <Box width="66%" sx={{ borderBottom: 1, color: "lightOlive.main" }}>
            <Typography
              sx={{
                whiteSpace: "pre-line",
                color: "olive.main",
                mr: "5px",
                mt: "66px",
                mb: "8px",
              }}
              variant="h3"
            >
              {props.type == "upcoming"
                ? "Your upcoming consultations"
                : "Your past consultations"}
            </Typography>
          </Box>

          {appointments.length ? (
            appointments.map((appointment, index) => {
              return (
                <ConsultationSummary
                  key={index}
                  date={new Date(appointment.appointmentDetails.startTime)}
                  id={appointment.id}
                  appointmentDetails={appointment.appointmentDetails}
                  presentedAt="account"
                  pro={appointment.appointmentDetails.pro}
                />
              );
            })
          ) : (
            <Box sx={{ mt: "20px" }}>
              <Typography variant="paragraphMono" color="blue.main">
                {props.type == "upcoming"
                  ? "You have no upcoming consultations"
                  : "You have no past consultations"}
              </Typography>
            </Box>
          )}
        </Box>
      </Stack>
      <Footer />
    </Box>
  );
};

export default Consultations;
