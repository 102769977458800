import React, { useState, useEffect, useContext, useRef } from "react";

import { UserContext } from "../../../user-context";
import { Link, useParams } from "react-router-dom";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../App";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import VideocamIcon from "@mui/icons-material/Videocam";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import FlipCameraIosIcon from "@mui/icons-material/FlipCameraIos";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Menu from "@mui/material/Menu";
import Tooltip from "@mui/material/Tooltip";
import Snackbar from "@mui/material/Snackbar";
import {
  RemoteParticipant,
  connect,
  createLocalAudioTrack,
  createLocalVideoTrack,
} from "twilio-video";
import Grid from "@mui/material/Unstable_Grid2/";
import { ActionButton } from "../../../SharedComponents/Buttons";
import { Box, Divider, IconButton, Stack } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Input from "@mui/material/Input";
const commonStyles = {
  pl: "4px",
  pt: "4px",
  mb: "12px",
  fontFamily: "Code Saver",
  fontStyle: "normal",
  fontWeight: "normal",
  fontSize: "16px",
  lineHeight: "145%",
  color: "blue.main",
  border: 1,
  borderColor: "lightOlive.main",
};

const Status = {
  NOT_STARTED: "notStarted",
  IN_PROGRESS: "inProgress",
  ENDED: "ended",
};

const timeWarningStatus = {
  FIVE_MINUTES: "fiveMinutes",
  ONE_MINUTE: "oneMinute",
  NONE: "none",
};

const timeOptions = {
  hour: "2-digit",
  minute: "2-digit",
};

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const VideoMeeting = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [userType, setUserType] = useState();
  const [room, setRoom] = useState();
  const [videoDevice, setVideoDevice] = useState("");
  const [audioDevice, setAudioDevice] = useState("");
  const [isAudioMuted, setIsAudioMuted] = useState(false);
  const [isVideoMuted, setIsVideoMuted] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [remoteParticipant, setRemoteParticipant] = useState();
  const [appointment, setAppointment] = useState();
  const [videoDevices, setVideoDevices] = useState();
  const [audioDevices, setAudioDevices] = useState();
  const [meetingStatus, setMeetingStatus] = useState(Status.NOT_STARTED);
  const [localVideoTrack, setLocalVideoTrack] = useState();
  const [localAudioTrack, setLocalAudioTrack] = useState();
  const [videoContainer, setVideoContainer] = useState();
  const [remoteVideoContainer, setRemoteVideoContainer] = useState();
  const [currentTimeWarning, setCurrentTimeWarning] = useState("");
  const [left, setLeft] = useState(false);
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState();
  const [twilioError, setTwilioError] = useState();
  const [pro, setPro] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const db = getFirestore();

  const localContainer = document.getElementById("local-video-container");

  const remoteContainer = document.getElementById("remote-video-container");

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const user = useContext(UserContext).user;

  const getAppointment = async () => {
    setLoading(true);
    const docRef = doc(db, "appointments", id);
    const docSnap = await getDoc(docRef);

    setAppointment(docSnap.data());

    setLoading(false);
  };

  function attachTracks(tracks, container) {
    tracks.forEach(function (track) {
      if (track) {
        container.appendChild(track.attach());
      }
    });
  }

  // Detach the Participant's Tracks from the DOM.
  function detachParticipantTracks(participant) {
    var tracks = Array.from(participant.tracks.values()).map(function (
      trackPublication
    ) {
      return trackPublication.track;
    });
    detachTracks(tracks);
  }
  // Detach the Tracks from the DOM.
  function detachTracks(tracks) {
    tracks.forEach(function (track) {
      if (track) {
        track.detach().forEach(function (detachedElement) {
          detachedElement.remove();
        });
      }
    });
  }

  function stopTracks(tracks) {
    tracks.forEach(function (track) {
      if (track) {
        track.stop();
      }
    });
  }

  const handleVideoDeviceChangeEvent = (e) => {
    handleVideoDeviceChange(e.target.value);
  };
  const handleAudioDeviceChangeEvent = (e) => {
    handleAudioDeviceChange(e.target.value);
  };

  const handleAudioDeviceChange = (deviceId) => {
    setAudioDevice(deviceId);

    if (room) {
      const tracks = Array.from(room.localParticipant.audioTracks.values()).map(
        function (trackPublication) {
          return trackPublication.track;
        }
      );

      detachTracks(tracks);
      stopTracks(tracks);

      room.localParticipant.unpublishTracks(tracks);
    } else if (localVideoTrack) {
      detachTracks([localVideoTrack]);
    }

    if (deviceId !== "") {
      createLocalAudioTrack({
        deviceId: { exact: deviceId },
      }).then(function (localAudioTrack) {
        setLocalAudioTrack(localAudioTrack);

        if (room) {
          room.localParticipant.publishTrack(localAudioTrack);
        }

        const localAudioContainer = localAudioTrack.attach();

        document
          .getElementById("local-video-container")
          .appendChild(localAudioContainer);
      });
    }
  };

  const handleVideoDeviceChange = (deviceId) => {
    setVideoDevice(deviceId);
    if (deviceId !== "") {
      if (room) {
        const tracks = Array.from(
          room.localParticipant.videoTracks.values()
        ).map(function (trackPublication) {
          return trackPublication.track;
        });

        detachTracks(tracks);
        stopTracks(tracks);

        room.localParticipant.unpublishTracks(tracks);
      } else if (localVideoTrack) {
        detachTracks([localVideoTrack]);
      }

      createLocalVideoTrack({
        deviceId: { exact: deviceId },
      }).then((newVideoTrack) => {
        setLocalVideoTrack(newVideoTrack);
        if (room) {
          room.localParticipant.publishTrack(newVideoTrack);
        }

        const localVideoContainer = newVideoTrack.attach();
        setVideoContainer(localVideoContainer);

        document
          .getElementById("local-video-container")
          .appendChild(localVideoContainer);
      });
    }
  };

  function gotDevices(mediaDevices) {
    //const select = document.getElementById('video-devices');
    //select.innerHTML = '';
    // select.appendChild(document.createElement('option'));
    let count = 1;

    const videoDevices = [];
    const audioDevices = [];
    mediaDevices.forEach((mediaDevice) => {
      if (mediaDevice.kind === "videoinput") {
        videoDevices.push(mediaDevice);
      } else if (mediaDevice.kind === "audioinput") {
        audioDevices.push(mediaDevice);
      }
    });
    console.log(audioDevices);
    setAudioDevices(audioDevices);

    setVideoDevices(videoDevices);
  }

  const initializeVideoCall = async () => {
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then((stream) => {
        console.log("Got MediaStream:", stream);
        navigator.mediaDevices.enumerateDevices().then(gotDevices);
        // Create a local video track from the MediaStream
        const localVideoTrack = createLocalVideoTrack({
          mediaStreamTrack: stream.getVideoTracks()[0],
        }).then((localVideoTrack) => {
          setLocalVideoTrack(localVideoTrack);

          const localVideoContainer = localVideoTrack.attach();
          setVideoContainer(localVideoContainer);

          document
            .getElementById("local-video-container")
            .appendChild(localVideoContainer);
        });
        const localAudioTrack = createLocalAudioTrack({
          mediaStreamTrack: stream.getAudioTracks()[0],
        }).then((localAudioTrack) => {
          setLocalAudioTrack(localAudioTrack);
        });
      });
  };

  function addMinutes(date, minutes) {
    return new Date(date.getTime() + minutes * 60000);
  }

  useInterval(() => {
    const currentTime = new Date();
    if (appointment) {
      const endTime = addMinutes(
        new Date(appointment.startTime),
        appointment.type.duration
      );
      if (currentTime < new Date(appointment.startTime)) {
        setMeetingStatus(Status.NOT_STARTED);
        setCurrentTimeWarning(timeWarningStatus.NONE);
      } else if (
        currentTime > new Date(appointment.startTime) &&
        currentTime < endTime
      ) {
        setMeetingStatus(Status.IN_PROGRESS);
        if (
          currentTime > addMinutes(endTime, -1) &&
          currentTimeWarning !== timeWarningStatus.ONE_MINUTE
        ) {
          setCurrentTimeWarning(timeWarningStatus.ONE_MINUTE);
          console.log("one minute");
          setSnackbarOpen(true);
        } else if (
          currentTime > addMinutes(endTime, -5) &&
          currentTimeWarning !== timeWarningStatus.FIVE_MINUTES &&
          currentTimeWarning !== timeWarningStatus.ONE_MINUTE
        ) {
          setSnackbarOpen(true);
          console.log(currentTimeWarning);
          console.log("five minute");
          setCurrentTimeWarning(timeWarningStatus.FIVE_MINUTES);
        }
      } else if (currentTime > endTime) {
        setMeetingStatus(Status.ENDED);
        setCurrentTimeWarning(timeWarningStatus.NONE);
        console.log("none minute");
        setSnackbarOpen(false);
      }
    }
  }, 1000);

  const getPro = async () => {
    const proRef = doc(db, `pros/${appointment.proId}/`);
    const docSnap = await getDoc(proRef);

    if (docSnap.exists()) {
      const proData = docSnap.data();

      setPro({ id: docSnap.id, ...proData });
      console.log(proData);
    }
  };

  // Call the function to start checking the current time
  useEffect(() => {
    if (appointment) {
      getPro();
    }
  }, [appointment]);

  useEffect(() => {
    if (audioDevices) {
      setAudioDevice(audioDevices[0].deviceId);
    }
    if (videoDevices) {
      setVideoDevice(videoDevices[0].deviceId);
    }
  }, [audioDevices, videoDevices]);

  const mediaErrors = [
    'NotAllowedError',
    'NotFoundError',
    'NotReadableError',
    'OverconstrainedError',
    'TypeError'
  ];
  
  const joinVideoCall = async (email) => {
    if (!name) {
      setNameError("Please fill out your name");
    } else {
      setLoading(true);
      const getTwilioToken = httpsCallable(functions, "getTwilioVideoToken");

      const twilioToken = await getTwilioToken({
        identity: name,
        room: id,
      });

      try {
        const room = await connect(twilioToken.data, {
          name: id,
          audio: true,
          video: true,
          preferredVideoCodecs: ["VP8"],
          maxAudioBitrate: 16000, //For music remove this line
          tracks: [localVideoTrack, localAudioTrack],
        });

        setRoom(room);
        console.log(room);
        //handleConnectedParticipant(room.localParticipant, true);
        //room.participants.forEach(handleConnectedParticipant);
        // When a Participant joins the Room, log the event.
        room.on("participantConnected", function (participant) {
          console.log("Joining: '" + participant.identity + "'");
          setRemoteParticipant(participant.identity);
        });

        // handle cleanup when a participant disconnects
        // When a Participant leaves the Room, detach its Tracks.
        room.on("participantDisconnected", function (participant) {
          console.log(
            "Participant '" + participant.identity + "' left the room"
          );
          detachParticipantTracks(participant);
        });

        window.addEventListener("pagehide", () => room.disconnect());
        window.addEventListener("beforeunload", () => room.disconnect());

        // When a Participant adds a Track, attach it to the DOM.
        room.on(
          "trackSubscribed",
          function (track, trackPublication, participant) {
            console.log(participant.identity + " added track: " + track.kind);
            var remoteContainer = document.getElementById(
              "remote-video-container"
            );
            if (!remoteParticipant) {
              setRemoteParticipant(participant.identity);
            }
            // if (track.kind === "audio") {
            attachTracks([track], remoteContainer);
            /*          } else {
              const remoteVideoContainer = track.attach();

              remoteVideoContainer.width = "1000px";

              setRemoteVideoContainer(remoteVideoContainer);
              remoteContainer.appendChild(remoteVideoContainer);
            }*/
          }
        );

        room.on(
          "trackUnsubscribed",
          function (track, trackPublication, participant) {
            console.log(participant.identity + " removed track: " + track.kind);
            detachTracks([track]);
          }
        );

        room.on("disconnected", function () {
          console.log("Left");

          room.participants.forEach(detachParticipantTracks);

          setRoom(null);
          setLeft(true);
        });
      } catch (error) {
        if ("code" in error) {
          // Handle connection error here.
          console.error("Failed to join Room:", error.code, error.message);
        }
        setLoading(false);
        if (error.code === 53105) {
          setTwilioError(
            "There are already two people in the meeting. Matriarchy Build consultations are 1:1 meetings. If you’re experiencing this error and you’re the Pro or Client, please email support"
          );
        } else {
         
          if (mediaErrors.includes(error.name)) {

            setTwilioError(`Failed to join meeting: Please grant audio and video permissions to your browser`);
            await initializeVideoCall();
            
          } else {
            setTwilioError(`Failed to join meeting: ${error.name}-${error.message}`)
          }
        }
       
      }
      setLoading(false);
    }
  };

  const onMuteUnmuteClick = (trackType) => {
    if (trackType === "audio") {
      const opts = { audio: true, video: false };

      isAudioMuted ? unmute(opts) : mute(opts);

      setIsAudioMuted(!isAudioMuted);
    }

    if (trackType === "video") {
      const opts = { audio: false, video: true };

      isVideoMuted ? unmute(opts) : mute(opts);

      setIsVideoMuted(!isVideoMuted);
    }
  };

  const unmute = (opts) => {
    if (!room || !room.localParticipant)
      throw new Error("You must be connected to a room to unmute tracks.");

    if (opts.audio) {
      room.localParticipant.audioTracks.forEach((publication) =>
        publication.track.enable()
      );
    }

    if (opts.video) {
      room.localParticipant.videoTracks.forEach((publication) =>
        publication.track.enable()
      );
    }
  };

  const mute = (opts) => {
    if (!room || !room.localParticipant)
      throw new Error("You must be connected to a room to mute tracks.");

    if (opts.audio) {
      room.localParticipant.audioTracks.forEach((publication) =>
        publication.track.disable()
      );
    }

    if (opts.video) {
      room.localParticipant.videoTracks.forEach((publication) =>
        publication.track.disable()
      );
    }
  };

  const setUserAttributes = async (id) => {
    if (user) {
      const proRef = doc(db, `pros/${id}/`);
      const docSnap = await getDoc(proRef);

      if (docSnap.exists()) {
        setUserType("pro");
      } else {
        user
          .getIdTokenResult()
          .then((idTokenResult) => {
            if (idTokenResult.claims.userType === "admin") {
              setUserType("admin");
            } else {
              setUserType("user");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }

    //joinVideoCall(user.email);
  };

  useEffect(() => {
    getAppointment();

    initializeVideoCall();
  }, []);
  const isMobileDevice = () => {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      return true;
    } else {
      return false;
    }
  };
  const handleLeaveMeeting = () => {
    room.disconnect();

    console.log(room);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSnackbarClose = (event, reason) => {
    setSnackbarOpen(false);
  };
  return (
    <>
      <Snackbar
        ContentProps={{
          sx: { backgroundColor: "blue.main" },
        }}
        onClose={handleSnackbarClose}
        open={snackbarOpen}
        autoHideDuration={6000}
        message={
          currentTimeWarning === timeWarningStatus.FIVE_MINUTES ? (
            <Typography ml="10px" variant="paragraphMono" color="white">
              Friendly Reminder: there are less than 5 minutes remaining in your
              consultation
            </Typography>
          ) : (
            <Typography ml="10px" variant="paragraphMono" color="white">
              Friendly Reminder: there is 1 minute remaining in your
              consultation
            </Typography>
          )
        }
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress sx={{ color: "blue.main" }} />
      </Backdrop>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
      >
        <Typography ml="10px" variant="paragraphMono" color="blue.main">
          Camera
        </Typography>
        <Divider />
        {videoDevices?.map((device) => (
          <MenuItem
            key={device.deviceId}
            value={device.deviceId}
            selected={videoDevice === device.deviceId}
            onClick={() => {
              handleVideoDeviceChange(device.deviceId);
              handleMenuClose();
            }}
          >
            <Typography variant="paragraphMono" color="blue.main">
              {device.label}
            </Typography>
          </MenuItem>
        ))}
        <Typography ml="10px" variant="paragraphMono" color="blue.main">
          Microphone
        </Typography>
        <Divider />
        {audioDevices?.map((device) => (
          <MenuItem
            key={device.deviceId}
            value={device.deviceId}
            selected={audioDevice === device.deviceId}
            onClick={() => {
              handleAudioDeviceChange(device.deviceId);
              handleMenuClose();
            }}
          >
            <Typography variant="paragraphMono" color="blue.main">
              {device.label}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
      <div>
        <Box width="200px"></Box>
        {(meetingStatus === Status.NOT_STARTED ||
          meetingStatus === Status.IN_PROGRESS) && (
          <Grid
            container
            spacing={3}
            pt="80px"
            mx="auto"
            sx={{ maxWidth: "1440px" }}
          >
            {room && (
              <Grid
                item
                xs={6}
                sx={{
                  "& video": room
                    ? {
                        position: "fixed",
                        left: "50%",
                        top: 0,
                        height: "100vh",
                        transform: "translate(-50%)",
                      }
                    : undefined,
                }}
              >
                <Box id="remote-video-container"></Box>
                {remoteParticipant && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: "200px",
                      width: "auto",
                      left: "0",
                      margin: 0,
                      padding: "0.5em 0.5em 0.5em 2em",
                      backgroundColor: "blue.main",
                      color: "#fff",
                    }}
                  >
                    <Typography variant="paragraphSans">
                      {remoteParticipant}
                    </Typography>
                  </Box>
                )}
              </Grid>
            )}
            <Grid item xs={6}>
              <Box
                sx={{
                  "& video": room
                    ? {
                        position: "fixed",
                        bottom: 100,
                        right: isMobileDevice() ? "65%" : "75%",
                        height: isMobileDevice() ? "20%" : undefined,
                        width: isMobileDevice() ? undefined : "20%",
                        transform: "scale(-1, 1)",
                      }
                    : undefined,
                }}
                id="local-video-container"
              ></Box>
            </Grid>
            {!room && (
              <Grid item xs={12} lg={6}>
                <>
                  {left ? (
                    <Stack width="400px" mb="20px">
                      <Typography variant="h2">
                        You've left the consultation
                      </Typography>
                      <Typography
                        mt="20px"
                        variant="paragraphMono"
                        color="blue.main"
                      >
                        Didn’t mean to leave yet?
                      </Typography>

                      <ActionButton
                        sx={{ mt: "10px" }}
                        title="Join Again"
                        onClick={() => {
                          joinVideoCall();
                          setLeft(false);
                        }}
                      ></ActionButton>
                      <Typography
                        mt="40px"
                        variant="paragraphMono"
                        color="blue.main"
                      >
                        We hope you enjoyed your consultation! Your Pro may
                        share a follow-up summary with next steps or links.
                        You’ll find this note in your{" "}
                        <Link to={`/my-account/consultation-details/${id}`}>
                          <Typography
                            variant="paragraphMono"
                            sx={{ textDecoration: "underline" }}
                            color="blue.main"
                          >
                            Consultation Details.
                          </Typography>
                        </Link>
                      </Typography>
                      <Typography
                        mt="20px"
                        variant="paragraphMono"
                        color="blue.main"
                      >
                        If you’re looking for additional guidance, feel free to
                        book a follow-up consultation with{" "}
                        <Link to={`/pro/${pro?.slug}`}>
                          <Typography
                            variant="paragraphMono"
                            sx={{ textDecoration: "underline" }}
                            color="blue.main"
                          >
                            your Pro.
                          </Typography>
                        </Link>
                      </Typography>
                      <Typography
                        my="20px"
                        variant="paragraphMono"
                        color="blue.main"
                      >
                        How did we do? Leave your Pro a testimonial and let us
                        know about any ways we can improve{" "}
                        <a href="https://forms.gle/LSTXBXfYz6sY6CkC9">
                          <Typography
                            variant="paragraphMono"
                            sx={{ textDecoration: "underline" }}
                            color="blue.main"
                          >
                            your experience.
                          </Typography>
                        </a>
                      </Typography>
                    </Stack>
                  ) : (
                    <Stack>
                      <Typography mb="10px" variant="h2">
                        Join your consultation
                      </Typography>
                      {meetingStatus === Status.NOT_STARTED && appointment && (
                        <Typography variant="paragraphMono" color="blue.main">
                          {`1:1 Consultation - ${
                            appointment.type.duration
                          } mins with ${appointment.pro.firstName} ${
                            appointment.pro.lastName
                          } begins at ${new Date(
                            appointment?.startTime
                          ).toLocaleTimeString("en-us", timeOptions)}`}
                        </Typography>
                      )}
                      {meetingStatus === Status.IN_PROGRESS && appointment && (
                        <Typography variant="paragraphMono" color="blue.main">
                          {`1:1 Consultation - ${
                            appointment.type.duration
                          } mins with ${appointment.pro.firstName} ${
                            appointment.pro.lastName
                          } began at ${new Date(
                            appointment?.startTime
                          ).toLocaleTimeString("en-us", timeOptions)}`}
                        </Typography>
                      )}
                      <Typography
                        sx={{
                          whiteSpace: "pre-line",
                          mt: "24px",
                          color: "blue.main",
                        }}
                        display="block"
                        variant="paragraphMono"
                      >
                        {"Your Name"}
                      </Typography>
                      <Input
                        type="text"
                        disableUnderline
                        fullWidth
                        value={name}
                        onChange={(event) => {
                          setName(event.target.value);
                        }}
                        height="40px"
                        placeholder="Your Name"
                        sx={{
                          ...commonStyles,
                        }}
                      ></Input>
                      {nameError && (
                        <Typography
                          sx={{
                            whiteSpace: "pre-line",
                            mt: "4px",
                            color: "blue.main",
                            fontStyle: "italic",
                          }}
                          display="block"
                          variant="paragraphMono"
                        >
                          {nameError}
                        </Typography>
                      )}
                      <Typography
                        sx={{
                          whiteSpace: "pre-line",
                          mt: "4px",
                          color: "blue.main",
                        }}
                        display="block"
                        variant="paragraphMono"
                      >
                        {"Camera"}
                      </Typography>
                      <Select
                        id="video-select"
                        onChange={handleVideoDeviceChangeEvent}
                        value={videoDevice}
                        size="small"
                        variant="standard"
                        disableUnderline={true}
                        height="31px"
                        sx={{
                          ...commonStyles,
                          width: "100%",
                        }}
                      >
                        {videoDevices?.map((device) => (
                          <MenuItem
                            key={device.deviceId}
                            value={device.deviceId}
                          >
                            <Typography
                              sx={{
                                whiteSpace: "pre-line",
                                my: "3px",
                                color: "blue.main",
                              }}
                              display="block"
                              variant="paragraphMono"
                            >
                              {device.label}
                            </Typography>
                          </MenuItem>
                        ))}
                      </Select>

                      <Typography
                        sx={{
                          whiteSpace: "pre-line",
                          mt: "4px",
                          color: "blue.main",
                        }}
                        display="block"
                        variant="paragraphMono"
                      >
                        {"Microphone"}
                      </Typography>
                      <Select
                        required
                        onChange={handleAudioDeviceChangeEvent}
                        value={audioDevice}
                        size="small"
                        variant="standard"
                        disableUnderline={true}
                        height="31px"
                        sx={{
                          ...commonStyles,
                          width: "100%",
                        }}
                      >
                        {audioDevices?.map((device) => (
                          <MenuItem
                            key={device.deviceId}
                            value={device.deviceId}
                          >
                            <Typography
                              sx={{
                                whiteSpace: "pre-line",
                                my: "3px",
                                color: "blue.main",
                              }}
                              display="block"
                              variant="paragraphMono"
                            >
                              {device.label}
                            </Typography>
                          </MenuItem>
                        ))}
                      </Select>
                      <ActionButton
                        sx={{ mt: "31px" }}
                        title="Join Now"
                        onClick={joinVideoCall}
                      ></ActionButton>
                      {twilioError && (
                        <Typography
                          sx={{
                            whiteSpace: "pre-line",
                            mt: "4px",
                            color: "blue.main",
                          }}
                          display="block"
                          variant="paragraphMono"
                        >
                          <Typography
                            mt="20px"
                            variant="paragraphMono"
                            color="blue.main"
                          >
                            {twilioError}
                            <a href="mailto:support@matriarchybuild.com">
                              <Typography
                                variant="paragraphMono"
                                sx={{ textDecoration: "underline" }}
                                color="blue.main"
                              >
                                {` support@matriarchybuild.com`}
                              </Typography>
                            </a>
                          </Typography>
                        </Typography>
                      )}
                    </Stack>
                  )}
                </>
              </Grid>
            )}
          </Grid>
        )}
        {meetingStatus === Status.ENDED && (
          <Box
            pt="80px"
            mx="auto"
            sx={{
              display: "flex",
              justifyContent: "center",
              maxWidth: "1440px",
            }}
          >
            <Stack mx="44px" mb="20px">
              <Typography variant="h2">Thank you!</Typography>
              <Typography mt="20px" variant="paragraphMono" color="blue.main">
                We hope you enjoyed your consultation! Your Pro may share a
                follow-up summary with next steps or links. You’ll find this
                note in your{" "}
                <Link to={`/my-account/consultation-details/${id}`}>
                  <Typography
                    variant="paragraphMono"
                    sx={{ textDecoration: "underline" }}
                    color="blue.main"
                  >
                    Consultation Details.
                  </Typography>
                </Link>
              </Typography>
              <Typography mt="20px" variant="paragraphMono" color="blue.main">
                If you’re looking for additional guidance, feel free to book a
                follow-up consultation with{" "}
                <Link to={`/pro/${pro?.slug}`}>
                  <Typography
                    variant="paragraphMono"
                    sx={{ textDecoration: "underline" }}
                    color="blue.main"
                  >
                    your Pro.
                  </Typography>
                </Link>
              </Typography>
              <Typography my="20px" variant="paragraphMono" color="blue.main">
                How did we do? Leave your Pro a testimonial and let us know
                about any ways we can improve{" "}
                <a href="https://forms.gle/LSTXBXfYz6sY6CkC9">
                  <Typography
                    variant="paragraphMono"
                    sx={{ textDecoration: "underline" }}
                    color="blue.main"
                  >
                    your experience.
                  </Typography>
                </a>
              </Typography>
            </Stack>
          </Box>
        )}
        {room && room.participants.size === 0 && (
          <Box
            pt="80px"
            mx="auto"
            sx={{
              display: "flex",
              justifyContent: "center",
              maxWidth: "1440px",
            }}
          >
            <Stack>
              <Typography variant="h2">
                Waiting for other participants
              </Typography>
              {meetingStatus === Status.IN_PROGRESS ? (
                <Typography variant="paragraphMono" color="blue.main">
                  {`1:1 Consultation - ${appointment.type.duration} mins with ${
                    appointment.pro.firstName
                  } ${appointment.pro.lastName} began at ${new Date(
                    appointment?.startTime
                  ).toLocaleTimeString("en-us", timeOptions)}`}
                </Typography>
              ) : (
                <Typography variant="paragraphMono" color="blue.main">
                  {`1:1 Consultation - ${appointment.type.duration} mins with ${
                    appointment.pro.firstName
                  } ${appointment.pro.lastName} begins at ${new Date(
                    appointment?.startTime
                  ).toLocaleTimeString("en-us", timeOptions)}`}
                </Typography>
              )}
            </Stack>
          </Box>
        )}
      </div>
      {room && (
        <Box
          justifyContent="center"
          sx={{
            position: "fixed",
            bottom: "0",
            left: "0",
            width: "100%",
            height: "auto",
            backgroundColor: "blue.main",
          }}
        >
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Tooltip
              title={
                <Typography variant="paragraphMono" color="white.main">
                  Audio and Video Settings
                </Typography>
              }
            >
              <IconButton
                onClick={handleMenuClick}
                sx={{ color: "pink.main" }}
                size="large"
              >
                <SettingsIcon fontSize="large" />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={
                <Typography variant="paragraphMono" color="white.main">
                  Mute/Unmute Audio
                </Typography>
              }
            >
              <IconButton
                sx={{ color: "pink.main" }}
                size="large"
                onClick={() => onMuteUnmuteClick("audio")}
              >
                {isAudioMuted ? (
                  <MicOffIcon fontSize="large" />
                ) : (
                  <MicIcon fontSize="large" />
                )}
              </IconButton>
            </Tooltip>
            <Tooltip
              title={
                <Typography variant="paragraphMono" color="white.main">
                  Enable/Disable Camera
                </Typography>
              }
            >
              <IconButton
                sx={{ color: "pink.main" }}
                size="large"
                onClick={() => onMuteUnmuteClick("video")}
              >
                {isVideoMuted ? (
                  <VideocamOffIcon fontSize="large" />
                ) : (
                  <VideocamIcon fontSize="large" />
                )}
              </IconButton>
            </Tooltip>
            <Tooltip
              title={
                <Typography variant="paragraphMono" color="white.main">
                  Leave Consultation
                </Typography>
              }
            >
              <IconButton
                onClick={handleLeaveMeeting}
                sx={{ color: "pink.main" }}
                size="large"
              >
                <LogoutIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          </Stack>
        </Box>
      )}
    </>
  );
};

export default VideoMeeting;
