import React from 'react';
import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

const ProCard = (props) => {
  return (
    <Link to={`/pro/${props.pro.slug}`}>
      <Box
        width="100%"
        height="100%"
        sx={{
          border: 1,
          color: 'blue.main',
        }}
      >
        <Stack direction="row" sx={{ ml: '0px', mt: '0px' }}>
          <Box height="171px" width="171px">
            <img
              src={props.pro.headshot}
              width="100%"
              height="100%"
              style={{ objectFit: 'cover' }}
            />
          </Box>

          <Stack width="100%" height="171px">
            <Box
              width="100%"
              height="100%"
              color="blue.main"
              textAlign="left"
              sx={{
                backgroundColor: 'white',
                borderLeft: 1,
                borderBottom: 1,
              }}
            >
              <Typography
                sx={{
                  whiteSpace: 'pre-line',
                  textColor: 'blue.main',
                  ml: '8px',
                  mt: '14px',
                }}
                display="block"
                variant="h3"
                color="blue.main"
              >
                {`${props.pro.firstName} \n ${props.pro.lastName}`}
              </Typography>
              {props.pro.proTrades.map((trade, index) => {
                return (
                  <Typography
                    key={index}
                    sx={{
                      whiteSpace: 'pre-line',
                      textColor: 'blue.main',
                      ml: '8px',
                    }}
                    display="block"
                    variant="paragraphMonoSmall"
                    color="blue.main"
                  >
                    {`${trade.displayName}`}
                  </Typography>
                );
              })}
            </Box>
            <Stack direction="row">
              <Box
                width="80%"
                height="30px"
                sx={{
                  border: 1,
                  color: 'blue.main',
                  borderTop: 0,
                  borderBottom: 0,
                }}
              ></Box>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </Link>
  );
};

export default ProCard;
