import { useState, useEffect, useCallback, useRef, useContext } from "react";
import { UserContext } from "../../user-context";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../App";
import Dialog from "@mui/material/Dialog";
// needed for the style wrapper
import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import {
  query,
  collection,
  getFirestore,
  getDocs,
  orderBy,
  doc,
  getDoc,
  where,
} from "firebase/firestore";
import Grid from "@mui/material/Unstable_Grid2/";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import DownArrow from "../../Assets/Images/down-arrow.svg";
import EventDetail from "./EventDetail";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";

const textStyles = {
  color: "blue.main",
  fontFamily: "Code Saver",
  fontStyle: "normal",
  fontWeight: "normal",
  fontSize: "14px",
  lineHeight: "17.5px",
};

const Arrow = () => {
  return (
    <Box sx={{ mr: "7px", mt: "2px" }}>
      <img src={DownArrow} />
    </Box>
  );
};

//import Header from "../../components/Header";

export const StyleWrapper = styled.div`
  --fc-button-hover-bg-color: #1e2b37;
    --fc-button-active-bg-color: #2D457C;
    --fc-button-hover-bg-color: #1e2b37;
  .fc-toolbar-title {
    color:#2D457C;
    font-family: "BN Nautic Grotesk";
    font-size:38px;
    font-weight:bold;
    text-transform: uppercase;
  }
  .fc-button.fc-dayGridMonth-button {
    margin:0;
    
  }
  .fc-button.fc-today-button{
    background: #FBF8EA;
    color: #2D457C;
 
    border-right:1
  }
  .fc-button.fc-prev-button, .fc-button.fc-next-button{
    background: #FBF8EA;
    color: #2D457C;
    margin:0;
    border-right:1;
  }
  .fc-button.fc-button-primary{
    color:#2D457C;
    background-color:#FBF8EA;
    border-radius:0;
  }
  .fc-event-main {
    cursor: pointer;
  }
  .fc-day-today {
    background: #FBF8EA !important;  
  } 
}
`;

const timeOptions = {
  hour: "2-digit",
  minute: "2-digit",
  hour12: false,
};

const Calendar = (props) => {
  const [events, setEvents] = useState([]);
  const [pro, setPro] = useState();
  const [pros, setPros] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showEventDetails, setShowEventDetails] = useState(false);
  const [eventSelection, setEventSelection] = useState();
  const [userType, setUserType] = useState();
  const [temporaryEvent, setTemporaryEvent] = useState();
  const calendarRef = useRef();
  const db = getFirestore();

  const [businessHours, setBusinessHours] = useState([]);
  useEffect(() => {
    // Update the document title using the browser API
    getPros();
  }, []);

  const user = useContext(UserContext);

  const setUserAttributes = async (id) => {

    if (user.loggedIn == true) {
      const proRef = doc(db, `pros/${id}/`);
      const docSnap = await getDoc(proRef);

      if (docSnap.exists()) {
        const proData = docSnap.data();


        setUserType("pro");
        setPro({ id: docSnap.id, ...proData });
      } else {
        user.user
          .getIdTokenResult()
          .then((idTokenResult) => {

            setUserType(idTokenResult.claims.userType);
            if (idTokenResult.claims.userType === "admin") {
              setUserType("admin");
            } else {
              setUserType("user");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };
  useEffect(() => {
    if (user.loggedIn) {
      setUserAttributes(user.user.uid);
    }
  }, [user]);

  const retrieveCalendarEvents = async (
    info,
    successCallback,
    failureCallback
  ) => {
    const retrieveEvents = httpsCallable(
      functions,
      "retrieveEventsFromCalendar"
    );

    const events = [];

    const q = query(collection(db, `pros/${pro?.id}/customSchedule`));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      if (
        doc.data().startTime > info.startStr &&
        doc.data().endTime < info.endStr
      ) {
        events.push({
          id: doc.id,
          start: doc.data().startTime,
          end: doc.data().endTime,
          borderColor: "#F7CBAD",
          backgroundColor: "#F7CBAD",
          textColor: "#2D457C",
          title: "Available",
        });
      }
    });

    //setLoading(true);
    await retrieveEvents({
      timeMin: info.startStr,
      timeMax: info.endStr,
      calendarId: pro?.calendarId,
      timeZone: pro?.timeZone,

      singleEvents: true,
    }).then((result) => {
      const res = result.data;

      var rule = {};

      res.data.items.map((event) => {
        events.push({
          id: event.id,
          key: event.id,
          title: event.summary,
          start: new Date(event.start.dateTime),
          end: new Date(event.end.dateTime),
          hangoutLink: event.hangoutLink,
          allDay: false,
        });
      });
      if (temporaryEvent) {
        events.push(temporaryEvent);
      }
      successCallback(events);
    });
  };

  const handleDateClick = (selected) => {

    var start = selected.startStr;
    var end = selected.endStr;
    if (selected.allDay) {
      start += "T00:00:00";
      end += "T00:00:00";
    }
    const event = {
      calendarId: pro.calendarId,
      title: "Adding New Event",
      available: false,
      start: start,
      end: end,
    };

    setTemporaryEvent(event);
    //const api = calendarRef.current.getApi();
    setEventSelection(event);
    setShowEventDetails(true);
  };

  const handleEventClick = (selected) => {
    setShowEventDetails(true);
    setEventSelection(selected.event);

  };

  const handleCalendarSelect = (event) => {
    setPro(event.target.value);
  };

  const handleLoading = useCallback(() => {
    setLoading(true);
  });
  const handleClose = useCallback(() => {
    setTemporaryEvent();
    setShowEventDetails(false);
    setLoading(false);
  });

  let queryPros = [];
  const getPros = async () => {
    const q = query(collection(db, "pros"), orderBy("lastName"));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const pro = {
        id: doc.id,
        ...doc.data(),
      };
      queryPros.push(pro);
    });
    setPros(queryPros);
  };

  const getSchedule = async () => {
    const proBookingsRef = collection(db, `pros/${pro.id}/schedule`);
    const q = query(proBookingsRef);
    const querySnapshot = await getDocs(q);
    var days = [];

    querySnapshot.docs.forEach((doc, index) => {

      if (doc.id === "Sunday") {
        if (doc.data().available) {
          days.push({
            daysOfWeek: [0],
            startTime: new Date(doc.data().start).toLocaleString(
              "en-US",
              timeOptions
            ),
            endTime: new Date(doc.data().end).toLocaleString(
              "en-US",
              timeOptions
            ),
          });
        }
      } else if (doc.id === "Monday") {
        if (doc.data().available) {
          days.push({
            daysOfWeek: [1],
            startTime: new Date(doc.data().start).toLocaleString(
              "en-US",
              timeOptions
            ),
            endTime: new Date(doc.data().end).toLocaleString(
              "en-US",
              timeOptions
            ),
          });
        }
      } else if (doc.id === "Tuesday") {
        if (doc.data().available) {
          days.push({
            daysOfWeek: [2],
            startTime: new Date(doc.data().start).toLocaleString(
              "en-US",
              timeOptions
            ),
            endTime: new Date(doc.data().end).toLocaleString(
              "en-US",
              timeOptions
            ),
          });
        }
      } else if (doc.id === "Wednesday") {
        if (doc.data().available) {
          days.push({
            daysOfWeek: [3],
            startTime: new Date(doc.data().start).toLocaleString(
              "en-US",
              timeOptions
            ),
            endTime: new Date(doc.data().end).toLocaleString(
              "en-US",
              timeOptions
            ),
          });
        }
      } else if (doc.id === "Thursday") {
        if (doc.data().available) {
          days.push({
            daysOfWeek: [4],
            startTime: new Date(doc.data().start).toLocaleString(
              "en-US",
              timeOptions
            ),
            endTime: new Date(doc.data().end).toLocaleString(
              "en-US",
              timeOptions
            ),
          });
        }
      } else if (doc.id === "Friday") {
        if (doc.data().available) {
          days.push({
            daysOfWeek: [5],
            startTime: new Date(doc.data().start).toLocaleString(
              "en-US",
              timeOptions
            ),
            endTime: new Date(doc.data().end).toLocaleString(
              "en-US",
              timeOptions
            ),
          });
        }
      } else if (doc.id === "Saturday") {
        if (doc.data().available) {
          days.push({
            daysOfWeek: [6],
            startTime: new Date(doc.data().start).toLocaleString(
              "en-US",
              timeOptions
            ),
            endTime: new Date(doc.data().end).toLocaleString(
              "en-US",
              timeOptions
            ),
          });
        }
      }

      setBusinessHours(days);
    });
  };

  useEffect(() => {
    getSchedule();
  }, [pro]);

  const ripple = useRef(null);

  const toggleSpinner = useCallback(
    (loading) => {

      if (ripple.current) {
        if (loading) {
          ripple.current.style.display = "block";
          ripple.current.style.zIndex = 2;
        } else {
          ripple.current.style.display = "none";
          ripple.current.style.zIndex = -10;
        }
      }
    },
    [ripple]
  );

  return (
    <>
      <Box
        ref={ripple}
        sx={{
          p: "50px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress mx="auto" />
      </Box>

      <Box sx={{ position: "static" }} m="45px">
        <Grid container>
          <Grid xs={3}>
            <FormControl sx={{ width: "100%", my: "22px" }}>
              {userType === "admin" && (
                <>
                  <InputLabel
                    id="project-type-label"
                    sx={{
                      mt: "5px",

                      ...textStyles,
                    }}
                  >
                    {"Select pro to view calendar"}
                  </InputLabel>
                  <Select
                    size="small"
                    variant="standard"
                    disableUnderline
                    width="100%"
                    height="31px"
                    label="Project Type"
                    value={pro ? pro : 0}
                    onChange={handleCalendarSelect}
                    inputProps={{
                      MenuProps: {
                        sx: {
                          borderRadius: 0,
                        },
                        MenuListProps: {
                          sx: {
                            backgroundColor: "cream.main",
                            borderRadius: 0,
                          },
                        },
                      },
                    }}
                    sx={{
                      borderRadius: 0,
                      outline: "none",
                      border: 1,
                      borderColor: "blue.main",

                      pl: "4px",
                      pt: "2px",
                      ...textStyles,
                    }}
                  >
                    <MenuItem
                      sx={{ ...textStyles, backgroundColor: "cream.main" }}
                      value={0}
                      key={0}
                    >
                      <Typography variant="paragraphMono">
                        {`Select a pro`}
                      </Typography>
                    </MenuItem>
                    {pros.map((pro) => (
                      <MenuItem
                        sx={{ ...textStyles, backgroundColor: "cream.main" }}
                        value={pro}
                        key={pro.id}
                      >
                        <Typography variant="paragraphMono">
                          {`${pro.firstName} ${pro.lastName}`}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>{" "}
                </>
              )}
              {false && (
                <Box>
                  <Typography variant="paragraphMono">
                    Fetching Calendar
                  </Typography>
                  <CircularProgress />
                </Box>
              )}
            </FormControl>
          </Grid>
        </Grid>
        <Grid container>
          <Typography
            pt="20px"
            sx={{
              whiteSpace: "pre-line",
              mb: "20px",
              color: "blue.main",
            }}
            display="block"
            variant="paragraphMono"
          >
            {"Please select a calendar date to customize your schedule"}
          </Typography>
          <Grid item md={showEventDetails ? 9 : 12}>
            <Box
              display="flex"
              justifyContent="space-between"
              sx={{ fontFamily: "Code Saver" }}
            >
              {/* CALENDAR */}
              <Box flex="1 1 100%">
                <StyleWrapper>
                  <FullCalendar
                    height="800px"
                    eventStartEditable={false}
                    eventDurationEditable={false}
                    editable={true}
                    plugins={[
                      dayGridPlugin,
                      timeGridPlugin,
                      interactionPlugin,
                      listPlugin,
                    ]}
                    ref={calendarRef}
                    headerToolbar={{
                      left: "prev next today",
                      center: "title",
                      right: "dayGridMonth timeGridWeek timeGridDay listMonth",
                    }}
                    initialView="dayGridMonth"
                    eventColor="#2D457C"
                    selectable={true}
                    selectMirror={true}
                    loading={(loading) => toggleSpinner(loading)}
                    dayMaxEvents={true}
                    select={handleDateClick}
                    events={(info, successCallback, failureCallback) =>
                      retrieveCalendarEvents(
                        info,
                        successCallback,
                        failureCallback
                      )
                    }
                    eventClick={handleEventClick}
                    businessHours={businessHours}
                  />
                </StyleWrapper>
              </Box>
            </Box>
          </Grid>
          {showEventDetails && (
            <Grid item md={3}>
              <EventDetail
                calendarId={pro?.calendarId}
                proId={pro?.id}
                event={eventSelection}
                handleLoading={handleLoading}
                handleClose={handleClose}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default Calendar;
