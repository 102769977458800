import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

const ContentCard = (props) => {
  const [image, setImage] = useState();
  const [categories, setCategories] = useState();
  useEffect(() => {
    async function loadPostImage() {
      const response = await fetch(
        `https://mblegacy.wpengine.com/wp-json/wp/v2/media/${props.post?.featured_media}`,
        {
          mode: 'cors',
        }
      );
      if (!response.ok) {
        return;
      }

      const imageJson = await response.json();

      if (imageJson.media_details.sizes.medium_large) {
        const image =
          imageJson.media_details.sizes.medium_large.source_url.replace(
            'matriarchybuild.com',
            'mblegacy.wpengine.com'
          );

        setImage(image);
      } else if (imageJson.media_details.sizes[0]) {
        const image = imageJson.media_details.sizes[0].source_url.replace(
          'matriarchybuild.com',
          'mblegacy.wpengine.com'
        );

        setImage(image);
      } else {
        const image = imageJson.source_url.replace(
          'matriarchybuild.com',
          'mblegacy.wpengine.com'
        );

        setImage(image);
      }
    }

    if (props.post?.categories.length === 2) {
      setCategories('DIY, Inspiration');
    } else if (props.post?.categories[0] === 1) {
      setCategories('DIY');
    } else {
      setCategories('Inspiration');
    }
    loadPostImage();
  }, [props.post]);

  return (
    <Link to={`/diy-inspiration/${props.post?.slug}`}>
      <Stack width="100%" height="auto" sx={{ cursor: 'pointer' }}>
        <Box
          width="100%"
          height="317px"
          sx={{
            border: 1,
            borderColor: 'lightOlive.main',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundImage: `url(${image})`,
          }}
        ></Box>
        {props.hideByline && (
          <Stack
            direction="row"
            width="100%"
            height="auto"
            sx={{ border: 1, borderColor: 'lightOlive.main', borderTop: 0 }}
          >
            <Box
              height="100px"
              width="50px"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                borderRight: 1,
                borderColor: 'lightOlive.main',
                background:
                  'linear-gradient(to top left,transparent 0,transparent calc(50% - 0.8px),#BBB69B 50%,transparent calc(50% + 0.8px),transparent), linear-gradient(to bottom left,transparent 0,transparent calc(50% - 0.8px),#BBB69B 50%,transparent calc(50% + 0.8px),transparent)',
              }}
            ></Box>
            <Box
              sx={{
                whiteSpace: 'pre-line',
                mx: '13px',
                mt: '17px',
                width: '100%',
                fontSize: '20px',
                lineHeight: '25px',
              }}
            >
              <Typography
                sx={{
                  fontSize: '23px',
                  lineHeight: '25px',
                }}
                variant="paragraphSansLarge"
                color="cream.main"
                dangerouslySetInnerHTML={{ __html: props.post?.title.rendered }}
              />
            </Box>
          </Stack>
        )}

        {!props.hideByline && (
          <>
            <Box
              width="100%"
              height="114px"
              sx={{
                border: 1,
                borderTop: 0,
                borderColor: 'lightOlive.main',
                backgroundColor: props.fromDIY ? 'white' : undefined,
              }}
            >
              <Typography
                sx={{
                  whiteSpace: 'pre-line',
                  mx: '13px',
                  mt: '17px',
                }}
                display="block"
                variant="paragraphSans"
                color={props.fromDIY ? 'blue.main' : 'white'}
                dangerouslySetInnerHTML={{ __html: props.post?.title.rendered }}
              ></Typography>
            </Box>

            <Stack direction="row" width="100%" height="auto">
              <Box
                height="33px"
                width="99px"
                sx={{
                  border: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  borderTop: 0,
                  borderRight: 0,
                  borderColor: 'lightOlive.main',
                  background:
                    'linear-gradient(to top left,transparent 0,transparent calc(50% - 0.8px),#BBB69B 50%,transparent calc(50% + 0.8px),transparent)',
                }}
              ></Box>

              <Stack width="90%" height="99px">
                <Box
                  height="33px"
                  sx={{
                    border: 1,
                    borderTop: 0,
                    display: 'flex',
                    alignItems: 'center',
                    textAlign: 'left',
                    borderColor: 'lightOlive.main',
                  }}
                >
                  <Typography
                    sx={{
                      whiteSpace: 'pre-line',
                      mt: '1px',
                      ml: '7px',
                    }}
                    display="block"
                    variant="paragraphMonoSmall"
                    color={props.fromDIY ? 'olive.main' : 'pink.main'}
                  >
                    {'by Matriarchy Build'}
                  </Typography>
                </Box>
                <Box
                  height="33px"
                  sx={{
                    border: 1,
                    display: 'flex',
                    alignItems: 'center',
                    textAlign: 'left',
                    borderTop: 0,
                    borderColor: 'lightOlive.main',
                  }}
                >
                  <Typography
                    sx={{
                      whiteSpace: 'pre-line',
                      mt: '1px',
                      ml: '7px',
                    }}
                    display="block"
                    variant="paragraphMonoSmall"
                    color="blue.main"
                  >
                    {categories}
                  </Typography>
                </Box>
              </Stack>
            </Stack>
          </>
        )}
      </Stack>
    </Link>
  );
};

export default ContentCard;
