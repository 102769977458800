import { Box, CircularProgress, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2/";
import FloorTools from "../../Assets/Images/floor-tools.png";
import { useMediaQuery } from "react-responsive";
import MailingList from "../../SharedComponents/MailingList";
import Footer from "../../SharedComponents/Footer";
import Stack from "@mui/material/Stack";
import { Formik, Form,  useFormikContext } from "formik";
import Input from "@mui/material/Input";
import { ActionButton } from "../../SharedComponents/Buttons";
import {
  addDoc,
  collection,
  getFirestore,
  onSnapshot,
  doc,
  getDoc
} from "firebase/firestore";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import { useSearchParams, createSearchParams, useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

const commonStyles = {
  pl: "4px",
  pt: "4px",
  mb: "12px",
  fontFamily: "Code Saver",
  fontStyle: "normal",
  fontWeight: "normal",
  fontSize: "16px",
  lineHeight: "145%",
  color: "blue.main",
  border: 1,
  borderColor: "lightOlive.main",
};

const SetInitialValues = (props) => {

  const db = getFirestore();
  
  const { setValues } = useFormikContext();

 useEffect(() => {
    const getPendingGiftCard = async (id) => {
      const docRef = doc(db, "pendingGiftCards", id);
      const docSnap = await getDoc(docRef);
      
      const giftCardData = docSnap.data();

      setValues(giftCardData);
    };

    if (props.id) {
      getPendingGiftCard(props.id);

    }
  }, [props.id]);
}

const GiftCardPage = () => {
  const [submitting, setSubmitting] = useState(false);
  const isDesktop = useMediaQuery({ minWidth: 1201 });
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })

  const [selectedAmount, setSelectedAmount] = useState("Choose an amount");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState ("");

  const amounts = [
    { label: "Choose an amount", amount: 0 },
    { label: "$50.00", amount: 5000 },
    { label: "$75.00", amount: 7500 },
    { label: "$100.00", amount: 10000 },
    { label: "$150.00", amount: 15000 },
    { label: "$200.00", amount: 20000 },
    { label: "$500.00", amount: 50000 },
  ];


  const db = getFirestore();

  const { id } = useParams ();

  const [initialValues, setInitialValues] = useState({
    amount: "Choose an amount",
    yourName: "",
    recipientEmail: "",
    senderEmail: "",
    message: "",
  });



  const startOrderListener = async (id) => {
    const unsub = onSnapshot(
      doc(db, `customers/gift-card-customer/checkout_sessions/${id}`),
      (doc) => {
  
        if (doc.data().url) {
         
          window.location.href = doc.data().url;
          setLoading(false);
        } else if (doc.data().error) {
          setLoading(false)
          setError (`${id}`)
          setLoading(false)
        }
      }
    );
  };

  const placeOrder = async (values) => {

     const cardDocRef = await addDoc(collection(db, `pendingGiftCards`), values);

     window.history.replaceState( {} , `/gift-card/${cardDocRef.id}`, `/gift-card/${cardDocRef.id}` );
     var stripeAmount = 0;
     if (values.amount === "$50.00") {
      stripeAmount = 5000;
     } else if (values.amount === "$75.00") {
      stripeAmount = 7500;
     } 
     else if (values.amount === "$100.00") {
      stripeAmount = 10000;
     } else if (values.amount === "$150.00") {
      stripeAmount = 15000;
     } else if (values.amount === "$200.00") {
      stripeAmount = 20000;
     } else if (values.amount === "$500.00") {
      stripeAmount = 50000;
     } 
     const docRef = await addDoc(
      collection(db, `customers/gift-card-customer/checkout_sessions`),
      {
        mode: "payment",
        customer_email: "giftcard@matriarchybuild.com",
        payment_status: "unpaid",
        line_items: [
          {
            name: "Matriarchy Build Gift Card",
            amount: stripeAmount,
          
            currency: "USD",
            quantity: 1,
          },
        ],
      
        success_url: `${window.location.origin}/gift-card-receipt/${cardDocRef.id}`,
        cancel_url: `${window.location.origin}/gift-card/${cardDocRef.id}`,
      }
    );
    startOrderListener(docRef.id);

  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          minHeight: "800px",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "top",
          backgroundImage: `url(${FloorTools})`,
          backgroundColor: "cream.main",
        }}
      >
        <Grid
          container
          width="100%"
          maxWidth="1440px"
          mx={ isPortrait ? "20px" : "44px"}
          mt="115px"
          mb="122px"
        >
          {isDesktop && (
            <Grid
              lg={1}
              width="100%"
              height="191px"
              sx={{
                border: 1,
                borderRight: 0,
                color: "pink.main",
                background:
                  "linear-gradient(to top left,transparent 0,transparent calc(50% - 0.8px),#f7cbad 50%,transparent calc(50% + 0.8px),transparent),linear-gradient(to top right,transparent 0,transparent calc(50% - 0.8px),#f7cbad 50%,transparent calc(50% + 0.8px),transparent)",
              }}
            ></Grid>
          )}
          <Grid
            item
            xs={12}
            lg={3}
            width="100%"
            height="191px"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "white",
              border: 1,

              color: "pink.main",
            }}
          >
            <Typography
              sx={{
                whiteSpace: "pre-line",
                textAlign: "center",
                my: "63px",
                pt: "10px",
                color: "olive.main",
              }}
              display="block"
              variant="h1"
            >
              {"Gift Cards"}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            lg={8}
            width="100%"
            height="auto"
            sx={{
              position: "relative",
              zIndex: 1,
              ml: "-1px",
              display: "flex",
              justifyContent: "center",
              backgroundColor: "white",
              border: 1,
              mt: "85px",
              color: "pink.main",
            }}
          >
            <Stack width="100%" mx= {isPortrait ? "20px": "44px"}>
              <Typography
                sx={{
                  whiteSpace: "pre-line",
                  my: "40px",

                  color: "lightOlive.main",
                }}
                display="block"
                variant="paragraphSansLarge"
              >
                {"Buy a Matriarchy Build gift card"}
              </Typography>
              {loading ? (
                <CircularProgress />
              ) : (
                <Formik
                  initialValues={{...initialValues}}
                  validate={(values) => {
           
                    const errors = {};
                    if (!values.amount) {
                      errors.amount = "Required";
                    } else if (values.amount === "Choose an amount") {
                      errors.amount = "Required";
                    }
                    if (!values.yourName) {
                      errors.yourName = "Required";
                    }
                    if (!values.recipientEmail) {
                      errors.recipientEmail = "Required";
                    } else if (
                      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                        values.recipientEmail
                      )
                    ) {
                      errors.recipientEmail = "Invalid email address";
                    }
                    if (!values.senderEmail) {
                      errors.senderEmail = "Required";
                    } else if (
                      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                        values.senderEmail
                      )
                    ) {
                      errors.senderEmail = "Invalid email address";
                    }
                    console.log(errors);
                    return errors;
                  }}
                  onSubmit={(values) => {
                    setSubmitting(true);
                    placeOrder(values);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <Grid item xs={12} md={4}>
                        <FormControl sx={{ width: "100%" }}>
                          <Typography
                            sx={{
                              whiteSpace: "pre-line",
                              my: "3px",
                              color: "blue.main",
                            }}
                            display="block"
                            variant="paragraphSans"
                          >
                            {"Amount"}
                          </Typography>
                          <Select
                            size="small"
                            variant="standard"
                            name="amount"
                            value={values.amount}
                            onChange={handleChange}
                            disableUnderline={true}
                            width="100%"
                            height="31px"
                            sx={{
                              ...commonStyles,
                            }}
                          >
                            {amounts.map((amount) => (
                              <MenuItem
                                key={amount.amount}
                                value={amount.label}
                              >
                                <Typography
                                  sx={{
                                    whiteSpace: "pre-line",
                                    my: "3px",
                                    color: "blue.main",
                                  }}
                                  display="block"
                                  variant="paragraphMono"
                                >
                                  {amount.label}
                                </Typography>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <Box>
                          {errors.amount && touched.amount && errors.amount}
                        </Box>
                      </Grid>
                      <Typography variant="paragraphSans" color="blue.main">
                        Recipient Email
                      </Typography>
                      <Input
                        type="email"
                        name="recipientEmail"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.recipientEmail}
                        disabled={submitting}
                        required
                        disableUnderline
                        fullWidth
                        height="40px"
                        placeholder="Please enter the recipent's email"
                        sx={{
                          ...commonStyles,
                        }}
                      ></Input>
                      <Box>
                        {errors.recipientEmail &&
                          touched.recipientEmail &&
                          errors.recipientEmail}
                      </Box>
                      <Typography variant="paragraphSans" color="blue.main">
                        Your Email
                      </Typography>
                      <Input
                        type="email"
                        name="senderEmail"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.senderEmail}
                        disabled={submitting}
                        disableUnderline
                        fullWidth
                        required
                        height="40px"
                        placeholder="Please enter your email to receive your reciept"
                        sx={{
                          ...commonStyles,
                        }}
                      ></Input>
                      <Box>
                        {errors.senderEmail &&
                          touched.senderEmail &&
                          errors.senderEmail}
                      </Box>
                      <Typography variant="paragraphSans" color="blue.main">
                        From
                      </Typography>
                      <Input
                        type="text"
                        name="yourName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.yourName}
                        disabled={submitting}
                        disableUnderline
                        fullWidth
                        height="40px"
                        placeholder="Your Name"
                        sx={{
                          ...commonStyles,
                        }}
                      ></Input>
                      <Box>
                        {errors.lastName && touched.lastName && errors.lastName}
                      </Box>

                      <Typography variant="paragraphSans" color="blue.main">
                        Message (optional)
                      </Typography>
                      <Input
                        type="text"
                        name="message"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.message}
                        disabled={submitting}
                        disableUnderline
                        fullWidth
                        multiline
                        required
                        minRows={4}
                        maxRows={4}
                        height="40px"
                        placeholder="Write your recipient a note"
                        sx={{
                          ...commonStyles,
                        }}
                      ></Input>
                      <Box>
                        {errors.message && touched.message && errors.message}
                      </Box>

                      {submitting && (
                        <Box
                          width="100%"
                          sx={{
                            display: "flex",
                            mt: "40px",
                            justifyContent: "center",
                          }}
                        >
                          <CircularProgress />
                        </Box>
                      )}

                      <Grid container py="44px">
                        <Grid item xs={12} md={4} mdOffset={8}>
                          {!submitting && (
                            <ActionButton title="Checkout" type="submit" />
                          )}
                        </Grid>
                        {error && 
                      <Typography variant="paragraphSans" color="blue.main">
                       {`Something went wrong! order number: ${error}`}
                      </Typography>}
                      </Grid>
                      <SetInitialValues id={id} />
                    </Form>
                  )}

                </Formik>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <MailingList />
      <Footer />
    </Box>
  );
};

export default GiftCardPage;
