import { useMediaQuery } from 'react-responsive';

import { addDoc, collection, getFirestore } from 'firebase/firestore';
import { Box, Grid, Stack, Typography, CircularProgress } from '@mui/material';

import Mariel from '../../Assets/Images/mariel-herring-profile.jpg';
import Check from '../../Assets/Images/check.svg';
import Input from '@mui/material/Input';

import LocalServicesFAQs from '../../SharedComponents/FAQ/Components/LocalServicesFAQs';
import { ActionButton } from '../../SharedComponents/Buttons';
import Footer from '../../SharedComponents/Footer';
import MailingList from '../../SharedComponents/MailingList';
import ArrowPink from '../../Assets/Images/arrow-pink.svg';
import { Formik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { functions } from '../../App';
import { httpsCallable } from 'firebase/functions';
import { localServicesResponseHtml } from '../../Assets/EmailAssets/response-email';
import { getAnalytics, logEvent } from 'firebase/analytics';
import Testimonials from '../../SharedComponents/Testimonials';

const commonStyles = {
  pl: '4px',
  pt: '4px',
  mb: '22px',
  fontFamily: 'Code Saver',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '16px',
  lineHeight: '145%',
  color: 'blue.main',
  border: 1,
  borderColor: 'lightOlive.main',
};

const desktopImage = {
  backgroundImage: `url(${Mariel})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  padding: '5rem',
  height: '525px',
};

const errorStyles = {
  color: 'olive.main',
  height: '0px',
  overflow: 'visible',
  display: 'block',
  position: 'relative',
  top: '-20px',
};

const AustinServices = () => {
  const isDesktop = useMediaQuery({ minWidth: 1201 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [submitting, setSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const navigate = useNavigate();

  const db = getFirestore();
  const analytics = getAnalytics();

  const commonProps = {
    width: '100%',
    spacing: 0,
    container: true,
    maxWidth: '72.6rem',
    margin: 'auto',
  };

  const sendEmail = async (values) => {
    const html = localServicesResponseHtml(values);
    await addDoc(collection(db, 'mail'), {
      to: ['info@matriarchybuild.com'],
      message: {
        subject: 'Austin Services: Form Submission!',
        html: html,
      },
    });
  };

  const submitForm = async (values) => {
    if (!values.email) {
      throw new Error('Form requires an email');
    }

    logEvent(analytics, 'form-submit', {
      form_name: 'austin-services',
      form_email: values.email,
    });

    // sign up for klayvio email list
    const addEmail = httpsCallable(functions, 'addEmailToList');
    await addEmail({
      email: values.email,
      list: 'VHswR7', //austin sign up list
    });
    await sendEmail(values);
    setSubmitSuccess(true);
    setSubmitting(false);
  };

  const navigateTo = (url) => () => navigate(url);

  const visit =
    (url, newTab = false) =>
    () => {
      if (newTab) {
        window.open(url, '_blank');
      } else {
        window.location.assign(url);
      }
    };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          ...(!isMobile && { ...desktopImage }),
        }}
      >
        <Grid {...commonProps} px={isMobile ? '0px' : '44px'}>
          {isMobile && (
            <Grid item xs={12}>
              <Box
                sx={{
                  maxHeight: '450px',
                  overflow: 'hidden',
                }}
              >
                <img src={Mariel} width="100%" />
              </Box>
            </Grid>
          )}
          <Grid item xs={12} my="auto">
            <Box
              sx={{
                backgroundColor: 'white',
                textAlign: 'center',
                padding: !isMobile ? '4rem 3.5rem' : '40px 20px',
                height: 'fit-content',
                width: 'fit-content',
                maxWidth: '71rem',
                margin: 'auto',
              }}
            >
              <Stack alignItems="center">
                <Typography
                  variant="section_h1"
                  color="blue.main"
                  fontSize="40px"
                  width="fit-content"
                >
                  Now Serving Greater Austin
                </Typography>
                <Typography
                  variant="section_h2"
                  color="olive.main"
                  fontSize="24px"
                  width="fit-content"
                  marginTop="1rem"
                >
                  Handyperson Services + Pro Referral Network
                </Typography>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box backgroundColor="pink.main">
        <Grid {...commonProps}>
          <Stack
            sx={{
              color: 'blue.main',
              paddingTop: '40px',
              paddingBottom: '52px',
              textAlign: 'center',
              paddingX: '5rem',
            }}
          >
            <Grid container spacing="25px">
              <Grid item xs={12}>
                <Typography variant="section_h1" color="blue.main">
                  How it Works
                </Typography>
              </Grid>
              <Grid item xs={12} lineHeight="33px">
                <Grid container spacing="20px">
                  <Grid item xs={12}>
                    <Typography variant="section_h3" fontSize="20px">
                      Matriarchy Build offers Handyperson services to the
                      Greater Austin area. We also offer more robust Membership
                      Packages for folks looking for recurring services,
                      additional support, and access to our Pro Referral
                      network.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="section_h3"
                      sx={{
                        fontStyle: 'italic',
                        fontSize: '20px',
                      }}
                    >
                      *Not in Austin? You can fill out the form below to let us
                      know where to come next.*
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Stack>
        </Grid>
      </Box>
      <Box backgroundColor="white">
        <Grid
          {...commonProps}
          sx={{
            maxWidth: '72.6rem',
            margin: '0 auto',
          }}
          py="36px"
          spacing={2}
        >
          <Grid container px="20px">
            <Typography
              variant="section_h2"
              alignItems="center"
              color="blue.main"
            >
              How can we help? Share a description of your project along with
              your address and we'll get you scheduled.
            </Typography>
          </Grid>
          {submitSuccess && (
            <Grid container p="20px">
              <img src={Check} style={{ paddingRight: '5px' }} />

              <Typography variant="paragraphMono">
                Thanks for contacting us. We'll get back to you as soon as
                possible.
              </Typography>
            </Grid>
          )}
          <Grid container p="20px">
            <Formik
              validateOnBlur={false}
              validateOnChange={false}
              initialValues={{
                name: '',
                email: '',
                address: '',
                zipcode: '',
                comment: '',
                project: '',
              }}
              validate={(values) => {
                const errors = {};
                if (!values.name) {
                  errors.name = 'Required';
                }
                if (!values.email) {
                  errors.email = 'Required';
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = 'Invalid email address';
                }
                if (!values.address) {
                  errors.address = 'Required';
                }
                if (!values.zipcode) {
                  errors.zipcode = 'Required';
                } else if (!/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(values.zipcode)) {
                  errors.zipcode = 'Invalid Zipcode';
                }
                if (!values.comment) {
                  errors.comment = 'Required';
                }
                if (!values.project) {
                  errors.project = 'Required';
                }

                return errors;
              }}
              onSubmit={(values) => {
                setSubmitting(true);
                submitForm(values);
              }}
            >
              {({
                values,
                errors,
                isSubmitting,
                isValidating,
                handleChange,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Input
                        type="text"
                        name="name"
                        onChange={handleChange}
                        value={values.name}
                        disableUnderline
                        disabled={isSubmitting}
                        height="40px"
                        placeholder="Name"
                        fullWidth
                        sx={{
                          ...commonStyles,
                          '& .MuiInput-input': {
                            fontFamily: 'Code Saver',
                          },
                        }}
                      ></Input>
                      <Typography
                        variant="paragraphMonoSmall"
                        sx={{
                          ...errorStyles,
                        }}
                      >
                        {errors.name && errors.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Input
                        type="email"
                        name="email"
                        onChange={handleChange}
                        value={values.email}
                        disableUnderline
                        disabled={isSubmitting}
                        height="40px"
                        placeholder="Email"
                        fullWidth
                        sx={{
                          ...commonStyles,
                          '& .MuiInput-input': {
                            fontFamily: 'Code Saver',
                          },
                        }}
                      ></Input>
                      <Typography
                        variant="paragraphMonoSmall"
                        sx={{
                          ...errorStyles,
                        }}
                      >
                        {errors.email && errors.email}
                      </Typography>
                    </Grid>
                    <Grid item xs={isDesktop ? 10 : 8}>
                      <Input
                        type="text"
                        name="address"
                        onChange={handleChange}
                        value={values.address}
                        disableUnderline
                        disabled={isSubmitting}
                        fullWidth
                        height="40px"
                        placeholder="Address"
                        sx={{
                          ...commonStyles,
                          '& .MuiInput-input': {
                            fontFamily: 'Code Saver',
                          },
                        }}
                      ></Input>
                      <Typography
                        variant="paragraphMonoSmall"
                        sx={{
                          ...errorStyles,
                        }}
                      >
                        {errors.address && errors.address}
                      </Typography>
                    </Grid>
                    <Grid item xs={isDesktop ? 2 : 4}>
                      <Input
                        type="text"
                        name="zipcode"
                        onChange={handleChange}
                        value={values.zipcode}
                        disableUnderline
                        disabled={isSubmitting}
                        fullWidth
                        height="40px"
                        placeholder="Zip Code"
                        sx={{
                          ...commonStyles,
                          '& .MuiInput-input': {
                            fontFamily: 'Code Saver',
                          },
                        }}
                      ></Input>
                      <Typography
                        variant="paragraphMonoSmall"
                        sx={{
                          ...errorStyles,
                        }}
                      >
                        {errors.zipcode && errors.zipcode}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Input
                        type="text"
                        name="comment"
                        onChange={handleChange}
                        value={values.comment}
                        disableUnderline
                        disabled={isSubmitting}
                        fullWidth
                        height="40px"
                        multiline
                        rows="4"
                        placeholder="How did you hear about us?"
                        sx={{
                          ...commonStyles,
                          '& .MuiInput-input': {
                            fontFamily: 'Code Saver',
                          },
                        }}
                      ></Input>
                      <Typography
                        variant="paragraphMonoSmall"
                        sx={{
                          ...errorStyles,
                        }}
                      >
                        {errors.comment && errors.comment}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Input
                        type="text"
                        name="project"
                        onChange={handleChange}
                        value={values.project}
                        disableUnderline
                        disabled={isSubmitting}
                        fullWidth
                        height="40px"
                        multiline
                        rows="4"
                        placeholder="Tell us about your project."
                        sx={{
                          ...commonStyles,
                          '& .MuiInput-input': {
                            fontFamily: 'Code Saver',
                          },
                        }}
                      ></Input>
                      <Typography
                        variant="paragraphMonoSmall"
                        sx={{
                          ...errorStyles,
                        }}
                      >
                        {errors.project && errors.project}
                      </Typography>
                    </Grid>
                    {(isSubmitting || isValidating) &&
                      !submitSuccess === true && (
                        <Box
                          width="100%"
                          sx={{
                            display: 'flex',
                            mt: '40px',
                            justifyContent: 'center',
                          }}
                        >
                          <CircularProgress />
                        </Box>
                      )}
                    {!submitting && submitSuccess !== true && (
                      <ActionButton
                        title="Send"
                        type="submit"
                        sx={{ marginLeft: '8px' }}
                      />
                    )}
                  </Grid>
                </form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Box>
      <Box backgroundColor="cream.main">
        <Grid {...commonProps}>
          <Stack
            sx={{
              paddingTop: '40px',
              paddingBottom: '52px',
              maxWidth: '72rem',
              margin: 'auto',
              textAlign: 'center',
              paddingX: isDesktop ? '5rem' : '40px',
            }}
          >
            <Grid container spacing="15px">
              <Grid item xs={12}>
                <Typography variant="section_h1" color="olive.main">
                  Details + FAQs
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <LocalServicesFAQs />
              </Grid>
            </Grid>
          </Stack>
        </Grid>
      </Box>
      <Box
        sx={{
          backgroundColor: 'pink.main',
          py: '36px',
          px: isDesktop ? '5rem' : '20px',
        }}
      >
        <Grid
          {...commonProps}
          px={isMobile ? '20px' : '36px'}
          justifyContent="center"
        >
          <Grid
            item
            xs={12}
            container
            sx={{ paddingBottom: '3rem', justifyContent: 'space-between' }}
          >
            <Grid item xs="auto" sx={{ flexShrink: 1 }}>
              <Typography
                variant="section_h1"
                color="blue.main"
                sx={{ maxWidth: '100%' }}
              >
                Membership Packages
              </Typography>
            </Grid>
            {!isDesktop && (
              <Grid
                item
                xs="auto"
                sx={{
                  alignItems: 'flex-end',
                  margin: '0',
                  display: 'flex',
                  textDecoration: 'underline',
                  color: 'blue.main',
                  cursor: 'pointer',
                }}
              >
                <Typography
                  variant="section_h3"
                  onClick={navigateTo('/membership-details')}
                >
                  Full Membership Details
                </Typography>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} container spacing={2}>
            <Grid item xs={12} md={12} lg={6}>
              <Box sx={{ height: '100%', backgroundColor: 'white' }}>
                <Stack
                  spacing={2}
                  sx={{
                    alignItems: 'center',
                    textAlign: 'center',
                    py: '2.5rem',
                    px: '15px',
                  }}
                >
                  <Typography variant="paragraphSansLarge" color="blue.main">
                    Basic Membership
                  </Typography>

                  <ul
                    style={{ listStylePosition: 'inside', paddingLeft: '0px' }}
                  >
                    <li>
                      <Typography variant="paragraphSans" color="blue.main">
                        Handyperson Services for $100/hr
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="paragraphSans" color="blue.main">
                        Home Health Report for $250
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="paragraphSans" color="blue.main">
                        Unlimited Access to Pro Referral Network
                      </Typography>
                    </li>
                  </ul>
                  <Typography
                    variant="paragraphMono"
                    color="blue.main"
                    onClick={visit(
                      'https://buy.stripe.com/7sI7t90cEeFs62Y001',
                      true
                    )}
                  >
                    Get Started $100/year <img src={ArrowPink} />
                  </Typography>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <Box sx={{ height: '100%', backgroundColor: 'white' }}>
                <Stack
                  spacing={2}
                  sx={{
                    alignItems: 'center',
                    textAlign: 'center',
                    py: '2.5rem',
                    px: '15px',
                  }}
                >
                  <Typography variant="paragraphSansLarge" color="blue.main">
                    Home Health Membership
                  </Typography>
                  <ul
                    style={{ listStylePosition: 'inside', paddingLeft: '0px' }}
                  >
                    <li>
                      <Typography variant="paragraphSans" color="blue.main">
                        Handyperson Services for $100/hr
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="paragraphSans" color="blue.main">
                        Matriarchy Build Home Health Report
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="paragraphSans" color="blue.main">
                        1 Hour Consultation w/ MB General Contractor to Review
                        Home Health Report
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="paragraphSans" color="blue.main">
                        Unlimited Access to Pro Referral Network
                      </Typography>
                    </li>
                  </ul>
                  <Typography
                    variant="paragraphMono"
                    color="blue.main"
                    onClick={visit(
                      'https://buy.stripe.com/00gbJpaRi68W3UQ28l',
                      true
                    )}
                  >
                    Get Started $500/year <img src={ArrowPink} />
                  </Typography>
                </Stack>
              </Box>
            </Grid>
          </Grid>
          {isDesktop && (
            <Grid
              item
              xs="auto"
              sx={{ marginTop: '4rem', alignItems: 'center' }}
            >
              <ActionButton
                onClick={navigateTo('/membership-details')}
                title="Full Membership Details"
                sx={{ width: '340px' }}
              />
            </Grid>
          )}
        </Grid>
      </Box>
      <Testimonials isLocalServices />
      <MailingList />
      <Footer />
    </>
  );
};

export default AustinServices;
