import ReactDOM from "react-dom";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useState, useMemo } from "react";
import { Box, useTheme } from "@mui/material";
import { useEffect } from "react";
import Typography from "@mui/material/Typography";
import Footer from "../../SharedComponents/Footer";
import MailingList from "../../SharedComponents/MailingList";
//import "./blog.min.css";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import "./css/wp.min.css";
import "./css/diy.css";
import PostHeader from "./PostHeader";
import ContentCard from "../../SharedComponents/ContentGrid/Components/ContentCard";
import { useMediaQuery } from "react-responsive";
const DIYAndInspiration = () => {
  const isMedium = useMediaQuery({ minWidth: 901 });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const [posts, setPosts] = useState();
  const [hero, setHero] = useState();
  const [html, setHTML] = useState();
  const [categories, setCategories] = useState();
  const db = getFirestore();

  useEffect(() => {
    async function loadHero() {
      const docRef = doc(db, "blog", "heroPost");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
      const response = await fetch(
        `https://mblegacy.wpengine.com/wp-json/wp/v2/posts?slug=${
          docSnap.data().slug
        }`
      );
      if (!response.ok) {
        // oops! something went wrong
        return;
      }

      const hero = await response.json();

      setHero(hero[0]);
    }
    async function loadPosts() {
      const response = await fetch(
        "https://mblegacy.wpengine.com/wp-json/wp/v2/posts?per_page=100",
        {
          mode: "cors",
        }
      );
      if (!response.ok) {
        // oups! something went wrong
        return;
      }

      const posts = await response.json();

      setPosts(posts);
    }
    async function loadCategories() {
      const response = await fetch(
        "https://mblegacy.wpengine.com/wp-json/wp/v2/categories?per_page=100"
      );
      if (!response.ok) {
        // oups! something went wrong
        return;
      }

      const categories = await response.json();

      setCategories(categories);
    }

    loadHero();
    loadPosts();
    loadCategories();
  }, []);

  return (
    <>
      <Box
        width="100%"
        sx={{ position: "relative", zIndex: 0, backgroundColor: "cream.main" }}
      >
        <PostHeader post={hero} isHero={true} />
        <Box px={isPortrait ? "20px" : "44px"} mx="auto" maxWidth="1440px">
          <Grid pt={isPortrait ? "400px" : "100px"} container spacing={3}>
            {posts?.map((post, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <ContentCard post={post} fromDIY={true} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
      <MailingList />
      <Footer />
    </>
  );
};

export default DIYAndInspiration;
