import { Box, useTheme } from "@mui/material";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import { blueBox } from "../../../../theme";
import { useMediaQuery } from "react-responsive";
import HowItWorksStaticCard from "./Components/HowItWorksStaticCard";
import React from "react";
import HowItWorksStaticCardMobile from "./Components/HowItWorksCardStaticMobile";
import ArrowOlive from "../../../../Assets/Images/arrow-olive.svg";
import { Link } from "react-router-dom";

const HowItWorksHero = () => {
  const isDesktop = useMediaQuery({ minWidth: 1201 });
  const isMedium = useMediaQuery({ minWidth: 901 });
  const isPortrait = useMediaQuery({ orientation: 'portrait' })

  const titleStep1 = "Search";
  const subtitleStep1 =
    "Sort our directory of Pros by trade or project type. We’re focused on connecting you to Pros nationally who can help you whether you're looking for guidance while tackling a DIY or want expert advice before kicking off a larger remodel.";
  const titleStep2 = "Book";
  const subtitleStep2 =
    "Pros on our site set their own schedules and determine their own rates. Find a Pro who meets your timeline and budget, and book a 1:1 consultation. We strongly recommend completing your pre-consultation questionnaire and submitting all relevant photos to your Pro prior to your session with ample time for them to review. Preparation in advance will set you up for a more thorough and efficient session.";
  const titleStep3 = "Talk";
  const subtitleStep3 =
    "The time with your Pro is yours — use it however (or in whatever way) best meets your needs. Depending on your project type, measurements, tools, drawings or images might be useful to review. It’s always helpful to have access to the area or project you are working on from your video device during the consultation. Be sure to have notetaking material handy. Most of all, ensure you have a strong internet signal for your video consultation.";
  const titleStep4 = "Build";
  const subtitleStep4 =
    "Some sessions will largely be information sharing and guidance. Other sessions might be the first step in tackling a larger project. If you run out of time with your Pro and have a few lingering questions, revisit their calendar and book a follow-up session. When your project is complete and you’re onto the next one, circle back to the directory and find a Pro who meets your needs.";

  const textStep1 =
    "Search our directory of Pros based on the type of project you're looking to tackle.";
  const textStep2 =
    "Book a 1:1 consultation with a Pro. Choose a date on their calendar based on your timeline and budget.";
  const textStep3 =
    "Enjoy your 1:1 video consultation with a Pro to learn how to take on your project with confidence.";
  const textStep4 =
    "Start tackling your project with confidence. Book follow-up sessions as necessary.";

  return (
    <React.Fragment>
      <Box
        height="auto"
        width="100%"
        sx={{
          backgroundColor: "cream.main",
          px: isPortrait ? "20px" : "44px",
          pt: "75px",
          pb: "75px",
        }}
      >
        <Box
          width="100%"
          height="auto"
    
          maxWidth="1440px"
          sx={{ margin: "auto" }}
        >
          <Grid container>
            <Grid item xs={12} xsOffset={0} sm={6} smOffset={6} lg={2} lgOffset={0}>
              <Stack  direction="row" component="div">
                <Box
                  width="100%"
                  height="106px"
                  color="blue.main"
                  sx={{
                    border: 1,
                    borderBottom: isDesktop ? 1 : 0,
                    background:
                      "linear-gradient(to top right,transparent calc(50% - 1px),#2d457c,transparent calc(50% + 1px))",
                  }}
                ></Box>
                <Box
                  width="100%"
                  height="106px"
                  color="blue.main"
                  sx={{
                    ...blueBox,
                    pt: "6px",
                    borderLeft: 0,
                    borderBottom: isDesktop ? 1 : 0,
                    borderRight: isDesktop ? 0 : 1,
                  }}
                >
                  <Typography
                    sx={{
                      whiteSpace: "pre-line",
                      textColor: "blue.main",
                      m: isPortrait ? "20px" : undefined
                    }}
                    display="block"
                    variant="h2"
                  >
                    {"How it\n works"}
                  </Typography>
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={12} md={12} lg={10}>
              {isMedium ? (
                <HowItWorksStaticCard
                  step={1}
                  title="Search"
                  subtitle={subtitleStep1}
                  text={textStep1}
                />
              ) : (
                <HowItWorksStaticCardMobile
                  step={1}
                  title="Search"
                  subtitle={subtitleStep1}
                  text={textStep1}
                />
              )}
            </Grid>
            <Grid item xs={12} md={12} lgOffset={2} lg={10}>
              {isMedium ? (
                <HowItWorksStaticCard
                  step={2}
                  title="Book"
                  subtitle={subtitleStep2}
                  text={textStep2}
                />
              ) : (
                <HowItWorksStaticCardMobile
                  step={2}
                  title="Book"
                  subtitle={subtitleStep2}
                  text={textStep2}
                />
              )}
            </Grid>
            <Grid item xs={12} lgOffset={2} lg={10}>
              {isMedium ? (
                <HowItWorksStaticCard
                  step={3}
                  title="Talk"
                  subtitle={subtitleStep3}
                  text={textStep3}
                />
              ) : (
                <HowItWorksStaticCardMobile
                  step={3}
                  title="Talk"
                  subtitle={subtitleStep3}
                  text={textStep3}
                />
              )}
            </Grid>
            <Grid item xs={12} lgOffset={2} lg={10}>
              {isMedium ? (
                <HowItWorksStaticCard
                  step={4}
                  title="Build"
                  subtitle={subtitleStep4}
                  text={textStep4}
                />
              ) : (
                <HowItWorksStaticCardMobile
                  step={4}
                  title="Build"
                  subtitle={subtitleStep4}
                  text={textStep4}
                />
              )}
            </Grid>
            <Grid xs={isPortrait ? 12 : 10} xsOffset={isPortrait ? 0 : 2}>
              <Link to="/pro">
                <Box
                  height="58px"
                  width="100%"
                  sx={{
                    backgroundColor: "pink.main",
                    ...blueBox,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "&:hover": {
                      backgroundColor: "lightOlive.main",
                    },
                  }}
                >
                  <Stack
                    width="100%"
                    direction="row"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      pt: "4px",
                      textAlign: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        whiteSpace: "pre-line",
                        mr: "7px",
                      }}
                      display="block"
                      variant="paragraphMono"
                      color="blue.main"
                    >
                      {"Find a Pro"}
                    </Typography>
                    <img src={ArrowOlive} />
                  </Stack>
                </Box>
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default HowItWorksHero;
