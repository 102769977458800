import { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import ArrowPink from "../../../Assets/Images/arrow-pink.svg";
import { getAuth, signOut } from "firebase/auth";
import { UserContext } from "../../../user-context";
import { getFirestore, getDoc, doc } from "firebase/firestore";

const SideBarLink = (props) => {
  return (
    <Link to={props.linkTo} state={{ test: "test" }}>
      <Stack
        direction="row"
        sx={{
          pt: "4px",
          textAlign: "center",
        }}
      >
        <Typography
          sx={{
            whiteSpace: "pre-line",
            color: "blue.main",
            mr: "5px",
            "&:hover": {
              color: "lightOlive.main",
            },
          }}
          variant="paragraphMono"
        >
          {props.title}
        </Typography>

        <img src={ArrowPink} />
      </Stack>
    </Link>
  );
};

const SideBar = (props) => {
  const isDesktop = useMediaQuery({ minWidth: 1201 });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const user = useContext(UserContext);
  const [userType, setUserType] = useState("user");
  const navigate = useNavigate();
  const db = getFirestore();

  const setUserAttributes = async (id) => {
    if (user.loggedIn == true) {
      const proRef = doc(db, `pros/${id}/`);
      const docSnap = await getDoc(proRef);

      if (docSnap.exists()) {
        const proData = docSnap.data();

        if (proData?.verified == true) {
          setUserType("pro");
        } else {
          setUserType("unverifiedPro");
        }
      } else {
        user.user
          .getIdTokenResult()
          .then((idTokenResult) => {
            setUserType(idTokenResult.claims.userType);
            if (idTokenResult.claims.userType === "admin") {
              setUserType("admin");
            } else {
              setUserType("user");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  useEffect(() => {
    if (user.loggedIn) {
      setUserAttributes(user.user.uid);
    }
  }, [user]);

  const logOut = () => {
    const auth = getAuth();

    signOut(auth)
      .then(() => {
        navigate("/");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Box
      width="100%"
      height="100%"
      sx={{
        backgroundColor: "cream.main",
        pl: "46px",
        pr: isDesktop ? "27px" : undefined,
        py: "66px",
      
      }}
    >
      {userType != "unverifiedPro" && (
        <Box>
          <Typography
            sx={{
              whiteSpace: "pre-line",
              color: "olive.main",
            }}
            display="inline"
            variant="paragraphMono"
          >
            {"CONSULTATIONS"}
          </Typography>
          {(userType === "pro" || userType === "admin") && (
            <SideBarLink linkTo="/my-account/calendar" title="Calendar" />
          )}

          <SideBarLink
            linkTo="/my-account/upcoming-consultations/"
            title="Upcoming"
            state={{ type: userType }}
          />

          <SideBarLink
            linkTo="/my-account/past-consultations/"
            title="Past"
            state={{ type: userType }}
          />
          {userType === "admin" && (
            <SideBarLink
              linkTo="/my-account/manage-consultations/"
              title="Manage"
              state={{ type: userType }}
            />
          )}
          <Box
            sx={{
              my: "11px",
              borderBottom: 1,
              color: "lightOlive.main",
            }}
          ></Box>
        </Box>
      )}

      <Box>
        <Typography
          sx={{
            whiteSpace: "pre-line",
            color: "olive.main",
          }}
          display="inline"
          variant="paragraphMono"
        >
          {"SETTINGS"}
        </Typography>
        {(userType === "user" || userType === "admin") && (
          <SideBarLink
            linkTo="/my-account/edit-account/"
            title="Account Details"
          />
        )}
        {(userType === "pro" ||
          userType === "admin" ||
          userType === "unverifiedPro") && (
          <SideBarLink linkTo="/my-account/schedule" title="Set Schedule" />
        )}
        {(userType === "admin" ||
          userType === "pro" ||
          userType === "unverifiedPro") && (
          <SideBarLink linkTo="/my-account/pricing" title="Set Pricing" />
        )}
        {userType !== "user" && (
          <Box
            sx={{
              my: "11px",
              borderBottom: 1,
              color: "lightOlive.main",
            }}
          ></Box>
        )}
      </Box>

      {userType !== "user" && (
        <Typography
          sx={{
            whiteSpace: "pre-line",
            color: "olive.main",
          }}
          display="inline"
          variant="paragraphMono"
        >
          {"MANAGE"}
        </Typography>
      )}
      {userType === "admin" && (
        <SideBarLink linkTo="/my-account/users/" title="Users" />
      )}

      {(userType === "pro" || userType === "unverifiedPro") && (
        <SideBarLink
          linkTo={`/my-account/pros/${user.user.uid}`}
          title="My Profile"
        />
      )}
      {userType === "admin" && (
        <SideBarLink linkTo="/my-account/pros/" title="Pros" />
      )}
      {userType === "admin" && (
        <SideBarLink linkTo="/my-account/gift-cards/" title="Gift Cards" />
      )}
       {userType === "admin" && (
        <SideBarLink linkTo="/my-account/promo-codes/" title="Promo Codes" />
      )}
      {userType === "admin" && (
        <SideBarLink linkTo="/my-account/testimonials/" title="Testimonials" />
      )}
      {userType === "admin" && (
        <SideBarLink linkTo="/my-account/emails/" title="Email" />
      )}

      {userType != "user" && (
        <Box
          sx={{
            my: "11px",
            borderBottom: 1,
            color: "lightOlive.main",
          }}
        ></Box>
      )}
      <Button
        disableRipple
        disableFocusRipple
        onClick={logOut}
        sx={{
          "&:hover": {
            backgroundColor: "transparent",
          },
          background: "none",
          border: "none",
          margin: 0,
          padding: 0,
          cursor: "pointer",
        }}
      >
        <Stack
          direction="row"
          sx={{
            pt: "4px",
            textAlign: "center",
          }}
        >
          <Typography
            sx={{
              whiteSpace: "pre-line",
              color: "blue.main",
              mr: "5px",
              "&:hover": {
                color: "lightOlive.main",
              },
            }}
            variant="paragraphMono"
          >
            {"Logout"}
          </Typography>

          <img src={ArrowPink} />
        </Stack>
      </Button>
    </Box>
  );
};

export default SideBar;
