import { Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import ADLogo from '../../../Assets/Images/ad-logo.png';
import DominoLogo from '../../../Assets/Images/domino-logo.svg';
import HomeBuildingLogo from '../../../Assets/Images/fine-home-building-logo.svg';
import FortuneLogo from '../../../Assets/Images/fortune-logo.svg';
import RemodelistaLogo from '../../../Assets/Images/remodelista-logo.svg';
import TexasLogo from '../../../Assets/Images/texas-monthly.png';

const FeaturedIn = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{
        width: '100%',
        height: 'auto',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundColor: 'lightOlive.main',
      }}
    >
      <Grid
        container
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        spacing={4}
        sx={{ mx: '20px', my: '60px' }}
      >
        <Grid xs={12} md={4} sm={6} lg={1}>
          <Typography
            sx={{
              whiteSpace: 'pre-line',
              textAlign: 'center',
            }}
            display="block"
            variant="paragraphMonoSmall"
            color="blue.main"
          >
            {'featured in'}
          </Typography>
        </Grid>
        <Grid
          xs={12}
          md={4}
          sm={6}
          lg="auto"
          display="flex"
          justifyContent="center"
        >
          <img width="195px" height="auto" src={DominoLogo} />
        </Grid>
        <Grid
          xs={12}
          md={4}
          sm={6}
          lg="auto"
          display="flex"
          justifyContent="center"
        >
          <img width="172px" height="auto" src={RemodelistaLogo} />
        </Grid>
        <Grid
          xs={12}
          md={4}
          sm={6}
          lg="auto"
          display="flex"
          justifyContent="center"
        >
          <img width="155px" height="auto" src={HomeBuildingLogo} />
        </Grid>
        <Grid
          xs={12}
          md={4}
          sm={6}
          lg="auto"
          display="flex"
          justifyContent="center"
        >
          <img width="113px" height="auto" src={ADLogo} />
        </Grid>
        <Grid
          xs={12}
          md={4}
          sm={6}
          lg="auto"
          display="flex"
          justifyContent="center"
        >
          <img width="100" height="auto" src={FortuneLogo} />
        </Grid>
        <Grid
          xs={12}
          md={4}
          sm={6}
          lg="auto"
          display="flex"
          justifyContent="center"
        >
          <img width="100" height="auto" src={TexasLogo} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default FeaturedIn;
