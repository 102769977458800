import { Box, useTheme } from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React from "react";
import { blueBox } from "../../../theme";
import Step1 from "../../../Assets/Images/step1.svg";
import Step2 from "../../../Assets/Images/step2.svg";
import Step3 from "../../../Assets/Images/step3.svg";
import Step4 from "../../../Assets/Images/step4.svg";
import LongArrow from "../../../Assets/Images/long-arrow.svg";

const HowItWorksCardMobile = (props) => {
  const step = props.step;

  return (
    <React.Fragment>
      <Box
        width="283px"  
        height="251px" 
        sx={{
          border: 1,
          borderRight: step === 4 ? "1" : "0",
          borderColor: "blue.main",
          backgroundColor: props.activePage === step ? props.backgroundColor : "",
          
        }}
      >
        <Stack sx={{

        }}>
          <Stack direction = "row"
            height="43px"
            width="283px"
            spacing={2}
            sx={{
              ...blueBox,
              borderTop: 0,
              borderLeft: 0,
              borderRight: step === 4 ? 0 : 1
            }}
          >
             <Typography
              sx={{
                whiteSpace: "pre-line",
                textColor: "blue.main",
        
                
              }}
              display="block"
              variant="paragraphMono"
              color="blue.main"
            >
               { step == 1 && "Step 1" }
            { step == 2 && "Step 2" }
            { step == 3 && "Step 3" }
            { step == 4 && "Step 4" }
            </Typography>
           
            <img src={LongArrow} />
          </Stack>
          <Box
            height="59px"
            width="100%"
            sx={{
              borderBottom: 1,
              borderColor: "blue.main",
            }}
          >
            <Typography
              sx={{
                whiteSpace: "pre-line",
                textColor: "blue.main",
                ml: "15px",
                mt: "16px",
                
              }}
              display="block"
              variant="h2"
              color="blue.main"
            >
              {props.title}
            </Typography>
          </Box>
        </Stack>
        <Stack direction="row">
          <Box
            height="187px"
            width="100%"
            sx={{
              borderBottom: 0,
              borderColor: "blue.main",
            }}
          >
            <Typography
              sx={{
                whiteSpace: "pre-line",
                textColor: "blue.main",
                mx: "15px",
                mt: "19px",
              }}
              display="block"
              variant="paragraphSans"
              color="blue.main"
            >
              {props.text}
            </Typography>
          </Box>
         
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default HowItWorksCardMobile;
