import { useMediaQuery } from 'react-responsive';

import { Box, Grid, Stack, Typography } from '@mui/material';

import ArrowPink from '../../../Assets/Images/arrow-pink.svg';
import { ActionButton } from '../../../SharedComponents/Buttons';

const MembershipInfos = () => {
  const isDesktop = useMediaQuery({ minWidth: 1201 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const commonProps = {
    width: '100%',
    spacing: 0,
    container: true,
    maxWidth: '72.6rem',
    margin: 'auto',
  };

  const visit =
    (url, newTab = false) =>
    () => {
      if (newTab) {
        window.open(url, '_blank');
      } else {
        window.location.assign(url);
      }
    };

  return (
    <Box
      sx={{
        backgroundColor: 'pink.main',
        py: '36px',
        px: isDesktop ? '5rem' : '20px',
      }}
    >
      <Grid
        {...commonProps}
        px={isMobile ? '20px' : '36px'}
        justifyContent="center"
      >
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={12}>
            <Box sx={{ height: '100%', backgroundColor: 'white' }}>
              <Stack
                spacing={2}
                sx={{
                  alignItems: 'center',
                  textAlign: 'center',
                  py: '2.5rem',
                }}
              >
                <Typography variant="paragraphSansLarge" color="blue.main">
                  Basic Membership
                </Typography>

                <ul style={{ listStyle: 'none', paddingLeft: '0px' }}>
                  <li style={{ paddingBottom: '15px' }}>
                    <Typography variant="paragraphMono" color="blue.main">
                      Handyperson Services for $100/hr
                    </Typography>
                  </li>
                  <li style={{ paddingBottom: '15px' }}>
                    <Typography variant="paragraphMono" color="blue.main">
                      Home Health Report for $250
                    </Typography>
                  </li>
                  <li style={{ paddingBottom: '15px' }}>
                    <Typography variant="paragraphMono" color="blue.main">
                      Unlimited Access to Pro Referral Network
                    </Typography>
                  </li>
                </ul>
                <Box
                  sx={{
                    width: 'auto',
                  }}
                >
                  <ActionButton
                    title="Get Started for $100/year"
                    sx={{
                      px: '16px',
                    }}
                    onClick={visit(
                      'https://buy.stripe.com/7sI7t90cEeFs62Y001',
                      true
                    )}
                  ></ActionButton>
                </Box>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: '100%', backgroundColor: 'white' }}>
              <Stack
                spacing={2}
                sx={{
                  alignItems: 'center',
                  textAlign: 'center',
                  py: '2.5rem',
                }}
              >
                <Typography variant="paragraphSansLarge" color="blue.main">
                  Home Health Membership
                </Typography>
                <ul style={{ listStyle: 'none', paddingLeft: '0px' }}>
                  <li style={{ paddingBottom: '15px' }}>
                    <Typography variant="paragraphMono" color="blue.main">
                      Handyperson Services for $100/hr
                    </Typography>
                  </li>
                  <li style={{ paddingBottom: '15px' }}>
                    <Typography variant="paragraphMono" color="blue.main">
                      Matriarchy Build Home Health Report
                    </Typography>
                  </li>
                  <li style={{ paddingBottom: '15px' }}>
                    <Typography variant="paragraphMono" color="blue.main">
                      1 Hour Consultation w/ MB General Contractor to Review
                      Home Health Report
                    </Typography>
                  </li>
                  <li style={{ paddingBottom: '15px' }}>
                    <Typography variant="paragraphMono" color="blue.main">
                      Unlimited Access to Pro Referral Network
                    </Typography>
                  </li>
                </ul>
                <Box
                  sx={{
                    width: 'auto',
                  }}
                >
                  <ActionButton
                    title="Get Started for $500/year"
                    sx={{
                      px: '16px',
                    }}
                    onClick={visit(
                      'https://buy.stripe.com/00gbJpaRi68W3UQ28l',
                      true
                    )}
                  ></ActionButton>
                </Box>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MembershipInfos;
