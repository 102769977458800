import { Box } from "@mui/material";
import { useContext, useState, useEffect } from "react";
import { UserContext } from "../../../user-context";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useMediaQuery } from "react-responsive";
import SideBar from "../SideBar";
import { useLocation } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import Footer from "../../../SharedComponents/Footer";
import {
  query,
  collection,
  getFirestore,
  doc,
  getDocs,
  where,
  orderBy,
  limit,
} from "firebase/firestore";

const Users = (props) => {
  const isDesktop = useMediaQuery({ minWidth: 1201 });
  const isMedium = useMediaQuery({ minWidth: 901 });
  const [userType, setUserType] = useState("user");

  const [users, setUsers] = useState([]);

  const db = getFirestore();

  const user = useContext(UserContext);

  const setUserAttributes = async (id) => {
    if (user) {
      user.user
        .getIdTokenResult()
        .then((idTokenResult) => {
          if (idTokenResult.claims.userType === "admin") {
            setUserType("admin");
          } else {
            setUserType("user");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    if (user) {
      setUserAttributes(user.uid);
    }
  }, [user]);

  let queryUsers = [];
  const getUsers = async () => {
    const q = query(collection(db, "customers"));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots

      const user = {
        id: doc.id,
        ...doc.data(),
      };
      queryUsers.push(user);
    });
    setUsers(queryUsers);
  };

  useEffect(() => {
    // Update the document title using the browser API
    getUsers();
  }, []);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
    },
    {
      field: "firstName",
      headerName: "First Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "lastName",
      headerName: "Last Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "stripeId",
      headerName: "Stripe Id",
      flex: 1,
    },
    {
      field: "accessLevel",
      headerName: "Access Level",
      flex: 1,
      renderCell: ({ row: { access } }) => {
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor="cream.main"
            borderRadius="4px"
          >
            {/*access === "admin" && <AdminPanelSettingsOutlinedIcon />}
            {access === "manager" && <SecurityOutlinedIcon />}
            {access === "user" && <LockOpenOutlinedIcon />*/}
            <Typography color={"blue.main"} sx={{ ml: "5px" }}>
              {access}
            </Typography>
          </Box>
        );
      },
    },
  ];

  return (
    <Box width="100%" minHeight="766px" height="auto">
      <Stack
        width="100%"
        minHeight="766px"
        direction={isDesktop ? "row" : undefined}
      >
        <Box width={isDesktop ? "345px" : "100%"}>
          <SideBar type={props.type} />
        </Box>
        {userType === "admin" && (
          <Box width="100%" height="auto" sx={{ mx: "44px" }}>
            <Box width="66%" sx={{ borderBottom: 1, color: "lightOlive.main" }}>
              <Typography
                sx={{
                  whiteSpace: "pre-line",
                  color: "olive.main",
                  mr: "5px",
                  mt: "66px",
                  mb: "8px",
                }}
                variant="h3"
              >
                {"Manage users"}
              </Typography>
            </Box>
            <Box
              m="40px 40px 0 0"
              height="75vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .name-column--cell": {},
                "& .MuiDataGrid-columnHeaders": {
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {},
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: "pink.main",
                  fontFamily: "Code Saver",
                  fontStyle: "normal",
                  fontWeight: "normal",
                  fontSize: "12px",
                  lineHeight: "145%",
                  mb: "40px",
                },
                "& .MuiTablePagination-selectLabel": {
                  fontFamily: "Code Saver",
                },
                "& .MuiSelect-select": {
                  fontFamily: "Code Saver",
                },
                "& .MuiTablePagination-displayedRows": {
                  fontFamily: "Code Saver",
                },
              }}
            >
              <DataGrid
                sx={{
                  fontFamily: "Code Saver",
                  fontStyle: "normal",
                  fontWeight: "normal",
                  fontSize: "12px",
                  lineHeight: "145%",
                  mt: "44px",
                  color: "blue.main",
                }}
                rows={users}
                columns={columns}
              />
            </Box>
          </Box>
        )}
      </Stack>
      <Footer />
    </Box>
  );
};

export default Users;
