import { Box, useTheme } from '@mui/material';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import { ReactComponent as ArrowOlive } from '../../Assets/Images/arrow-blue.svg';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import ArrowOliveSVG from '../../Assets/Images/arrow-olive.svg';
import Typography from '@mui/material/Typography';

const AccordionWrapper = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props}>
    {props.children}
  </MuiAccordion>
))(({ theme }) => ({
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },

  backgroundColor: 'transparent',
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    sx={{ borderBottom: 1, color: 'lightOlive.main' }}
    expandIcon={<ArrowOlive sx={{ fontSize: '0.9rem' }} />}
    {...props}
  >
    {props.children}
  </MuiAccordionSummary>
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
    fontFamily: 'Code Saver',
    color: theme.palette.blue.main,
    fontSize: 16,
    lineHeight: '145%',
  },
}));

const AccordionDetails = styled((props) => (
  <MuiAccordionDetails sx={{ display: 'flex', flexDirection: 'column' }}>
    {props.children}
  </MuiAccordionDetails>
))(({ theme }) => ({
  padding: '16px 16px 16px 24px',
}));

const Accordion = (props) => {
  const [expanded, setExpanded] = React.useState('');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Box
      width="100%"
      height="auto"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        pb: '62px',
      }}
    >
      <Box height="auto" sx={{ pt: '46px' }}>
        {props.children}
      </Box>
    </Box>
  );
};

Accordion.Section = AccordionWrapper;
Accordion.Summary = AccordionSummary;
Accordion.Details = AccordionDetails;

export default Accordion;
