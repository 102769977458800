import { Box, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import Stack from "@mui/material/Stack";
import { useMediaQuery } from "react-responsive";
import ConsultationSummary from "../../../SharedComponents/ConsultationSummary";
import SideBar from "../SideBar";
import Footer from "../../../SharedComponents/Footer";
import Calendar from "../../../SharedComponents/Calendar";
import FilterSelect from "../../../SharedComponents/FilterSelect";
import { query, collection, getFirestore, getDocs } from "firebase/firestore";
import { useLocation } from "react-router-dom";
import { useState, useContext } from "react";
import { UserContext } from "../../../user-context";


const CalendarView = (props) => {
  const isDesktop = useMediaQuery({ minWidth: 1201 });
  const isMedium = useMediaQuery({ minWidth: 901 });

  const location = useLocation();
  const { state } = location;

  const [pros, setPros] = useState([]);

  const db = getFirestore();

  const user = useContext(UserContext);

  
  let queryPros = [];
  const getPros = async () => {
    const q = query(collection(db, "pros"));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {

      const user = {
        id: doc.id,
        ...doc.data(),
      };
      queryPros.push(user);
    });
    setPros(queryPros);
  };

  return (
    <Box width="100%" minHeight="766px">
      <Stack
        width="100%"
        minHeight="766px"
        direction={isDesktop ? "row" : undefined}
      >
        <Box width={isDesktop ? "345px" : "100%"}>
          <SideBar type={props.type} />
        </Box>
        <Box width="100%" sx={{ mx: "44px" }}>
          <Calendar />
        </Box>
      </Stack>
      <Footer />
    </Box>
  );
};

export default CalendarView;
