import { Box, CircularProgress, Switch } from "@mui/material";
import { useContext, useCallback } from "react";
import { UserContext } from "../../../user-context";
import { useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useMediaQuery } from "react-responsive";
import SideBar from "../SideBar";
import Footer from "../../../SharedComponents/Footer";
import ProSelect from "../../../SharedComponents/ProSelect";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { storage } from "../../../App";
import {
  getFirestore,
  doc,
  addDoc,
  setDoc,
  getDoc,
  updateDoc,
  collection,
  query,
  getDocs,
  orderBy,
} from "firebase/firestore";
import DownArrow from "../../../Assets/Images/down-arrow.svg";
import Grid from "@mui/material/Unstable_Grid2/";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { useState, useEffect } from "react";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../App";
import { ActionButton } from "../../../SharedComponents/Buttons";
import { createDateEntries } from "../Pros/EditPro/AccountCreate";
import moment from "moment-timezone";

const textStyles = {
  color: "blue.main",
  fontFamily: "Code Saver",
  fontStyle: "normal",
  fontWeight: "normal",
  fontSize: "14px",
  lineHeight: "17.5px",
};

const Arrow = () => {
  return (
    <Box sx={{ mr: "7px", mt: "2px" }}>
      <img src={DownArrow} />
    </Box>
  );
};

const commonStyles = {
  pl: "4px",
  pt: "4px",
  mb: "12px",
  fontFamily: "Code Saver",
  fontStyle: "normal",
  fontWeight: "normal",
  fontSize: "16px",
  lineHeight: "145%",
  color: "blue.main",
  border: 1,
  borderColor: "lightOlive.main",
};

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 55,
  height: 29,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",

      "& .MuiSwitch-thumb:before": {},
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#F7CBAD",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: "#FBF8EA",
    width: 26,
    height: 26,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,

    borderRadius: 20 / 2,
  },
}));

const StyledTextField = styled(TextField)`

  .MuiOutlinedInput-root  {

    border-radius:0;
    height:33px;
    font-family:'Code Saver';
    color:#2D457C;
    
  },
  
}
`;
const ScheduleDay = (props) => {
  const [available, setAvailable] = useState(false);
  const [startValue, setStartValue] = useState();
  const [endValue, setEndValue] = useState();

  useEffect(() => {
    props.values.forEach((day) => {
      if (day.id === props.day) {
        setAvailable(day.availability.available);
        setStartValue(day.availability.start);
        setEndValue(day.availability.end);
      }
    });
  }, [props.values]);
  const onEnableChange = (value) => {
    setAvailable(value);
    props.setDayEnabled(props.day, value);
  };

  const onStartChange = (value) => {
    setStartValue(value);
    var d = new Date(value);
    d.setSeconds(0, 0);

    props.setDayStartValue(props.day, d.getTime());
  };

  const onEndChange = (value) => {
    setEndValue(value);
    var d = new Date(value);
    d.setSeconds(0, 0);

    props.setDayEndValue(props.day, d.getTime());
  };

  return (
    <Grid
      container
      sx={{
        color: "lightOlive.main",
        mx: "31px",
        pt: "32px",
        mb: "8px",
      }}
    >
      <Grid item xs={2}>
        <Typography
          variant="paragraphMonoSmall"
          sx={{ whiteSpace: "pre-line", color: "olive.main" }}
        >
          {props.day}
        </Typography>
      </Grid>
      <Grid
        item
        xs={2}
        sx={{
          color: "lightOlive.main",
        }}
      >
        <MaterialUISwitch
          checked={available}
          onChange={(event) => {
            onEnableChange(event.target.checked);
          }}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MobileTimePicker
            orientation="landscape"
            disabled={!available}
            minutesStep={30}
            label="From"
            value={startValue}
            onChange={(value) => {
              onStartChange(value);
            }}
            renderInput={(params) => <StyledTextField {...params} />}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} sm={3}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MobileTimePicker
            orientation="landscape"
            disabled={!available}
            minutesStep={30}
            label="Until"
            value={endValue}
            onChange={(value) => {
              onEndChange(value);
            }}
            renderInput={(params) => <StyledTextField {...params} />}
          />
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
};

const timeZones = [
  "America/St_Johns",
  "America/Puerto_Rico",
  "America/New_York",
  "America/Chicago",
  "America/Denver",
  "America/Los_Angeles",
];

const SetSchedule = (props) => {
  const db = getFirestore();
  const isDesktop = useMediaQuery({ minWidth: 1201 });
  const user = useContext(UserContext);
  const [userType, setUserType] = useState("user");
  const [dayAvailability, setDayAvailability] = useState([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [savingError, setSavingError] = useState("");
  const [updated, setUpdated] = useState(false);
  const [firstLoad, setFirstLoad] = useState(false);
  const [pro, setPro] = useState();
  const [timezone, setTimezone] = useState();

  const setUserAttributes = async (id) => {
   
    if (user.loggedIn == true) {
      const proRef = doc(db, `pros/${id}/`);
      const docSnap = await getDoc(proRef);
     
      if (docSnap.exists()) {
        const proData = docSnap.data();

        setUserType("pro");
        handleProSelect({ id: docSnap.id, ...proData });
      } else {
        user.user
          .getIdTokenResult()
          .then((idTokenResult) => {
    
            setUserType(idTokenResult.claims.userType);
            if (idTokenResult.claims.userType === "admin") {
              setUserType("admin");
            } else {
              setUserType("user");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };
  useEffect(() => {
    if (user.loggedIn) {
      setUserAttributes(user.user.uid);
    }
  }, [user]);

  const handleProSelect = useCallback(async (selectedPro) => {

    setFirstLoad(true);
    setLoading(true);

    setTimezone(
      selectedPro.timeZone ? selectedPro.timeZone : "America/New_York"
    );

    setPro(selectedPro);

    const proBookingsRef = collection(db, `pros/${selectedPro.id}/schedule`);

    const q = query(proBookingsRef);
    var querySnapshot = await getDocs(q);

    if (querySnapshot.docs.length === 0) {
      createDateEntries(selectedPro.id);
      querySnapshot = await getDocs(q);
    }
    const days = [];

    querySnapshot.docs.forEach((doc, index) => {

      days.push({ id: doc.id, availability: doc.data() });
    });

    setDayAvailability(days);
    setLoading(false);
  });

  const setDayEnabled = (day, value) => {
    setFirstLoad(false);
    setUpdated(true);

    for (let i = 0; i < dayAvailability.length; i++) {
      if (dayAvailability[i].id == day) {
        dayAvailability[i].availability.available = value;
      }
    }
    setDayAvailability(dayAvailability);
  };

  const setDayStartValue = (day, value) => {
    setFirstLoad(false);
    setUpdated(true);

    var dateNew = moment.tz(value, timezone).toDate();

    for (let i = 0; i < dayAvailability.length; i++) {
      if (dayAvailability[i].id == day) {
        dayAvailability[i].availability.start = dateNew.getTime();
      }
    }
    setDayAvailability(dayAvailability);
  };

  const handleTimezoneChange = (e) => {
    setFirstLoad(false);
    setUpdated(true);
    setTimezone(e.target.value);

    const updateTimezone = httpsCallable(functions, "updateTimezone");

    updateTimezone({
      calendarId: pro.calendarId,

      timeZone: e.target.value,
    })
      .then((result) => {

        updateDoc(doc(db, "pros", pro.id), {
          timeZone: e.target.value,
        })
          .then((result) => {
            //setLoading(false);

          })
          .catch((error) => {
            console.log(error);
            //setLoading(false);
          });
      })
      .catch((error) => {
        //setLoading(false);
        console.log(error);
      });
  };

  const setDayEndValue = (day, value) => {
    setFirstLoad(false);
    setUpdated(true);
    for (let i = 0; i < dayAvailability.length; i++) {
      if (dayAvailability[i].id == day) {
        dayAvailability[i].availability.end = value;
      }
    }
    setDayAvailability(dayAvailability);
  };

  const udpateSchedule = async () => {
    setSavingError("");
    await updateDoc(doc(db, `pros/${pro.id}`), {
      timeZone: timezone,
    });

    dayAvailability.forEach((day) => {
      setDoc(doc(db, `pros/${pro.id}/schedule/${day.id}`), {
        start: day.availability.start,
        end: day.availability.end,
        available: day.availability.available,
      })
        .then((result) => {
          setUpdated(false);

        })
        .catch((error) => {
          console.log(error);
          setSavingError("There was an error saving your schedule");
        });
    });
  };

  return (
    <Box width="100%" minHeight="766px" height="auto">
      <Stack
        width="100%"
        height="auto"
        minHeight="766px"
        direction={isDesktop ? "row" : undefined}
      >
        <Box width={isDesktop ? "345px" : "100%"}>
          <SideBar type={props.type} />
        </Box>
        <Box width="100%" height="auto" sx={{ mx: "44px" }}>
          <Box width="66%" sx={{ borderBottom: 1, color: "lightOlive.main" }}>
            <Typography
              sx={{
                whiteSpace: "pre-line",
                color: "olive.main",
                mr: "5px",
                mt: "66px",
                mb: "8px",
              }}
              variant="h3"
            >
              Create recurring availability
            </Typography>
          </Box>
          {userType === "admin" && (
            <ProSelect handleProSelect={(event) => handleProSelect(event)} />
          )}
          {loading ? (
            <Box
              width="100%"
              height="100px"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box my="15px">
              {pro?.verified && <><Typography
                sx={{
                  whiteSpace: "pre-line",
                  mt: "4px",
                  color: "blue.main",
                }}
                display="inline"
                variant="paragraphMono"
              >
                {"You can visit "}
              </Typography>

              <a href="/my-account/calendar">
                <Typography
                  sx={{
                    whiteSpace: "pre-line",
                    "&:hover": {
                      color: "pink.main",
                    },
                  }}
                  display="inline"
                  variant="paragraphSans"
                  color="blue.main"
                >
                  {"your calendar"}
                </Typography>
              </a>
              <Typography
                sx={{
                  whiteSpace: "pre-line",
                  mt: "4px",
                  color: "blue.main",
                }}
                display="inline"
                variant="paragraphMono"
              >
                {" to further customize your schedule"}
              </Typography></> }
              <Box
                height="auto"
                sx={{
                  
                  mx: "auto",
                  pb: "10px",
                  backgroundColor: "extraLightOlive.main",
                }}
              >
                <Grid
                  container
                  sx={{
                    color: "lightOlive.main",
                    mx: "31px",
                    pt: "15px",
                    mb: "8px",
                    borderBottom: 1,
                  }}
                >
                  <Grid item xs={12} md={3} mdOffset={9}>
                    <FormControl sx={{ width: "100%" }}>
                      <Typography
                        sx={{
                          whiteSpace: "pre-line",
                          mt: "4px",
                          color: "blue.main",
                        }}
                        display="block"
                        variant="paragraphMono"
                      >
                        {"Timezone"}
                      </Typography>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="timezone-checkbox"
                        onChange={handleTimezoneChange}
                        value={timezone}
                        size="small"
                        variant="standard"
                        disableUnderline={true}
                        width="100%"
                        height="31px"
                        sx={{
                          ...commonStyles,
                        }}
                      >
                        {timeZones.map((timeZone) => (
                          <MenuItem key={timeZone} value={timeZone}>
                            <Typography
                              sx={{
                                whiteSpace: "pre-line",
                                my: "3px",
                                color: "blue.main",
                              }}
                              display="block"
                              variant="paragraphMono"
                            >
                              {timeZone}
                            </Typography>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={2}>
                    <Typography
                      variant="paragraphMonoSmall"
                      sx={{ whiteSpace: "pre-line", color: "olive.main" }}
                    >
                      Day
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sx={{
                      color: "lightOlive.main",
                    }}
                  >
                    <Typography
                      variant="paragraphMonoSmall"
                      sx={{ whiteSpace: "pre-line", color: "olive.main" }}
                    >
                      Available
                    </Typography>
                  </Grid>
                </Grid>
                <ScheduleDay
                  day={"Monday"}
                  values={dayAvailability}
                  setDayStartValue={(day, value) =>
                    setDayStartValue(day, value)
                  }
                  setDayEndValue={(day, value) => setDayEndValue(day, value)}
                  setDayEnabled={(day, value) => setDayEnabled(day, value)}
                ></ScheduleDay>
                <ScheduleDay
                  day={"Tuesday"}
                  values={dayAvailability}
                  setDayStartValue={(day, value) =>
                    setDayStartValue(day, value)
                  }
                  setDayEndValue={(day, value) => setDayEndValue(day, value)}
                  setDayEnabled={(day, value) => setDayEnabled(day, value)}
                ></ScheduleDay>
                <ScheduleDay
                  day={"Wednesday"}
                  values={dayAvailability}
                  setDayStartValue={(day, value) =>
                    setDayStartValue(day, value)
                  }
                  setDayEndValue={(day, value) => setDayEndValue(day, value)}
                  setDayEnabled={(day, value) => setDayEnabled(day, value)}
                ></ScheduleDay>
                <ScheduleDay
                  day={"Thursday"}
                  values={dayAvailability}
                  setDayStartValue={(day, value) =>
                    setDayStartValue(day, value)
                  }
                  setDayEndValue={(day, value) => setDayEndValue(day, value)}
                  setDayEnabled={(day, value) => setDayEnabled(day, value)}
                ></ScheduleDay>
                <ScheduleDay
                  day={"Friday"}
                  values={dayAvailability}
                  setDayStartValue={(day, value) =>
                    setDayStartValue(day, value)
                  }
                  setDayEndValue={(day, value) => setDayEndValue(day, value)}
                  setDayEnabled={(day, value) => setDayEnabled(day, value)}
                ></ScheduleDay>
                <ScheduleDay
                  day={"Saturday"}
                  values={dayAvailability}
                  setDayStartValue={(day, value) =>
                    setDayStartValue(day, value)
                  }
                  setDayEndValue={(day, value) => setDayEndValue(day, value)}
                  setDayEnabled={(day, value) => setDayEnabled(day, value)}
                ></ScheduleDay>
                <ScheduleDay
                  day={"Sunday"}
                  values={dayAvailability}
                  setDayStartValue={(day, value) =>
                    setDayStartValue(day, value)
                  }
                  setDayEndValue={(day, value) => setDayEndValue(day, value)}
                  setDayEnabled={(day, value) => setDayEnabled(day, value)}
                ></ScheduleDay>
                {!firstLoad && (
                  <Box width="122px" height="60px" mt="40px" ml="30px">
                    {saving ? (
                      <Box
                        width="100%"
                        height="100px"
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <CircularProgress />{" "}
                      </Box>
                    ) : updated ? (
                      <ActionButton title="Update" onClick={udpateSchedule} />
                    ) : (
                      <Typography
                        height="60px"
                        width="200px"
                        variant="paragraphMono"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          whiteSpace: "pre-line",
                          color: "blue.main",
                        }}
                      >
                        {savingError != "" ? savingError : "Saved"}
                      </Typography>
                    )}
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Stack>

      <Footer />
    </Box>
  );
};

export default SetSchedule;
