import { createSearchParams } from 'react-router-dom';
import { Box, CircularProgress, SxProps, useTheme } from '@mui/material';
import { useState, useEffect, useContext } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2/';
import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { httpsCallable } from 'firebase/functions';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { functions } from '../../../App';
import { ActionButton, LinkButton } from '../../../SharedComponents/Buttons';
import styled from '@emotion/styled';
import { UserContext } from '../../../user-context';
import Input from '@mui/material/Input';
import Checkbox from '@mui/material/Checkbox';
import { useMediaQuery } from 'react-responsive';
import { Field, Formik } from 'formik';
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signInWithRedirect,
} from 'firebase/auth';
import { setDoc, getDocs, query, collection } from 'firebase/firestore';
const timeOptions = {
  hour: '2-digit',
  minute: '2-digit',
};

const commonStyles = {
  pl: '4px',
  pt: '4px',
  mb: '12px',
  fontFamily: 'Code Saver',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '16px',
  lineHeight: '145%',
  color: 'blue.main',

  border: 1,
  borderColor: 'lightOlive.main',
};

const TimeButton = (props) => {
  return (
    <Button {...props} fullWidth disableRipple sx={{ m: 0, p: 0 }}>
      <Box
        height="34px"
        width="100%"
        sx={{
          backgroundColor:
            props.selected.getTime() == props.date.getTime()
              ? 'blue.main'
              : 'pink.main',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '4px',
          color:
            props.selected.getTime() == props.date.getTime()
              ? 'pink.main'
              : 'blue.main',
          '&:hover': {
            backgroundColor: 'lightOlive.main',
            color: 'blue.main',
          },
        }}
      >
        <Box
          width="100%"
          direction="row"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            pt: '4px',
            textAlign: 'center',
          }}
        >
          <Typography
            sx={{
              whiteSpace: 'pre-line',
              mr: '7px',
            }}
            display="block"
            variant="paragraphMonoSmall"
            color="inherit"
          >
            {props.date.toLocaleTimeString('en-us', timeOptions)}
          </Typography>
        </Box>
      </Box>
    </Button>
  );
};

export const StyleWrapper = styled.div`

  .MuiCalendarOrClockPicker-root {

    background-color:#FBF8EA;
  },
  .MuiDayPicker-weekDayLabel {
    color:#2D457C;
    font-family: "BN Nautic Grotesk";
    font-size:20px;
    font-weight:bold;
    text-transform: uppercase;
    background-color:#FBF8EA;
  },
  .MuiPickersDay-root {
   
    color:#2D457C;
    font-family: "Code Saver";
    font-size:14px;
    font-weight:regular;
    text-transform: uppercase;
    background-color:#F7CBAD;
  },
  
  .Mui-disabled {
    color:#808080;
    font-family: "Code Saver";
    font-size:14px;
    font-weight:regular;
    text-transform: uppercase;
    background-color:#FBF8EA;
    
  },
  .Mui-selected{
    color:white;
    font-family: "Code Saver";
    font-size:14px;
    font-weight:regular;
    text-transform: uppercase;
    color:#FBF8EA;
    background-color:#2D457C;
    &:hover { 
      background-color:#2D457C;
    }
  },
  .MuiPickersCalendarHeader-label {
    color:#2D457C;
    font-family: "Code Saver";
    font-size:14px;
    font-weight:regular;
  },
  .MuiPickersCalendarHeader-switchViewButton {

    display: none;
  },
  .MuiDialogActions-root {
    display: none;
  }
}
`;

const StaticDatePickerLandscape = (props) => {
  const [value, setValue] = useState(new Date().setHours(0, 0, 0));
  const [dayIsEnabled, setDayIsEnabled] = useState([]);
  const [defaultDate, setDefaultDate] = useState(new Date());
  const [customSchedule, setCustomSchedule] = useState([]);

  const [availableTimeslots, setAvailableTimeslots] = useState([]);

  const db = getFirestore();

  const isToday = (date) => {
    const today = new Date();
    return (
      date.getDate() == today.getDate() &&
      date.getMonth() == today.getMonth() &&
      date.getFullYear() == today.getFullYear()
    );
  };

  const datesAreOnSameDay = (first, second) =>
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate();

  const disableDay = (date) => {
    const day = date.day();
    var disabled = true;

    if (isToday(new Date(date)) && !props.pro.sameDayAvailability) {
      disabled = true;
    } else if (dayIsEnabled?.length) {
      disabled = !dayIsEnabled[day];
    } else {
      disabled = true;
    }

    customSchedule.forEach((availableTime) => {
      if (
        datesAreOnSameDay(new Date(date), new Date(availableTime.startTime))
      ) {
        disabled = false;
      }
    });

    return disabled;
  };

  const getSchedule = async () => {
    const proBookingsRef = collection(db, `pros/${props.pro.id}/schedule`);

    var q = query(proBookingsRef);
    var querySnapshot = await getDocs(q);
    var dayArray = [false, false, false, false, false, false, false];

    querySnapshot.docs.forEach((doc, index) => {
      if (doc.id === 'Sunday') {
        dayArray[0] = doc.data().available;
      } else if (doc.id === 'Monday') {
        dayArray[1] = doc.data().available;
      } else if (doc.id === 'Tuesday') {
        dayArray[2] = doc.data().available;
      } else if (doc.id === 'Wednesday') {
        dayArray[3] = doc.data().available;
      } else if (doc.id === 'Thursday') {
        dayArray[4] = doc.data().available;
      } else if (doc.id === 'Friday') {
        dayArray[5] = doc.data().available;
      } else if (doc.id === 'Saturday') {
        dayArray[6] = doc.data().available;
      }
      setDayIsEnabled(dayArray);
    });

    const customScheduleRef = collection(
      db,
      `pros/${props.pro.id}/customSchedule`
    );
    q = query(customScheduleRef);

    querySnapshot = await getDocs(q);
    const querySchedule = [];

    querySnapshot.docs.forEach((doc, index) => {
      customSchedule.push(doc.data());
    });

    const today = new Date();

    let tomorrow = new Date();

    tomorrow.setDate(today.getDate() + 1);
    var dayIsSet = false;
    dayArray.forEach((day) => {
      if (!dayIsSet) {
        let day = tomorrow.getDay();
        if (dayArray[day] == true) {
          setDefaultDate(tomorrow);
          dayIsSet = true;
        }
        tomorrow.setDate(tomorrow.getDate() + 1);
      }
    });
  };
  useEffect(() => {
    getSchedule();
  }, []);

  const getAvailableTimeSlots = async (selectedDate) => {
    props.isLoading(true);

    const getFreeBusy = httpsCallable(functions, 'getFreeBusyFromCalendar');

    //var dateStr = "01/20/2023";
    let timeMin = new Date(selectedDate);

    let timeMax = new Date(selectedDate);
    timeMax.setHours(24);

    getFreeBusy({
      timeMin: timeMin.toISOString(),
      timeMax: timeMax.toISOString(),
      calendarId: props.pro.calendarId,
      timeZone: 'CST',
    }).then((result) => {
      const res = result.data;

      const events = Object.values(res.data.calendars);

      // Get today's date
      var todaysDate = new Date();

      if (timeMin.setHours(0, 0, 0, 0) == todaysDate.setHours(0, 0, 0, 0)) {
        if (todaysDate > timeMin) {
          timeMin = todaysDate;
        }
      }

      findFreeSlots(timeMin, events[0]);

      props.isLoading(false);
    });
  };

  const findFreeSlots = async (date, events) => {
    const dayOfWeek = date.toLocaleDateString('en-US', { weekday: 'long' });

    const docRef = doc(db, `pros/${props.pro.id}/schedule`, dayOfWeek);

    const docSnap = await getDoc(docRef);

    var available = docSnap.data().available;

    const startHours = new Date(docSnap.data().start);
    var startTime = new Date(date.setHours(startHours.getHours()));
    startTime.setMinutes(startHours.getMinutes());
    startTime.setDate(date.getDate());

    const endHours = new Date(docSnap.data().end);

    var endTime = new Date(date.setHours(endHours.getHours()));
    endTime.setDate(date.getDate());

    customSchedule.forEach((availableTime) => {
      if (
        datesAreOnSameDay(new Date(date), new Date(availableTime.startTime))
      ) {
        if (available) {
          if (new Date(availableTime.startTime) < startTime) {
            startTime = new Date(availableTime.startTime);
          }
          if (new Date(availableTime.endTime) > endTime) {
            endTime = new Date(availableTime.endTime);
          }
        } else {
          startTime = new Date(availableTime.startTime);
          endTime = new Date(availableTime.endTime);
          available = true;
        }
      }
    });

    //check if within 24 hours
    const today = new Date();

    let tomorrow = new Date();

    tomorrow.setDate(today.getDate() + 1);

    tomorrow.setMinutes(date.getMinutes());

    //by pass check for 24 hours
    if (
      props.pro.sameDayAvailability &&
      startTime.getTime() < tomorrow.getTime()
    ) {
      // if(available hours matter)
      const ONE_HOUR = 60 * 60 * 1000;
      let sameDay = new Date();

      sameDay.setHours(startTime.getHours());
      sameDay.setMinutes(startTime.getMinutes());
      if (sameDay - new Date() < ONE_HOUR) {
        // if it's less than an hour from now, add an hour and round up to the next whole 30 min
        sameDay.setTime(new Date().getTime() + ONE_HOUR);
        let ms = 1000 * 60 * 30; // convert minutes to ms
        sameDay = new Date(Math.ceil(sameDay.getTime() / ms) * ms);
      }
      startTime = sameDay;

      // if available hours don't matter
      // const sameDay = new Date();
      // sameDay.setHours(sameDay.getHours() + 1);

      // let ms = 1000 * 60 * 30; // convert minutes to ms
      // let roundedDate = new Date(Math.ceil(sameDay.getTime() / ms) * ms);
      // startTime = roundedDate;
    } else if (startTime.getTime() < tomorrow.getTime()) {
      startTime = tomorrow;
    }

    if (available) {
      var availableTimeslots = [];

      var currentStartDate = startTime;
      var bufferTime = 5;

      if (props.appointmentDetails.type.duration === 10) {
        bufferTime = 20;
      }
      const appointmentSlotLength =
        Number(props.appointmentDetails.type.duration) + bufferTime;

      var currentEndDate = new Date(
        startTime.getTime() + appointmentSlotLength * 60000
      );

      var conflict = false;
      var pushed = false;

      var lastEventEnd = new Date(
        date.setHours(currentStartDate.getHours() - 1)
      );

      if (events.busy?.length != 0) {
        lastEventEnd = new Date(events.busy[events.busy.length - 1].end);
      }

      while (currentEndDate <= endTime) {
        conflict = false;
        pushed = false;
        if (currentStartDate > lastEventEnd) {
          availableTimeslots.push(currentStartDate);
        } else {
          events.busy.forEach((event) => {
            const eventStart = new Date(event.start);
            const eventEnd = new Date(event.end);

            if (currentStartDate < eventEnd && !conflict && !pushed) {
              if (
                currentStartDate >= eventStart &&
                currentStartDate < eventEnd
              ) {
                conflict = true;
              } else if (
                currentEndDate > eventStart &&
                currentEndDate < eventEnd
              ) {
                conflict = true;
              } else {
                availableTimeslots.push(currentStartDate);

                pushed = true;
              }
            }
          });
        }

        currentStartDate = new Date(
          currentStartDate.getTime() + appointmentSlotLength * 60000
        );

        currentEndDate = new Date(
          currentEndDate.getTime() + appointmentSlotLength * 60000
        );
      }

      setAvailableTimeslots(availableTimeslots);
      props.setAvailableTimeslots(availableTimeslots);
    } else {
      props.setAvailableTimeslots([]);
    }
  };

  return (
    <Box>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StyleWrapper>
          <StaticDatePicker
            orientation="landscape"
            openTo="day"
            disablePast
            value={value}
            defaultValue={defaultDate}
            shouldDisableDate={disableDay}
            showToolbar={false}
            onChange={(newValue) => {
              setValue(newValue);
              getAvailableTimeSlots(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </StyleWrapper>
      </LocalizationProvider>
    </Box>
  );
};
const TimeSelect = (props) => {
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const [availableTimeslots, setAvailableTimeslots] = useState([]);
  const [needsLogin, setNeedsLogin] = useState(false);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [agreedToTos, setAgreedToTos] = useState(false);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [selectedTimeSlot, setSelectedTimeSlot] = useState();
  const [signUp, setSignUp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isSmall = useMediaQuery({ query: '(max-width: 901px)' });
  const [initialLoad, setInitialLoad] = useState(true);
  const provider = new GoogleAuthProvider();

  const signInWithGoogle = () => {
    const auth = getAuth();
    auth.useDeviceLanguage();

    signInWithPopup(auth, provider)
      .then((result) => {
        const db = getFirestore();
        // The signed-in user info.
        const user = result.user;
        setDoc(doc(db, 'customers', result.user.uid), {
          firstName: firstName,
          lastName: lastName,
          email: email,
          signUpDate: new Date(),
        }).then((userDoc) => {});
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  };

  const signUpWithEmail = () => {
    const auth = getAuth();
    auth.useDeviceLanguage();

    if (firstName === '' || lastName === '') {
      setErrorMessage('Please enter first and last name');
      return;
    } else if (agreedToTos === false) {
      setErrorMessage('You must agree to the terms of service');
      return;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      setErrorMessage('Please enter a valid email address');
      return;
    } else if (!password) {
      setErrorMessage('Please create a password');
      return;
    } else {
      setErrorMessage('');
    }
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const db = getFirestore();
        const user = userCredential.user;

        setDoc(doc(db, 'customers', userCredential.user.uid), {
          firstName: firstName,
          lastName: lastName,
          email: email,
          signUpDate: new Date(),
        }).then((userDoc) => {
          //navigate(`/my-account/pros/${userCredential.user.uid}`);
        });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
      });
  };

  const signInWithEmail = (e) => {
    if (email === '') {
      setErrorMessage('Email field cannot be empty');
      return;
    }

    if (password === '') {
      setErrorMessage('Password field cannot be empty');
      return;
    }

    const auth = getAuth();

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;

        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorMessage);
      });
  };

  useEffect(() => {
    if (needsLogin && user.loggedIn) {
      navigate({
        pathname: '/checkout',
        search: `?${createSearchParams({
          date: selectedTimeSlot,
          typeId: props.appointmentDetails.id,
          proId: props.pro.id,
        })}`,
      });
    }
  }, [user]);

  const handleNextClicked = () => {
    if (user.loggedIn) {
      navigate({
        pathname: '/checkout',
        search: `?${createSearchParams({
          date: selectedTimeSlot,
          typeId: props.appointmentDetails.id,
          proId: props.pro.id,
        })}`,
      });
      navigate(0);
    } else {
      setNeedsLogin(true);
    }
  };
  const setSelectedTime = (selected) => {
    setSelectedTimeSlot(selected);
  };

  return (
    <Box
      width="100%"
      height="auto"
      sx={{
        px: '44px',
        py: '42px',

        overflow: 'auto',
        color: 'lightOlive.main',
      }}
    >
      {needsLogin ? (
        signUp ? (
          <Box>
            <Typography
              sx={{
                whiteSpace: 'pre-line',
                color: 'olive.main',
                mb: '20px',
              }}
              display="block"
              variant="h2"
            >
              {'Create Your Account'}
            </Typography>

            <Stack width="100%">
              <Formik
                initialValues={{
                  firstName: '',
                  lastName: '',
                  email: '',
                  password: '',
                  agreedToTOS: false,
                }}
                validate={() => {
                  const errors = {};
                  if (!firstName) {
                    errors.firstName = 'Required';
                  }
                  if (!lastName) {
                    errors.lastName = 'Required';
                  }
                  if (!email) {
                    errors.email = 'Required';
                  } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)
                  ) {
                    errors.email = 'Invalid email address';
                  }
                  if (!password) {
                    errors.password = 'Required';
                  }
                  if (!agreedToTos) {
                    errors.agreedToTos = 'Required';
                  }

                  return errors;
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  /* and other goodies */
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Input
                      type="text"
                      name="firstName"
                      onChange={(e) => setFirstName(e.target.value)}
                      onBlur={handleBlur}
                      value={firstName}
                      disableUnderline
                      fullWidth
                      height="40px"
                      placeholder="First Name"
                      sx={{
                        ...commonStyles,
                      }}
                    ></Input>
                    {errors.firstName && touched.firstName && errors.firstName}
                    <Input
                      type="text"
                      name="lastName"
                      onChange={(e) => setLastName(e.target.value)}
                      onBlur={handleBlur}
                      value={lastName}
                      disableUnderline
                      fullWidth
                      height="40px"
                      placeholder="Last Name"
                      sx={{
                        ...commonStyles,
                      }}
                    ></Input>
                    {errors.lastName && touched.lastName && errors.lastName}
                    <Stack direction="row" mb="20px">
                      <Checkbox
                        type="checkbox"
                        value={agreedToTos}
                        onClick={(e) => {
                          setAgreedToTos(e.target.checked);
                        }}
                        name="agreedToTOS"
                      />
                      {errors.agreedToTOS && errors.agreedTOS}
                      <Typography
                        sx={{
                          whiteSpace: 'pre-line',
                          color: 'blue.main',
                          pl: '5px',
                          pt: '8px',
                        }}
                        display="block"
                        variant="paragraphMono"
                      >
                        {"I agree to Matriarchy Build's"}
                        <a href="/terms-of-use">
                          {' '}
                          <Typography
                            sx={{
                              whiteSpace: 'pre-line',
                              color: 'blue.main',
                            }}
                            display="inline"
                            variant="paragraphMono"
                          >
                            {'Terms of Use'}
                          </Typography>
                        </a>
                      </Typography>
                    </Stack>
                    <ActionButton
                      title="Sign Up With Google"
                      onClick={() => {
                        if (firstName === '' || lastName === '') {
                          setErrorMessage('Please enter first and last name');
                          return;
                        } else if (agreedToTos === false) {
                          setErrorMessage(
                            'You must agree to the terms of service'
                          );
                          return;
                        }
                        signInWithGoogle();
                      }}
                    />
                    <Typography
                      sx={{
                        whiteSpace: 'pre-line',
                        color: 'blue.main',
                        mb: '20px',
                        mt: '22px',
                      }}
                      display="block"
                      variant="paragraphMono"
                    >
                      {'or with email'}
                    </Typography>
                    <Input
                      type="email"
                      name="email"
                      onChange={(e) => setEmail(e.target.value)}
                      onBlur={handleBlur}
                      value={email}
                      disableUnderline
                      fullWidth
                      height="40px"
                      placeholder="Email"
                      sx={{
                        ...commonStyles,
                      }}
                    ></Input>
                    {errors.email && touched.email && errors.email}
                    <Input
                      type="password"
                      name="password"
                      onChange={(e) => setPassword(e.target.value)}
                      onBlur={handleBlur}
                      value={password}
                      disableUnderline
                      fullWidth
                      height="40px"
                      placeholder="Password"
                      sx={{
                        ...commonStyles,
                      }}
                    ></Input>
                    {errors.password && touched.password && errors.password}

                    <ActionButton
                      title="Create Account"
                      onClick={signUpWithEmail}
                    />
                    <Typography
                      sx={{
                        whiteSpace: 'pre-line',
                        color: 'blue.main',
                        mb: '20px',
                        mt: '22px',
                      }}
                      display="block"
                      variant="paragraphMono"
                    >
                      {errorMessage}
                    </Typography>
                  </form>
                )}
              </Formik>
            </Stack>
          </Box>
        ) : (
          <Box>
            <Typography
              sx={{
                whiteSpace: 'pre-line',
                color: 'olive.main',
                mb: '20px',
              }}
              display="block"
              variant="h2"
            >
              {'Sign In'}
            </Typography>
            <ActionButton
              title="Sign In With Google"
              onClick={signInWithGoogle}
            />
            <Stack width="100%" mt="24px">
              <Typography
                sx={{
                  whiteSpace: 'pre-line',
                  color: 'blue.main',
                  mb: '20px',
                }}
                display="block"
                variant="paragraphMono"
              >
                {'or with email'}
              </Typography>
              <Input
                type="text"
                disableUnderline
                fullWidth
                height="40px"
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
                sx={{
                  ...commonStyles,
                }}
              ></Input>
              <Input
                type="password"
                disableUnderline
                fullWidth
                height="40px"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                sx={{
                  ...commonStyles,
                }}
              ></Input>

              <Stack direction="row" mb="20px">
                <Checkbox />
                <Typography
                  sx={{
                    whiteSpace: 'pre-line',
                    color: 'blue.main',
                    pt: '9px',
                  }}
                  display="block"
                  variant="paragraphMono"
                >
                  {'Remember me'}
                </Typography>
              </Stack>
              <a href="/forgot-password" style={{ marginBottom: '20px' }}>
                <Typography
                  sx={{
                    whiteSpace: 'pre-line',
                    color: 'blue.main',
                  }}
                  display="inline"
                  variant="paragraphMono"
                >
                  {'Forgot your password?'}
                </Typography>
              </a>
              <ActionButton title="Sign In" onClick={signInWithEmail} />
              <Typography
                sx={{
                  whiteSpace: 'pre-line',
                  color: 'olive.main',
                  my: '20px',
                }}
                display="block"
                variant="h2"
              >
                {"Don't have an account? "}
              </Typography>
              <ActionButton title="Sign Up" onClick={() => setSignUp(true)} />
            </Stack>
          </Box>
        )
      ) : (
        <Box>
          <Box
            width="100%"
            sx={{
              overflowY: 'auto',
              borderBottom: 1,
              color: 'lightOlive.main',
            }}
          >
            <Typography
              sx={{
                whiteSpace: 'pre-line',
                color: 'olive.main',

                mb: '8px',
              }}
              variant="h3"
            >
              Book a Consultation
            </Typography>
          </Box>
          <Grid container>
            <Grid
              item
              height="78px"
              xs={12}
              md={6}
              sx={{
                borderRight: isSmall ? 0 : 1,
                color: 'lightOlive.main',
                mb: '8px',
              }}
            >
              <Stack sx={{ py: '14px' }}>
                <Typography
                  sx={{ whiteSpace: 'pre-line', color: 'blue.main' }}
                  variant="paragraphMono"
                >
                  {`${props.appointmentDetails.type.label} ${props.appointmentDetails.type.description} - ${props.appointmentDetails.type.duration} mins`}
                </Typography>
                <Typography
                  sx={{ whiteSpace: 'pre-line', color: 'blue.main' }}
                  variant="paragraphMono"
                >
                  {`with ${props.pro.firstName} ${props.pro.lastName} ${
                    isSmall ? ` - $${props.appointmentDetails.type.price}` : ''
                  }`}
                </Typography>
              </Stack>
            </Grid>
            {!isSmall && (
              <Grid
                item
                height="78px"
                md={6}
                sx={{
                  color: 'olive.main',
                  pl: '19px',
                  mb: '8px',
                }}
              >
                <Stack sx={{ py: '14px' }}>
                  <Typography
                    sx={{ whiteSpace: 'pre-line', color: 'blue.main' }}
                    variant="paragraphMono"
                  >
                    {`${props.appointmentDetails.type.duration} mins`}
                  </Typography>
                  <Typography
                    sx={{ whiteSpace: 'pre-line', color: 'blue.main' }}
                    variant="paragraphMono"
                  >
                    {`$${props.appointmentDetails.type.price}`}
                  </Typography>
                </Stack>
              </Grid>
            )}
          </Grid>
          <Grid container sx={{ backgroundColor: 'cream.main' }}>
            <Grid item xs={12} md={7}>
              <StaticDatePickerLandscape
                appointmentDetails={props.appointmentDetails}
                pro={props.pro}
                setAvailableTimeslots={(availableTimeslots) => {
                  setInitialLoad(false);
                  setAvailableTimeslots(availableTimeslots);
                }}
                isLoading={(loading) => setIsLoading(loading)}
              />
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
              sx={{
                px: isSmall ? '20px' : undefined,
                pt: '20px',
                backgroundColor: 'cream.main',
              }}
            >
              {isLoading ? (
                <Box
                  height="100%"
                  minWidth="200px"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <Box minWidth="200px">
                  {availableTimeslots.length ? (
                    <Grid
                      mb="20px"
                      maxHeight={isSmall ? undefined : '300px'}
                      container
                      spacing={1}
                      sx={{ overflow: 'auto' }}
                    >
                      {availableTimeslots.map((timeSlot, index) => {
                        return (
                          <Grid key={index} item xs={6}>
                            <TimeButton
                              onClick={() =>
                                setSelectedTime(new Date(timeSlot))
                              }
                              selected={new Date(selectedTimeSlot)}
                              date={new Date(timeSlot)}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  ) : (
                    <Box
                      height="100%"
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        sx={{ whiteSpace: 'pre-line', color: 'blue.main' }}
                        variant="paragraphMono"
                      >
                        {!initialLoad && 'No Availability'}
                      </Typography>
                    </Box>
                  )}
                </Box>
              )}
            </Grid>
          </Grid>
          {selectedTimeSlot && (
            <Grid mt="10px" container>
              <Grid item xs={12} sm={2} smOffset={10}>
                <ActionButton
                  title="Next"
                  onClick={handleNextClicked}
                  //linkTo="/checkout"
                />
              </Grid>
            </Grid>
          )}
        </Box>
      )}
    </Box>
  );
};

export default TimeSelect;
