import { Box, useTheme } from '@mui/material';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import { ReactComponent as ArrowOlive } from '../../../Assets/Images/arrow-blue.svg';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import ArrowOliveSVG from '../../../Assets/Images/arrow-olive.svg';
import Typography from '@mui/material/Typography';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    sx={{ borderBottom: 1, color: 'lightOlive.main' }}
    expandIcon={<ArrowOlive sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: '16px 16px 16px 24px',
}));

const GeneralFAQs = (props) => {
  const [expanded, setExpanded] = React.useState('');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Box
      width="100%"
      height="auto"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'white',
        pb: '62px',
      }}
    >
      <Box
        width="664px"
        height="auto"
        sx={{ pt: props.noTitle ? '46px' : '86px' }}
      >
        {!props.noTitle && (
          <Typography
            sx={{
              mt: '5px',
              ml: '50px',
              mb: '10px',
              whiteSpace: 'pre-line',
            }}
            display="block"
            variant="h1"
            color="olive.main"
          >
            FAQ
          </Typography>
        )}

        <Accordion
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography variant="paragraphMono" color="blue.main">
              What topics can I cover in a consultation with a Pro?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="paragraphSans" color="blue.main">
              <ul style={{ padding: '0 0 0 16px' }}>
                <Typography variant="paragraphSans" color="blue.main">
                  <li style={{ fontSize: '20px' }}>
                    Construction process and project management
                  </li>
                  <li style={{ fontSize: '20px' }}>
                    Remodel guidance and second opinions (vetting quotes,
                    timeline & budget questions)
                  </li>
                  <li style={{ fontSize: '20px' }}>
                    Architectural guidance (review of drafted plans, layout
                    advice, questions on material specifications)
                  </li>
                  <li style={{ fontSize: '20px' }}>
                    Old house renovation tips (buying an old house, guidance for
                    updating an old house)
                  </li>
                  <li style={{ fontSize: '20px' }}>
                    Materials selection (painting questions, tile questions,
                    flooring questions, etc)
                  </li>
                  <li style={{ fontSize: '20px' }}>
                    Wall treatments (questions and guidance on painting/plaster,
                    how to install wallpaper)
                  </li>
                  <li style={{ fontSize: '20px' }}>
                    Plumbing projects (how to change faucet, unclog a toilet,
                    etc)
                  </li>
                  <li style={{ fontSize: '20px' }}>
                    Carpentry projects (how to refinish kitchen cabinets, custom
                    cabinetry, basic woodworking)
                  </li>
                  <li style={{ fontSize: '20px' }}>
                    Basic electric projects (how to change a light fixture, add
                    dimmers)
                  </li>
                  <li style={{ fontSize: '20px' }}>
                    DIY Decor (how to flip furniture, hang a gallery wall, IKEA
                    hacks, etc)
                  </li>
                  <li style={{ fontSize: '20px' }}>
                    General handyperson (how to change hardware, change door
                    hinges, hang shelves)
                  </li>
                  <li style={{ fontSize: '20px' }}>
                    Tilework and installation (how to tile backsplash, how to
                    tile bathroom floor)
                  </li>
                </Typography>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel2'}
          onChange={handleChange('panel2')}
        >
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
            <Typography variant="paragraphMono" color="blue.main">
              How do I choose the right Pro for me?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="paragraphSans" color="blue.main">
              Our Pros are handpicked across a variety of licensed trades and
              DIY skill sets. You’ll find general contractors, plumbers,
              electricians, carpenters, handypeople, and DIYers. You are able to
              sort and filter Pros by trade or project type. Still not sure who
              you want to hire? You can always email us at
              <a href="mailto:support@matriarchybuild.com">
                <Typography
                  sx={{
                    whiteSpace: 'pre-line',
                    textColor: 'pink.main',
                    '&:hover': {
                      color: 'lightOlive.main',
                    },
                  }}
                  display="inline"
                  variant="paragraphSans"
                  color="pink.main"
                >
                  {' support@matriarchybuild.com '}
                </Typography>
              </a>
              with a description of your project and/or questions and we’ll be
              happy to point you in the right direction.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel3'}
          onChange={handleChange('panel3')}
        >
          <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
            <Typography variant="paragraphMono" color="blue.main">
              What should I prepare for my consulation?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="paragraphSans" color="blue.main">
              We strongly recommend completing your pre-consultation
              questionnaire and submitting all relevant photos to your Pro prior
              to your session with ample time for them to review it. Depending
              on your project type, measurements, tools, drawings or images
              might be useful. If you plan to execute your project during your
              consultation, be sure to have your full toolbox and all of your
              materials handy. It’s always helpful to have access to the area or
              project you are working on from your video device during the
              consultation. Make sure that area is well lit. Be sure to have
              note taking material handy. Most of all, ensure you have a strong
              internet signal for your video consultation.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel4'}
          onChange={handleChange('panel4')}
        >
          <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
            <Typography variant="paragraphMono" color="blue.main">
              Is there anything that can't be covered in a Pro consultation?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="paragraphSans" color="blue.main">
              Here are some specific examples of projects that can’t be covered
              in a Pro consultation.
              <ul>
                <li>
                  Plumbers can’t provide DIY advice on how to execute complex
                  plumbing over video, but Pros can advise on who to call and
                  what to expect in these situations. Examples are: removing
                  tree roots in pipes, or repairing backed up sewer line.
                </li>
                <li>
                  Electricians can’t provide DIY advice on how to execute
                  complex electrical work over video, but Pros can advise on who
                  to call and what to expect in these situations. Examples are:
                  re-wiring in walls, ungrounded outlets, malfunctioning
                  switches.
                </li>
                <li>
                  Location-specific ordinances aren’t suitable for Pro
                  consultations. Examples are: building or construction
                  limitations imposed by your community, city or state;
                  floodplain laws and restrictions; weather-specific or
                  terrain/soil-specific building requirements.
                </li>
                <li>
                  Structural assessments aren’t suitable for Pro consultations.
                  Examples are: whether structural elements are sound;
                  foundation interventions; negative drainage issues.
                </li>
                <li>In person support or tangible deliverables.</li>
                <li>
                  Pros are unable to provide exact dimensions or measurements
                  that are specific to your site/project.
                </li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel9'}
          onChange={handleChange('panel9')}
        >
          <AccordionSummary aria-controls="panel9d-content" id="panel9d-header">
            <Typography variant="paragraphMono" color="blue.main">
              What if I need more time during my consultation
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="paragraphSans" color="blue.main">
              Consultations are limited to the timeframe that they were
              originally booked for and can’t be extended. If you need more
              time, book a fresh session with your Pro.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel12'}
          onChange={handleChange('panel12')}
        >
          <AccordionSummary
            aria-controls="panel12d-content"
            id="panel12d-header"
          >
            <Typography variant="paragraphMono" color="blue.main">
              What's the soonest I can meet with a Pro?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="paragraphSans" color="blue.main">
              You are able to book sessions until 24 hours before that session
              is scheduled to begin. Sessions scheduled within 48 hours of the
              start time run a greater risk of needing to be rescheduled.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel15'}
          onChange={handleChange('panel15')}
        >
          <AccordionSummary
            aria-controls="panel15d-content"
            id="panel15d-header"
          >
            <Typography variant="paragraphMono" color="blue.main">
              What time zone are appointments scheduled in?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="paragraphSans" color="blue.main">
              When you select a Pro, and click on “Book a Time,” you'll see your
              own timezone displayed on the upper right hand corner of the
              calendar. All meeting times will be displayed in your local
              timezone.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === 'panel18'}
          onChange={handleChange('panel18')}
        >
          <AccordionSummary
            aria-controls="panel18d-content"
            id="panel18d-header"
          >
            <Typography variant="paragraphMono" color="blue.main">
              Who participates in the Matriarchy Build community?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="paragraphSans" color="blue.main">
              Matriarchy Build is built by women, and built for everyone. We
              take pride in our female-first perspective, but we also invite
              male and non-binary folks to participate in our community. Our
              community reveres and is made stronger by inclusivity and
              diversity. No tool know-how is required to participate: all levels
              of knowledge and experience are welcome. We have a firm “positive
              vibes, no mansplaining” policy.
            </Typography>
          </AccordionDetails>
        </Accordion>
        {props.large && (
          <>
            <Accordion
              expanded={expanded === 'panel5'}
              onChange={handleChange('panel5')}
            >
              <AccordionSummary
                aria-controls="panel5d-content"
                id="panel5d-header"
              >
                <Typography variant="paragraphMono" color="blue.main">
                  Tackling a Plumbing Question? Here's how to prepare.
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="paragraphSans" color="blue.main">
                  <p>
                    Be sure to chat with your Pro about the location of your
                    water lines and turn off water to the device you’re working
                    on.{' '}
                  </p>
                  <p>
                    Images of the area you are going to work on are important.
                    Here are some ways to prep for specific project examples:{' '}
                  </p>
                  <p>
                    Changing your faucet: images of the underside of your sink,
                    including any other pipes or items (like a disposal) that
                    might be close to what you are working on are also helpful.
                    Be certain that your new faucet has the same number of holes
                    as your original or is otherwise compatible.
                  </p>
                  <p>
                    Fixing a leaky toilet: note when and under what
                    circumstances the leak is occurring. Images of the area
                    leaking water are also helpful.
                  </p>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === 'panel6'}
              onChange={handleChange('panel6')}
            >
              <AccordionSummary
                aria-controls="panel6d-content"
                id="panel6d-header"
              >
                <Typography variant="paragraphMono" color="blue.main">
                  Tackling an Electrical Question? Here's how to prepare.
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="paragraphSans" color="blue.main">
                  Be certain to know where your breaker box is. The first step
                  in your session will be ensuring there is no electrical
                  current running through the switch/outlet/light/etc. that you
                  are working on. Check, double check, triple check that your
                  power is off by flipping on a light switch or plugging in a
                  lamp. Even better, use an electrical tester to ensure no
                  current is running in your work area. Have work gloves on
                  hand.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === 'panel7'}
              onChange={handleChange('panel7')}
            >
              <AccordionSummary
                aria-controls="panel7d-content"
                id="panel7d-header"
              >
                <Typography variant="paragraphMono" color="blue.main">
                  How do you vet Matriarchy Build Pros?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="paragraphSans" color="blue.main">
                  <p>
                    Our philosophy on Pros: positive vibes; no mansplaining.
                  </p>
                  Before we onboard Pros, we hold a 30 minute video interview to
                  ensure Pros uphold the Matriarchy Build values of creating a
                  safe space without judgment or intimidation. We survey their
                  online reputation and review their portfolio work. We check
                  all licenses and credentials (where applicable.) And we also
                  reach out to professional references.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === 'panel8'}
              onChange={handleChange('panel8')}
            >
              <AccordionSummary
                aria-controls="panel8d-content"
                id="panel8d-header"
              >
                <Typography variant="paragraphMono" color="blue.main">
                  Do you offer gift cards for consultations?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="paragraphSans" color="blue.main">
                  Yes!{' '}
                  <a href="mailto:support@matriarchybuild.com">
                    <Typography
                      sx={{
                        whiteSpace: 'pre-line',
                        textColor: 'pink.main',
                        '&:hover': {
                          color: 'lightOlive.main',
                        },
                      }}
                      display="inline"
                      variant="paragraphSans"
                      color="pink.main"
                    >
                      {' Click here to buy a gift card '}
                    </Typography>
                  </a>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Box>
              <Accordion
                expanded={expanded === 'panel10'}
                onChange={handleChange('panel10')}
              >
                <AccordionSummary
                  aria-controls="panel10d-content"
                  id="panel10d-header"
                >
                  <Typography variant="paragraphMono" color="blue.main">
                    What type of device should I use for the consultation
                    (Desktop, Laptop, Tablet, Mobile)?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="paragraphSans" color="blue.main">
                    We strongly recommend using a desktop or laptop for your
                    consultation to seamlessly utilize all of the features that
                    will help optimize your video consultation experience. For
                    some projects, it might be helpful to be able to move with
                    your device in order to show your Pro exactly what you are
                    working on, so keep that in mind when selecting your device.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === 'panel11'}
                onChange={handleChange('panel11')}
              >
                <AccordionSummary
                  aria-controls="panel11d-content"
                  id="panel11d-header"
                >
                  <Typography variant="paragraphMono" color="blue.main">
                    Can I take a consultation from my phone?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="paragraphSans" color="blue.main">
                    Voice-only consultations are possible, but we strongly
                    encourage you to enable video functionality for your
                    consultation to get the most from your session. If a Pro is
                    unable to support you during your call because they are
                    unable to see what you are working on, Matriarchy Build will
                    not be able to refund your consultation.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === 'panel13'}
                onChange={handleChange('panel13')}
              >
                <AccordionSummary
                  aria-controls="panel13d-content"
                  id="panel13d-header"
                >
                  <Typography variant="paragraphMono" color="blue.main">
                    What is your policy to reschedule or cancel a consultation
                    with a Pro?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="paragraphSans" color="blue.main">
                    Consultations can be rescheduled or canceled until 48 hours
                    before your meeting time. Please email us at{' '}
                    <a href="mailto:support@matriarchybuild.com">
                      <Typography
                        sx={{
                          whiteSpace: 'pre-line',
                          textColor: 'pink.main',
                          '&:hover': {
                            color: 'lightOlive.main',
                          },
                        }}
                        display="inline"
                        variant="paragraphSans"
                        color="pink.main"
                      >
                        {' support@matriarchybuild.com '}
                      </Typography>
                    </a>
                    to cancel your appointment. If you need to reschedule an
                    appointment with a Pro, just cancel your session and rebook
                    with your Pro at another time. Cancellations or reschedules
                    with fewer than 48 hours notice will not be refunded.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === 'panel14'}
                onChange={handleChange('panel14')}
              >
                <AccordionSummary
                  aria-controls="panel14d-content"
                  id="panel14d-header"
                >
                  <Typography variant="paragraphMono" color="blue.main">
                    What if my Pro needs to cancel or is late for our
                    consultation?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="paragraphSans" color="blue.main">
                    If your Pro has to cancel your consultation, Matriarchy
                    Build will make every effort to advise you ahead of time and
                    rebook your session. Last minute cancellations will be
                    refunded. If your Pro is late for a consultation, please
                    contact us and we will take the necessary measures to fix
                    the situation.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === 'panel16'}
                onChange={handleChange('panel16')}
              >
                <AccordionSummary
                  aria-controls="panel16d-content"
                  id="panel16d-header"
                >
                  <Typography variant="paragraphMono" color="blue.main">
                    What if I'm unhappy with my consultation?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="paragraphSans" color="blue.main">
                    We want you to have a great experience with our Pros and
                    leave feeling empowered and ready to tackle your project. We
                    cannot guarantee that you’ll be happy with the advice you
                    receive from your Pro and we cannot take responsibility for
                    the results of taking that advice. Therefore, we cannot
                    offer refunds based on dissatisfaction that arises out of
                    advice given. Please take the time to provide feedback of
                    your Pro after your consultation or contact us to discuss
                    issues that may require further review.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === 'panel17'}
                onChange={handleChange('panel17')}
              >
                <AccordionSummary
                  aria-controls="panel17d-content"
                  id="panel17d-header"
                >
                  <Typography variant="paragraphMono" color="blue.main">
                    What if I need more time during my consultation?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="paragraphSans" color="blue.main">
                    Consultations are limited to the timeframe that they were
                    originally booked for and can’t be extended. If you need
                    more time, book a fresh session with your Pro.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
          </>
        )}
        {!props.large && (
          <Link to="/faq">
            <Box
              height="58px"
              width="100%"
              sx={{
                backgroundColor: 'pink.main',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'lightOlive.main',
                border: 1,
                mt: '15px',
                '&:hover': {
                  backgroundColor: 'lightOlive.main',
                },
              }}
            >
              <Stack
                width="100%"
                direction="row"
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  pt: '4px',
                  textAlign: 'center',
                }}
              >
                <Typography
                  sx={{
                    whiteSpace: 'pre-line',
                    mr: '7px',
                  }}
                  display="block"
                  variant="paragraphMono"
                  color="olive.main"
                >
                  {'View All'}
                </Typography>
                <img src={ArrowOliveSVG} />
              </Stack>
            </Box>
          </Link>
        )}
      </Box>
    </Box>
  );
};

export default GeneralFAQs;
