import { Box, useTheme } from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React from "react";
import { blueBox } from "../../../../../theme";
import LongArrow from "../../../../../Assets/Images/long-arrow.svg";

const HowItWorksStaticCardMobile = (props) => {
  const step = props.step;

  return (
    <React.Fragment>
      <Box
        width="100%"
        height="auto"
        sx={{
          border: 1,
          borderRight: "0",
          borderColor: "blue.main",
          mb:"20px"
        }}
      >
        <Stack sx={{}}>
          <Stack
            direction="row"
            height="43px"
            width="100%"
            spacing={2}
            sx={{
              ...blueBox,
              borderTop: 0,
              borderLeft: 0,
            }}
          >
            <Typography
              sx={{
                whiteSpace: "pre-line",
                textColor: "blue.main",
              }}
              display="block"
              variant="paragraphMono"
              color="blue.main"
            >
              {step == 1 && "Step 1"}
              {step == 2 && "Step 2"}
              {step == 3 && "Step 3"}
              {step == 4 && "Step 4"}
            </Typography>

            <img src={LongArrow} />
          </Stack>
          <Box
            height="59px"
            width="100%"
            sx={{
              borderBottom: 1,
              borderRight: 1,
              borderColor: "blue.main",
            }}
          >
            <Typography
              sx={{
                whiteSpace: "pre-line",
                textColor: "blue.main",
                ml: "15px",
                mt: "16px",
              }}
              display="block"
              variant="h2"
              color="blue.main"
            >
              {props.title}
            </Typography>
          </Box>
        </Stack>
        <Stack direction="row">
          <Box
            height="auto"
            width="100%"
            sx={{
              borderBottom: 0,
              borderRight:1,
              borderColor: "blue.main",
              pb:"22px",
            }}
          >
            <Typography
              sx={{
                whiteSpace: "pre-line",
                textColor: "blue.main",
                mx: "15px",
                mt: "19px",
              }}
              display="block"
              variant="paragraphSans"
              color="blue.main"
            >
              {props.text}
            </Typography>
            <Typography
            sx={{
              whiteSpace: "pre-line",
              textColor: "blue.main",
              mx: "15px",
              mt: "8px",
            }}
            display="block"
            variant="paragraphMono"
            color="blue.main"
          >
            {props.subtitle}
          </Typography>
          </Box>
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default HowItWorksStaticCardMobile;
