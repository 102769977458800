import { Stack, Typography, Box, Grid } from '@mui/material';
import { useMediaQuery } from 'react-responsive';
import paintingWallBackground from '../../../Assets/Images/painting-wall.jpg';
import Yentas from '../../../Assets/Images/gabe-lacey.jpeg';
import GridButton from '../../../SharedComponents/GridButtons';

const MeetUs = () => {
  const isDesktop = useMediaQuery({ minWidth: 1201 });
  const isXs = useMediaQuery({ maxWidth: 900 });
  const isPortrait = useMediaQuery({ orientation: 'portrait' });

  return (
    <Box
      display="flex"
      justifyContent="center"
      sx={{
        zIndex: 0,
        width: '100%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundImage: `url(${paintingWallBackground})`,
        py: isDesktop ? '150px' : '100px',
        px: isPortrait ? '20px' : !isDesktop ? '20px' : undefined,
      }}
    >
      <Grid container sx={{ borderColor: 'blue.main', maxWidth: '1050px' }}>
        <Grid
          item
          lg={6}
          md={6}
          xs={12}
          sx={{
            background: `no-repeat url(${Yentas})`,
            backgroundPosition: 'center',
            border: 1,
            borderRight: isXs ? 1 : 0,
            borderBottom: isXs ? 0 : 1,
            borderColor: 'blue.main',
            display: 'flex',
            justifyContent: 'center',
            backgroundSize: 'cover',
            py: '30px',
            ...(isDesktop ? {} : { minHeight: '450px' }),
            ...(isXs ? { minHeight: '750px' } : {}),
          }}
          order={{ xs: 1, lg: 1 }}
        ></Grid>
        <Grid
          item
          lg={6}
          md={6}
          xs={12}
          sx={{
            display: 'flex',
            border: 1,
            borderColor: 'blue.main',
            backgroundColor: 'white',
          }}
          order={{ xs: 3, lg: 2 }}
        >
          <Stack
            direction="column"
            color="blue.main"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              py: '110px',
              px: isDesktop ? '80px' : '10%',
              textAlign: 'center',
              width: '100%',
            }}
          >
            <Box mx="40px">
              <Typography
                variant="paragraphSansLarge"
                sx={{ fontSize: '40px' }}
              >
                Meet your building yentas.
              </Typography>
            </Box>
            <Box pt="10px" px="10px">
              <Typography
                variant="paragraphMono"
                sx={{ whiteSpace: 'break-spaces' }}
              >
                {
                  "Hi, we're Lacey and Gabriella! Let us match you with a Matriarchy Build-approved General Contractor for your upcoming kitchen, bath, or full home remodel. If we know the right person, we'd love to set you up. We've done the homework so you can kick off your project with confidence!"
                }
              </Typography>
            </Box>
          </Stack>
        </Grid>
        <Grid item lg={6} xs={0} order={{ xs: 0, lg: 3 }}></Grid>
        <GridButton
          textColor="blue"
          backgroundColor="pink"
          hoverTextColor="pink"
          hoverBackgroundColor="blue"
          linkTo="/find-a-builder"
          linkText="Connect me with a builder"
          order={{ xs: 4, lg: 4 }}
        />
      </Grid>
    </Box>
  );
};

export default MeetUs;
