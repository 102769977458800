import { useState, useEffect } from 'react';
import { Box, useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2/';

import FilterSelect from '../../../SharedComponents/FilterSelect';
import { query, collection, getFirestore, getDocs } from 'firebase/firestore';

const Filter = (props) => {
  const [trades, setTrades] = useState([]);
  const [services, setServices] = useState([]);
  const [regions, setRegions] = useState([]);
  const db = getFirestore();

  useEffect(() => {
    getSelectionOptions();
  }, []);
  const getSelectionOptions = async () => {
    var q = query(collection(db, 'Services'));

    var querySnapshot = await getDocs(q);
    var services = [];
    querySnapshot.forEach((doc) => {
      const service = {
        id: doc.id,
        name: doc.data().shortName,
      };
      services.push(service);
    });
    setServices(services);

    q = query(collection(db, 'Trades'));

    querySnapshot = await getDocs(q);

    var trades = [];
    querySnapshot.forEach((doc) => {
      const trade = {
        id: doc.id,
        name: doc.data().displayName,
      };
      trades.push(trade);
    });
    setTrades(trades);

    q = query(collection(db, 'regions'));

    querySnapshot = await getDocs(q);

    var regions = [];
    querySnapshot.forEach((doc) => {
      const region = {
        id: doc.id,
        name: doc.data().displayName,
      };
      regions.push(region);
    });
    setRegions(regions);
  };
  return (
    <Box width="100%" height="auto" maxWidth="1440px" sx={{ mx: 'auto' }}>
      <Stack>
        <Grid
          container
          spacing={2}
          sx={{
            pt: '70px',
            mb: '28px',
          }}
        >
          <Grid item sm={12} md={1}>
            <Typography
              sx={{
                whiteSpace: 'pre-line',
                mr: '17px',
                mt: '20px',
              }}
              display="block"
              variant="paragraphSans"
              color="blue.main"
            >
              {'Filter'}
            </Typography>
          </Grid>
          <Grid item xs={12} md={2}>
            <FilterSelect
              title="Project Type"
              options={services}
              onChange={props.handleServiceChange}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <FilterSelect
              title="Trade"
              options={trades}
              onChange={props.handleTradeChange}
            />
          </Grid>
          {false && (
            <Grid item xs={12} md={2}>
              <FilterSelect title="Price" />
            </Grid>
          )}
          {false && (
            <Grid item xs={12} md={2}>
              <FilterSelect title="Availability" />
            </Grid>
          )}
        </Grid>
      </Stack>
    </Box>
  );
};

export default Filter;
