import { Box } from '@mui/material';
import { useContext, useState, useEffect } from 'react';
import { UserContext } from '../../../user-context';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useMediaQuery } from 'react-responsive';
import SideBar from '../SideBar';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import Footer from '../../../SharedComponents/Footer';
import Input from '@mui/material/Input';
import { debounce } from '@mui/material/utils';

import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import {
  query,
  collection,
  getFirestore,
  doc,
  getDocs,
  onSnapshot,
  updateDoc,
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { LinkButton } from '../../../SharedComponents/Buttons';
import Grid from '@mui/material/Unstable_Grid2/';
import { CheckBox } from '@mui/icons-material';

const commonStyles = {
  pl: '4px',
  pt: '4px',
  mb: '12px',
  mx: 'auto',
  fontFamily: 'Code Saver',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '16px',
  lineHeight: '145%',
  color: 'blue.main',
  width: '50px',
  border: 1,
  borderColor: 'lightOlive.main',
};
const Pros = (props) => {
  const isDesktop = useMediaQuery({ minWidth: 1201 });
  const isMedium = useMediaQuery({ minWidth: 901 });
  const [userType, setUserType] = useState('user');
  const [pros, setPros] = useState([]);

  const db = getFirestore();

  const user = useContext(UserContext);

  const setUserAttributes = async (id) => {
    if (user) {
      user.user
        .getIdTokenResult()
        .then((idTokenResult) => {
          console.log(idTokenResult);
          if (idTokenResult.claims.userType === 'admin') {
            setUserType('admin');
          } else {
            setUserType('user');
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    if (user) {
      setUserAttributes(user.uid);
    }
    console.log(user);
  }, [user]);

  let queryPros = [];
  const getPros = async () => {
    const q = query(collection(db, 'pros'));

    const querySnapshot = await getDocs(q);
    var unsubscribe = onSnapshot(q, (querySnapshot) => {
      let queryPros = [];
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots

        const pro = {
          id: doc.id,
          ...doc.data(),
        };
        queryPros.push(pro);
      });
      setPros(queryPros);
    });

    return () => unsubscribe();
  };

  useEffect(() => {
    if (userType === 'admin') {
      getPros();
    }
  }, [userType]);

  const columns = [
    {
      field: 'firstName',
      headerName: 'First Name',
      flex: 1,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      flex: 1,
      cellClassName: 'name-column--cell',
    },

    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
    },
    {
      field: 'verified',
      headerName: 'Verified',
      flex: 1,
    },

    {
      field: 'featured',
      headerName: 'Featured(max 5)',
      flex: 1,
      renderCell: ({ row: { featured, id } }) => {
        return (
          <Button onClick={() => setFeatured(id, featured)}>
            <Stack
              direction="row"
              width="80px"
              m="0 auto"
              p="5px"
              display="flex"
              justifyContent="center"
              backgroundColor={featured ? 'blue.main' : 'cream.main'}
            >
              <Typography
                variant="paragraphMonoSmall"
                color={featured ? 'cream.main' : 'blue.main'}
              >
                {featured ? 'Featured' : 'Feature'}
              </Typography>
            </Stack>
          </Button>
        );
      },
    },
    {
      field: 'position',
      headerName: 'Position',
      flex: 1,
      renderCell: ({ row: { id, position } }) => {
        return (
          <Input
            name="firstName"
            disableUnderline
            type="text"
            min={0}
            placeholder={position}
            onChange={debounce((e) => handlePositionChange(e, id), 500)}
            fullWidth
            inputProps={{ style: { ...commonStyles } }}
            height="40px"
            sx={{
              ...commonStyles,
            }}
          ></Input>
        );
      },
    },
    {
      field: 'id',
      headerName: 'Id',
      flex: 1,
      renderCell: ({ row: { id } }) => {
        return (
          <Link to={id}>
            <Stack
              direction="row"
              width="80px"
              m="0 auto"
              p="5px"
              display="flex"
              justifyContent="center"
              backgroundColor="cream.main"
            >
              <Typography variant="paragraphMonoSmall" color={'blue.main'}>
                {'Manage'}
              </Typography>
            </Stack>
          </Link>
        );
      },
    },
  ];

  const handlePositionChange = async (e, id) => {
    const proRef = doc(db, 'pros', id);
    await updateDoc(proRef, {
      position: Number(e.target.value),
    });
  };
  const setFeatured = async (id, featured) => {
    const proRef = doc(db, 'pros', id);
    await updateDoc(proRef, {
      featured: !featured,
    });
  };

  return (
    <Box width="100%" minHeight="766px" height="auto">
      <Stack
        width="100%"
        minHeight="766px"
        direction={isDesktop ? 'row' : undefined}
      >
        <Box width={isDesktop ? '345px' : '100%'}>
          <SideBar type={props.type} />
        </Box>
        {userType === 'admin' && (
          <Box width="100%" height="auto" sx={{ mx: '44px' }}>
            <Box width="66%" sx={{ borderBottom: 1, color: 'lightOlive.main' }}>
              <Typography
                sx={{
                  whiteSpace: 'pre-line',
                  color: 'olive.main',
                  mr: '5px',
                  mt: '66px',
                  mb: '8px',
                }}
                variant="h3"
              >
                {'Manage Pros'}
              </Typography>
            </Box>
            <Grid container sx={{ py: '24px' }}>
              <Grid item xs={12} md={3}>
                <LinkButton
                  linkTo="create"
                  arrowColor="blue"
                  title="Add new pro"
                  width="100%"
                />
              </Grid>
            </Grid>
            <Box
              m="40px 40px 0 0"
              height={'800px'}
              sx={{
                '& .MuiDataGrid-root': {
                  border: 'none',
                },
                '& .MuiDataGrid-cell': {
                  borderBottom: 'none',
                },
                '& .name-column--cell': {},
                '& .MuiDataGrid-columnHeaders': {
                  borderBottom: 'none',
                },
                '& .MuiDataGrid-virtualScroller': {},
                '& .MuiDataGrid-footerContainer': {
                  borderTop: 'none',
                  backgroundColor: 'pink.main',
                  fontFamily: 'Code Saver',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: '12px',
                  lineHeight: '145%',
                  mb: '40px',
                },
                '& .MuiTablePagination-selectLabel': {
                  fontFamily: 'Code Saver',
                },
                '& .MuiSelect-select': {
                  fontFamily: 'Code Saver',
                },
                '& .MuiTablePagination-displayedRows': {
                  fontFamily: 'Code Saver',
                },
              }}
            >
              <DataGrid
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'lastName', sort: 'asc' }],
                  },
                }}
                sx={{
                  fontFamily: 'Code Saver',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: '12px',
                  lineHeight: '145%',
                  mt: '44px',
                  color: 'blue.main',
                }}
                rows={pros}
                columns={columns}
              />
            </Box>
          </Box>
        )}
      </Stack>
      <Footer />
    </Box>
  );
};

export default Pros;
