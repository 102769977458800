import { useMediaQuery } from 'react-responsive';

import { getFirestore } from 'firebase/firestore';
import { Box, Grid, Stack, Typography } from '@mui/material';

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Accordion from '../../Accordion';

const commonStyles = {
  variant: 'paragraphSans',
  color: 'blue.main',
  textAlign: 'left',
};

const linkStyles = {
  variant: 'paragraphSans',
  color: 'pink.main',
  sx: {
    cursor: 'pointer',
    '&:hover': {
      color: 'lightOlive.main',
    },
  },
};

const ListItem = ({ children }) => (
  <li>
    <Typography {...commonStyles}>{children}</Typography>
  </li>
);

const DetailFAQs = (props) => {
  const { noTitle } = props;
  const [expanded, setExpanded] = useState('');

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const panels = [
    {
      key: 'panel1',
      summary: 'How do I request a Pro recommendation?',
      details:
        'Just email us anytime you’d like. Let us know what you’re looking for, and we’ll get back to you within 24-48 hours with our recommended Pros.',
    },
    {
      key: 'panel2',
      summary: 'How do I book Maintenance Services?',
      details:
        'Just email us and we’ll schedule your Pro appointment directly with you. We’ll connect you to your Pro for a quick virtual consultation to discuss the details of your project, so your Pro can be prepared to tackle the job most efficiently when they come to your home.',
    },
    {
      key: 'panel4',
      summary: 'What if my project runs over?',
      details:
        'If your project runs over, don’t worry, your Pro won’t leave you hanging. The online consultation in advance of your project will typically go through all the details to ensure you and your Pro are on the same page. If you need more time or have another project, additional time can be scheduled seamlessly and billed directly.',
    },
    {
      key: 'panel5',
      summary: 'Who is responsible for materials?',
      details:
        'Matriarchy Build Home Maintenance Pros will cover basic materials like screws, nails, drywall anchors, caulking – along with bringing basic tools, 8 ft. ladder, power washer, etc. You are responsible for paint, grout, fixtures, or other materials specific to your home.',
    },
    {
      key: 'panel6',
      summary: 'What type of maintenance and handywork can be completed?',
      details:
        'The handyperson services listed above are fairly exhaustive, but don’t hesitate to ask if there’s something specific you’re looking for. Our Home Maintenance Pros do not tackle complicated plumbing or electrical beyond swapping light fixtures, plumbing fixtures, or installing smart devices.',
    },

    {
      key: 'panel7',
      summary: 'How do I get a Home Health Report?',
      details:
        'Both Basic and Maintenance Members have access to our Home Health Report. Just email us and we’ll get you scheduled.',
    },
  ];

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
      }}
    >
      <Grid
        container
        spacing={0}
        sx={{
          pt: noTitle ? '0px' : '86px',
          maxWidth: '664px',
        }}
      >
        {!noTitle && (
          <Grid item xs={12}>
            <Typography
              variant="h1"
              sx={{
                marginLeft: '49px',
                marginBottom: '10px',
              }}
            >
              FAQs
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Accordion>
            {panels.map((p, i) => (
              <Accordion.Section
                key={i}
                expanded={expanded === p.key}
                onChange={handleChange(p.key)}
              >
                <Accordion.Summary>{p.summary}</Accordion.Summary>
                <Accordion.Details>
                  <Typography {...commonStyles}>{p.details}</Typography>
                </Accordion.Details>
              </Accordion.Section>
            ))}
          </Accordion>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DetailFAQs;
