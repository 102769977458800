import ReactDOM from 'react-dom';
import { useState } from 'react';
import styled from '@emotion/styled';
import Switch from '@mui/material/Switch';
import { Box, IconButton, Stack, useTheme } from '@mui/material';
import { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { Formik, Form } from 'formik';
import Input from '@mui/material/Input';
import {
  doc,
  setDoc,
  collection,
  addDoc,
  getFirestore,
  deleteDoc,
} from 'firebase/firestore';
import Grid from '@mui/material/Unstable_Grid2/';
import { ActionButton, LinkButton } from '../../Buttons';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import TextField from '@mui/material/TextField';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../../App';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
const commonStyles = {
  pl: '4px',
  pt: '4px',
  mb: '12px',

  fontFamily: 'Code Saver',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '16px',
  lineHeight: '145%',
  color: 'blue.main',
  border: 1,
  borderColor: 'lightOlive.main',
};
const StyledTextField = styled(TextField)`

  .MuiOutlinedInput-root  {

    border-radius:0;
    height:33px;
    font-family:'Code Saver';
    color:#2D457C;
    
  },
  
}
`;
const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 55,
  height: 29,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',

      '& .MuiSwitch-thumb:before': {},
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#F7CBAD',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#FBF8EA',
    width: 26,
    height: 26,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,

    borderRadius: 20 / 2,
  },
}));

const EventDetail = (props) => {
  const [eventId, setEventId] = useState(props.event?.id);
  const [calendarId, setCalendarId] = useState(props.event?.calendarId);
  const [eventName, setEventName] = useState(props.event?.title);
  const [startTime, setStartTime] = useState(props.event?.start);
  const [endTime, setEndTime] = useState(props.event?.end);
  const [available, setAvailable] = useState(props.event?.available);
  const [cantEdit, setCantEdit] = useState(false);
  const [canSwitch, setCanSwitch] = useState(false);
  useEffect(() => {
    setEventId(props.event?.id);

    setCalendarId(props.calendarId);
    setAvailable(props.event?.available);
    setEventName(props.event?.title);
    setStartTime(props.event?.start);
    setEndTime(props.event?.end);
    if (props.event?.extendedProps?.hangoutLink) {
      setCantEdit(true);
    } else {
      setCantEdit(false);
    }
    if (props.event?.title === 'Adding New Event') {
      setCanSwitch(true);
    } else {
      setCanSwitch(false);
    }
  }, [props.event]);

  const deleteEvent = async () => {
    props.handleLoading(true);
    if (eventName === 'Available') {
      await deleteDoc(doc(db, `pros/${props.proId}/customSchedule/${eventId}`));
    } else {
      const deleteEvent = httpsCallable(functions, 'deleteCalendarEvent');

      const result = await deleteEvent({
        id: eventId,
        calendarId: calendarId,
      });
    }

    props.handleClose();
  };

  const db = getFirestore();

  const addEvent = async () => {
    props.handleLoading(true);
    if (!available) {
      const addEvent = httpsCallable(functions, 'addBlockToCalendar');

      await addEvent({
        calendarId: calendarId,
        eventName: available ? 'Available' : 'Busy',

        conferenceDataVersion: 0,
        startTime: new Date(startTime),
        endTime: new Date(endTime),
      });
    } else {
      const docRef = await addDoc(
        collection(db, `pros/${props.proId}/customSchedule`),
        {
          startTime: new Date(startTime),
          endTime: new Date(endTime),
        }
      );
    }

    props.handleClose();
  };

  return (
    <Box width="100%" height="100vh">
      <Box width="100%" height="auto" sx={{ mx: '44px' }}>
        <Box width="100%" sx={{ borderBottom: 1, color: 'lightOlive.main' }}>
          <Typography
            sx={{
              whiteSpace: 'pre-line',
              color: 'olive.main',
              mr: '5px',
              mt: '40px',
              mb: '8px',
            }}
            variant="h3"
          >
            {eventId ? 'Event Detail' : 'Create Event'}
          </Typography>
        </Box>
        <Stack mb="20px" direction="row">
          <Typography
            pt="20px"
            sx={{
              whiteSpace: 'pre-line',
              my: '3px',
              color: eventName === 'Available' ? 'pink.main' : 'blue.main',
            }}
            display="block"
            variant="paragraphSansLarge"
          >
            {eventName}
          </Typography>
          <Box
            width="100%"
            sx={{ pt: '10px', display: 'flex', justifyContent: 'right' }}
          >
            {eventId ? (
              !cantEdit ? (
                <IconButton disabled={cantEdit} onClick={deleteEvent}>
                  <DeleteIcon fontSize="large" sx={{ color: 'blue.main' }} />
                </IconButton>
              ) : (
                <></>
              )
            ) : (
              <IconButton onClick={props.handleClose}>
                <CancelIcon fontSize="large" sx={{ color: 'blue.main' }} />
              </IconButton>
            )}
          </Box>
        </Stack>

        <Formik
          initialValues={{}}
          validate={() => {
            const errors = {};

            if (!startTime) {
              errors.startTime = 'Required';
            }
            if (!endTime) {
              errors.endTime = 'Required';
            }

            return errors;
          }}
          onSubmit={async () => {
            if (startTime && endTime) {
              if (eventId) {
                if (!cantEdit) {
                  props.handleLoading(true);
                  if (eventName === 'Available') {
                    const docRef = await setDoc(
                      doc(db, `pros/${props.proId}/customSchedule/${eventId}`),
                      {
                        startTime: new Date(startTime).toISOString(),
                        endTime: new Date(endTime).toISOString(),
                      }
                    );
                  } else {
                    const updateEvent = httpsCallable(
                      functions,
                      'updateCalendarEvent'
                    );

                    var start = startTime;
                    var end = endTime;

                    const result = await updateEvent({
                      id: eventId,
                      calendarId: calendarId,
                      eventName: eventName,
                      description: 'Unavailable',
                      conferenceDataVersion: 0,
                      startTime: new Date(startTime),
                      endTime: new Date(endTime),
                    });
                  }
                }
                props.handleClose();
              } else {
                addEvent();
              }
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <Form onSubmit={handleSubmit}>
              {canSwitch && (
                <>
                  <Typography
                    pt="20px"
                    sx={{
                      whiteSpace: 'pre-line',
                      my: '3px',
                      color: 'blue.main',
                    }}
                    display="block"
                    variant="paragraphMono"
                  >
                    {'I will be:'}
                  </Typography>
                  <Stack py="20px" direction="row">
                    <Typography
                      sx={{
                        whiteSpace: 'pre-line',
                        my: '3px',
                        color: !available ? 'blue.main' : 'lightOlive.main',
                      }}
                      display="block"
                      variant={!available ? 'paragraphSans' : 'paragraphMono'}
                    >
                      {'Not Available'}
                    </Typography>

                    <MaterialUISwitch
                      disabled={cantEdit}
                      onChange={(e) => {
                        setEventName(e.target.checked ? 'Available' : 'Busy');
                        setAvailable(e.target.checked);
                      }}
                      checked={available}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <Typography
                      sx={{
                        whiteSpace: 'pre-line',
                        my: '3px',
                        color: available ? 'blue.main' : 'lightOlive.main',
                      }}
                      display="block"
                      variant={available ? 'paragraphSans' : 'paragraphMono'}
                    >
                      {'Available'}
                    </Typography>
                  </Stack>
                  <Typography
                    sx={{
                      whiteSpace: 'pre-line',
                      my: '3px',
                      mb: '20px',
                      color: 'blue.main',
                    }}
                    display="block"
                    variant="paragraphMono"
                  >
                    {'For consultations at this time'}
                  </Typography>{' '}
                </>
              )}
              {cantEdit && (
                <Typography
                  pt="20px"
                  sx={{
                    whiteSpace: 'pre-line',
                    my: '3px',
                    color: 'blue.main',
                  }}
                  display="block"
                  variant="paragraphMono"
                >
                  {'This event cannot be edited'}
                </Typography>
              )}

              {errors.eventName && touched.eventName && errors.eventName}

              <Box mb="20px">
                <Typography
                  sx={{
                    whiteSpace: 'pre-line',
                    my: '3px',
                    color: 'blue.main',
                  }}
                  display="block"
                  variant="paragraphMono"
                >
                  {'Start Time'}
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    disabled={cantEdit}
                    value={startTime}
                    onChange={(value) => setStartTime(value)}
                    renderInput={(params) => <StyledTextField {...params} />}
                  />
                </LocalizationProvider>
              </Box>
              <Box mb="44px">
                <Typography
                  sx={{
                    whiteSpace: 'pre-line',
                    my: '3px',
                    color: 'blue.main',
                  }}
                  display="block"
                  variant="paragraphMono"
                >
                  {'End Time'}
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    disabled={cantEdit}
                    value={endTime}
                    onChange={(value) => setEndTime(value)}
                    renderInput={(params) => <StyledTextField {...params} />}
                  />
                </LocalizationProvider>
              </Box>

              <Grid container>
                <Grid item xs={12}>
                  <ActionButton
                    type="submit"
                    title={cantEdit ? 'Done' : 'Save'}
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default EventDetail;
