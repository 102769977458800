import { Box, Grid, Stack, Typography } from '@mui/material';

import Footer from '../../SharedComponents/Footer';
import MailingList from '../../SharedComponents/MailingList';
import HandypersonServices from './Components/HandypersonServices';
import HomeHealthReport from './Components/HomeHealthReport';
import DetailFAQs from '../../SharedComponents/FAQ/Components/DetailFAQs';
import MembershipInfos from './Components/MembershipInfos';

const commonProps = {
  width: '100%',
  spacing: 0,
  container: true,
  maxWidth: '72.6rem',
  margin: 'auto',
};

const MembershipDetails = () => {
  return (
    <>
      <Box backgroundColor="extraLightOlive.main">
        <Grid {...commonProps}>
          <Stack
            sx={{
              color: 'blue.main',
              paddingTop: '40px',
              paddingBottom: '52px',
              textAlign: 'center',
              paddingX: '5rem',
            }}
          >
            <Grid container spacing="25px">
              <Grid item xs={12}>
                <Typography variant="section_h1" color="blue.main">
                  Membership Details
                </Typography>
              </Grid>
              <Grid item xs={12} lineHeight="33px">
                <Grid container spacing="20px">
                  <Grid item xs={12}>
                    <Typography variant="section_h3" fontSize="20px">
                      We offer a Basic Membership and Home Health Membership.
                      See details below.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Stack>
        </Grid>
      </Box>
      <MembershipInfos />
      <HandypersonServices />
      <HomeHealthReport />
      <DetailFAQs />
      <MailingList />
      <Footer />
    </>
  );
};

export default MembershipDetails;
